import { Injectable } from '@angular/core';
import { GlobalVariable } from 'app/main/globals';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AspRequestDetailsService {
  approveRequest: any;

  constructor(
    private http: HttpClient,
  ) { }


  requestDetails(garageKey, requestId, userKey){
    let paramData = {      
      garageKey: garageKey,
      requestId: requestId,
      userKey: userKey,
      source: "web"
    };

    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/spare/request/details', paramData);
  }

  approveQuote(garageKey, spareRequestItems, userKey) {
    let paramData = {      
      garageKey: garageKey,
      spareRequestItems: spareRequestItems,
      userKey: userKey,
      status: 4,
      source: "web"
    };
    // console.log(paramData)
    // console.log("--------approve quotation----------")
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/spare/request/approve`, paramData);
  }

  submitData(payload: any) {
    const paramData=payload;
    console.log("paramData",JSON.stringify(paramData))
    return this.http.post(`${GlobalVariable. BASE_API_URL_SPARES}/garage/customer/quote/create`, paramData);
}
orderList(userKey, garageKey){
  let paramData = {      
    userKey: userKey,
    garageKey: garageKey,
    source: "web"
  }

  console.log('-----Get Order List-----------');    
  return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/v2/order/getAllOrders`, paramData);

}
submitDataUpdate(payload: any){
  const paramData=payload;
  console.log('-----submitDataUpdate-----------');    
  return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/garage/customer/quote/update`, paramData);

}
searchItems(garageKey,searchWord,variantKey){
  let paramData={
    garageKey:garageKey,
    searchWord:searchWord,
    variantKey:variantKey
  }
  console.log('-----Get Search List-----------');    
  return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/customerQuotationSearchSuggestions`,paramData);
// https://iapi.testpitstop.com/v1.0/pitstopspare/customerQuotationSearchSuggestions'
}
displayLabourList(garageKey: string) {
  // const paramData = { garageKey };
  console.log('-----Get labour List-----------');
  return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}`+'/labours/'+garageKey);
}
editLabour(id,labourId,garageKey,labourName,labourAmount)
{
  let paramData={
    id:id,
    labourId:labourId,
    garageKey:garageKey,
    labourName:labourName,
    labourAmount:labourAmount
  }
  return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/labour/edit`,paramData);
}
addLabourList(garageKey,labourName,labourAmount)
{
  let paramData={
    garageKey:garageKey,
    labourName:labourName,
    labourAmount:labourAmount
  }
  return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/labour/add`,paramData);
}
    getAccessoriesList(){
  
      // console.log(this.paramData);
      console.log('-----Registration Decode-----------');    
      return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}` + '/booking/v1/getServiceList');
    }
    updateCheckIn(payload: any){
      const paramData=payload;
      // console.log(this.paramData);
      console.log('-----updateCheckIn-----------');    
      return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/booking/v1/updateCheckInReport',paramData);
    }
    markAsComplete(payload: any){
      payload.source = 'web';
      return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/garage/quoteItemsStatus`,payload);
    }
}
