import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Routes, RouterModule } from '@angular/router';
import { AspFeedbackComponent } from './asp-feedback.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';


const routes: Routes = [
  {
    path: 'customer-feedback/:id/:status', 
    component: AspFeedbackComponent
  },
];

@NgModule({
  declarations: [
    AspFeedbackComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    HttpClientModule,
    BrowserModule,
    
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    FormsModule,
  ],
  exports: [AspFeedbackComponent],
})
export class AspFeedbackModule { }
