import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspFeedbackService {

  constructor(private http: HttpClient) { }

  submitGarageFeedback(
userMobile: string, rating: number, comments: string, images: string[], status: string, id: string, selectedTags: string[]  ): Observable<any> {
    const jobCardId = id;
    const jobCardStatus = 4; 
    const garageKey = localStorage.getItem('garageKey');
  
    if (!garageKey) {
      console.error('Garage key not found in localStorage');
      return;
    }
  
    const feedbackData = {
      jobCardId: jobCardId,
      garageKey: garageKey,
      rating: rating,
      feedBack: comments,
      jobCardStatus: status, 
      images: images,
      tags: selectedTags
    };
  
    console.log('Submitting Feedback:', feedbackData);
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/booking/v1/garageFeedBack`, feedbackData);
  }
  checkFeedbackExists(jobCardId: string): Observable<any> {
    const garageKey = localStorage.getItem('garageKey');

    if (!garageKey) {
        console.error('Garage key not found in localStorage');
    }

    return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}/booking/v1/feedbackExists/${jobCardId}?garageKey=${garageKey}`);
}


}
