<div class="overlay" [class.visible]="isVisible" *ngIf="!isJobCardDetails" (click)="closeSidebar(false)"></div>
<div [ngClass]="{
  'fullPage': isJobCardDetails
}">
<div *ngIf="((!type && isVisible) || isJobCardDetails)" [ngClass]="{
  'sidebar': !isJobCardDetails,
  'visible': isVisible && !isMobile,
  'isVisibleMobile': isVisible && isMobile
}">
  <!-- Sidebar content -->

   <!-- Quotation Container inside the Sidebar -->
    <div [ngClass]="{
      'quotation-container': !isJobCardDetails,
      'quotation-container1': isJobCardDetails
    }">
        <!-- Header Section -->
        <header class="quotation-header">
                <div [ngClass]="{
                  'back-button': !isJobCardDetails,
                  'back-button1': isJobCardDetails
                }">
                  <i (click)="closeSidebar(false)" *ngIf="!isJobCardDetails" 
                    class="material-icons" 
                    style="color: black; font-weight: bold; cursor: pointer;">
                    arrow_back
                  </i>
                  <div class="title">Customer Quotation</div>
                   <i class="material-icons" style="width: 1%;color: black;cursor: pointer;" (click)="openMenu()">more_vert</i>
                </div>
             </header>
          <div class="page" id="quotationContent">
            <span [ngClass]="{
              'headingDescr': isJobCardDetails}">
                <img src="../../../../assets/images/car-repair.png" alt="car-repait">
                <h3 [ngClass]="isMobile ? 'car-repairMobile' : 'car-repair'">CAR REPAIR QUOTATION</h3>
            </span>
    
        <!-- Garage Info Section -->
         <div class="garage-quote" [ngClass]="{'garage-quoteMobile': isMobile}">
        <div class="garage-info">
          <h2 style="margin: 0px;">{{grageName}}</h2>
          <p>{{data?.shippingAddress?.address}}</p>
          <p>{{data?.shippingAddress?.locality}} {{data?.shippingAddress?.city}},</p>
    
            <p>{{phone}}</p>
            <p>{{email}}</p>
        </div>

        <!-- Quotation Details -->
        <div class="quotation-info">
            <div class="info-item">
                <span  [ngClass]="isMobile ? 'labelMobile' : 'label'">Date:</span>
                <span [ngClass]="isMobile ? 'valueMobile' : 'value'">{{data?.placedOn | date: 'dd-MM-yyyy'}}</span>
            </div>
              <div class="info-item">
                <span [ngClass]="isMobile ? 'labelMobile' : 'label'">Quotation #:</span>
                <span [ngClass]="isMobile ? 'valueMobile' : 'value'">{{ quoteId }}</span>
              </div>
            <div class="info-item" >
                <span [ngClass]="isMobile ? 'labelMobile' : 'label'">Registration Number:</span>

                <span [ngClass]="isMobile ? 'valueMobile' : 'value'">{{data?.registrationNo}}</span>
            </div>
        </div>
    </div>
<div >
  <p class="spare-part-header1">SPARE PARTS</p>
</div>
        <!-- Items Table -->
        <table class="items-table1">
            <thead>
                <tr class="items-table">
                    <th>SPARE PARTS</th>
                    <th>QUANTITY</th>
                    <th>UNIT PRICE</th>
                    <th>AMOUNT</th>
                </tr>
            </thead>
            <tbody>
              <tr *ngFor="let itemData of data?.items">
                <td>{{ itemData.itemName }}</td>
                <td>{{ itemData.quantity }}</td>
                <td>₹ {{ itemData.sellingPrice }}</td>
                <td>₹ {{ itemData.sellingPrice * itemData.quantity }}</td>
              </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="3">TOTAL</td>
                    <td>₹{{calculateTotalAmount()}}</td>
                </tr>
            </tfoot>
        </table>
        <div>
          <p class="spare-part-header1" style="margin-top: 30px;">LABOUR CHARGES</p>
        </div>

        <table class="items-table1">
          <thead>
            <tr class="items-table">
              <td>LABOUR DESCRIPTION</td>
              <td>QUANTITY</td>
              <td>UNIT PRICE</td>
              <td>AMOUNT</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let itemData of data?.labourQuoteItemResponses">
              <td>{{ itemData.labourName }}</td>
              <td>{{ itemData.quantity }}</td>
              <td>₹ {{ itemData.labourAmount }}</td>
              <td>₹ {{ itemData.labourAmount * itemData.quantity }}</td>
            </tr>
          </tbody>
   
        <tfoot>
          <tr>
              <td colspan="3">TOTAL</td>
              <td>₹{{ calculateLabourAmount() }}</td>
          </tr>
      </tfoot>
        </table>

        <div>
          <p class="spare-part-header1" style="margin-top: 30px;">TOTAL AMOUNT</p>
        </div>
        <table class="items-table1">
          <thead>
            <tr class="items-table">
              <td>DESCRIPTION</td>
              <td>AMOUNT</td>
            </tr>
          </thead>
          <tr>
            <td>SPARE PARTS TOTAL</td>
            <td >₹{{calculateTotalAmount()}}</td>
          </tr>
          <tr style="background-color: #e0f7fa;">
            <td>LABOUR CHARGES TOTAL</td>
            <td >₹{{ calculateLabourAmount()}}</td>
          </tr>
   
        <tfoot>
          <tr>
              <td colspan="1">TOTAL</td>
              <td>₹{{ calculateTotalQuoteAmount() }}</td>
          </tr>
      </tfoot>
        </table>

        <!-- Disclaimer Section -->
        <div class="disclaimer">
            <p>Disclaimer: Quoted price is approximate price provided from our source at the time of giving quotation. The actual price may vary during the final billing based on availability and actual system price.</p>
        </div>
    </div>
    </div>
    <div class="overlay" [class.visible]="isBottomSheetOpen" (click)="closeMenu()"></div>
        <mat-bottom-sheet-container *ngIf="isBottomSheetOpen"  [ngClass]="{'centerBottom': isJobCardDetails}">
            <mat-nav-list>
                <mat-list-item>
                    <span class="names" (click)="shareForApproval()">Share For Approval</span>
                    <i class="material" (click)="shareForApproval()">share</i>
                </mat-list-item>
                <mat-divider></mat-divider>
                
                <mat-list-item>
                    <span class="names" (click)="openSidebarQuote1('approve')">Approve Quotation</span>
                    <img src="../../../../assets/images/request-details images/requestdetailsimages.png" style="height: 24px;cursor: pointer;" (click)="openSidebarQuote1('approve')" alt="">
                    
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item (click)="downloadPDF()">
                    <span class="names">Download Quotation</span>
                    <img src="../../../../assets/images/download.png" style="height: 24px;cursor: pointer;"  alt="">
                    
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item>
                    <span class="names" (click)="openSidebarQuote1('edit')">Edit Quotation</span>
                    <i class="material" (click)="openSidebarQuote1('edit')">edit</i>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item (click)="openSidebarQuote1('view')">
                    <span class="names" >View Approved Quotation</span>
                    <i class="material" ></i>
                </mat-list-item>
            </mat-nav-list>
            
        </mat-bottom-sheet-container>
    </div>
</div>

<div class="edits">
<mat-sidenav-container>
    <div class="overlay" [class.visible]="sidebarOpenQuote" (click)="CloseSidebarQuote()" ></div>
    <div  *ngIf="sidebarOpenQuote" [ngClass]="{
      'sidebarJob': isJobCardDetails,
      'sidebar': !isJobCardDetails,
      'sidebar-openQuote': sidebarOpenQuote && !isMobile,
      'sidebar-openQuoteMobile': sidebarOpenQuote && isMobile
    }">
      <mat-list>

      <header>
        <div class="back-button">
           <i (click)="CloseSidebarQuote()" class="material-icons" style="color: black;font-weight: bold; cursor: pointer;">arrow_back</i>
           <span class="title1">Customer Quotation</span>
           <div *ngIf="quoteId">
            <h2 class="quoteid">{{ quoteId }}</h2>
          </div>
        </div>
     </header>
     <div class="detailsQuote">
      <div class="logoModel">
        <img [src]="getMakeImageUrl(requestDetails?.spareRequest?.make)" alt="Icon" class="img">
        <div class="model-info">
          {{requestDetails?.spareRequest}}
          <span class="model-name">{{requestDetails?.spareRequest?.make}}</span>
          <span class="model-details">{{requestDetails?.spareRequest?.model}}, {{requestDetails?.spareRequest?.fuelType}}</span>
        </div>
    </div>
      <mat-divider></mat-divider>

      <div  class="items" style="margin-bottom: 88px;">
        <div class="request-info" style="font-weight: 900;font-size: larger; padding: 10px 0;border-bottom: 1px solid #ddd;">Quote received for {{requestDetails?.spareRequestItemList?.length}} items </div>
        <div class="item" >
          <div class="checkboxselect">
            <input type="checkbox" [checked]="selectAllQuote" (change)="toggleSelectAllQuote()">
            <span style="margin-left: 5px;">Select All</span>
          </div>
          <div class="Total">
            <span style="margin-left: 97px;">₹ {{customerQuotationPrice | number:'1.2-2'}}</span>
            <div style="margin-left: 45px;"> Total Amount  </div>
          </div>
        </div>

        <div class="quotereceived-items" *ngFor="let quote of customerQuotation" [ngClass]="{'border-box': quote.selected}">

          <div class="checkboxselect">
            <div class="lines" >
              <input type="checkbox" [(ngModel)]="quote.selected" (change)="toggleItemSelectionQuote()">
              <span class="part-name" style="margin-left: 5px;">{{quote.itemName}}</span>
              <!-- {{quote.items.itemName}} -->
              <!-- <span class="ellipsis" >⋮</span> -->
              <i class="material-icons" style="cursor: pointer;" (click)="openEditDialog(quote)">edit</i>

              <!-- <div class="overlay1" [class.visible]="isPopupOpenEdit" (click)="closePopupEdit()" ></div>
              <div *ngIf="isPopupOpenEdit" [ngClass]="isMobile ? 'bottom-sheetMobile' : 'bottom-sheet'">
              
                  <header class="back-button">
                  <h3 class="header-name">{{ itemQuote.itemName }}</h3>
                    <i (click)="closePopupEdit()" style="cursor: pointer;">close</i>
                  </header>
                  <div class="bottom-sheet-content">
                  <div>
                    <label>Brand</label>
                    <span >{{ itemQuote?.brand }}</span>
                  </div>
                  <div>
                    <label>Quantity</label>
                    <span>{{ itemQuote?.itemQuantity }}</span>
                  </div>
                  <div>
                    <label>Availability</label>
                    <span>{{ itemQuote?.edt  | date:'dd MMM yyyy, hh:mm a' }}</span>
                  </div>
                  <div>
                </div>
                  <div>
                    <label>Total Amount</label>
                    <span>₹ {{ itemQuote?.mrp * itemQuote?.itemQuantity  | number:'1.2-2'}}</span>
                  </div>
                  <button  (click)="save()">Save Quote</button>
                </div>
                
              </div> -->
              
              
            </div>
            <!-- <input type="checkbox" [(ngModel)]="item.selected" (change)="toggleItemSelection()"> -->
            <!-- <span>{{item.itemName}}</span> -->
            <div class="lines" >
              <span style="margin-left: 5px" class="availability"> Availability: </span>
              <span class="date">₹{{quote.mrp*quote.itemQuantity | number:'1.0-0'}}</span>

            </div>
          </div>
          <div class="lines">
            <span style="margin-left: 5px" class="date-time">{{quote.edt  | date:'dd MMM yyyy'}}</span>
            <!-- <span class="quantity">  </span> -->
            <p style="background-color: black;">hello workd</p>
          </div>
        </div>
       

      </div>
    </div>
    
    <div *ngIf="anyItemSelectedQuote()" [ngClass]="isMobile ? 'bottom-boxQuoteMobile' : 'bottom-boxQuote'">
      <div class="bottom-contentQuote">
        
          <div class="total-items">{{selectedItemsCountQuote}} items
          <p class="total-amount">₹{{totalSelectedPriceQuote | number:'1.0-0'}}</p>
        </div>
          <button type = "button" *ngIf="selectedItemsCountQuote>0 && selectedLabourslength == 0" [ngClass]="isMobile ? 'createQuoteMobile' : 'createQuote'" (click)="addLabour()">Add Labour</button>
          <button type="button" *ngIf="selectedLabourslength > 0" [ngClass]="isMobile ? 'createQuoteMobile' : 'createQuote'" (click)="openPopup()">Create Customer Quote</button>
          
           
      </div>

    </div>
    
    <div *ngIf="viewApprovePopUpOpen" class="popup">
    <div class="popup-content">
    <img src="{{successIcon}}" alt="successIcon" style="width: 36%;">
    <h3 >Work Updated</h3>
   
    <p class="order-label">
      <span class="order-id">JobCard ID {{ jobcardId }}</span>
    </p>
    <p class="date"><span>{{resData?.placedOn | date:'dd-MMM-yyyy, hh:mm a'}}</span></p>
    <p class="thank you">Let Us Deliver an awesome Experience</p>
  
    <button class="goto-order" (click)="labourSideOpen()">Send Update</button>
  </div>
    </div>
    </mat-list>
    

    
    </div>
    <div *ngIf="viewApprovePopUpOpen" class="popup">
      <div class="popup-content">
      <img src="{{successIcon}}" alt="successIcon" style="width: 36%;">
      <h3 >Work Updated</h3>
     
      <p class="order-label">
        <span class="order-id">JobCard ID {{ jobcardId }}</span>
      </p>
      <p class="date"><span>{{resData?.placedOn | date:'dd-MMM-yyyy, hh:mm a'}}</span></p>
      <p class="thank you">Let Us Deliver an awesome Experience</p>
    
      <button class="goto-order" (click)="labourSideOpen()">Send Update</button>
    </div>
      </div>
     <!--
    
     Popup content -->
    
    <div *ngIf="isPopupOpen" class="popup">
      <div class="popup-content">
      <img src="{{successIcon}}" style="width: 36%;" alt="">
      <h3 *ngIf="quoteId === null || quoteId === 'null'">Quote Created</h3>
      <h3 *ngIf="quoteId !== null && quoteId !== 'null'">Quote Updated</h3>
      <p class="order-label" *ngIf="quoteId === null || quoteId === 'null'">
        <span class="order-id">Quotation id {{ resData?.id }}</span>
      </p>
      <p class="order-label" *ngIf="quoteId !== null && quoteId !== 'null'">
        <span class="order-id">Quotation id {{ quoteId }}</span>
      </p>
      <p class="date"><span>{{resData?.placedOn | date:'dd-MMM-yyyy, hh:mm  a': 'GMT+1060'}}</span></p>
      <p>You have successfully Updated quotation</p>
    
      <button class="goto-order" *ngIf="quoteId === null || quoteId === 'null'" (click)="opendetais()">View Updated Quote</button>
      <button class="goto-order" *ngIf="quoteId !== null && quoteId !== 'null'" (click)="opendetais()">View Updated Quote</button>
    </div>
  </div>
  
</mat-sidenav-container>
</div>
<mat-sidenav-container>
  <div class="overlay" [class.visible]="sidebarOpenQuote" (click)="CloseSidebarQuote()" ></div>
  <div *ngIf="sidebarOpenQuote" [ngClass]="{
    'sidebarJob': isJobCardDetails,
      'sidebar': !isJobCardDetails,
    'sidebar-openQuote': sidebarOpenQuote && !isMobile,
    'sidebar-openQuoteMobile': sidebarOpenQuote && isMobile
  }">


<!-- Approving Quote -->
<mat-list *ngIf="type=='approve'">
  <header>
    <div class="back-button">
      <i (click)="CloseSidebarQuote()" style="margin-right: 10px;" class="material-icons-arrowback">arrow_back</i>
      <span class="title1" style="font-size: 18px;">Approve Quotation</span>
      <div *ngIf="quoteId">
        <h2>{{ quoteId }}</h2>
      </div>
    </div>
  </header>
  <div class="detailsQuote">

    <div class="logoModel" *ngIf="isJobCardDetails && !requestId" style="margin-bottom: 0px;">
      <img [src]="getMakeImageUrl(request?.jobCard?.make)" alt="Car Make Image" class="img">
      <div class="model-info">
          <span class="model-name">{{ request?.jobCard?.make || 'Unknown Make' }}</span>
          <span class="model-details">{{ request?.jobCard?.model || 'Unknown Model' }}, {{ request?.jobCard?.fuelType || 'Unknown Fuel Type' }}</span>
          <span class="model-details">{{ request?.jobCard?.registrationNumber || '' }}</span>
          <span class="model-details">{{ request?.jobCard?.vinNumber || '' }}</span>
      </div>
    </div>
    <!-- <mat-divider></mat-divider> -->
  
    <div *ngIf="totalApprovingItemsCount > 0; else noQuotesApproved" class="items" style="margin-bottom: 56px;">
      <!-- Select All -->
      <div class="item" >
        <div class="checkboxselect">
          <input type="checkbox" [checked]="selectAllQuote" (change)="sidebarToggleSelectAllQuote()">
          <span>Select All</span>
          
        </div>
        <div style="text-align: end;">
          <span class="customer-quote-price">₹ {{grandFinalApprovedTotalAmount | number:'1.0-0'}}</span>
          <div class="quote_total_amount">Total Amount</div>
        </div>
      </div>
      <div class="spare-part-header" *ngIf="customerQuotation?.length>0">
      <h2 style="font-weight: bold;">Spare Parts</h2>
      <!-- <p style="margin-top:23px;color:#144579;cursor:pointer;" (click)="AddItems()" *ngIf="totallabourSelectedPrice>0">Add More</p> -->
    </div>

    <div class="quoteapproved-items" style="height: 84px;" *ngFor="let quote of customerQuotation" [ngClass]="{'border-box': quote.selected}">

      <div>
        <div class="approve-lines" style="margin-bottom:0px;">
          <input type="checkbox" [(ngModel)]="quote.selected" (change)="toggleItemApproveSelectionQuote()">
          <span class="part-name" style="width: 75%;margin-left: 5px;">{{quote.itemName}}</span>
          <span *ngIf="quote.quantity" class="spare-mrp" style="text-align: end;float: right;">₹{{quote.sellingPrice * quote.quantity | number:'1.0-0'}}</span> 
          <!-- {{quote.mrp * quote.itemQuantity}} -->
         
          <span *ngIf="quote.itemQuantity" class="spare-mrp" style="text-align: end;">₹{{quote.mrp * quote.itemQuantity | number:'1.0-0'}}</span> 
         
        </div>
      
        <div class="lines" >
          </div>
          <hr style="border: 1px solid #ebebeb;" />
          <div class="quantity-control">
            <div class="brand" style="width: 71%;
            color: #999;
            font-size: 13px;
            margin-top: -14px;">
              {{quote.brand}}
            </div>
            <div class="addtocartcompatiblecar1">
              <div class="spare-add-cart1">
                  <div class="btn-approve-group">
                 
                      <p class="quantity-measure" >{{quote.quantity}} units
                      </p>
                    </div>
              </div>
          </div>
            <!-- <div class="add-minus">
              <button mat-icon-button (click)="decrementjobcardQuantity(quote)">
                <mat-icon style="margin-right: 6px;">remove</mat-icon>
              </button>
              <span style="padding: 7px;margin-left: 12px;">{{ quote.itemQuantity }}</span>
              <button mat-icon-button (click)="incrementjobcardQuantity(quote)">
                <mat-icon>add</mat-icon>
              </button>
          </div> -->
          </div>
    
    </div>
    </div>
     <div class="labour-section-details" style="margin-top: -14px;" *ngIf="selectedItemsLabourQuote?.length>0">
    <div class="labour-header-title" style="    margin-bottom: -4px;">
      <h2 style="font-weight: bold;
      margin: 0;
      padding: 5px 2px;">Labour Charges</h2>
      <!-- <p style="margin-top:23px;color:#144579;cursor:pointer;font-weight: bold;" (click)="addLabourSidebar()" >Add More</p> -->
    </div>
    <!-- Labour Items -->
    <div class="quoteapproved-items" style="height: 84px;" *ngFor="let quote of selectedItemsLabourQuote" [ngClass]="{'border-box': quote.selected}">
<!--       
      <div class="checkboxselect" > -->
      <!-- *ngIf="selectedItemsCount>0"> -->
        <div class="labour-lines1">
          <input type="checkbox" [(ngModel)]="quote.selected" (change)="toggleItemApproveSelectionQuote()">
          <span class="part-name" style="width: 75%;margin-left:5px">{{quote.labourName}}</span>
          <span class="spare-mrp" style="text-align: end; float: right;">₹{{quote.labourAmount | number:'1.0-0'}}</span>
          <!-- <i class="material-icons" (click)="openLabourEditSidebar(quote)">edit</i> -->
        </div>
      <!-- </div> -->
      <div class="lines">
        <span *ngIf="labourItemsCount > 0" class="date-time">{{quote.edt  | date:'dd MMM yyyy'}}</span>
      </div>
      <hr style="border: 1px solid #ebebeb;" />
      <div class="quantity-control">
        <div class="brand" style="width: 71%;
        color: #999;
        font-size: 13px;
        margin-top: -18px;">
          {{quote.brand}}
        </div>
        <div class="addtocartcompatiblecar1">
          <div class="spare-add-cart1">
              <div class="btn-approve-group">
                  <p  class="quantity-measure">
                    {{quote.quantity}} Units
              </p>
                </div>
          </div>
      </div>
        <!-- <div class="add-minus">
          <button mat-icon-button (click)="decrementjobcardQuantity(quote)">
            <mat-icon style="margin-right: 6px;">remove</mat-icon>
          </button>
          <span style="padding: 7px;margin-left: 12px;">{{ quote.itemQuantity }}</span>
          <button mat-icon-button (click)="incrementjobcardQuantity(quote)">
            <mat-icon>add</mat-icon>
          </button>
      </div> -->
      </div>
    </div>
  </div>

</div>
<ng-template #noQuotesApproved>
  <div class="empty-state">
    <div >
      
      <img src="../../../../assets/images/request-details images/requestdetailsimages.png" alt="Quote Not Approved" class="quote-image">
      <div class="message">
        <strong>Quote Has Been Approved!</strong>
        <p>Check Quote Approved Section</p>
      </div>
    </div>
  </div>
  <button class="approveNow" (click)="approveNow()" *ngIf="activeTabs === 'quotation'">APPROVE QUOTE NOW</button>
</ng-template>
</div>
  <!-- <div *ngIf="activeTabs=== 'quotation'" (click)="AddItems()">
    <button class="quotation-bottom-cta">Add Quote Items</button>
  </div> -->

  <!-- Add Item Side Bar -->
  <div *ngIf="isSidebarOpen" class="new-sidebar">
    <div class="header1">
      <div class="maticon" style="margin-left: -14px;">
      <mat-icon (click)="toggleSidebar()">arrow_back</mat-icon>
    </div>
    <div class="add-items">
      <h3 style="text-align: left;">Add Items</h3>
    </div>
    </div>
    <div class="search-bar">
      <input
        type="text"
        placeholder="Search by Part Name"
        [(ngModel)]="searchWord"
        class="search-input"
        (input)="onSearch()"
      />
      <button *ngIf="searchWord" class="clear-btn" (click)="clearSearch()">&#x2715;</button>
    </div>
    
    <div class="item-list">
      <div *ngFor="let item of filteredItems" class="dropdown-item" (click)="addItemToQuotation(item)">
        <img [src]="item.imageUrl" alt="{{ item.partName }}" class="dropdown-image" />
        <div class="dropdown-details" style="width: 100%;">
          <h4>{{ item.name }}</h4>
          <p>Brand: {{ item.brand }}</p>
          <div class="item-price1">
            <p>₹{{item.mrp }}</p>
          </div>
        </div>
      </div>
    </div>
    
    
  </div>
  <!-- Add Labour -->
  <div *ngIf="isAddLabour" class="labour-sidebar">
   <div class="labour-section">
      <div class="labour-maticon" >
        <mat-icon (click)="labourRedirectSidebar()">arrow_back</mat-icon>
      </div>
      <div class="labour-header" >
        <h2>Add Labour</h2>
      </div>
      <div class="labour-skip" >
        <p style="position: relative;float:right;padding:10px;cursor:pointer;color:#144579;"(click)="openSkipConfirmation()">Skip</p>
      </div>
    </div>
    <div class="search-bar">
      <input
        type="text"
        placeholder="Search Labour"
        [(ngModel)]="searchText"
        class="search-input"
        (input)="onLabourSearch()"/>

      <button *ngIf="searchText" class="clear-btn" (click)="clearLabourSearch()">&#x2715;</button>
    </div>
    <div class="item-list1">
    <!-- Labour List -->
    <div *ngFor="let labour of labourListData" class="dropdown-item">
      <!-- {{labourListData|json}} -->
      <div class="checkboxselect">
        <input 
          type="checkbox" 
          [(ngModel)]="labour.selected" 
          (change)="onLabourSelectionChange(labour)"
        />
      </div>
      <div class="dropdown-details" style="margin-left: 20px;">
        <h4>{{ labour.labourName }}</h4>
        <div class="item-price">
          <p>₹{{ labour.labourAmount }}</p>
        </div>
      </div>
      <div class="edit-btn">
        <i class="material-icons"  style="cursor: pointer;" (click)="openLabourEditSidebar(labour)">edit</i>
      </div>
    </div>
    
      <div class="floater-labour-btn" (click)="openLabourEditSidebar()">
        <p>ADD LABOUR</p>
      </div>
  </div>
      <!-- Skip Confirmation Popup -->
      <div class="skip-confirmation" *ngIf="showSkipConfirmation">
        <!-- <button class="cancel-popup-close" (click)="closeSkipConfirmation()">×</button> -->
        <p class="skip-text">Are you sure you want to skip Adding Labour?</p>
        <button class="cancel-popup-btn no-btn" (click)="closeSkipConfirmation()">No</button>
        <button class="cancel-popup-btn confirm-btn" (click)="openPopup()">Yes</button>
        
    </div>
    <!-- labour bottom CTA -->
    <div 
      *ngIf="selectedItemsCount > 0" 
      [ngClass]="isMobile?'bottom-boxQuoteMobile':'bottom-boxQuote'">
        <div class="bottom-contentQuote">
          <div class="total-items">{{ selectedItemsCount }} items
            <p *ngIf="activeTab === 'quote-received'" class="total-amount">₹{{totalSelectedApprovedriceQuote | number:'1.2-2' }}</p>
          
          </div>
            <button type="button" class="add-labour-bottom-cta" (click)="toggleSelectItem()">Add Labour</button>
        </div>
    </div>
  </div>
  <!-- quoteApprovedPrice -->
  <!-- <div 
    *ngIf="selectedtCount > 0" 
    [ngClass]="isMobile ? 'bottom-boxQuoteMobile' : 'bottom-boxQuote'">
    <div class="bottom-contentQuote">
      <div class="total-items">
        {{ selectedItemsCount }} items
        <p class="total-amount">₹{{ totallabourSelectedPrice | number:'1.2-2' }}</p>
      </div>
      <button type="button" class="create-quote-btn" (click)="addLabourSidebar()">Add Labour</button>
    </div>
  </div> -->
  
  <!-- customer quote bottom CTA -->
  <div *ngIf="anyItemSelectedQuote() && totalApprovingItemsCount>0" [ngClass]="isMobile ? 'bottom-boxQuoteMobile':'bottom-boxQuote'" >
      <div class="bottom-contentQuote">
        <div class="total-items" >{{selectedItemsCount}} items
          <p class="total-amount">₹{{totalSelectedApprovedriceQuote | number:'1.0-0'}}</p>
          <!-- <p *ngIf="activeTab === 'quote-approved'" class="total-amount">₹{{quoteApprovedPrice | number:'1.2-2' }}</p> -->
        </div>
        <div class="update-approve-btn2">
        <button class="create-quote-btn" (click)="approveQuote()" class="approve-quote-btn">Approve Quote</button> 
        <!-- <button class="add-labour-bottom-btn" *ngIf="totalSelectedPriceQuote>0 && totallabourSelectedPrice==0 && activeTab === 'quote-received' " type="button" (click)="addLabourSidebar()">Add Labour</button>
        <button class="create-quote-btn" *ngIf="totallabourSelectedPrice>0 && activeTab === 'quote-received'" type="button" (click)="openPopup()">Create Customer Quote</button>       -->
        <!-- <button class="create-quote-btn" *ngIf="activeTab === 'quote-approved'" type="button" (click)="openPopup()">Mark As Complete</button>  -->
      </div>

      </div>  
  </div>
        <div class="item-update" *ngIf="customerQuotation?.length == 0 && selectedItemsLabourQuote?.length == 0" style="margin-top: -14px;">
          <button class="item-update-btn" (click)="openSidebarQuote1('view')">View Approved Quotation</button> 
        </div>
  <!-- Edit labour name Sidebar -->
  <div class="overlay" [class.visible]="isEditLabourSidebarOpen" (click)="closeAll()"></div>
  <div *ngIf="isEditLabourSidebarOpen" class="edit-labour-sidebar">
    <div class="labour-sidebar-header">
      <h2>Edit Labour</h2>
      <mat-icon (click)="closeLabourEditSidebar()">close</mat-icon>
    </div>
    <form [formGroup]="laborDetails" >
        <div class="labour-name-column" style="margin-top: -8px;margin-left: -20px;">
          <mat-form-field appearance="outline" class="full-width" class="edit-labour-details" style="margin-top: 10px;">
          <mat-label>Labour Name</mat-label>
          <input
            matInput
            formControlName="labourName"
            [(ngModel)]="selectedLabour.labourName"
            placeholder="Enter Labour Name"
            name="labourName"
          />
          </mat-form-field>
        </div>
        <div class="laboue-price-column" style="margin-top: -14px;margin-left: -20px;">
          <mat-form-field appearance="outline" class="edit-labour-details">
            <mat-label>Labour Amount</mat-label>
            <input
              matInput
              formControlName="labourAmount"
              [(ngModel)]="selectedLabour.labourAmount"
              placeholder="Enter Labour Amount"
              name="labourPrice"
            />
          </mat-form-field>
        </div>  
    </form>
    <div class="labour-sidebar-footer" style="margin-top: -14px;">
      <button class="save-btn" *ngIf="isEditLabourSidebarOpen && !isAddLabour" (click)="saveLabour()">Save Labour</button>
      <button class="add-btn" *ngIf="isEditLabourSidebarOpen && isAddLabour" (click)="addLabour()">Add Labour</button>
    </div>

  </div>
  <!-- Popup for skip labour -->
    <!-- <div class="cancel-popup-container" *ngIf="showCancelConfirmation">
      <div class="cancel-popup">
        <button class="cancel-popup-close" (click)="closeCancelPopup()">×</button>
        <p>Are you sure you want to skip?</p>
        <button class="cancel-popup-btn confirm-btn" (click)="confirmAction()">Yes</button>
        <button class="cancel-popup-btn no-btn" (click)="closeCancelPopup()">No</button>
      </div>
    </div> -->
</mat-list>

<mat-list *ngIf="type=='view'">
<div class="overlay" [class.visible]="isEditLabourSidebarOpen" (click)="closeAll()"></div>

  <header>
    <div class="back-button">
      <i (click)="CloseSidebarQuote()" style="margin-right: 10px;" class="material-icons-arrowback">arrow_back</i>
      <span class="title1" style="font-size: 18px;">Approved Quotation</span>
      <div *ngIf="quoteId">
        <h2>{{ quoteId }}</h2>
      </div>
    </div>
  </header>
  <div class="detailsQuote">
    <div class="logoModel" *ngIf="(isJobCardDetails && requestId) ">
        <img [src]="getMakeImageUrl(requestDetails?.spareRequest?.make)" alt="Icon" class="img">
        <div class="model-info">
          <span class="model-name">{{requestDetails?.spareRequest?.make}}</span>
          <span class="model-details">{{requestDetails?.spareRequest?.model}}, {{requestDetails?.spareRequest?.fuelType}}</span>
        </div>
    </div>
    <div class="logoModel" *ngIf="isJobCardDetails && !requestId" style="margin-bottom: 1px;">
      <img [src]="getMakeImageUrl(request?.jobCard?.make)" alt="Car Make Image" class="img">
      <div class="model-info">
          
    
          <span class="model-name">{{ request?.jobCard?.make || 'Unknown Make' }}</span>
        <span class="model-details">{{ request?.jobCard?.model || 'Unknown Model' }}, {{ request?.jobCard?.fuelType || 'Unknown Fuel Type' }}</span>
        <span class="model-details">{{ request?.jobCard?.registrationNumber || '' }}</span>
        <span class="model-details">{{ request?.jobCard?.vinNumber || '' }}</span>
      </div>
      <div style="width: 88%;
    text-align: end;">
      <button style="font-size: 14px;
      border: 2px solid #144579;
      display: flex contents;
      padding: 3px;
      color: #144579;
      font-weight: bold;
      border-radius: 5px;
      cursor: pointer;" (click)="sendUpdateApproval()">Send Update</button>
    </div>
  </div>
    <div *ngIf="totalApprovedItemsCount>0 else noQuotesApproved" class="items" style="margin-bottom: 56px;">
      <!-- <div *ngIf="customerQuotationCount>0" else noQuotesReceived> -->
      <!-- <div class="request-info">Quote Approved for {{requestDetails?.spareRequestItemList?.length}} items </div> -->
      <div class="item" *ngIf="quoteItemStatus && !isAllItemsAndLabourCompleted()">
        <div class="checkboxselect" >
          <input type="checkbox" [checked]="selectAllQuote" (change)="sidebarToggleSelectAllQuote()">
          <span>Select All</span>
        </div>
        <div style="text-align: end;margin-bottom: -25px;">
          <span class="customer-quote-price">₹ {{grandFinalApprovedViewTotalAmount | number:'1.0-0'}}</span>
          <div class="quote_total_amount">Total Amount</div>
        </div>
      </div>
      <div class="spare-part-header">
      <h2 style="font-weight: bold;">Spare Parts</h2>
      <!-- <p style="margin-top:23px;color:#144579;cursor:pointer;" (click)="AddItems()" *ngIf="totallabourSelectedPrice>0">Add More</p> -->
    </div>
    <p *ngIf="customerQuotation1.length === 0">No Data Available</p>
    <div class="quotereceived-items" style="height: 112px;" *ngFor="let quote of customerQuotation1" [ngClass]="{'border-box': quote.selected}">
        <div class="">
          <div >
            <div class="approved-pending-tag1" *ngIf="quote.quoteItemStatus== 2">
              <mat-icon class="hourglass-icon"  style="font-size: 14px;margin-left: -12px;margin-right: -4px;" >hourglass_full</mat-icon>
              Pending
            </div>
            <div class="approved-completed-tag1" *ngIf="quote.quoteItemStatus == 1">
              <mat-icon class="thumb-icon" style="margin-left: -6px;
              font-size: 18px;
              margin-right: -2px;">thumb_up</mat-icon>
              Completed
            </div>
          </div>

            <div style="display: flex; justify-content: space-between; width: 100%;">


              <input 
              *ngIf="quote.quoteItemStatus == 2" 
              type="checkbox" 
              [(ngModel)]="quote.selected" 
              (change)="toggleItemViewApproveSelectionQuote()"
            >
              <span class="part-name" style="margin-left: 5px;padding: 1px;">{{quote.itemName}}</span>
            <span class="spare-mrp" style="text-align: end;">₹{{quote.sellingPrice * quote.quantity | number:'1.0-0'}}</span> 
            <!-- <i class="material-icons" (click)="openEditDialog(quote)">edit</i>                   -->
          </div>

      <hr style="border: 1px solid #ebebeb;" />
      <div class="quantity-control">
        <div class="brand" style="    width: 71%;
        color: #999;
        font-size: 13px;
        margin-top: -17px;">
          {{quote.brand}}
        </div>
        <div class="addtocartcompatiblecar1">
          <div class="spare-add-cart1">
              <div class="btn-approve-group">
                  <p class="quantity-measure" >{{quote.quantity}} units
                  </p>
                </div>
          </div>
      </div>
        <!-- <div class="add-minus">
          <button mat-icon-button (click)="decrementjobcardQuantity(quote)">
            <mat-icon style="margin-right: 6px;">remove</mat-icon>
          </button>
          <span style="padding: 7px;margin-left: 12px;">{{ quote.itemQuantity }}</span>
          <button mat-icon-button (click)="incrementjobcardQuantity(quote)">
            <mat-icon>add</mat-icon>
          </button>
      </div> -->
      </div>
    
        </div>
    </div>
    <!-- </div> -->
    <div *ngIf="activeTab === 'quote-approved'">
      <div *ngIf="quotationapproved.length > 0; else noQuotesApproved" class="items">

      
        <div class="request-info"> Quote approved  for {{quotationapproved.length}} items </div>


        <div class="item" >
         
          <div class="checkboxselect">
            <input type="checkbox" [checked]="selectAllQuote" (change)="reloadApprovedQuotes()">
            <span>Select All</span>
          </div>
          <div>
            <div class="total-selling-price">
              <span>Total Selling Price</span>
              <span class="total-price">₹ {{quoteApprovedPrice | number:'1.0-0'}} </span>
              
            </div>
          </div>
        </div>

      
        <div class="quoteaproved-items" *ngFor="let item of quotationapproved" [ngClass]="{'border-box': item.selected}">
          <div class="line" >
            <input type="checkbox" [(ngModel)]="item.selected" (change)="toggleItemSelectionQuote()">
            <span class="part-name">{{item.itemName}}</span>
            <span class="ellipsis" (click)="openSidebar(item)">⋮</span>
          </div>
          <div class="line">
            <span class="availability">item.brand</span>
            <span class="date">₹{{(item.mrp - (item.mrp* item.sellingDiscount /100))*item.itemQuantity}}</span>
          </div>
          <div class="line">
            <span class="date-time">{{item.edt  | date:'dd MMM yyyy'}}</span>
            <span class="amount">Total Selling Price</span>
        </div> 
      </div>  
    </div>
    <ng-template #noQuotesApproved>
      <div class="empty-state">
        <div >
          
          <img src="../../../../assets/images/request-details images/requestdetailsimage.png" alt="Quote Not Approved" class="quote-image">
          <div class="message">
            <strong>Quote Not Approved Yet!</strong>
            <p>Awaiting your approval</p>
          </div>
        </div>
      </div>
      <button class="approveNow" (click)="approveNow()" *ngIf="activeTabs === 'quotation'">APPROVE QUOTE NOW</button>
    </ng-template>
  </div>
    <div class="labour-section-details"  *ngIf="selectedItemsLabour?.length>0">
    <!-- *ngIf="totallabourSelectedPrice>0"> -->
    <div class="labour-header-title" style="gap: 134px;margin-bottom: -4px;">
      <h2 style="font-weight: bold;">Labour Charges</h2>
      <!-- <p style="margin-top:23px;color:#144579;cursor:pointer;font-weight: bold;" (click)="addLabourSidebar()" >Add More</p> -->
    </div>
    
    <div class="quotereceived-items" style="height:95px" *ngFor="let quote of selectedItemsLabour" [ngClass]="{'border-box': quote.selected}">

      <div class="approved-pending-tag1" *ngIf="quote.quoteItemStatus== 2">
        <mat-icon class="hourglass-icon" style="margin-left: -6px;font-size: 17px;margin-right: -1px;">hourglass_full</mat-icon>
        Pending
      </div>
      <div class="approved-completed-tag1" *ngIf="quote.quoteItemStatus == 1">
        <mat-icon class="thumb-icon" style="margin-left: -6px;
        font-size: 18px;
        margin-right: -2px;">thumb_up</mat-icon>
        Completed
      </div>
      <div class="checkboxselect" >
      <!-- *ngIf="selectedItemsCount>0"> -->
        <div class="labour-lines">
          <input *ngIf="quote.quoteItemStatus== 2" type="checkbox" [(ngModel)]="quote.selected" (change)="toggleItemViewApproveSelectionQuote()">
          <!-- <span class="part-name" style="width: 75%;margin-left: 5px;">{{quote.labourName}}</span>
          <span class="spare-mrp" style="text-align: end;float: right;">₹{{quote.labourAmount | number:'1.0-0'}}</span>
           -->
          <span class="part-name-view" style="width: 75%;margin-left: 5px;">{{quote.labourName}}</span>
          <span *ngIf="quote.quantity" class="spare-mrp" style="text-align: end;float: right;">₹{{quote.labourAmount | number:'1.0-0'}}</span> 
  
          <!-- <i class="material-icons" (click)="openLabourEditSidebar(quote)">edit</i> -->
        </div>
      </div>
      <div class="lines">
        <span *ngIf="labourItemsCount > 0" class="date-time">{{quote.edt  | date:'dd MMM yyyy'}}</span>
      </div>
      <hr style="border: 1px solid #ebebeb;" />
      <div class="quantity-control">
        <div class="brand" style="    width: 71%;
        color: #999;
        font-size: 13px;">
          {{quote.brand}}
        </div>
        <div class="addtocartcompatiblecar1">
          <div class="spare-add-cart1">
              <div class="btn-approve-group">
             
                  <p class="quantity-measure" >{{quote.quantity}} Units
                  </p>
                </div>
          </div>
      </div>
        <!-- <div class="add-minus">
          <button mat-icon-button (click)="decrementjobcardQuantity(quote)">
            <mat-icon style="margin-right: 6px;">remove</mat-icon>
          </button>
          <span style="padding: 7px;margin-left: 12px;">{{ quote.itemQuantity }}</span>
          <button mat-icon-button (click)="incrementjobcardQuantity(quote)">
            <mat-icon>add</mat-icon>
          </button>
      </div> -->
      </div>
    </div>
  </div>

</div>
<ng-template #noQuotesApproved>
  <div class="empty-state">
    <div >
      
      <img src="../../../../assets/images/request-details images/requestdetailsimage.png" alt="Quote Not Approved" class="quote-image">
      <div class="message">
        <strong>Quote Not Approved Yet!</strong>
        <p>Awaiting your approval</p>
      </div>
    </div>
  </div>

  <div class="item-update">
    <button class="item-update-btn" (click)="openSidebarQuote1('approve')">APPROVE QUOTE NOW</button>
  </div>
  <!-- <button class="approveNow" (click)="openSidebarQuote1('approve')">APPROVE QUOTE NOW</button> -->
</ng-template>
</div>
  <!-- <div *ngIf="activeTabs=== 'quotation'" (click)="AddItems()">
    <button class="quotation-bottom-cta">Add Quote Items</button>
  </div> -->
  <!-- <div *ngIf="(totallabourSelectedPrice<1) && (activeTab === 'quote-received')" class="floater-btn" (click)="AddItems()">
    <p *ngIf="activeTab === 'quote-received'" style="font-weight: bold;">ADD ITEM</p>

  </div> -->
  <!-- Add Item Side Bar -->
  <div *ngIf="isSidebarOpen" class="new-sidebar">
    <div class="header1">
      <div class="maticon">
      <mat-icon (click)="toggleSidebar()">arrow_back</mat-icon>
    </div>
    <div class="add-items">
      <h3 style="font-weight: bold;margin-right:215px;margin-bottom:20px;">Add Items</h3>
    </div>
    </div>
    <div class="search-bar">
      <input
        type="text"
        placeholder="Search by Part Name"
        [(ngModel)]="searchWord"
        class="search-input"
        (input)="onSearch()"
      />
      <button *ngIf="searchWord" class="clear-btn" (click)="clearSearch()">&#x2715;</button>
    </div>
    
    <div class="item-list">
      <div *ngFor="let item of filteredItems" class="dropdown-item" (click)="addItemToQuotation(item)">
        <img [src]="item.imageUrl" alt="{{ item.partName }}" class="dropdown-image" />
        <div class="dropdown-details" style="width: 100%;">
          <h4>{{ item.name }}</h4>
          <p>Brand: {{ item.brand }}</p>
          <div class="item-price1">
            <p>₹{{item.mrp }}</p>
          </div>
        </div>
      </div>
    </div>
    
    
  </div>
  <!-- Add Labour -->
  <div *ngIf="isAddLabour" class="labour-sidebar">
   <div class="labour-section">
      <div class="labour-maticon" >
        <mat-icon (click)="labourRedirectSidebar()">arrow_back</mat-icon>
      </div>
      <div class="labour-header" >
        <h2>Add Labour</h2>
      </div>
      <div class="labour-skip" >
        <p style="position: relative;float:right;padding:10px;cursor:pointer;color:#144579;"(click)="openSkipConfirmation()">Skip</p>
      </div>
    </div>
    <div class="search-bar">
      <input
        type="text"
        placeholder="Search Labour"
        [(ngModel)]="searchText"
        class="search-input"
        (input)="onLabourSearch()"/>

      <button *ngIf="searchText" class="clear-btn" (click)="clearLabourSearch()">&#x2715;</button>
    </div>
    <div class="item-list1">
    <!-- Labour List -->
    <div *ngFor="let labour of labourListData" class="dropdown-item">
      <!-- {{labourListData|json}} -->
      <div class="checkboxselect">
        <input 
          type="checkbox" 
          [(ngModel)]="labour.selected" 
          (change)="onLabourSelectionChange(labour)"
        />
      </div>
      <div class="dropdown-details" style="margin-left: 20px;">
        <h4>{{ labour.labourName }}</h4>
        <div class="item-price">
          <p>₹{{ labour.labourAmount }}</p>
        </div>
        <div class="edit-btn" style="margin-left: 361px; margin-top: -32px;">
          <i class="material-icons" (click)="openLabourEditSidebar(labour)">edit</i>
        </div>
      </div>
    </div>
    
      <!-- <div class="floater-labour-btn" (click)="openLabourEditSidebar()">
        <p>ADD LABOUR</p>
      </div> -->
  </div>
      <!-- Skip Confirmation Popup -->
      <div class="skip-confirmation" *ngIf="showSkipConfirmation">
        <!-- <button class="cancel-popup-close" (click)="closeSkipConfirmation()">×</button> -->
        <p class="skip-text">Are you sure you want to skip Adding Labour?</p>
        <button class="cancel-popup-btn no-btn" (click)="closeSkipConfirmation()">No</button>
        <button class="cancel-popup-btn confirm-btn" (click)="openPopup()">Yes</button>
        
    </div>
    <!-- labour bottom CTA -->
    <div 
      *ngIf="selectedItemsCount > 0" 
      [ngClass]="isMobile?'bottom-boxQuoteMobile':'bottom-boxQuote'">
        <div class="bottom-contentQuote">
          <div class="total-items">{{ selectedItemsCount }} items
            <p *ngIf="activeTab === 'quote-received'" class="total-amount">₹{{totalSelectedApprovedriceQuote | number:'1.2-2' }}</p>
          
          </div>
            <button type="button" class="add-labour-bottom-cta" (click)="toggleSelectItem()">Add Labour</button>
        </div>
    </div>
  </div>
  <!-- quoteApprovedPrice -->
  <!-- <div 
    *ngIf="selectedtCount > 0" 
    [ngClass]="isMobile ? 'bottom-boxQuoteMobile' : 'bottom-boxQuote'">
    <div class="bottom-contentQuote">
      <div class="total-items">
        {{ selectedItemsCount }} items
        <p class="total-amount">₹{{ totallabourSelectedPrice | number:'1.2-2' }}</p>
      </div>
      <button type="button" class="create-quote-btn" (click)="addLabourSidebar()">Add Labour</button>
    </div>
  </div> -->

  <!-- customer quote bottom CTA -->
  <div *ngIf="anyItemAlreadyApprovedQuote1() && !isAllItemsAndLabourCompleted()" [ngClass]="isMobile ? 'bottom-boxQuoteMobile':'bottom-boxQuote'" style="width:30%;">
      <div class="bottom-contentQuote">
        <div class="total-items" style="width: 47%;">{{selectedAlreadyApprovedQuotelength}} items
          <p class="total-amount">₹{{selectedAlreadyApprovedQuote | number:'1.2-2'}}</p>
          <!-- <p *ngIf="activeTab === 'quote-approved'" class="total-amount">₹{{quoteApprovedPrice | number:'1.2-2' }}</p> -->
        </div>
        <div class="update-quote-btn2">
        <button class="markascomplete-quote-button"  (click)="markAsCompleteUpdate()" class="approve-quote-btn" style="padding: 8px 20px;">Mark As Complete</button> 
        </div>
        <!-- <button class="add-labour-bottom-btn" *ngIf="totalSelectedPriceQuote>0 && totallabourSelectedPrice==0 && activeTab === 'quote-received' " type="button" (click)="addLabourSidebar()">Add Labour</button>
        <button class="create-quote-btn" *ngIf="totallabourSelectedPrice>0 && activeTab === 'quote-received'" type="button" (click)="openPopup()">Create Customer Quote</button>       -->
        <!-- <button class="create-quote-btn" *ngIf="activeTab === 'quote-approved'" type="button" (click)="openPopup()">Mark As Complete</button>  -->

      </div>  
  </div>
  <div class="overlay" [class.visible]="isEditLabourSidebarOpen" (click)="closeAll()"></div>
  <div *ngIf="isEditLabourSidebarOpen" class="edit-labour-sidebar1" style="height:48%;">
    <div class="labour-sidebar-header1" style="display: flex;
        padding: 1px 0px;
        margin-top: -10px;
        justify-content: space-between;">
      <h2>Enter Estimated Delivery Time</h2>
      <mat-icon style="padding: 13px;" (click)="closeLabourEditSidebar()">close</mat-icon>
    </div>
    <form [formGroup]="laborDetails">
      <div class="labour-time-column" style="margin-top: -8px;">
    
        <!-- Date Picker -->
        <mat-form-field appearance="outline" class="full-width" style="margin-top: 10px;width: 358px;margin-left: -19px;">
            <mat-label>Estimated Date</mat-label>
            <input 
                matInput 
                [matDatepicker]="picker" 
                formControlName="estimatedTimeDelivery"
                placeholder="Select Date"
                (dateChange)="updateEstimatedTime($event.value)"  
                style="    padding: 5px;"
            />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    
        <!-- Time Picker -->
        <mat-form-field appearance="outline" class="full-width" style="margin-top: 10px;width: 358px;margin-left: -19px;">
          <mat-label>Select Time</mat-label>
          <input
            matInput
            type="time"
            formControlName="estimatedTime"
            placeholder="Select Time"
            (change)="updateEstimatedTime(laborDetails.value.estimatedTimeDelivery)" 
          />
        </mat-form-field>
      </div>
    </form>

    
  <div class="item-update" style="margin-top: 6px;">
    <button class="item-update-btn" *ngIf="isEditLabourSidebarOpen && !isAddLabour" (click)="sendUpdate()">Send Update</button>
  </div>
  
</div>

  <!-- Edit labour name Sidebar -->
  <!-- <div class="overlay" [class.visible]="isEditLabourSidebarOpen" (click)="closeAll()"></div>
  <div *ngIf="isEditLabourSidebarOpen" class="edit-labour-sidebar">
    <div class="labour-sidebar-header">
      <h2>Edit Labour</h2>
      <mat-icon (click)="closeLabourEditSidebar()">close</mat-icon>
    </div>
    <form [formGroup]="laborDetails" >
        <div class="labour-name-column" style="margin-top: -8px;">
          <mat-form-field appearance="outline" class="full-width" class="edit-labour-details" style="margin-top: 10px;">
          <mat-label>Labour Name</mat-label>
          <input
            matInput
            formControlName="labourName"
            [(ngModel)]="selectedLabour.labourName"
            placeholder="Enter Labour Name"
            name="labourName"
          />
          </mat-form-field>
        </div>
        <div class="laboue-price-column" style="margin-top: -14px;">
          <mat-form-field appearance="outline" class="edit-labour-details">
            <mat-label>Labour Amount</mat-label>
            <input
              matInput
              formControlName="labourAmount"
              [(ngModel)]="selectedLabour.labourAmount"
              placeholder="Enter Labour Amount"
              name="labourPrice"
            />
          </mat-form-field>
        </div>  
    </form>
    <div class="labour-sidebar-footer" style="margin-top: -14px;">
      <button class="save-btn" *ngIf="isEditLabourSidebarOpen && !isAddLabour" (click)="saveLabour()">Save Labour</button>
      <button class="add-btn" *ngIf="isEditLabourSidebarOpen && isAddLabour" (click)="addLabour()">Add Labour</button>
    </div>
    
  </div> -->
  <!-- Popup for skip labour -->
    <!-- <div class="cancel-popup-container" *ngIf="showCancelConfirmation">
      <div class="cancel-popup">
        <button class="cancel-popup-close" (click)="closeCancelPopup()">×</button>
        <p>Are you sure you want to skip?</p>
        <button class="cancel-popup-btn confirm-btn" (click)="confirmAction()">Yes</button>
        <button class="cancel-popup-btn no-btn" (click)="closeCancelPopup()">No</button>
      </div>
    </div> -->
</mat-list>
<mat-list *ngIf="type=='edit'">
  <header>
    <div class="back-button">
      <i (click)="CloseSidebarQuote()" style="margin-right: 10px;" class="material-icons-arrowback">arrow_back</i>
      <span class="title1" style="font-size: 18px;">Edit Quotation</span>
      <div *ngIf="quoteId">
        <h2>{{ quoteId }}</h2>
      </div>
    </div>
  </header>
  <div class="detailsQuote">
    <div class="logoModel" *ngIf="(isJobCardDetails && requestId) ">
        <img [src]="getMakeImageUrl(requestDetails?.spareRequest?.make)" alt="Icon" class="img">
        <div class="model-info">
          <span class="model-name">{{requestDetails?.spareRequest?.make}}</span>
          <span class="model-details">{{requestDetails?.spareRequest?.model}}, {{requestDetails?.spareRequest?.fuelType}}</span>
        </div>
    </div>
    <div class="logoModel" *ngIf="isJobCardDetails && !requestId">
      <img [src]="getMakeImageUrl(request?.jobCard?.make)" alt="Car Make Image" class="img">
      <div class="model-info">
          <span class="model-name">{{ request?.jobCard?.make || 'Unknown Make' }}</span>
        <span class="model-details">{{ request?.jobCard?.model || 'Unknown Model' }}, {{ request?.jobCard?.fuelType || 'Unknown Fuel Type' }}</span>
        <span class="model-details">{{ request?.jobCard?.registrationNumber || '' }}</span>
        <span class="model-details">{{ request?.jobCard?.vinNumber || '' }}</span>
      </div>
    </div>
    <div *ngIf="totalEditItemsCount>0 else noQuoteEdit" class="items">
      <div class="item" >
        <div class="checkboxselect">
          <input type="checkbox" [checked]="selectAllQuote" (change)="sidebarToggleSelectAllQuote()">
          <span>Select All</span>
        </div>
        <div style="text-align: right;">
          <span class="customer-quote-price">₹ {{quoteGrandTotal | number:'1.0-0'}}</span>
          <!-- customerQuotationPrice -->
          <div class="quote_total_amount">Total Amount</div>
        </div>
      </div>
      <div class="spare-part-header">
      <h2 style="font-weight: bold;">Spare Parts</h2>
      <p style="margin-top:23px;color:#144579;cursor:pointer;" (click)="AddItems()" *ngIf="(customerQuotation?.length > 0 && selectedItemsLabourQuote?.length > 0)">Add More</p>
    </div>
    <div class="quotereceived-items"  *ngFor="let quote of customerQuotation" [ngClass]="{'border-box': quote.selected}">
      <!-- <div class="lines" style="margin-bottom: 4px;">
          <input type="checkbox" [(ngModel)]="quote.selected" (change)="sidebarToggleItemSelectionQuote()">
          <span class="part-name">{{quote.itemName}}</span>
          <i class="material-icons" (click)="openEditDialog(quote)" style="width: 86%;">edit</i>                  
        </div > -->
        

        <div class="lines" style="display: flex;">
          <input class="checkbox-spare" type="checkbox" [(ngModel)]="quote.selected" (change)="sidebarToggleItemSelectionQuote()">
          <span class="part-name">{{quote.itemName}}</span>
          <i class="material-icons-spare"  style="cursor: pointer;" (click)="openEditDialog(quote)">edit</i>                  
        </div>
        <!-- Edit Dialog and Overlay -->
        <div class="overlay1" [class.visible]="isPopupOpenEdit" (click)="closePopupEdit()"></div>
        <div *ngIf="isPopupOpenEdit" [ngClass]="isMobile ? 'bottom-sheetMobile' : 'bottom-sheet'">
          <header class="back-button">
            <div style="flex-direction: column;width: 87%;">
            <h3 class="header-name" style="margin-bottom: 2px;margin-top: -2px;">{{ itemQuote.itemName }}</h3>
            <h3 class="header-name" style="margin-bottom: 2px;margin-top: -2px;font-weight:100;font-size:13px">{{ itemQuote.brand }}</h3>
          </div>
            <mat-icon (click)="closePopupEdit()" class="close-pop-up">close</mat-icon>
          </header>
          <div class="bottom-sheet-content">
            <form [formGroup]="unitPrice" style="width: 100%;
            margin-left: -9px;">
            <div class="unit-price">
              <mat-form-field appearance="outline" class="full-width" class="edit-labour-details">
                <mat-label>Unit Price</mat-label>
                <input
                  matInput
                  formControlName="unitprice"                            
                  [(ngModel)]="itemQuote.mrp"
                  placeholder="Enter Unit Price*"
                  required
                  name="Unit Price"
                  pattern="^[0-9]*$"
                />
                </mat-form-field>
            </div>
          </form>
          <div class="item-update" style="bottom: -15px;height: 50px;">
            <button class="item-update-btn" style="margin-top:0px;" (click)="save()">Save Quote</button>
          </div>
          </div>
        </div>
        <div class="lines-mrp" style="text-align: right;">
          <!-- {{quote.mrp * quote.itemQuantity}} -->
          <!-- ₹{{quote.sellingPrice * quote.quantity | number:'1.2-2'}} -->
          <span class="spare-mrp"> ₹{{quote.mrp * quote.quantity | number:'1.0-0'}}</span> 
        </div>
        <!-- <div class="lines">
          <span class="date-time">{{quote.edt | date:'dd MMM yyyy'}}</span>
        </div>  -->
      <hr style="border: 1px solid #c0bebe;" />
      <div class="quantity-control">
        <div class="brand" style="color: #999;">
          {{quote.brand}}
         
        </div>
        <!-- <div class="add-minus">

          <button mat-icon-button (click)="decrementQuantity(quote)">
          <mat-icon style="margin-left:0px;">remove</mat-icon>
          </button>
          <span style="padding: 7px;margin-left: 12px;">{{quote.quantity}}</span>
          <button mat-icon-button (click)="incrementQuantity(quote)">
            <mat-icon class="increment" style="margin-left: 1px;">add</mat-icon>
          </button>
      </div> -->

      <div class="addtocartcompatiblecar1">
        <div class="spare-add-cart1">
            <div class="btn-group">
                <button class="button1" mat-raised-button style="min-width: 8px;
                padding: 2px 0px;
                margin-right: -3px;
                height: 38px;
                font-size: 11px;" (click)="decrementQuantity(quote)">
                    <mat-icon class="sidebuttons1" style="font-size: 18px;margin-left: 0px;">remove</mat-icon>
                </button>
                <button  class="button1" mat-raised-button style="padding: 2px 0px;
                margin-left: -3px;
                height: 32px;
                margin-right: 1px;
                font-size: 13px;">
                  {{quote.quantity}} 
                </button>
                <mat-icon class="sidebutton1" style="width: 1px;
                padding: 7px 0px;
                margin-left: -6px;
                margin-right: -3px;
                font-size: 18px;
                height: 35px;
                vertical-align: center;
                color: #144579;" (click)="incrementQuantity(quote)">add</mat-icon>
            </div>
        </div>
    </div>
      </div>
    </div>
    </div>
    <div *ngIf="activeTab === 'quote-approved'">
      <div *ngIf="quotationapproved.length > 0; else noQuotesApproved" class="items">

      
        <div class="request-info"> Quote approved  for {{quotationapproved.length}} items </div>


        <div class="item" >
         
          <div class="checkboxselect">
            <input type="checkbox" [checked]="selectAllQuote" (change)="reloadApprovedQuotes()">
            <span>Select All</span>
          </div>
          <div>
            <div class="total-selling-price">
              <span>Total Selling Price</span>
              <span class="total-price">₹ {{quoteApprovedPrice | number:'1.0-0'}} </span>
              
            </div>
          </div>
        </div>

      
        <div class="quoteaproved-items" *ngFor="let item of quotationapproved" [ngClass]="{'border-box': item.selected}">
          <div class="line" >
            <input type="checkbox" [(ngModel)]="item.selected" (change)="toggleItemEditSelectionQuote()">
            <span class="part-name">{{item.itemName}}</span>
            <span class="ellipsis" (click)="openSidebar(item)">⋮</span>
          </div>
          <div class="line">
            <span class="availability">item.brand</span>
            <span class="date">₹{{(item.mrp - (item.mrp* item.sellingDiscount /100))*item.itemQuantity}}</span>
          </div>
          <div class="line">
            <span class="date-time">{{item.edt  | date:'dd MMM yyyy'}}</span>
            <span class="amount">Total Selling Price</span>
        </div> 
      </div>  
    </div>
    <ng-template #noQuotesApproved>
      <div class="empty-state">
        <div >
          
          <img src="../../../../assets/images/request-details images/requestdetailsimage.png" alt="Quote Not Approved" class="quote-image">
          <div class="message">
            <strong>Quote Not Approved Yet!</strong>
            <p>Awaiting your approval</p>
          </div>
        </div>
      </div>
      <button class="approveNow" (click)="approveNow()" *ngIf="activeTabs === 'quotation'">APPROVE QUOTE NOW</button>
    </ng-template>
  </div>
    <div class="labour-edit-section-details" style="margin-bottom: 179px;" *ngIf="selectedItemsLabourQuote?.length>0">
    <div class="labour-header-title" style="    display: flex;justify-content: space-between;margin-top: -12px;margin-bottom: -5px;">
      <h2 style="font-weight: bold;">Labour Charges</h2>
      <p style="margin-top:23px;color:#144579;cursor:pointer;font-weight: bold;" (click)="addLabourSidebar()" >Add More</p>
    </div>
    <div class="quotereceived-items" style="height: 120px;" *ngFor="let quote of selectedItemsLabourQuote" [ngClass]="{'border-box': quote.selected}">
      <div class="checkboxselect" >
        <!-- <div class="labour-lines">
          <input type="checkbox" [(ngModel)]="quote.selected" (change)="toggleItemEditSelectionQuote()">
          <span class="part-name">{{quote.labourName}}</span>
          <i class="material-icons" (click)="openLabourEditSidebar(quote)">edit</i>
          

        </div> -->
        
        <div class="labour-lines" style="display:flex">
          <input type="checkbox" class="checkbox-spare" [(ngModel)]="quote.selected" (change)="toggleItemSelectionQuote()">
          <span class="part-name">{{quote.labourName}}</span>

          <i class="material-icons-spare"  style="cursor: pointer;" (click)="openLabourEditSidebar(quote)">edit</i>
        </div>

        <span class="date" style="text-align: end;">₹{{quote.labourAmount * quote.quantity | number:'1.0-0'}}</span>

            </div>
      <div class="lines">
        <span *ngIf="labourItemsCount > 0" class="date-time">{{quote.edt  | date:'dd MMM yyyy'}}</span>
      </div>
      <hr style="border: 1px solid #c0bebe;" />
      <div class="quantity-control">
        <div class="brand" style="    width: 71%;
        color: #999;
        font-size: 13px;">
          {{quote.brand}}
        </div>
        <div class="addtocartcompatiblecar1">
          <div class="spare-add-cart1">
              <div class="btn-group">
                  <button class="button1" mat-raised-button style="min-width: 8px;
                  padding: 2px 0px;
                  margin-right: -3px;
                  height: 38px;
                  font-size: 11px;" (click)="decrementLabourQuantity(quote)">
                      <mat-icon class="sidebuttons1" style="font-size: 18px;margin-left: 1px;">remove</mat-icon>
                  </button>
                  <button  class="button1" mat-raised-button style="padding: 2px 0px;
                  margin-left: -3px;
                  height: 32px;
                  margin-right: 1px;
                  font-size: 13px;">
                    {{quote.quantity}} 
                  </button>
                  <mat-icon class="sidebutton1" style="    width: 1px;
                  padding: 7px 0px;
                  margin-left: -6px;
                  margin-right: -3px;
                  font-size: 18px;
                  height: 35px;
                  vertical-align: center;
                  color: #144579;" (click)="incrementLabourQuantity(quote)">add</mat-icon>
              </div>
          </div>
      </div>
      </div>

    </div>
  </div>
  <ng-template #noQuoteEdit>
    <div class="empty-state">
      <div >
        
        <img src="../../../../assets/images/request-details images/requestdetailsimage.png" alt="Quote Not Approved" class="quote-image">
        <div class="message">
          <strong>Cannot be Edited</strong>
          <p>Create new quote</p>
        </div>
      </div>
    </div>
    <button class="approveNow" (click)="approveNow()" *ngIf="activeTabs === 'quotation'">APPROVE QUOTE NOW</button>
  </ng-template>
  </div>
  <!-- Add Item Side Bar -->
  <div *ngIf="isSidebarOpen" class="new-sidebar">
    <div class="header1">
      <div class="maticon" style="margin-left: 3px;">
      <mat-icon style="margin-left: 3px;" (click)="toggleSidebar()">arrow_back</mat-icon>
    </div>
    <div class="add-items">
      <h3 style="text-align: left;">Add Items</h3>
    </div>
    </div>
    <div class="search-bar">
      <input
        type="text"
        placeholder="Search by Part Name"
        [(ngModel)]="searchWord"
        class="search-input"
        (input)="onSearch()"
      />
      <button *ngIf="searchWord" class="clear-btn" (click)="clearSearch()">&#x2715;</button>
    </div>
    
    <div class="item-list">
      <div *ngFor="let item of filteredItems" class="dropdown-item" (click)="addItemToQuotation(item)">
        <img [src]="item.imageUrl" alt="{{ item.partName }}" class="dropdown-image" />
        <div class="dropdown-details" style="width: 100%;">
          <h4>{{ item.name }}</h4>
          <p>Brand: {{ item.brand }}</p>
          <div class="item-price1">
            <p>₹{{item.mrp }}</p>
          </div>
        </div>
      </div>
    </div>
    
    
  </div>
  <div *ngIf="(customerQuotation?.length > 0 && selectedItemsLabourQuote?.length == 0)" class="floater-btn" (click)="AddItems()">
    <p style="font-weight: bold;">ADD ITEM</p>
  </div>
  <!-- Add Labour -->
  <div *ngIf="isAddLabour" class="labour-sidebar">
   <div class="labour-section" style="height: 45px;">
      <div class="labour-maticon" style="margin-top: 18px;">
        <mat-icon (click)="labourRedirectSidebar()" style="margin-left: 0px;">arrow_back</mat-icon>
      </div>
      <div class="labour-header" >
        <h2 style="font-weight:600;">Add Labour</h2>
      </div>
      <div class="labour-skip" >
        <p style="position: relative;float:right;padding:10px;cursor:pointer;color:#144579;"(click)="openSkipConfirmation()">Skip</p>
      </div>
    </div>
    <div class="search-bar">
      <input
        type="text"
        placeholder="Search Labour"
        [(ngModel)]="searchText"
        class="search-input"
        (input)="onLabourSearch()"/>

      <button *ngIf="searchText" class="clear-btn" (click)="clearLabourSearch()">&#x2715;</button>
    </div>
    <div class="item-list1">
    <!-- Labour List -->
    <div *ngFor="let labour of labourListData" class="dropdown-item">
      <div class="checkboxselect">
        <input 
          type="checkbox" 
          [(ngModel)]="labour.selected" 
          (change)="onLabourSelectionChange(labour)"
        />
      </div>
      <div class="dropdown-details" style="margin-left: 20px;">
        <h4>{{ labour.labourName }}</h4>
        <div class="item-price">
          <p>₹{{ labour.labourAmount }}</p>
        </div>

      </div>
      <div class="edit-btn">
        <i class="material-icons"  style="cursor: pointer;" (click)="openLabourEditSidebar(labour)">edit</i>
      </div>
    </div>
    
      <div class="floater-labour-btn" (click)="openLabourEditSidebar()">
        <p>ADD LABOUR</p>
      </div>
  </div>
      <!-- Skip Confirmation Popup -->
      <div class="skip-confirmation" *ngIf="showSkipConfirmation">
        <!-- <button class="cancel-popup-close" (click)="closeSkipConfirmation()">×</button> -->
        <p class="skip-text">Are you sure you want to skip Adding Labour?</p>
        <button class="cancel-popup-btn no-btn" (click)="closeSkipConfirmation()">No</button>
        <button class="cancel-popup-btn confirm-btn" (click)="openPopup()">Yes</button>
        
    </div>
    <!-- labour bottom CTA -->
    <div 
      *ngIf="selectedLabourslength > 0" 
      [ngClass]="isMobile?'bottom-boxQuoteMobile':'bottom-boxQuote'">
        <div class="bottom-contentQuote">
          <div class="total-items">{{ selectedLabourslength }} items
            <p class="total-amount">₹{{totallabourSelectedPrice | number:'1.0-0' }}</p>
          
          </div>
            <button type="button" class="add-labour-bottom-cta" style="width: 57%;" (click)="toggleSelectItem()">Add Labour</button>
        </div>
    </div>
  </div>
  <div *ngIf="anyItemSelectedQuote()" [ngClass]="isMobile ? 'bottom-boxQuoteMobile':'bottom-boxQuote'">
    <div class="bottom-contentQuote">
      <div class="total-items">{{labourCount}} items
        <p class="total-amount">₹{{totalSelectedEditPriceQuote | number:'1.0-0'}}</p>
        <!-- <p *ngIf="activeTab === 'quote-approved'" class="total-amount">₹{{quoteApprovedPrice | number:'1.2-2' }}</p> -->
      </div>
      <!-- <button class="add-labour-bottom-btn1" 

        *ngIf="customerQuotation?.length>0 && (selectedLabourslength == 0 || selectedLabourslength == null || selectedLabourslength == '')" 
        type="button" 
        (click)="addLabourSidebar()">
        Add Labour
        <span class="next-step-text">(In Next Step)</span>
      </button> -->
      <button class="add-labour-bottom-btn1" 
      *ngIf="customerQuotation?.length>0 && (selectedLabourslength == 0 || selectedLabourslength == null || selectedLabourslength == '')" 
      type="button" 
      (click)="addLabourSidebar()">
      Add Labour
      <span class="next-step-text">(In Next Step)</span>
    </button>
    </div>  
</div>


<div *ngIf="(anyItemSelectedQuote()) && customerQuotation?.length>=0 && selectedItemsLabourQuote?.length>0" [ngClass]="isMobile ? 'bottom-boxQuoteMobile':'bottom-boxQuote'" style=" width: 34.6%;">
  <div class="bottom-contentQuote">

    <div class="total-items">{{selectedItemsCount}} items
      <p class="total-amount">₹{{totalSelectedEditPriceQuote | number:'1.0-0'}}</p>
      <!-- <p *ngIf="activeTab === 'quote-approved'" class="total-amount">₹{{quoteApprovedPrice | number:'1.2-2' }}</p> -->
    </div>
    <div class="update-quote-btn2">
      <button class="create-quote-btn"  type="button" 
        (click)="openPopup()">Update Customer Quote</button>
    </div>
  </div>  
</div>
<!-- ============================================ -->
  <!-- Edit labour name Sidebar -->
  <div class="overlay1" [class.visible]="isEditLabourSidebarOpen" (click)="closeLabourEditSidebar()"></div>
  <div *ngIf="isEditLabourSidebarOpen" class="edit-labour-sidebar">
    <div class="labour-sidebar-header">
      <h2>{{ selectedLabour?.id || selectedLabour?.labourId ? 'Edit Labour' : 'Add Labour' }}</h2>
      <mat-icon (click)="closeLabourEditSidebar()" style="margin-right: 0px;">close</mat-icon>
    </div>
  
    <form [formGroup]="laborDetails">
      <div class="laboue-price-column" >
        <mat-form-field appearance="outline" class="full-width" style="margin-top: 10px;width:100%">
          <mat-label>Labour Name</mat-label>
          <input
            matInput
            formControlName="labourName"
            [(ngModel)]="selectedLabour.labourName"
            placeholder="Enter Labour Name"
            name="labourName"
          />
        </mat-form-field>
      </div>
  
      <div class="laboue-price-column" >
        <mat-form-field appearance="outline" class="full-width" style="width:100%;">
          <mat-label>Labour Amount</mat-label>
          <input
            matInput
            formControlName="labourAmount"
            [(ngModel)]="selectedLabour.labourAmount"
            placeholder="Enter Labour Amount"
            name="labourPrice"
          />
        </mat-form-field>
      </div>
    </form>
  
    <div class="item-update" style="margin-top: -14px;">
      <button class="item-update-btn" *ngIf="selectedLabour?.id || selectedLabour?.labourId" (click)="saveLabour()">Save Labour</button>
<button class="item-update-btn" *ngIf="!(selectedLabour?.id || selectedLabour?.labourId)" (click)="addLabour()">Add Labour</button>
 </div>
  </div>
  
  <!-- Popup for skip labour -->
    <!-- <div class="cancel-popup-container" *ngIf="showCancelConfirmation">
      <div class="cancel-popup">
        <button class="cancel-popup-close" (click)="closeCancelPopup()">×</button>
        <p>Are you sure you want to skip?</p>
        <button class="cancel-popup-btn confirm-btn" (click)="confirmAction()">Yes</button>
        <button class="cancel-popup-btn no-btn" (click)="closeCancelPopup()">No</button>
      </div>
    </div> -->
    <div *ngIf="isPopupOpen" class="popup">
      <div class="popup-content">
      <img src="{{successIcon}}" alt="successIcon" style="width: 36%;">
      <h3 *ngIf="quoteId === null || quoteId === 'null'">Quote Created</h3>
      <h3 *ngIf="quoteId !== null && quoteId !== 'null'">Quote Updated</h3>
      <!-- <p class="order-label" *ngIf="quoteId === null || quoteId === 'null'">

        <span class="order-id">Quotation id {{ resData?.id }}</span>
      </p>
      <p class="order-label" *ngIf="quoteId !== null && quoteId !== 'null'">
        <span class="order-id">Quotation ID {{ quoteId }}</span>
      </p> -->
      <p class="order-label" *ngIf="quoteId === null || quoteId === 'null'">
        <span class="order-id">Quotation id 
          <span [ngStyle]="{ 'color': '#144579', 'font-weight': 'bold' }">{{ resData?.id }}</span>
        </span>
      </p>
      
      <p class="order-label" *ngIf="quoteId !== null && quoteId !== 'null'">
        <span class="order-id">Quotation id 
          <span [ngStyle]="{ 'color': '#144579', 'font-weight': 'bold' }">{{ quoteId }}</span>
        </span>
      </p>
      <p class="date"><span>{{resData?.placedOn | date:'dd-MMM-yyyy, hh:mm a'}}</span></p>
      <p class="thank you">You have successfully updated quotation</p>
    
      <button class="goto-order" *ngIf="quoteId === null || quoteId === 'null'" (click)="closeSidebar1()">View Updated Customer Quote</button>
      <button class="goto-order" *ngIf="quoteId !== null && quoteId !== 'null'" (click)="closeSidebar1()">View Updated Customer Quote</button>
    </div>
      </div>
  </mat-list>


  
</div>

</mat-sidenav-container>

<div class="overlay" [class.visible]="isApprovePopUpOpen"></div>
<div *ngIf="isApprovePopUpOpen" class="popup">
  <div class="popup-content">
    <img src="{{successIcon}}" alt="successIcon" style="width: 36%;">
    <h3 *ngIf="quoteId === null || quoteId === 'null'">Quote Approved</h3>
    <h3 *ngIf="quoteId !== null && quoteId !== 'null'">Quote Approved</h3>
    <p class="order-label" *ngIf="quoteId === null || quoteId === 'null'">
      <span class="order-id">Quotation id {{ resData?.id }}</span>
    </p>
    <p class="order-label" *ngIf="quoteId !== null && quoteId !== 'null'">
      <span class="order-id">Quotation ID {{ quoteId }}</span>
    </p>
    <p class="date"><span>{{resData?.placedOn | date:'dd-MMM-yyyy, hh:mm a'}}</span></p>
    <p class="thank you">You have successfully Approved quotation</p>

    <button class="goto-order" *ngIf="quoteId === null || quoteId === 'null'" (click)="openSidebarQuote1('view')">View Approved Quote</button>
    <button class="goto-order" *ngIf="quoteId !== null && quoteId !== 'null'" (click)="openSidebarQuote1('view')">View Approved Quote</button>
  </div>
</div>
<div #dynamicContainer1></div>
<div #dynamicContainer></div>

<!-- <div #dynamicContainer2></div> -->
    <!-- Job Card Created Pop-up Modal -->
    <!-- <div class="confirmation-sidebar" *ngIf="isConfirmationSidebarOpen && jobCardDetails" (click)="$event.stopPropagation()"> -->
    
      <!-- <div class="overlay" [class.visible]="isJobCardCreatedPopupVisible" (click)= "closeJobCardPopup()"></div>
      <div *ngIf="isJobCardCreatedPopupVisible" class="modal-overlay">
      <div class="modal-content">
        <div class="modal-header">
          <img src="{{successIcon}}" alt="Success Icon" class="success-icon">
          <h2>Job Card Created</h2>
          <button class="close-modal" (click)="closeJobCardPopup()">
            <mat-icon>close</mat-icon>
        </button>
        </div>
        <div class="modal-body">
          <p>Job Card ID: <span class="job-card-id">{{jobCardId}}</span></p>
          <p><span class="createdAt1">{{date}}</span></p>
          <p>Let us deliver an awesome experience!</p>
        </div>
        <div class="modal-footer">
          <button class="share-job-card-btn" (click)="navigateToDetails()">View Job Card</button>
        </div>
      </div>
    </div> -->