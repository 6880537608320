import { ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AspMyBookingsService } from './asp-my-bookings.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { fuseAnimations } from '@fuse/animations';
import { SideBarJobcardComponent } from '../side-bar-jobcard/side-bar-jobcard.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
// import { AspRecordPaymentComponent } from '../asp-record-payment/asp-record-payment.component';
import { NgForm } from '@angular/forms';
import { AspLoadingService } from '../asp-loading/asp-loading.service';

interface Booking {
  createdAt: string | number | Date;
  bookingId: string;
  phoneNumber: string;
  name: string;
}

@Component({
  selector: 'app-asp-my-bookings',
  templateUrl: './asp-my-bookings.component.html',
  styleUrls: ['./asp-my-bookings.component.scss'],
  animations: fuseAnimations
})
export class AspMyBookingsComponent implements OnInit
 {
  loading: boolean = false;
  requestList: Booking[] = [];
  formSubmitted: boolean = false;
  isSidebarOpen: boolean = false;
  customerName: string = '';
  customerMobile: string = '';
  isPopupOpen: boolean = false;
  successIcon: string = '../../../../assets/icons/icons8-ok.gif';
  data: Booking | null = null;
  currentText: string = '';
  searchOptions = ["Search By 'Booking ID'", "Search By 'Name'", "Search By 'Mobile Number'"];
  currentIndex: number = 0;
  textInterval: any;
  searchText: string = ''; // Search text property
  bookingId: string;
  orderID: void;
  createdAt: string;
  isJobCardSidebarOpen = false;
  isConfirmationSidebarOpen = false;
  submitted = false;
  filteredRequestList: Booking[] = []; // Filtered list

  // New properties for vehicle registration form
  vehicleForm1: FormGroup;
  vehicleInfo: boolean = false;
  vehicleData: any;
  sidebarOpen: boolean;
  isMobile: boolean;
  showRecordPayment = false;
  isKeyboardOpen = false;


  constructor(
    private aspMyBookingsService: AspMyBookingsService,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private resolver: ComponentFactoryResolver,
    private breakpointObserver: BreakpointObserver,
    private aspLoadingService: AspLoadingService,
  ) {
      this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); }

  ngOnInit(): void {
    this.fetchBookings();
    this.scrollText();

    // Initialize vehicleForm1 instead of vehicleForm
    this.vehicleForm1 = this._formBuilder.group({
      regNo: ['', [Validators.required,Validators.maxLength(12)]]
    });

    this.vehicleForm1.get('regNo')?.valueChanges.subscribe(value => {
      if (value) {
        const formattedValue = this.formatRegNo(value);
        this.vehicleForm1.get('regNo')?.setValue(formattedValue, { emitEvent: false });
      }
    });

    // Check for existing vehicle data in local storage
    this.vehicleData = JSON.parse(localStorage.getItem('vehicleData'));
    this.vehicleInfo = !!this.vehicleData; // Set to true if vehicleData exists
  }

  get f() {
    return this.vehicleForm1.controls;
  }
  @HostListener('window:resize', [])
  onResize() {
    this.checkIfKeyboardIsOpen();
  }

  scrollText() {
    this.textInterval = setInterval(() => {
      this.currentText = this.searchOptions[this.currentIndex];
      this.currentIndex = (this.currentIndex + 1) % this.searchOptions.length;
    }, 2000); // Change scroll interval as needed
  }

  onInputChange(): void {
    if (this.searchText.length === 0) {
      this.fetchBookings();
      this.scrollText(); // Restart scroll text when input is cleared
    } else {
      clearInterval(this.textInterval); // Stop scroll text when typing
    }
    this.filterRequests(); // Filter requestList based on search input
  }

  filterRequests(): void {

    const searchValue = this.searchText.trim().toLowerCase();
    this.requestList = this.requestList.filter(request => {
      const idMatch = request.bookingId.toString().toLowerCase().includes(searchValue);
      const nameMatch = request.name.toLowerCase().includes(searchValue);
      const phoneMatch = request.phoneNumber.includes(this.searchText);

      return idMatch || nameMatch || phoneMatch;
    });


  }

  clearSearch(): void {
    this.searchText = '';
    this.fetchBookings();
    this.scrollText();
    this.requestList = this.requestList || [];
  }

  toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  fetchBookings(): void {
    this.aspLoadingService.show();
    let garageKey = localStorage.getItem('garageKey');
    this.aspMyBookingsService.getMyBookings(garageKey).subscribe(
      (result: any) => {
        this.aspLoadingService.hide();
        if (result && result.code == 200) {
          this.requestList = result.data.bookings;
          this.requestList.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        }
      },
      (error: HttpErrorResponse) => {
        this.aspLoadingService.hide();
        console.error('Error fetching bookings:', error);
      }
    );
  }

  // onSubmit(form: any): void {
  //   this.formSubmitted = true;
  //   // You can modify and enable the booking creation logic here
  //   if (form.valid) {
  //     const garageKey = localStorage.getItem("garageKey");
  //     const userKey = localStorage.getItem('spareUserKey');

  //     this.aspMyBookingsService.createBooking(garageKey, userKey, this.customerName, this.customerMobile).subscribe(
  //       (res: any) => {
  //         if (res && res.code === 200) {
  //           const newBooking: Booking = {
  //             bookingId: res.data.bookingId,
  //             name: this.customerName,
  //             phoneNumber: this.customerMobile,
  //             createdAt: res.data.createdAt,
  //           };
           
  //           this.addNewBookingToList(newBooking); 
  //           this.orderID=res.data;
  //           this.openPopup(res.data.bookingId, res.data.createdAt); 
  //           this.toggleSidebar(); 
  //           this.formSubmitted = false;
  //         } else {
  //           console.error('Failed to create booking:', res.message);
  //           this.toastr.error('Error creating booking');
  //         }
  //       },
  //       (error: HttpErrorResponse) => {
  //         console.error('Error creating booking:', error);
  //         this.toastr.error('Error creating booking');
  //       }
  //     );
  //   } else {
  //     form.controls['customerName'].markAsTouched();
  //     form.controls['customerMobile'].markAsTouched();
    
  // }
  // }
  onSubmit(form: NgForm): void {
    this.formSubmitted = true;
    
    if (form.valid) {
      this.aspLoadingService.show();
      const garageKey = localStorage.getItem("garageKey");
      const userKey = localStorage.getItem('spareUserKey');
  
      this.aspMyBookingsService.createBooking(garageKey, userKey, this.customerName, this.customerMobile).subscribe(
        (res: any) => {
          if (res && res.code === 200) {
            this.aspLoadingService.hide();
            const newBooking: Booking = {
              bookingId: res.data.bookingId,
              name: this.customerName,
              phoneNumber: this.customerMobile,
              createdAt: res.data.createdAt,
            };
  
            this.addNewBookingToList(newBooking);
            this.orderID = res.data;
            this.openPopup(res.data.bookingId, res.data.createdAt);
            this.toggleSidebar();
            this.formSubmitted = false;
          } else {
            this.aspLoadingService.hide();
            this.toastr.error('Error creating booking');
          }
        },
        (error: HttpErrorResponse) => {
          this.aspLoadingService.hide();
          this.toastr.error('Error creating booking');
        }
      );
    } else {
      form.controls['customerName'].markAsTouched();
      form.controls['customerMobile'].markAsTouched();
    }
  }

  addNewBookingToList(newBooking: Booking): void {
    this.requestList.unshift(newBooking);
  }

  openPopup(bookingId: string, createdAt: string): void {
    this.bookingId = bookingId;
    this.createdAt = createdAt;
    this.isPopupOpen = true;
  }

  closePopup(): void {
    this.isPopupOpen = false;
  }

  shareOnWhatsApp(data): void {
    if (data) {
      const garageName = localStorage.getItem('spareSelectedGarageName') || 'AUTOVERSE GARAGE';
      const message = `Hello customer,\n\nYour booking has been created successfully.\n\nBooking ID: ${data.bookingId}\nName: ${data.name}\nMobile: ${data.phoneNumber}\n\nBest regards,\n${garageName}`;
      // const url = `https://web.whatsapp.com/send?text=${encodeURIComponent(message)}`;
      const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;

      window.open(url, '_blank');
    } else {
      alert('No booking data available to share.');
    }
  }

  toggleJobCardSidebar(bookingId: string = '', name: string = '', phoneNumber: string = ''): void {
    this.isJobCardSidebarOpen = !this.isJobCardSidebarOpen;

    if (this.isJobCardSidebarOpen) {
      this.bookingId = bookingId;
      this.customerName = name;
      this.customerMobile = phoneNumber;
    }
  }

  toggleConfirmationSidebar(): void {
    this.isConfirmationSidebarOpen = !this.isConfirmationSidebarOpen;

    // Close other sidebars if the confirmation sidebar is opened
    if (this.isConfirmationSidebarOpen) {
      this.isSidebarOpen = false;
      this.isJobCardSidebarOpen = false;
    }
  }

  confirmCarDetails(): void {
    this.toggleConfirmationSidebar();
    this.isPopupOpen = true;
  }

  onRegDecodeSubmit(): void {
    this.loading = true;

    this.aspMyBookingsService.regDecode(this.f.regNo.value.replace(/\s/g, '')).subscribe(res => {
      // handle the response
    });
  }

  formatRegNo(value: string): string {
    value = value.replace(/\s+/g, '').toUpperCase();
    if (value.length > 2) {
      value = value.slice(0, 2) + ' ' + value.slice(2);
    }
    if (value.length > 5) {
      value = value.slice(0, 5) + ' ' + value.slice(5);
    }
    return value;
  }

  onSearch(): void {
    if (this.searchText.trim() === '') {
      this.fetchBookings();
      return;
    }

    clearInterval(this.textInterval);

    const searchLower = this.searchText.toLowerCase();
    this.requestList = this.requestList.filter(
      request =>
        request.bookingId.toLowerCase().includes(searchLower) ||
        request.name.toLowerCase().includes(searchLower) ||
        request.phoneNumber.includes(searchLower)
    );
  }

  onEnterPress(event: any): void {
    this.filterRequests();
  }

  @ViewChild('dynamicContainer', { read: ViewContainerRef }) container!: ViewContainerRef;
  @ViewChild('dynamicContainer', { read: ViewContainerRef }) recordpaymentcontainer!: ViewContainerRef;


  // @ViewChild(AspRecordPaymentComponent) recordPayment!: AspRecordPaymentComponent;
  loadorderdetails(bookingId,name,phoneNumber) {
    
    // const factory = this.resolver.resolveComponentFactory(SideBarJobcardComponent);
    // const componentRef = this.container.createComponent(factory);

    bookingId = bookingId;
    name = name;
    phoneNumber = phoneNumber;
    const factory = this.resolver.resolveComponentFactory(SideBarJobcardComponent);
    const componentRef = this.container.createComponent(factory);

    // Access the instance and call the method
    const componentBInstance = componentRef.instance as SideBarJobcardComponent;
    componentBInstance.bookingId = bookingId;
    componentBInstance.name=name;
    componentBInstance.phoneNumber=phoneNumber;
    componentBInstance.closeSidebar(true);

  }
// openRecordPaymentSidebar() {
//   this.container.clear(); // Clear any existing component

//   const factory = this.resolver.resolveComponentFactory(AspRecordPaymentComponent);
//   const componentRef = this.container.createComponent(factory);

//   // Optional: Add a close method to remove the sidebar
//   const componentInstance = componentRef.instance as AspRecordPaymentComponent;
//   componentInstance.closeSidebar = () => {
//     this.container.clear(); // Close sidebar when called
//   };
// }

  // closeSidebar(): void {
  //   this.sidebarOpen = false;
  // }
  checkIfKeyboardIsOpen() {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.clientHeight;
    
    // If window height reduces significantly, assume keyboard is open
    this.isKeyboardOpen = windowHeight < documentHeight * 0.8;

    // Update button position dynamically
    const submitBtn = document.querySelector('.sbmtbtn') as HTMLElement;
    if (submitBtn) {
      submitBtn.style.transform = this.isKeyboardOpen ? 'translateY(-40px)' : 'translateY(0)';
    }
  }
}
