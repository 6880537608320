import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'app/main/globals';
import { BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AspLoadingService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.loadingSubject.asObservable();


  constructor(
    private http: HttpClient
  ) { }

  show(): void {
    this.loadingSubject.next(true);
  }

  hide(): void {
    this.loadingSubject.next(false);
  }

  getLoadingMsg(){
    let paramData ={
      source: "web" 
    }
    // console.log(paramData);
    console.log('-----get loading msg-----------');  
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` +'/app/getAppData',paramData);
  }
}
