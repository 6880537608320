<div class="customer-quote">
    <header>
      <div class="back-button">
        <span class="title1">Customer Quotation</span>
        <div *ngIf="quoteId">
          <h2>{{quoteId}}</h2>
        </div>
      </div>
    </header>

    <div class="detailsQuote">
        <div class="logoModel">
            <img [src]="makeImg" alt="Icon" class="img">
            <div class="model-info">
                <span class="model-name">{{make}}</span>
                <span class="model-details">{{model}}, {{fuelType}}</span>
                <span class="model-details">{{registrationNo}}</span>
            </div>
        </div>

        <!-- Tabs -->
        <mat-tab-group animationDuration="0ms" [(selectedIndex)]="activeTabIndex">
            <!-- Quote Received -->
            <mat-tab class="request-button" label="QUOTE RECEIVED">
            <!-- <mat-tab>
                <ng-template mat-tab-label>
                    <button mat-raised-button color="primary">QUOTE RECEIVED</button>
                </ng-template> -->
                <div class="tab-content-sec quote-received">
                    <div  class="items">
                        <div class="request-info">Quote received for {{quoteReceivedDetails?.length>0 ? quoteReceivedDetails.length : 0}} items </div>
                        <div class="item" >
                            <div class="checkboxselect">
                                <!-- <input type="checkbox" [checked]="selectAllQuote" (change)="toggleSelectAllQuote()">
                                <span>Select All</span> -->

                                <mat-checkbox [(ngModel)]="selectAllQuote" (change)="toggleSelectAllQuote()" color="primary" >
                                    Select All
                                </mat-checkbox>                            
                            </div>
                            <div>
                                <span class="customer-quote-price">₹{{quoteReceivedTotal | number:'1.2-2'}}</span>
                                <div class="quote_total_amount">Total Amount</div>
                            </div>
                        </div>
                        <div class="spare-part-header">
                            <h2 style="font-weight: bold;">Spare Parts</h2>
                        </div>

                        <div *ngFor="let received of quoteReceivedDetails">
                            <div class="quotereceived-items" *ngIf="received.type=='parts'">
                                <div class="lines">
                                    <!-- <input type="checkbox" (change)="toggleItemSelectionQuote()" value="received.itemId">
                                    <span class="part-name">{{received.itemName}}</span>   -->

                                    <mat-checkbox
                                        [(ngModel)]="received.checked"
                                        (change)="checkIndividualOption()"
                                        color="accent"
                                        class="part-name"
                                    >
                                        {{received.itemName}}
                                    </mat-checkbox>
                                    
                                    <span class="mrp">₹{{received.sellingPrice*received.quantity | number:'1.2-2'}}</span>            
                                </div>
                                
                                <hr style="border: 1px solid #c0bebe;" />
                                <div class="quantity-control">
                                    <div class="brand">{{received.brand?received.brand:""}}</div>                            
                                    <div class="qty">{{received.quantity}} units</div>                            
                                </div>
                            </div>
                        </div>
                    </div>
            
                    <div class="labour-section-details" >
                        <div class="labour-header-title">
                            <h2 style="font-weight: bold !important; margin: 5px 0px !important;">Labour Charges</h2>
                        </div>

                        <div *ngFor="let received of quoteReceivedDetails">
                            <div class="quotereceived-items" *ngIf="received.type=='labour'">
                                <div class="checkboxselect" >
                                    <div class="labour-lines">
                                        <!-- <input type="checkbox" (change)="toggleItemSelectionQuote()">
                                        <span class="part-name">{{received.labourName}}</span> -->
                                        <mat-checkbox
                                        [(ngModel)]="received.checked"
                                        (change)="checkIndividualOption()"
                                        color="accent"
                                        class="part-name"
                                        >
                                            {{received.labourName}}
                                        </mat-checkbox>

                                        <span class="mrp">₹{{received.labourAmount*received.quantity | number:'1.2-2'}}</span>
                                    </div>

                                    <hr style="border: 1px solid #c0bebe;" />
                                    <div class="quantity-control">
                                        <div class="brand">{{received.brand?received.brand:""}}</div>                            
                                        <div class="qty">{{received.quantity}} units</div>                            
                                    </div>
                                </div>                
                            </div>
                        </div>
                    </div>

                    <div class="bottom-contentQuote" *ngIf="isActiveApprove">
                        <div class="total-items">{{quoteReceivedSelectedCount}} items
                          <p class="total-amount">₹{{quoteReceivedSelectedTotal | number:'1.2-2'}}</p>
                        </div>
                        <div class="btn-sec">
                            <button class="add-labour-bottom-btn" type="button" (click)="approveQuotation()">Approve Quote</button>
                        </div>
                    </div> 
                </div>
            </mat-tab>

            <!-- Quote Approved -->
            <mat-tab class="request-button" label="QUOTE APPROVED">
            <!-- <mat-tab>
                <ng-template mat-tab-label>
                    <button mat-raised-button color="primary">QUOTE APPROVED</button>
                </ng-template> -->
                <div class="tab-content-sec quote-approved">
                    <div class="empty-state" *ngIf="quoteApprovedDetails?.length==0">                      
                        <img src="../../../../assets/images/request-details images/requestdetailsimage.png" alt="Quote Not Approved" class="quote-image">
                        <div class="message">
                            <strong>Quote Not Approved Yet!</strong>
                            <p>Awaiting your approval</p>
                        </div>                    
                        <button class="approveNow" (click)="switchToTab(0)">APPROVE QUOTE NOW</button>
                    </div>
                    <div *ngIf="quoteApprovedDetails?.length>0">
                        <div  class="items">
                            <div class="request-info">Quote approved for {{quoteApprovedDetails?.length>0 ? quoteApprovedDetails.length : 0}} items </div>
                            <div class="item" >
                                <div class="checkboxselect">    
                                    <!-- <mat-checkbox [(ngModel)]="selectAllQuote" (change)="toggleSelectAllQuote()" color="primary" >
                                        Select All
                                    </mat-checkbox> -->
                                    <div class="quote_total_amount">Total Amount</div>
                                </div>
                                <div>
                                    <span class="customer-quote-price">₹{{quoteApprovedTotal | number:'1.2-2'}}</span>
                                    <!-- <div class="quote_total_amount">Total Amount</div> -->
                                </div>
                            </div>
                            <div class="spare-part-header">
                                <h2>Spare Parts</h2>
                            </div>
    
                            <div *ngFor="let approved of quoteApprovedDetails">
                                <div class="quotereceived-items" *ngIf="approved.type=='parts'">
                                    <div class="lines">    
                                        <!-- <mat-checkbox
                                            [(ngModel)]="approved.checked"
                                            (change)="checkIndividualOption()"
                                            color="accent"
                                            class="part-name"
                                        >
                                            {{approved.itemName}}
                                        </mat-checkbox> -->
                                        <span class="part-name">{{approved.itemName}}</span>
                                        <span class="mrp">₹{{approved.sellingPrice*approved.quantity | number:'1.2-2'}}</span>            
                                    </div>
                                    
                                    <hr style="border: 1px solid #c0bebe;" />
                                    <div class="quantity-control">
                                        <div class="brand">{{approved.brand?approved.brand:""}}</div>                            
                                        <div class="qty">{{approved.quantity}} units</div>                            
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <div class="labour-section-details" >
                            <div class="labour-header-title">
                                <h2 style="font-weight: bold !important; margin: 5px 0px !important;">Labour Charges</h2>
                            </div>
    
                            <div *ngFor="let approved of quoteApprovedDetails">
                                <div class="quotereceived-items" *ngIf="approved.type=='labour'">
                                    <div class="checkboxselect" >
                                        <div class="labour-lines">
                                            <!-- <mat-checkbox
                                            [(ngModel)]="approved.checked"
                                            (change)="checkIndividualOption()"
                                            color="accent"
                                            class="part-name"
                                            >
                                                {{approved.labourName}}
                                            </mat-checkbox> -->
                                            <span class="part-name">{{approved.labourName}}</span>
                                            <span class="mrp">₹{{approved.labourAmount*approved.quantity | number:'1.2-2'}}</span>
                                        </div>
    
                                        <hr style="border: 1px solid #c0bebe;" />
                                        <div class="quantity-control">
                                            <div class="brand">{{approved.brand?approved.brand:""}}</div>                            
                                            <div class="qty">{{approved.quantity}} units</div>                            
                                        </div>
                                    </div>                
                                </div>
                            </div>
                        </div>
    
                        <!-- <div class="bottom-contentQuote" *ngIf="isActiveComplete">
                            <div class="total-items">{{quoteReceivedSelectedCount}} items
                              <p class="total-amount">₹{{quoteReceivedSelectedTotal | number:'1.2-2'}}</p>
                            </div>
                            <div class="btn-sec">
                                <button class="add-labour-bottom-btn" type="button" (click)="approveQuotation()">Approve Quote</button>
                            </div>
                        </div>  -->
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
        <!-- End Tabs -->
    </div> 
    
</div>


<div *ngIf="isPopupOpen" class="popup">
    <div class="popup-content">
        <img src="{{successIcon}}" style="width: 36%;" alt="">
        <h3>Quote Approved</h3>
        <p class="order-label" *ngIf="quoteId === null || quoteId === 'null'">
            <span class="order-id">Quotation id {{ resData?.id }}</span>
        </p>
        <p class="order-label">
            <span class="order-id">Quotation id {{ quoteId }}</span>
        </p>
        <!-- <p class="date"><span>{{resData?.placedOn | date:'dd-MMM-yyyy, hh:mm  a': 'GMT+1060'}}</span></p> -->
        <p class="thank you">Quotation approved successfully!!!</p>
    
        <button class="goto-order"  (click)="viewApprovedQuote()">View Approved Quote</button>
    </div>
</div>