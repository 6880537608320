<!-- <div class="navigation-buttons" *ngIf="(images?.length>0)">
  <button (click)="goToPreviousPage()" [disabled]="currentPage === 1" class="previous"><</button>
  <button (click)="goToNextPage()" [disabled]="currentPage === totalPages" class="next">></button>
</div> -->
<!-- <header class="quotation-header" > -->
  <div class="back-button" *ngIf="(currentPage === 1 || currentPage === 0)||((currentPage === 2 || currentPage === 0) && (images?.length>0))">
     <!-- <i (click)="closeSidebar(false)" class="material-icons" style="color: black;font-weight: bold; cursor: pointer;">arrow_back</i> -->
     <span class="title">Check In Report</span>
     <i class="material-icons" style="width: 2%;color: black;cursor: pointer;z-index: 9;" (click)="openBottomSheet()">more_vert</i>
  </div>
<!-- </header> -->
<div style="width: 100%;top: -161px;;position: relative;" id="quotationContent">
<!-- <div class="form-container" id="page1" *ngIf="currentPage === 1 || currentPage === 0" > -->
 
<div class="form-container" id="page1" >

  <div>
  <div class="documents1 form-page">
    <img class="icon" src="../../../../assets/images/my-job-card-images/reportpage.png">
    <header class="form-header">
      <div class="business-info">
        <h1>{{data?.garageName | uppercase}}</h1>
        <p class="p">{{data?.garageAddress}}, {{data?.city}}</p>
        <p class="p"><strong>Mobile:</strong> {{data?.garagePhone}}</p>
        <p class="p"><strong>Email: </strong>{{ data?.email}}</p>
        <p class="p"><strong>Created On: </strong>{{ data?.createdOn | date:'dd MMM yyyy, h:mm a': 'GMT+1060' }}</p>

      </div>
      <h2>VEHICLE CHECK IN REPORT</h2>
      <div style="margin-top: -30px;">
        <h2>JOB CARD ID: {{jobCard}}</h2>
      </div>
    </header>
  
        <!-- Main Layout -->
        <div class="form-grid">
          <!-- Left Section -->
          <div class="form-left">
            <!-- Collection and Delivery -->
            <div  class="documents-section">
              <div class="documents-header">
                <span>CUSTOMER DETAILS</span>
              </div>
              <div class="content">
                <p><strong>Name:</strong> {{data?.customerName}}</p>
                <p><strong>Mobile:</strong> {{data?.customerMobile}}</p>
                <p><strong>Email:</strong> {{data?.customerEmail}}</p>
              </div>
            </div>
      
            <!-- Vehicle Details -->
            <div  class="documents-section">
              <div class="documents-header">
                <span>VEHICLE DETAILS</span>
              </div>
              <div class="content">
              <p><strong>Reg No:</strong> {{data?.registrationNumber}}</p>
              <p><strong>Odometer Reading:</strong> {{data?.odometer}}</p>
              <p>
                <span class="vehicle-info"> <strong>Make:</strong> {{data?.make}}</span> 
                <span class="vehicle-info"> <strong>Model:</strong> {{data?.model}}</span>
              </p>
              <p>
                <span class="vehicle-info"><strong>Fuel:</strong> {{data?.fuelType}}</span> 
                <span class="vehicle-info"><strong>Year:</strong> {{data?.year}}</span>
              </p>
              <p><strong>Varient:</strong> {{data?.variant}}</p>
              <p><strong>VIN:</strong> {{data?.vinNumber || 'Unidentified'| uppercase }}</p>
              <p *ngIf="data?.engineNumber"><strong>Engine No:</strong> {{data?.engineNumber}}</p>
              </div>
            </div>
      
            <!-- Documents -->
              <div class="documents-section" *ngIf="accessoriesYes?.length > 0">
                <div class="documents-header">
                  <span>ACCESSORIES</span>
                  <!-- <div class="yes-no">
                    <span>Yes</span>
                    <span>No</span>
                  </div> -->
                </div>
                <div class="documents-list" *ngFor="let list of accessoriesYes">
                  <div class="document-item">
                    <span>{{list}}</span>
                    <!-- <div class="status-icons">
                      <span  class="yes-icon">&#10003;</span>
                      <span *ngIf="!list.isYes" class="no-icon">&#10003;</span> 
                      <span class="no-icon"></span>
                    </div> -->
                  </div>
                  
                </div>
              </div>
              
            </div>
         
      
          <!-- Right Section -->
          <div class="form-right">
            <!-- Items -->
            <!-- <div class="items">
              <h3>ITEMS</h3>
              <p>Spare Key: Yes [] No []</p>
              <p>Locking Wheel Nuts: Yes [] No []</p>
              <p>Sat. Nav. Disc: Yes [] No []</p>
            </div> -->
            <div class="documents-section" *ngIf="data?.serviceType?.length > 0">
              <div class="documents-header">
                <span>SERVICE TYPE</span>
              </div>
              
              <div class="documents-list" *ngFor="let types of data?.serviceType">
                <div class="document-item">
                  <span>{{types}}</span>
                </div>
                <!-- <div class="document-item">
                  <span>Service Book</span>
                  <div class="status-icons">
                    <span class="yes-icon"></span>
                    <span class="no-icon">&#10003;</span>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="documents-section" *ngIf="data?.issues?.length > 0">
              <div class="documents-header">
                <span>ISSUES</span>
              </div>
              
              <div class="documents-list" *ngFor="let issue of data.issues">
                <div class="document-item">
                  <span>{{issue}}</span>
                </div>
                <!-- <div class="document-item">
                  <span>Service Book</span>
                  <div class="status-icons">
                    <span class="yes-icon"></span>
                    <span class="no-icon">&#10003;</span>
                  </div>
                </div> -->
              </div>
            </div>
            <!-- Fuel & Mileage -->
            <div class="fuel-mileage">
              <div class="documents-header">
                <span>FUEL LEVEL</span>
              </div>
              <img src="../../../../assets/images/Fuel.png" class="fuelImg">
              <div class="content">
                <p>Fuel Level:{{data?.fuelLevel}}% </p>
                <!-- <p>Delivery Mileage: _____________</p> -->
              </div>
            </div>
      
          </div>
      </div>
      

  <div class="overlay" [class.visible]="isBottomSheetOpen" (click)="closeBottomSheet()"></div>
        <!-- <mat-bottom-sheet-container *ngIf="isBottomSheetOpen">
            <mat-nav-list>
                <mat-list-item>
                    <span class="names">Share For Approval</span>
                    <i class="material" (click)="shareForApproval()">share</i>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item>
                    <span class="names">Download Quotation</span>
                    <img src="../../../../assets/images/download.png" style="height: 24px;cursor: pointer;" (click)="downloadPDF()" alt="">
                    
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item>
                    <span class="names">Edit Quotation</span>
                    <i class="material" (click)="openSidebarQuote()">edit</i>
                </mat-list-item>
            </mat-nav-list>
            
        </mat-bottom-sheet-container> -->
        <ng-template #bottomSheetTemplate >
          <div class="bottom-sheet-content">
            <div class="bottom" (click)="shareForApproval()">
              <span class="names">Share With Customer</span>
              <i class="material" >share</i>
          </div>
          <div class="bottom" (click)="downloadPDF()">
              <span class="names">Download Check-in Report</span>
              <img src="../../../../assets/images/download.png" style="height: 24px;"  alt="">
              
          </div>
          <div class="bottom" (click)="openInformationTab()">
              <span class="names">Edit Check-in Report</span>
              <i class="material" >edit</i>
          </div>
          </div>
        </ng-template> 
      </div>

      
    </div>
    </div>
    <!-- <div class="form-container"  id="page2"  *ngIf="(currentPage === 2 || currentPage === 0) && (images?.length>0)"> -->
    <div class="form-container" style="margin-top: 17px;" id="page2"  *ngIf="damageImages?.length>0">

    <div class="documents1 form-page">
    
      <header class="form-header">
        <div class="business-info">
          <h1>{{data?.garageName}}</h1>
          <p class="p">{{data?.garageAddress}}, {{data?.city}}</p>
          <p class="p"><strong>Mobile:</strong> {{data?.garagePhone}}</p>
          <p class="p"><strong>Email:</strong>{{ data?.email}}</p>
        </div>
        <h2>EXTERIOR CHECKS</h2>
      </header>
  
      <div class="image-container">
        <div *ngFor="let imageUrl of damageImages"  class="image-URL" >
          <img [src]="imageUrl.url" alt="Image" class="image" crossorigin="anonymous" />
          <p class="tag">{{ (imageUrl.tag.replace('damage_', '') | lowercase) | titlecase }}</p>
        </div>
      </div>

  <div class="overlay" [class.visible]="isBottomSheetOpen" (click)="closeBottomSheet()"></div>
        <!-- <mat-bottom-sheet-container *ngIf="isBottomSheetOpen">
            <mat-nav-list>
                <mat-list-item>
                    <span class="names">Share For Approval</span>
                    <i class="material" (click)="shareForApproval()">share</i>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item>
                    <span class="names">Download Quotation</span>
                    <img src="../../../../assets/images/download.png" style="height: 24px;cursor: pointer;" (click)="downloadPDF()" alt="">
                    
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item>
                    <span class="names">Edit Quotation</span>
                    <i class="material" (click)="openSidebarQuote()">edit</i>
                </mat-list-item>
            </mat-nav-list>
            
        </mat-bottom-sheet-container> -->
        <ng-template #bottomSheetTemplate >
          <div class="bottom-sheet-content">
            <div class="bottom">
              <span class="names">Share With Customer</span>
              <i class="material" (click)="shareForApproval()">share</i>
          </div>
          <div class="bottom">
              <span class="names">Download Check-in Report</span>
              <img src="../../../../assets/images/download.png" style="height: 24px;cursor: pointer;" (click)="downloadPDF()" alt="">
              
          </div>
          <div class="bottom">
              <span class="names">Edit Check-in Report</span>
              <i class="material"  (click)="openInformationTab()">edit</i>
          </div>
          </div>
        </ng-template> 

      </div>

      </div>

      <div class="form-container" style="margin-top: 17px;" id="page3"  *ngIf="images?.length>0">

        <div class="documents1 form-page">
        
          <header class="form-header">
            <div class="business-info">
              <h1>{{data?.garageName}}</h1>
              <p class="p">{{data?.garageAddress}}, {{data?.city}}</p>
              <p class="p"><strong>Mobile:</strong> {{data?.garagePhone}}</p>
              <p class="p"><strong>Email:</strong>{{ data?.email}}</p>
            </div>
            <h2>IMAGES</h2>
          </header>
      
          <div class="image-container">
            <div *ngFor="let imageUrl of images"  class="image-URL" >
              <img [src]="imageUrl.url" alt="Image" class="image" crossorigin="anonymous" />
              <p class="tag">{{ (imageUrl.tag.replace('damage_', '') | lowercase) | titlecase }}</p>
            </div>
          </div>
    
          </div>
    
          </div>
    </div>
    <div>
    