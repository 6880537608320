<div style="    width: 100%;
    padding: 10px;">
  <header class="quotation-header">
    <div class="back-button">
       <i *ngIf="!isJobCardDetails" (click)="goBack()" class="material-icons" style="color: black;font-weight: bold; cursor: pointer;">arrow_back</i>
       <div class="title">Customer Invoice</div>
       <div class="delivery-status" *ngFor="let status of filters.jobCardStatus" >
        <label class="{{status.statusclass}}" *ngIf="data?.invoiceStatus && status.value==data?.invoiceStatus" >{{status.label}}</label>  
      </div>
       <i class="material-icons" style="width: 1%;color: black;cursor: pointer;" (click)="openMenu()">more_vert</i>
    </div>
 </header>
<div class="invoice-container">
 
 <div style="border: 1.5px solid;" id="invoiceContent">
  <div class="tax-invoice">
    TAX INVOICE
  </div>
    <div class="header" style="border-top: 1px solid;padding: 0px;">
      <div class="flexBox">
      <div class="company-details">
        <div style="width: 20%;">
        <img  src="../../../../assets/images/car-repair.png" class="imageLogo">
      </div>
        <div style="width: 80%;word-break: break-word;">
        <h2 class="gName">{{data?.garageName}}</h2>
        <p class="noMargin" >{{data?.garageAddress}}, {{data?.garageEmail}}</p>
        <p class="noMargin" ><strong>Mobile:</strong> {{data?.garagePhoneNumber}}</p>
      </div>
      </div>
      <div class="CustomerDetails">
        <p class="noMargin detailsHead" >Customer Details:</p>
        <p class="noMargin customerName"  *ngIf="details?.customerName">{{details?.customerName}}</p>
        <p class="noMargin"  *ngIf="details?.customerPhoneNumber">Ph: {{details?.customerPhoneNumber}}</p>
        <p class="noMargin"  *ngIf="details?.customerEmail">Email ID: {{details?.customerEmail}}</p>
      </div>
    </div>
      <div class="invoice-details1">
        <p>
          <span class="invoice-info detailsHead" style="border-left: none;">Invoice #:<p class="customerName">{{data?.invoiceId}}</p>  </span>
          <span class="invoice-info detailsHead" >Invoice Date:<p class="customerName">{{data?.createdAt | date:'dd-MMM-yyyy'}}</p></span>
        </p>
        <div style="padding: 9px 0px 0px 8px;">
          <p class="detailsHead">Registration Number: <span  class="customerName">{{details?.registrationNumber}} </span></p>
          <div >
          <p  class="detailsHead">Make: <span  class="customerName">{{details?.make}}</span></p>
          <p   class="detailsHead">Model: <span  class="customerName">{{details?.model}}</span></p>
        </div>
        <div >
          <p  class="detailsHead">Year: <span  class="customerName">{{details?.year}}</span></p>
          <p  class="detailsHead">Fuel Type: <span  class="customerName">{{details?.fuelType}}</span></p>
        </div>
          <p class="detailsHead">Variant: <span  class="customerName">{{details?.variant}}</span></p>
        </div>
      </div>
    </div>
    <table>
      <tr class="tableBorder" >
          <th class="hashTag" style="    width: 5% !important;
          padding: 0;
          padding-top: 7px;
          padding-bottom: 7px;">#</th>
          <th class="item-labour">Item/Labour</th>
          <!-- <th>HSN/SAC</th> -->
          <th class="gst">GST%</th>
          <th  class="gst" >Unit Price</th>
          <th  class="gst">Qty</th>
          <th  class="gst">Discount </th>
          <th  class="gst">Taxable</th>
          <th class="total-Right gst">Total</th>
      </tr>
      <tr *ngFor="let item of items123; let i = index">
        <td>{{ i + 1 }}</td>
        <td ><strong>{{ item.itemName | titlecase }}</strong>
          <p class="noMargin" *ngIf="item.brand" style="margin-bottom: 10px;">Brand: {{ item.brand }}</p>
          <p class="noMargin" *ngIf="item.hsncode && item.itemType == 'labour'">SAC: {{ item.hsncode }}</p>
          <p class="noMargin" *ngIf="item.hsncode && item.itemType == 'spare'">HSN: {{ item.hsncode }}</p>
          <p class="noMargin" *ngIf="item.partNumber">Part Number: {{ item?.partNumber }}</p>

        </td>
        <td class="rightAlign">{{ item.taxRate }}</td>
        <td  class="rightAlign">{{ item.sellingPrice | number: '1.0-0'  }}</td>
        <td  class="rightAlign">{{ item.quantity }}</td>
        <td  class="rightAlign">{{ item.discount }}%</td>
        <td  class="rightAlign">{{ item.totalTaxable | number: '1.0-0'  }}</td>
        <td   class="total-Right rightAlign">{{ item.itemTotal | number: '1.0-0' }}</td>
      </tr>
      <tr class="summary-row">
          <td></td>
          <td class="taxableItalic">Taxable Amount</td>
          <td ></td>
          <td ></td>
          <td ></td>
          <td ></td>
          <td ></td>
          <td class="total-Right rightAlign">₹{{data?.sumOfTaxableTotal| number: '1.0-0' }}</td>
      </tr>
      <tr class="summary-row summary-row1">
        <td></td>
        <td class="taxableItalic">CGST 9.0%</td>
        <td ></td>
        <td ></td>
        <td ></td>
        <td ></td>
        <td ></td>
        <td class="total-Right">₹{{data?.sumOfCgstTotal | number: '1.0-0' }}</td>
      </tr>
      <tr class="summary-row summary-row1">
        <td></td>
        <td class="taxableItalic" style="padding-bottom: 20px;">SGST 9.0%</td>
        <td ></td>
          <td ></td>
          <td ></td>
          <td ></td>
          <td ></td>
        <td class="total-Right"  style="padding-bottom: 20px;">₹{{data?.sumOfSgstTotal | number: '1.0-0' }}</td>
      </tr>
  
      <tr class="total-row">
        <td ></td>
          <td class="rightAlign">Total</td>
          <td colspan="2"></td>
          <td class="rightAlign">{{totalQuantity}}</td>
          <td ></td>
          <td class="rightAlign">{{data?.sumOfTaxableTotal + data?.sumOfCgstTotal + data?.sumOfSgstTotal | number: '1.0-0' }}</td>
          <td class="total-Right rightAlign">{{data?.sumOfItemTotal | number: '1.0-0' }} </td>
      </tr>
  </table>

  
    <div style="padding: 10px;">
      <p><strong style=" top: 10px; position: relative;">Notes:</strong></p>
      <p >Thank you for the Business</p>
    </div>
  </div>
  <div class="overlay" [class.visible]="isBottomSheetOpen" (click)="closeMenu()"></div>
  <div class="bottom-sheet1" *ngIf="isBottomSheetOpen && data?.invoiceStatus==1" >
      <mat-nav-list>
        <mat-list-item (click)="shareForApproval()">
          <span class="names">Share For Customer</span>
          <i class="material" >share</i>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item (click)="downloadPDF()">
          <span class="names">Download Invoice</span>
          <img src="../../../../assets/images/download.png" style="height: 24px;cursor: pointer;"  alt="">
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item (click)="openSidebarQuote()">
          <span class="names">Edit Invoice</span>
          <i class="material" >edit</i>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item (click)="confirmedInvoice()">
          <span class="names" >Mark As Confirmed</span>
          <i class="material" > payment</i>
          <!-- <i class="material" (click)="openSidebarQuote()">edit</i> -->
        </mat-list-item>
      </mat-nav-list>
  </div>
  <div class="bottom-sheet1" *ngIf="isBottomSheetOpen && data?.invoiceStatus==2" >
    <mat-nav-list>
      <mat-list-item (click)="shareForApproval()">
        <span class="names">Share For Customer</span>
        <i class="material" >share</i>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item (click)="downloadPDF()">
        <span class="names">Download Invoice</span>
        <img src="../../../../assets/images/download.png" style="height: 24px;cursor: pointer;"  alt="">
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <span class="names" (click)="openRecordPaymentSidebar()">Record Payment</span>
        <i class="material" (click)="openRecordPaymentSidebar()"> payment</i>
        <!-- <i class="material" (click)="openSidebarQuote()">edit</i> -->
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item (click)="shareForPayment()">
        <span class="names">Share Payment Link</span>
        <i class="material" >share</i>
      </mat-list-item>
    </mat-nav-list>
</div>
<div *ngIf="confirmedInvoicePopup" class="popup">
  <div class="popup-content">
  <img src="{{successIcon}}" alt="successIcon" style="width: 36%;">
  <h3 >Invoice Confirmed</h3>
  <p class="order-label" >
    <span class="order-id">Invoice ID {{ resIds }}</span>
  </p>
  <p class="date"><span>{{date |date:'dd MMM yyyy, h:mm a': 'GMT+1060'}}</span></p>
  <p class="thank you">Let us deliver an awesome experience</p>

  <button class="goto-order"  (click)="closePopupConfirm()">OKAY, GOT IT</button>
 </div>
  </div>
  <div class="overlay" [class.visible]="openInvoice" (click)="close()"></div>
  <mat-list class="sidebar1" *ngIf="openInvoice">
    <header>
      <div class="back-button1">
        <i (click)="CloseSidebarQuote()" class="material-icons-arrowback">arrow_back</i>
        <span class="title1">Edit Invoice</span>
          <h2 *ngIf="resIds" style="margin-top: 4px;margin-right: 9px;font-weight: bold;">{{ resIds }}</h2>
      </div>
    </header>
    <div class="detailsQuote">
      <div class="logoModel" >
          <img [src]="getMakeImageUrl(details?.make)" alt="Icon" style="width: 17%;padding: 4px;">
          <div class="model-info">
            <span class="model-name">{{details?.make}}</span>
            <span class="model-details">{{details?.model}}, {{details?.fuelType}}</span>
            <span class="model-name">{{details?.registrationNumber}}</span>
            <span class="model-name">{{details?.vinNumber || ''}}</span>
          </div>
      </div>
      <!-- <div class="buttons" *ngIf="activeTabs === 'quotation'">
        <button class="request-button" 
                [ngClass]="{'active': activeTab === 'quote-received'}"
                (click)="setActiveTab('quote-received')">QUOTE RECEIVED</button>
        <button class="request-button" 
                [ngClass]="{'active': activeTab === 'quote-approved'}"
                (click)="setActiveTab('quote-approved')">QUOTE APPROVED</button>
                
      </div> -->
      <!-- <mat-divider></mat-divider> -->
      <div  class="items" >
        <div class="item">
          <div class="checkboxselect">
            <input type="checkbox" [checked]="selectAllQuote" (change)="sidebarToggleSelectAllQuote()">
            <span>Select All</span>
          </div>
          <div style="text-align:end">
            <span class="customer-quote-price">₹ {{quoteGrandTotal || 0}}</span>
            <!-- itemsPrice -->
            <div class="quote_total_amount">Total Amount</div>
          </div>
        </div>
        <div class="labour-header-title">
        <h2 style="font-weight: bold;">Spare Parts</h2>
        <p style="margin-top:23px;color:#144579;cursor:pointer;font-weight:bold" (click)="AddItems()">Add More</p>
      </div>
      <div class="quotereceived-items" *ngFor="let quote of customerQuotation" [ngClass]="{'border-box': quote.selected}">
        <div class="lines">
            <input type="checkbox" [(ngModel)]="quote.selected" (change)="sidebarToggleItemSelectionQuote()">
            <span class="part-name">{{quote.itemName}}</span>
            <i  style="float: right; cursor: pointer;" (click)="openEditDialog(quote)">edit</i>                  
          </div>
  <div class="overlay1" [class.visible]="isPopupOpenEdit" (click)="closePopupEdit()"></div>
  <div *ngIf="isPopupOpenEdit" [ngClass]="isMobile ? 'bottom-sheetMobile' : 'bottom-sheet'">
    <header class="back-button">
      <h3 class="header-name">{{ itemQuote.itemName }}</h3>
      <mat-icon (click)="closePopupEdit()" class="close-pop-up">close</mat-icon>
    </header>
    <div class="bottom-sheet-content">
      <!-- <form [formGroup]="unitPrice" >
      <div class="unit-price">
        <mat-form-field appearance="outline" class="full-width" class="edit-labour-details">
          <mat-label>Unit Price</mat-label>
          <input
            matInput
            formControlName="unitprice"                            
            [(ngModel)]="itemQuote.sellingPrice"
            placeholder="Enter Unit Price*"
            required
            name="Unit Price"
            pattern="^[0-9]*$"
          />
          </mat-form-field>
      </div>
    </form> -->
    <form [formGroup]="unitPrice" style="width: 100%;
    margin-left: -9px;">
    <div class="unit-price">
      <mat-form-field appearance="outline" class="full-width" class="edit-labour-details">
        <mat-label>Unit Price</mat-label>
        <input
          matInput
          formControlName="unitprice"                            
          [(ngModel)]="itemQuotesEdit.sellingPrice"
          placeholder="Enter Unit Price*"
          required
          name="Unit Price"
          pattern="^[0-9]*$"
        />
        </mat-form-field>
    </div>
  </form>
    <button (click)="save()" style="cursor: pointer;">Save Quote</button>
    </div>
  </div>
          <div class="lines-mrp" style="text-align:end;">
            <!-- {{quote.mrp * quote.itemQuantity}} -->
            <span class="spare-mrp">₹{{quote.sellingPrice * quote.quantity | number:'1.0-0'}}  
              {{quote.mrp * quote.itemQuantity | number:'1.0-0'}}</span> 
          </div>
        <hr style="border: 1px solid #c0bebe;" />
        <div class="quantity-control">
          <div class="brand">
            {{quote.brand}}
          </div>
          <!-- <div class="add-minus">

            <button mat-icon-button (click)="decrementQuantity(quote)">
            <mat-icon style="margin-left:0px;margin-top: -10px;">remove</mat-icon>
            </button>
            <span style="padding: 7px;margin-left: 12px;">{{quote.quantity}}</span>
            <button mat-icon-button (click)="incrementQuantity(quote)">
              <mat-icon class="increment" style="margin-left: 1px;margin-top: -10px;">add</mat-icon>
            </button>
        </div> -->
        <div class="addtocartcompatiblecar1">
          <div class="spare-add-cart1">
              <div class="btn-group">
                  <button class="button1" mat-raised-button style="min-width: 8px;
                  padding: 2px 0px;
                  margin-right: -3px;
                  height: 38px;
                  font-size: 11px;
                  margin-top: -2px;"
                   (click)="decrementQuantity(quote)">
                      <mat-icon class="sidebuttons1" style="font-size: 18px;margin-left: 0px;">remove</mat-icon>
                  </button>
                  <button  class="button1" mat-raised-button style="padding: 2px 0px;
                  margin-left: -3px;
                  height: 32px;
                  margin-right: 1px;
                  font-size: 13px;
                  margin-top: -4px;">
                    {{quote.quantity}} 
                  </button>
                  <mat-icon class="sidebutton1" style="width: 1px;
                  padding: 7px 0px;
                  margin-left: -6px;
                  margin-right: -3px;
                  font-size: 18px;
                  height: 35px;
                  vertical-align: center;
                  color: #144579;" (click)="incrementQuantity(quote)">add</mat-icon>
              </div>
          </div>
      </div>
        </div>
      </div>
      </div>

      <div class="labour-section-details">
      <div class="labour-header-title">
        <h2 style="font-weight: bold;">Labour Charges</h2>
        <p style="margin-top:23px;color:#144579;cursor:pointer;font-weight: bold;" (click)="addLabourSidebar()" >Add More</p>
      </div>
      <div class="quotereceived-items" style="height: 120px;" *ngFor="let quote of selectedItemsLabourQuote" [ngClass]="{'border-box': quote.selected}">
        <!-- {{quote | json}} -->
        <div class="checkboxselect">
          <div class="labour-lines" style="display:flex">
            <input type="checkbox" class="checkbox-spare" [(ngModel)]="quote.selected" (change)="toggleItemSelectionQuote()">
            <span class="part-name">{{quote.labourName}}</span>
  
            <i class="material-icons-spare" style="cursor:pointer;" (click)="openLabourEditSidebar(quote)">edit</i>
          </div>
  
          <!-- <span class="date" style="text-align: end;">₹{{quote.labourAmount * quote.quantity | number:'1.0-0'}}</span>
   -->
          <span class="date" style="text-align: end;">₹{{quote.labourAmount * quote.quantity | number:'1.0-0'}}</span>

        </div>
        <div class="lines">
          <span *ngIf="labourItemsCount > 0" class="date-time">{{quote.edt  | date:'dd MMM yyyy'}}</span>
        </div>
        <hr style="border: 1px solid #c0bebe;" />
        <div class="quantity-control">
          <div class="brand" style="    width: 71%;
          color: #999;
          font-size: 13px;">
            {{quote.brand}}
          </div>
          <div class="addtocartcompatiblecar1">
            <div class="spare-add-cart1">
                <div class="btn-group">
                    <button class="button1" mat-raised-button style="    min-width: 8px;
                    padding: 2px 0px;
                    margin-right: -3px;
                    height: 38px;
                    font-size: 11px;
                    /* vertical-align: middle; */
                    margin-top: -2px;" (click)="decrementLabourQuantity(quote)">
                        <mat-icon class="sidebuttons1" style="font-size: 18px;margin-left: 1px;">remove</mat-icon>
                    </button>
                    <button  class="button1" mat-raised-button style="padding: 2px 0px;
                    margin-left: -3px;
                    height: 32px;
                    margin-right: 0px;
                    font-size: 13px;
                    margin-top: -3px;">
                      {{quote.quantity}} 
                    </button>
                    <mat-icon class="sidebutton1" style="    width: 1px;
                    padding: 7px 0px;
                    margin-left: -6px;
                    margin-right: -3px;
                    font-size: 18px;
                    height: 35px;
                    vertical-align: center;
                    color: #144579;" (click)="incrementLabourQuantity(quote)">add</mat-icon>
                </div>
            </div>
        </div>
        </div>

  
      </div>
    </div>
    
    </div>
    <div *ngIf="customerQuotation?.length==0 || customerQuotation?.length == null"(click)="AddItems()" style="padding: 10px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    height: 79px;
    margin-top: -15px;">
      <button class="quotation-bottom-cta">Add Quote Items</button>
    </div>
   
    <!-- <div *ngIf="(totallabourSelectedPrice<1)" class="floater-btn" (click)="AddItems()">
      <p *ngIf="activeTab === 'quote-received'" style="font-weight: bold;">ADD ITEM</p>
  
    </div> -->
    <!-- Add Item Side Bar -->
    <div *ngIf="isSidebarOpen" class="new-sidebar">
      <div class="header1">
        <div class="maticon" style="width: 8%;margin-left: -14px;">
        <mat-icon (click)="toggleSidebar()">arrow_back</mat-icon>
      </div>
      <div class="add-items" style="    margin-left: 21px;
    font-size: 18px;">
        <h3 style="font-weight: bold;float:left;margin-bottom:20px;">Add Items</h3>
      </div>
      </div>
      <div class="search-bar">
        <input
          type="text"
          placeholder="Search by Part Name"
          [(ngModel)]="searchWord"
          class="search-input"
          (input)="onSearch()"
        />
        <button *ngIf="searchWord" class="clear-btn" (click)="clearSearch()">&#x2715;</button>
      </div>
      
      <div class="item-list">
        <div *ngFor="let item of filteredItems" class="dropdown-item" (click)="addItemToQuotation(item)">
          <img [src]="item.imageUrl" alt="{{ item.partName }}" class="dropdown-image" />
          <div class="dropdown-details" style="width: 100%;">
            <h4>{{ item.name }}</h4>
            <p>Brand: {{ item.brand }}</p>
            <div class="item-price1">
              <p>₹{{item.mrp }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="cantFind" (click)="createNewItems()" >
        <p>Can't Find Your Item?</p>
        <button mat-icon-button class="arrow-button">
          <mat-icon style="font-size: 15px;" class="arrow_forward">arrow_forward</mat-icon>
        </button>
      </div>
      
    </div>
    <div class="overlay" [class.visible]="newItem" (click)="createNewItems()" ></div>
        <div class="custom-sidebar" *ngIf="newItem">
          <button mat-icon-button class="back-buttonNew" (click)="createNewItems()" >
            <mat-icon>arrow_back</mat-icon>
          </button>
        
          <div class="content">
            <div class="image-container">
              <img  src="../../../../assets/images/car-repair.png" alt="car-repair">
            </div>
            
            <p class="title">Can't find what you are looking for?</p>
            <p class="subtitle" *ngIf="searchWord?.length>0">Create {{searchWord}}</p>
            
          </div>
          <div class="fixedCTA">
            <button mat-button class="primary-btn" (click)="createItemSidebar()">CREATE ITEM</button>
          </div>
        </div>
        

        <div class="overlay" [class.visible]="newItems" (click)="createItemSidebar()" ></div>

        <mat-sidenav-container class="sidebar-containerNew" *ngIf="newItems">
          <mat-sidenav #sidenav mode="side" class="custom-sidebar" [(opened)]="newItems">
            <div class="sidebar-header">
              <mat-icon style="margin-top: 0px;" (click)="newItems = false">arrow_back</mat-icon>
              <h3>Create Item</h3>
            </div>
            <div class="sidebar-content">
              <form [formGroup]="itemForm" (ngSubmit)="onSubmit()">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Item Name *</mat-label>
                  <input matInput formControlName="itemName">
                  <mat-error *ngIf="itemForm.get('itemName')?.invalid && itemForm.get('itemName')?.touched">
                    Item Name is required
                  </mat-error>
                </mat-form-field>
              
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Part Number *</mat-label>
                  <input matInput formControlName="partNumber">
                  <mat-error *ngIf="itemForm.get('partNumber')?.invalid && itemForm.get('partNumber')?.touched">
                    Part Number is required
                  </mat-error>
                </mat-form-field>
              
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Brand *</mat-label>
                  <input matInput formControlName="brand">
                  <mat-error *ngIf="itemForm.get('brand')?.invalid && itemForm.get('brand')?.touched">
                    Brand is required
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>GST% *</mat-label>
                  <mat-select formControlName="gst">
                    <mat-option *ngFor="let gst of gstOptions" [value]="gst">
                      GST {{ gst }}%
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="itemForm.get('gst')?.invalid && itemForm.get('gst')?.touched">
                    Select GST%
                  </mat-error>
                </mat-form-field>
              
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>HSN *</mat-label>
                  <input matInput formControlName="hsn" >
                  <mat-error *ngIf="itemForm.get('hsn')?.invalid && itemForm.get('hsn')?.touched">
                    Enter a valid HSN (4-8 digits)
                  </mat-error>
                </mat-form-field>
              
            
                <mat-form-field appearance="outline" class="full-width" style="margin-bottom: 128px;">
                  <mat-label>Unit Price *</mat-label>
                  <input matInput formControlName="unitPrice">
                  <mat-error *ngIf="itemForm.get('unitPrice')?.invalid && itemForm.get('unitPrice')?.touched">
                    Enter a valid Unit Price 
                  </mat-error>
                </mat-form-field>
                <div class="fixedCTA" style="width: 29.5%;right: 18px;" >
                <button mat-flat-button color="primary" class="primary-btn" type="submit" >
                  Create Item
                </button>
              </div>
              </form>
            </div>
          </mat-sidenav>
        </mat-sidenav-container>


    <div *ngIf="isAddLabour" class="labour-sidebar">
     <div class="labour-section" style="height: 45px;">
        <div class="labour-maticon" >
          <mat-icon (click)="labourRedirectSidebar()">arrow_back</mat-icon>
        </div>
        <div class="labour-header" style="margin-left: 9px;">
          <h2>Add Labour</h2>
        </div>
        <div class="labour-skip" >
          <p style="position: relative;float:right;padding:10px;cursor:pointer;color:#144579;"(click)="openSkipConfirmation()">Skip</p>
        </div>
      </div>
      <div class="search-bar">
        <input
          type="text"
          placeholder="Search Labour"
          [(ngModel)]="searchText"
          class="search-input"
          (input)="onLabourSearch()"/>
  
        <button *ngIf="searchText" class="clear-btn" (click)="clearLabourSearch()">&#x2715;</button>
      </div>
      <div class="item-list1">
      <div *ngFor="let labour of labourListData" class="dropdown-item">
        <div class="checkboxselect">
          <input 
            type="checkbox" 
            [(ngModel)]="labour.selected" 
            (change)="onLabourSelectionChange(labour)"
          />
        </div>
        <div class="dropdown-details" style="margin-left: 20px;">
          <h4>{{ labour.labourName }}</h4>
          <div class="item-price">
            <p>₹{{ labour.labourAmount }}</p>
          </div>
          <div class="edit-btn" style="margin-left: 275px;margin-top: -33px;">
            <i class="material-icons" (click)="openLabourEditSidebar(labour)">edit</i>
          </div>
        </div>
      </div>
      
        <div class="floater-labour-btn" (click)="openLabourEditSidebar()">
          <p>ADD LABOUR</p>
        </div>
    </div>
        <div class="skip-confirmation" *ngIf="showSkipConfirmation">
          <p class="skip-text">Are you sure you want to skip Adding Labour?</p>
          <button class="cancel-popup-btn no-btn" (click)="closeSkipConfirmation()">No</button>
          <button class="cancel-popup-btn confirm-btn" (click)="openPopup()">Yes</button>
          
      </div>
      <!-- labour bottom CTA -->
      <!-- <div 
        *ngIf="selectedLabourslength > 0" 
        [ngClass]="isMobile?'bottom-boxQuoteMobile':'bottom-boxQuote'">
          <div class="bottom-contentQuote">
            <div class="total-items">{{ selectedItemsCount }} items
              
            </div>
              <button type="button" class="add-labour-bottom-cta" (click)="toggleSelectItem()">Add Labour</button>
          </div>
      </div> -->


                  <!-- labour bottom CTA -->
                  <div 
                  *ngIf="selectedLabourslength > 0" 
                  [ngClass]="isMobile?'bottom-boxQuoteMobile':'bottom-boxQuote'"  style="width: 30%;">
                    <div class="bottom-contentQuote" style="display: flex;gap: 5px;">
                      <div class="total-items" style="width: 117px;">{{ selectedLabourslength }} items
                        <p class="total-amount">₹{{totalSelectedEditPriceQuote | number:'1.0-0' }}</p>
                      
                      </div>
                        <button type="button" class="add-labour-bottom-cta" style="height: 38px;width: 122px;cursor:pointer;" (click)="toggleSelectItem()">Add Labour</button>
                        
                    </div>
                </div>
    </div>
    <!-- quoteApprovedPrice -->
    <!-- <div 
      *ngIf="selectedtCount > 0" 
      [ngClass]="isMobile ? 'bottom-boxQuoteMobile' : 'bottom-boxQuote'">
      <div class="bottom-contentQuote">
        <div class="total-items">
          {{ selectedItemsCount }} items
          <p class="total-amount">₹{{ totallabourSelectedPrice | number: '1.0-0'  }}</p>
        </div>
        <button type="button" class="create-quote-btn" (click)="addLabourSidebar()">Add Labour</button>
      </div>
    </div> -->
  
    <!-- customer quote bottom CTA -->
    <div *ngIf="anyItemSelectedQuote()" [ngClass]="isMobile ? 'bottom-boxQuoteMobile':'bottom-boxQuote'" style="width: 30%;">
        <div class="bottom-contentQuote">
          <div class="total-items">{{selectedItemsCount}} items
           
            <p class="total-amount">₹{{totalSelectedEditPriceQuote | number: '1.0-0' }}</p>
          </div>
  
          <button class="add-labour-bottom-btn" *ngIf="totallabourSelectedPrice==0" type="button" (click)="addLabourSidebar()">Add Labour</button>
          <button class="create-quote-btn" *ngIf="totallabourSelectedPrice>0" type="button" (click)="openPopup()">Update Invoice</button>      
        </div>  
    </div> 
            <!-- customer quote bottom CTA -->
            <!-- <div *ngIf="anyItemSelectedQuote() || anyItemSelected() || selectedItemsCountQuote>0 " [ngClass]="isMobile ? 'bottom-boxQuoteMobile':'bottom-boxQuote'"> -->
              <div *ngIf="anyItemSelectedQuote() && customerQuotation?.length>0 && !selectedItemsLabourQuote" [ngClass]="isMobile ? 'bottom-boxQuoteMobile':'bottom-boxQuote'">
                  <div class="bottom-contentQuote" style="gap: 101px;">
                    <div class="total-items">{{selectedItemsCount}} items
                      <p class="total-amount">₹{{totalSelectedEditPriceQuote | number:'1.0-0'}}</p>
                      <!-- <p *ngIf="activeTab === 'quote-approved'" class="total-amount">₹{{quoteApprovedPrice | number:'1.2-2' }}</p> -->
                    </div>
    
                    <button class="add-labour-bottom-btn1" 
                      *ngIf="totalSelectedPriceQuote > 0 && (selectedLabourslength == 0 || selectedLabourslength == null || selectedLabourslength == '') && activeTab === 'quote-received'" 
                      type="button" 
                      (click)="addLabourSidebar()">
                      Add Labour
                      <span class="next-step-text">(In Next Step)</span>
                    </button>
            
                  </div>  
              </div>
              
    
              <div *ngIf="(anyItemSelectedQuote() || anyItemSelected()) && customerQuotation?.length>0 && selectedItemsLabourQuote?.length>0" [ngClass]="isMobile ? 'bottom-boxQuoteMobile':'bottom-boxQuote'">
                <div class="bottom-contentQuote" style="gap: 105px;">
                  <div class="total-items">{{selectedItemsCount}} items
                    <p class="total-amount">₹{{totalSelectedEditPriceQuote | number:'1.0-0'}}</p>
                    <!-- <p *ngIf="activeTab === 'quote-approved'" class="total-amount">₹{{quoteApprovedPrice | number:'1.2-2' }}</p> -->
                  </div>
    
                  <button class="create-quote-btn"  type="button" 
                    (click)="openPopup()" style="margin-left: 80px;">Update Invoice</button>
          
                </div>  
            </div>
    <!-- Edit labour name Sidebar -->
    <div class="overlay" [class.visible]="isEditLabourSidebarOpen" (click)="closeAll()"></div>
    <!-- <div *ngIf="isEditLabourSidebarOpen" class="edit-labour-sidebar" style="z-index: 9876545678;">
      <div class="labour-sidebar-header">
        <h2>Edit Labour</h2>
        <mat-icon (click)="closeLabourEditSidebar()">close</mat-icon>
      </div>
      <form [formGroup]="laborDetails" >
          <div class="labour-name-column" style="margin-top: -8px;">
            <mat-form-field appearance="outline" class="full-width" class="edit-labour-details" style="margin-top: 10px;">
            <mat-label>Labour Name</mat-label>
            <input
              matInput
              formControlName="labourName"
              [(ngModel)]="selectedLabour.itemName"
              placeholder="Enter Labour Name"
              name="labourName"
            />
            </mat-form-field>
          </div>
          <div class="laboue-price-column" style="margin-top: -14px;">
            <mat-form-field appearance="outline" class="edit-labour-details">
              <mat-label>Labour Amount</mat-label>
              <input
                matInput
                formControlName="labourAmount"
                [(ngModel)]="selectedLabour.sellingPrice"
                placeholder="Enter Labour Amount"
                name="labourPrice"
              />
            </mat-form-field>
          </div>  
      </form>
      <div class="labour-sidebar-footer" style="margin-top: -14px;">
        <button class="save-btn" *ngIf="isEditLabourSidebarOpen && !isAddLabour" (click)="saveLabour()">Save Labour</button>
        <button class="add-btn" *ngIf="isEditLabourSidebarOpen && isAddLabour" (click)="addLabour()">Add Labour</button>
      </div>
      
    </div> -->
   

    <div *ngIf="isEditLabourSidebarOpen" class="edit-labour-sidebar">
      <div class="labour-sidebar-header">
        <h2>{{ selectedLabour?.id || selectedLabour?.labourId ? 'Edit Labour' : 'Add Labour' }}</h2>
        <mat-icon (click)="closeLabourEditSidebar()" style="margin-right: 0px;">close</mat-icon>
      </div>
    
      <form [formGroup]="laborDetails" class="labour-details">
        <div class="labour-name-column" >
          <mat-form-field appearance="outline" class="full-width" style="margin-top: 10px;width:100%">
            <mat-label>Labour Name</mat-label>
            <input
              matInput
              formControlName="labourName"
              [(ngModel)]="selectedLabour.labourName"
              placeholder="Enter Labour Name"
              name="labourName"
            />
          </mat-form-field>
        </div>
    
        <div class="labour-name-column" >
          <mat-form-field appearance="outline" class="full-width" style="margin-top: -7px;width:100%">
            <mat-label>Labour Amount</mat-label>
            <input
              matInput
              formControlName="labourAmount"
              [(ngModel)]="selectedLabour.labourAmount"
              placeholder="Enter Labour Amount"
              name="labourPrice"
            />
          </mat-form-field>
        </div>
      </form>
    
      <div class="item-update" style="margin-top: -14px;">
        <button class="item-update-btn" *ngIf="selectedLabour?.id || selectedLabour?.labourId" (click)="saveLabour()">Save Labour</button>
  <button class="item-update-btn" *ngIf="!(selectedLabour?.id || selectedLabour?.labourId)" (click)="addLabour()">Add Labour</button>
   </div>
    </div>

    </mat-list>
    <div *ngIf="isPopupOpen" class="popup">
      <div class="popup-content">
      <img src="{{successIcon}}" alt="successIcon" style="width: 36%;">
      <h3 >Invoice Updated</h3>
      <p class="order-label" >
        <span class="order-id">Invoice ID {{ resIds }}</span>
      </p>
      <p class="date"><span>{{resData?.createdAt |date:'dd MMM yyyy, h:mm a': 'GMT+1060'}}</span></p>
      <p class="thank you">You have successfully Updated Invoice</p>
    
      <button class="goto-order"  (click)="closeSidebar1()">View Updated Invoice</button>
     </div>
      </div>
    </div>
</div>

    <div #dynamicContainer></div>