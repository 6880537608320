<div class="page-layout blank fullwidth p-12" style="height: 1px; overflow-y: scroll; top: 1px;">

  <div class="allign">
    <div class="heading">My Customer Invoice</div> 
    <div class="invoice">
      <div class="left-sec" *ngIf="!isMobile" >
        <!-- <div class="sidebar" [ngClass]="{'sidebarMobile': isMobile}"> -->
        <!-- <div class="sidebar"> -->
          <div class="filandclr">
            <h3 >FILTERS</h3>
            <div *ngIf="hasAppliedFilters()" (click)="clearFilters()" class="clear-filters-btn">CLEAR ALL</div>
            </div>
            <div class="clear">
              <div *ngIf="hasAppliedFilters()" class="applied-filters">
                <!-- <h4>Applied Filters</h4> -->
                <div *ngFor="let appliedFilter of getAppliedFilters()" class="apply">
                  <span>{{ appliedFilter.option.label }}</span>
                  <mat-icon (click)="removeFilter(appliedFilter.jobTime, appliedFilter.option)" class="apply1">cancel</mat-icon>
                </div>
              </div>
              <!-- <div *ngIf="hasAppliedFilters()" (click)="clearFilters()" class="clear-filters-btn">CLEAR ALL</div> -->
            </div>
            <!-- <hr style="width: 106%;
            left: -10px;
            /* border: 1px; */
            position: relative;
            box-sizing: content-box;
            height: 0;
            overflow: visible;"/> -->
  
            <div class="filter-sectionMobile">
              <h4 >INVOICE STATUS</h4>
              <div *ngFor="let status of filters.jobCardStatus" class="filter-option">
                <label >
                  <span>{{ status.label }}</span>
                  <span class="radio-container">
                    <input type="radio" name="jobCardStatus" [(ngModel)]="selectedJobCardStatus" [value]="status.value" (change)="toggleSelection('jobCardStatus', status)" class="filter-radio">
                  </span>
                </label>
              </div>
            </div>
            <!-- <hr style="width: 106%;
            left: -10px;
            /* border: 1px; */
            position: relative;
            box-sizing: content-box;
            height: 0;
            overflow: visible;" /> -->
  
            <div class="filter-sectionMobile-jobcard-time">
              <h4 >INVOICE TIME</h4>
              <div *ngFor="let time of filters.jobTime">
                <div *ngIf="time.children; else singleRadio" class="filter-option">
                  <h5>{{ time.label }}</h5>
                  <div *ngFor="let child of time.children" class="filter-option">
                    <label>
                      <span>{{ child.label }}</span>
                      <span class="radio-container">
                        <input type="radio" name="jobTime" [(ngModel)]="selectedJobCardTime" [value]="child.value" (change)="toggleSelection('jobTime', child)" class="filter-radio">
                      </span>
                    </label>
                  </div>
                </div>
                <ng-template #singleRadio>
                  <label class="filter-option">
                    <span>{{ time.label }}</span>
                    <span class="radio-container">
                      <input type="radio" name="jobTime" [(ngModel)]="selectedJobCardTime" [value]="time.value" (change)="toggleSelection('jobTime', time)" class="filter-radio">
                    </span>
                  </label>
                </ng-template>
              </div>
            </div>
            <!-- </div> -->
          </div>
          <div class="displayList">

            <div>
              <div class="SearchBarPlaceholder">
                <div class="search">
                  <div class="input-container">
                    <span *ngIf="searchText" class="close-button" (click)="clearSearch()">✖</span>
                    <div class="scroll-text-container" *ngIf="!searchText">
                      <div class="SearchBarIcon">
                        <i class="material-icons-search" >search</i>
                      </div>
                      <div class="scroll-text">
                        <div id="animationText-1" class="SearchBarAnimationText">Search By "Invoice ID"</div>
                        <div id="animationText-2" class="SearchBarAnimationText">Search By "Job Card ID"</div>
                        <div id="animationText-3" class="SearchBarAnimationText">Search By "Registration Number"</div>
                        <div id="animationText-4" class="SearchBarAnimationText">Search By "Make"</div>
                        <div id="animationText-5" class="SearchBarAnimationText">Search By "Model"</div>
                        <div id="animationText-6" class="SearchBarAnimationText">Search By "Status"</div>
                        <div id="animationText-7" class="SearchBarAnimationText">Search By "Total Amount"</div>
                      </div>
                    </div>
                    <input type="text" class="search-input" [(ngModel)]="searchText" (keyup)="onEnterPress($event)" (input)="filterRequest()">   
              </div>
            <!-- <button #myButton class="create-booking" (click)="onCreateJobCardClick()">Create Job Card</button> -->
              <!-- Mobile Header with Search & Filter Button -->
              <div class="mobile-header" *ngIf="isMobile">
                <!-- <input type="text" placeholder="Search By 'Car Model'" class="search-input" /> -->
                <div (click)="toggleFilterSidebar()" class="filter-btn1">
                  <mat-icon>filter_list</mat-icon>
                </div>
              </div>
              <!-- // --------------------------mobile-view-filter------------------- -->
          <!-- Sidebar Overlay for Mobile -->
          <div class="sidebar-overlay" *ngIf="isFilterSidebarOpen && isMobile" (click)="toggleFilterSidebar()"></div>
    
          <!-- Mobile Filter Sidebar -->
          <div class="sidebar-sidebarMobile" *ngIf="isFilterSidebarOpen && isMobile">
            <div class="sidebar-header">
              <h3>FILTERS</h3>
              <mat-icon (click)="toggleFilterSidebar()">close</mat-icon>
            </div>
            <div class="filandclr">
              <div class="clear">
              <div *ngIf="hasAppliedFilters()" class="applied-filters">
                <div *ngFor="let appliedFilter of getAppliedFilters()" class="apply">
                  <span>{{ appliedFilter.option.label }}</span>
                  <mat-icon (click)="removeFilter(appliedFilter.jobTime, appliedFilter.option)" class="apply1">cancel</mat-icon>
                </div>
              </div>
            </div>
            <div *ngIf="hasAppliedFilters()" (click)="clearFilters()" class="clear-filters-btn">CLEAR ALL</div>
          </div>
  
          <div class="filter-sectionMobile">
            <h4>INVOICE STATUS</h4>
            <div *ngFor="let status of filters.jobCardStatus" class="filter-option">
              <label class="label">
                <span>{{ status.label }}</span>
                <span class="radio-container">
                  <input type="radio" name="jobCardStatus" [(ngModel)]="selectedJobCardStatus" [value]="status.value" (change)="toggleSelection('jobCardStatus', status)" class="filter-radio">
                </span>
              </label>
            </div>
          </div>
  
          <div class="filter-sectionMobile-jobcard-time">
            <h4>INVOICE TIME</h4>
            <div *ngFor="let time of filters.jobTime">
              <div *ngIf="time.children; else singleRadio" class="filter-option">
                <h5>{{ time.label }}</h5>
                <div *ngFor="let child of time.children" class="filter-option">
                  <label>
                    <span>{{ child.label }}</span>
                    <span class="radio-container">
                      <input type="radio" name="jobTime" [(ngModel)]="selectedJobCardTime" [value]="child.value" (change)="toggleSelection('jobTime', child)" class="filter-radio">
                    </span>
                  </label>
                </div>
              </div>
              <ng-template #singleRadio>
                <label class="filter-option">
                  <span>{{ time.label }}</span>
                  <span class="radio-container">
                    <input type="radio" name="jobTime" [(ngModel)]="selectedJobCardTime" [value]="time.value" (change)="toggleSelection('jobTime', time)" class="filter-radio">
                  </span>
                </label>
              </ng-template>
            </div>
          </div>
        </div>
          <!-- ----------------------------filter-mobile=view--end--------------- -->
      </div>
    </div> 
    <div *ngIf="!loading && filteredJobCards.length > 0"> 
      <div *ngFor="let list of filteredJobCards">
      <div class="header" >  
        <div class="header-row">
          <!-- <div [ngClass]="isMobile ? 'order-iconMobile' : 'order-icon'">
            <i class="material-icons">check_box</i>
          </div> -->
          <div class="order-info">
            <div class="order-status">
              <h1 *ngIf="list.id" >
                Invoice #{{ list.id }}</h1>
              <!-- <div 
                class="delivery-status" 
                *ngIf="!isMobile"
                [ngClass]="{
                    'delivered-status': list.orderCurrentStatus === 'Delivered',
                    'confirmed-status': list.orderCurrentStatus === 'Confirmed',
                    'packed-status': list.orderCurrentStatus === 'Packed',
                    'on-the-way':list.orderCurrentStatus==='On The Way'}">
                  {{list.orderCurrentStatus}}
              </div> -->
              <div class="delivery-status" *ngFor="let status of filters.jobCardStatus" >
                <label class="{{status.statusclass}}" *ngIf="list.invoiceStatus && status.value==list.invoiceStatus" >{{status.label}}</label>  
  
              </div>
            </div>
            <div class="request-ids" style="margin-top: -3px;">
            <div class="request-id jobcardId"  *ngIf="list?.jobcardId">Job Card # {{list.jobcardId}}</div>

            <!-- <div class="request-id" *ngIf="list.quoteId">Quote # {{list.quoteId}}</div> -->
            <!-- <div class="order-amount">Total Amount - ₹{{list.grandTotal}} </div> -->
            <div class="order-amount" >
              Total Amount - ₹{{ getTotalAmount(list) }}
              </div>
            <div style="margin-top: 11px; margin-bottom: 23px;" *ngIf="list?.jobCard?.make">
            <div class="logo-container"  >
              <div class="logo">
              <img 
                  [src]="'https://cube.getpitstop.com/assets/img/' + list.jobCard.make.toLowerCase().replace(' ', '') + '.png'" 
                  alt="Make Image"
                >
              </div>
            
            </div>
          
            <div class="model-sec" style="width:80%;line-height: 2.4px;" *ngIf="list?.jobCard.model">
              <p style="font-weight:bold">{{list.jobCard.model}}, {{list.jobCard.fuelType}}</p>
              <p>{{list.jobCard.variant}} [{{list.jobCard.year}}]</p>
              <p *ngIf="list?.jobCard.registrationNumber" style="margin-bottom: -12px;">{{list?.jobCard.registrationNumber}}</p>
          </div>
        </div>
      
                
            
          </div>
        </div>

        </div>
        <div class="header-row1">
          <div class="order-item"><span *ngFor="let items of list.invoiceItems; index as i"><span *ngIf="i!=0">,</span> {{items.itemName}}</span></div>
        </div>
        <div class="header-row2">
          <div class="order-date">
            <span>Placed on: {{list.createdAt | date: 'dd MMM yyyy'}}, {{list.createdAt | date: 'hh:mm a'}}</span>
            <button (click)="navigateToDetails(list.id,list.jobcardId,list.quoteId)" class="Viewbtn">
              <span class="view">View Details</span>
              <i class="material-icons">arrow_right</i> 
            </button>
          </div>
        </div>
      </div>
      </div>
    </div>
    <div class="no-image-car">
      <div class="no-img" *ngIf="!loading && filteredJobCards.length <= 0">
        <img [src]="emptyList" alt="No data available" />
        <h1 class="no-request-text">No Invoice Found</h1>
        <div class="eidt-text">Edit filter or go back to my jobcards page</div>
        <button class="go-back-btn" (click)="clearFilters()" >Go to My Invoice</button>
      </div>
    </div>

 
    <!-- <infinite-scroll
  [infiniteScrollDistance]="scrollDistance"
  [infiniteScrollUpDistance]="scrollUpDistance"
  [infiniteScrollDelay]="scrollDelay"
  [infiniteScrollImmediateCheck]="true"
  (scrolled)="onScroll()">
  </infinite-scroll> -->
</div>
</div>
</div>
</div>
</div>