import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { AspLoadingService } from './asp-loading.service';

@Component({
  selector: 'app-asp-loading',
  templateUrl: './asp-loading.component.html',
  styleUrls: ['./asp-loading.component.scss']
})
export class AspLoadingComponent implements OnInit {
  isLoading: boolean = false;
  Messages: string='';
  randomMessage: string='';

  constructor(
    private aspLoadingService:AspLoadingService,
    private cdr: ChangeDetectorRef
  ) { }

  options: AnimationOptions = {
    path: './assets/images/loader.json' // Replace with your JSON animation file URL
  };
  
  animationCreated(animationItem: any): void {
  }
  ngOnInit(): void {
    this.aspLoadingService.isLoading$.subscribe((state) => {
      this.isLoading = state;
      this.cdr.detectChanges();
      if (state) {
        this.getLoadingMsg();
      }
    });
  }

  getLoadingMsg() {
    this.aspLoadingService.getLoadingMsg().subscribe(
      result => {
        let res = JSON.parse(JSON.stringify(result));
        this.Messages = res.data.appLoadingMessages;
  
        // Call getRandomMessage only after the Messages array is populated
        this.getRandomMessage();
        
      },
      error => {
        console.error('Error fetching loading messages', error);
      }
    );
  }
  
  getRandomMessage() {
    if (this.Messages && this.Messages.length > 0) {
      const randomIndex = Math.floor(Math.random() * this.Messages.length);
      this.randomMessage = this.Messages[randomIndex];
    } else {
      console.error('No messages available to pick from');
    }
  }

}
