import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspCustomerPaymentsListService {

  constructor(private http: HttpClient) { }
  getAllInvoices(): Observable<any> {
    const garageKey = localStorage.getItem('garageKey');
  
    if (!garageKey) {
      console.error('Garage key not found in localStorage');
      return;
    }
  
    const requestData = {
      garageKey: garageKey
    };
  
    // console.log('Fetching invoices with data:', requestData);
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/garageManagementPayment/v1/paymentRecord/getAll?page=0&size=500`, requestData);
  }
  
}
