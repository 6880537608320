<div class="page-layout blank fullwidth p-12" fusePerfectScrollbar style="height: 1px; overflow-y: scroll; margin-bottom: 5em;    top: 1px;">
    <div class="rating-container" *ngIf="!isPopupVisible"> 
        
        <!-- <h2 class="title" *ngIf="selectedStars === 0">How was your service experience with {{ selectedGarageName }} ? </h2>
        <div class="stars" *ngIf="selectedStars === 0">
            <span *ngFor="let star of [1, 2, 3, 4, 5]" 
                (click)="rate(star)" 
                [class.selected]="star <= selectedStars">
                ★
            </span>
        </div> -->

        <!-- First Code: Show only if feedback is NOT received -->
            <div *ngIf="!feedbackReceived">
                <div class="logo-container" *ngIf="selectedStars === 0">
                    <img [src]="garageIconUrl" alt="Garage Icon" class="garage-icon" />
                </div>
                <h2 class="title" *ngIf="selectedStars === 0">
                    How was your service experience with {{ selectedGarageName }} ?
                </h2>

                <!-- Star Rating -->
                <div class="stars" *ngIf="selectedStars === 0">
                    <span *ngFor="let star of [1, 2, 3, 4, 5]" 
                        (click)="rate(star)" 
                        [class.selected]="star <= selectedStars">
                        ★
                    </span>
                </div>
            </div>

            <!-- Second Code: Show only if feedback is received -->
            <div *ngIf="feedbackReceived">
                <mat-icon class="finalstar">star</mat-icon>
                <div class="rating-submit-new">RATING SUBMITTED</div>
                <h2 class="thank-you-new">Thank you for your feedback</h2>
                <p>See you again soon!</p>
            </div>


        <!-- Feedback Section for each star -->
        <div *ngIf="selectedStars >= 1 && selectedStars <= 5" class="feedback-section">
            <div class="feedback">
                <!-- <button class="feedbackbackbtn" (click)="goBackToRating()"><mat-icon style="    font-size: 33px; margin-right: 15px;">keyboard_arrow_left</mat-icon></button> -->
                <h2>Feedback</h2>
            </div>
            
            <div class="sectiontab">
                <div class="emoji">
                    <div class="emojiheading">YOUR FEEDBACK IS IMPORTANT</div>
                    <span *ngIf="selectedStars === 1">😔</span>
                    <span *ngIf="selectedStars === 2">😟</span>
                    <span *ngIf="selectedStars === 3">😫</span>
                    <span *ngIf="selectedStars === 4">😊</span>
                    <span *ngIf="selectedStars === 5">😃</span>
                </div>

                <div class="opinion">
                    <div *ngIf="selectedStars === 1">TERRIBLE</div>
                    <div *ngIf="selectedStars === 2">BAD</div>
                    <div *ngIf="selectedStars === 3">DISAPPOINTING</div>
                    <div *ngIf="selectedStars === 4">PLEASANT</div>
                    <div *ngIf="selectedStars === 5">AWESOME</div>
                </div>
                <div class="titlesub" *ngIf="selectedStars === 1 || selectedStars === 2 || selectedStars === 3">We're really sorry</div>
                <p class="subtitle" *ngIf="selectedStars === 1 || selectedStars === 2 || selectedStars === 3">Tell Us What Went Wrong?</p>

                <div class="titlesub" *ngIf="selectedStars === 4 || selectedStars === 5">That's Great</div>
                <p class="subtitle" *ngIf="selectedStars === 4 || selectedStars === 5">Tell Us What We Got Right</p>
                <div class="stars" *ngIf="selectedStars >=1 && selectedStars <= 5 ">
                    <span *ngFor="let star of [1, 2, 3, 4, 5]" 
                        (click)="rate(star)" 
                        [class.selected]="star <= selectedStars">
                        ★
                    </span>
                </div>

                <!-- Feedback Tags -->
                <div class="tags">
                    <span *ngFor="let tag of getTagsForRating(selectedStars)" 
                        (click)="toggleTag(tag)" 
                        [class.selected]="selectedTags.includes(tag)">
                        {{ tag }}
                    </span>
                </div>
                <!-- New Section for Additional Feedback -->
                <div   class="additional-feedback">
                    <div class="otherfeedback">Any other feedback?</div>
                    <div class="txtarea">
                        <textarea placeholder="Tell us about your experience" [(ngModel)]="additionalFeedback" rows="4" style=" border: solid #666 1px; text-align: left; width: -webkit-fill-available;padding: 10px;"></textarea>
                        <div class="upload-container">
                            <!-- <label for="image-upload">Upload Image (Optional):</label> -->
                            <div *ngFor="let image of images" class="imageuploaded">
                                <img [src]="image" alt="uploaded image" class="uploaded-image" />
                                <mat-icon (click)="deleteImage(i)" class="delete-icon">close</mat-icon>
                            </div>
                
                            <!-- Add new camera icon for uploading more images -->
                            <!-- <mat-icon *ngIf="images.length < 5" (click)="document.getElementById('image-upload')?.click()">camera_alt</mat-icon> -->
                        </div>
                        <input type="file" id="image-upload" (change)="onImageUpload($event)" />
                    </div>
                </div>
                
            </div>
            <div #bottomSection class="bottomSection">
                <div #submitButton class="botm-sec-sbmt-btn">
                    <button  #submitButton *ngIf="selectedStars > 0" class="submit-button" (click)="popUP()"  [disabled]="selectedTags.length === 0"
                        [ngClass]="{'disabled-button': selectedTags.length === 0}">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    
    </div>
</div>

    <!-- -----------------   P0P-UP-------------------- -->
    <div class="popup-overlay" *ngIf="isPopupVisible">
        <div class="popup">
            <mat-icon class="pop-upclose" (click)="closePopUp()">close</mat-icon>
            <div class="popupbottom">
                <mat-icon class="finalstar">star</mat-icon>
                <div class="rating-submit">RATING SUBMITTED</div>
                <h2 class="thank-you">Thank you for your feedback</h2>
                <p>See you again soon!</p>
                <!-- <button class="close-btn" (click)="isPopupVisible = false">OK</button> -->
            </div>
        </div>
    </div>   
