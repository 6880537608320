// import { Component, OnInit } from '@angular/core';
import{MyJobCardsDetailsService} from './my-job-cards-details.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
// import { AspMyRequestService } from '../asp-my-request/asp-my-request.service';
import { Component, ViewChild, ViewContainerRef, ComponentFactoryResolver, OnInit } from '@angular/core';
import { AspRequestDetailsComponent } from '../asp-request-details/asp-request-details.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { AspVehicleServiceHistoryComponent } from '../asp-vehicle-service-history/asp-vehicle-service-history.component';
import { ChangeDetectorRef } from '@angular/core';
import { AspQuotationDetailsService } from 'app/main/customer/asp-quotation-details/asp-quotation-details.service';
import { GlobalVariable } from 'app/main/globals';
import { AspJobcardQuotationComponent } from '../asp-jobcard-quotation/asp-jobcard-quotation.component';
import { AspCheckinReportsService} from '../asp-checkin-reports/asp-checkin-reports.service';
import {AspRequestDetailsService} from '../asp-request-details/asp-request-details.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AspInvoiceDetailsComponent } from '../asp-invoice-details/asp-invoice-details.component';
import { Pipe, PipeTransform } from '@angular/core';
import { AspCustomerQuoteService } from '../asp-customer-quote/asp-customer-quote.service';
import { AspLoadingService } from '../asp-loading/asp-loading.service'; 

interface FilterOption {
  label: string;
  value: string;
  checked: boolean;
  children?: FilterOption[];
}

interface Filters {
  jobCardStatus: FilterOption[];
}


@Component({
  selector: 'app-my-job-cards-details',
  templateUrl: './my-job-cards-details.component.html',
  styleUrls: ['./my-job-cards-details.component.scss']
})
export class MyJobCardsDetailsComponent implements OnInit {
  @ViewChild('myDynamicSec', { read: ViewContainerRef, static: true }) container: ViewContainerRef;
  @ViewChild('vehicleServiceHistoryContainer', { read: ViewContainerRef }) historyContainer!: ViewContainerRef;

  
  activeTab: string = 'quoteReceived';
  sidebarOpen: boolean = false;
  quotationReceived: any[] = [];
  quotationApproved: any[] = [];
  itemDetails: any;
  selectedItemsCount: number = 0;
  totalSelectedPrice: number = 0;
  request: any;
  jobCard: any; 
  booking: any;
  jobCardId: number | null = null;
  bookingId: number;
  viewContainerRef: any;
  isMobile: boolean;
  status: string = '';

  successIcon: any = '../../../../assets/icons/icons8-ok.gif';
  showServiceHistory: boolean;
  customer: any;
  car: any;
  openSideShow: boolean;
  PopupOpen: boolean;
  quoteDetails: any[];
  quote: any;
  items: unknown;
  data: any;
  jobCardStatus: number | null = null;  

  sidebarOpenInvoice: boolean;
  selectAllQuote: boolean;
  customerQuotation: any[]=[];
  customerQuotationPrice: number=0;
  totalSelectedPriceQuote: number=0;
  selectedItemsCountQuote: number=0;
  isSidebarOpen: boolean;
  searchWord: string = '';
  filteredItems: any[] = [];
  selectedItemsLabourQuote: any;
  selectedLaboursCount: any;
  quoteItemStatus: any;
  selectedApprovedItemsCount: number;
  totalApprovedSelectedPrice: any;
  totalSelectedApprovedPrice: any;
  customerquoteid: any;
  selectedLabourslength: number=0;
  selectedLabours: any;
  searchText: string;
  itemQuote: any[];
  isPopupOpenEdit: boolean;
  selectedLabourCount:any;
  sparePartsTotal: any;
  labourListData: any[];
  totallabourSelectedPrice: number=0;
  filteredItems1: any[];
  selectedItemsQuote: any[];
  quotationreceived: any;
  quotationapproved: any;
  quoteApprovedPrice: any;
  side1235: boolean;
  requestId: any;
  requestDetails: any;
  filteredOrderList: any[]=[];
  quoteReceivedPrice: number=0;
  isEditLabourSidebarOpen: boolean;
  selectedLabour: any;
  labourName: any;
  labourAmount: any;
  labourId: any;
  id: any;
  partNumber: any;
  selectAll: boolean = false; 
  isAddLabour: boolean;
  showSkipConfirmation: boolean;
  showCancelConfirmation: boolean;
  isPopupOpen: boolean;
  sidebarOpenQuote: boolean;
  unitPrice: any;
  laborDetails: any;
  totalSelectedPriceQuote1: any;
  selectedItems: any[];
  resData: any;
  dataid: any;
  item: any;
  itemLength: any;
  request1: any;
  selectedItemsLabourQuotePrice: any;
  selectedLabourLength: any;
  selectedItemsCount1: any;
  selectedItemsCountTotal: any;
  totalSelectedPriceQuoteTotal: any;
 
  customerName: any;
  isOpen = false;
  invoiceOpenItem: string;
  onClose!: () => void;
  quoteGrandTotal: number = 0;
  totallabourListSelectedPrice: any;
  sparePartsTotalcreation: number;
  newItem: boolean;
  newItems: boolean;
  itemForm: FormGroup;
  originalData: any;

  gstOptions: number[] = [5, 12, 18, 28]; 
  labourQuotationPrice: number;
  itemQuotesEdit: any;
  fullLabourList: any;
  selectedNewLabours: number;
  totallabourListNewSelectedPrice: any;
  selectedNewLabourSelected: any[];
  customerQuotation1: any;
  constructor(
    private myJobCardsDetailsService: MyJobCardsDetailsService,
    private route: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver,
    private location: Location,
    private cdr: ChangeDetectorRef,
    
    private aspQuotationDetailsService:AspQuotationDetailsService,
    private _formBuilder: FormBuilder,
    // private aspMyRequestService: AspMyRequestService,
    private breakpointObserver: BreakpointObserver,
    private resolver: ComponentFactoryResolver,
    private AspCheckinReportsService:AspCheckinReportsService,
    // private router: Router
    private aspRequestDetailsService:AspRequestDetailsService,
    private toastr: ToastrService,
    private router:Router,
    private aspCustomerQuoteService:AspCustomerQuoteService,
    private aspLoadingService: AspLoadingService,
  ) {
      this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); 
if (window.location.pathname === '/feedback') {
  this.router.navigate(['asp-feedback']);
}
this.itemForm = new FormGroup({
  itemName: new FormControl('', Validators.required),
  partNumber: new FormControl('', Validators.required),
  brand: new FormControl('', Validators.required),
  hsn: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{4,8}$')]), // HSN validation (4-8 digits)
  gst: new FormControl('', Validators.required), // Dropdown selection required
  unitPrice: new FormControl('', [Validators.required, Validators.min(0)]) // Unit price must be > 0
});
}

statusList = [
  { label: 'Quote Due', value: '1', checked: false, statusclass: 'quote-due'},
  { label: 'Approval Due', value: '2', checked: false, statusclass: 'approval-due'},
  { label: 'In Progress', value: '3', checked: false, statusclass: 'inprogress'},
  { label: 'Ready', value: '4', checked: false, statusclass: 'ready'},
  { label: 'Invoice', value: '5', checked: false, statusclass: 'invoice'},
];
filters: Filters = {
  jobCardStatus: this.statusList,
}
  ngOnInit(): void {
    this.invoiceOpenItem=localStorage.getItem('invoiceOpen');
    this.jobCardId = Number(this.route.snapshot.paramMap.get('id'));
    this.bookingId = Number(this.route.snapshot.paramMap.get('bookingId'));

    this.fetchJobCardDetails();
    // this.fetchMyRequest();
    this.laborDetails = this._formBuilder.group({
      labourName: ['', [Validators.required, Validators.minLength(2)]], 
      labourAmount: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
    });
    this.unitPrice=this._formBuilder.group({
      unitprice:['',[Validators.required, Validators.pattern(/^[0-9]{10}$/)]]
    });
    
    this.checkInDetails();
  }
  get up() {
    return this.unitPrice.controls;
  }
    
    get ld() {
      return this.laborDetails.controls;
    }
  openMenu() {
    this.openSideShow = !this.openSideShow;
  }
  
  markReady() {
    // const itemsArray = Array.isArray(this.items) ? this.items : [this.items];
  
    // const hasValidStatus = itemsArray.some(item => item['jobCardStatus'] === 3 || item['jobCardStatus'] === 4);
  
  
    // if (hasValidStatus) {
      this.PopupOpen=true;
      // Perform your action here
    // } else {
    //   console.log("No items have jobCardStatus 3 or 4");
    // }
  }
  checkInDetails(){

    let userKey = localStorage.getItem('spareUserKey');
    let garageKey = localStorage.getItem('garageKey');
    this.AspCheckinReportsService.checkInDetails(garageKey, userKey, this.bookingId, this.jobCardId).subscribe(
      result => {
        
        const res = JSON.parse(JSON.stringify(result));
        this.data = res['data'];
        console.log("this.data-----",this.data);
        this.customerName=this.data.customerName;
        
      }
    );
  }
  
  shareDeails(){
    const message = `*📢 Car Service Update*

Dear ${this.data.customerName},

We are pleased to inform you that the service and repair of your car with *Registration Number ${this.data.registrationNumber}* have been successfully completed. 🚗✨

Your car is now ready for pickup from *${this.data.garageName}*.

📍 *Pickup Location*: ${this.data.garageAddress}
⏰ *Timings*: 9 AM - 7 PM

If you have any questions or need assistance, feel free to reach out to us.

Thank you for choosing us for your car’s care! 😊

*Warm regards,*  
*Team ${this.data.garageName}* `
      
    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
    this.openSideShow=false;
  }
  closePopup(){
    this.PopupOpen=false;
    this.openSideShow=false;
  }
  updateInfo() {
    // Ensure this.items is always an array
    const itemsArray = Array.isArray(this.items) ? this.items : [this.items];
  
    // Transform items into expected structure
    const customerQuoteItems = itemsArray.map((item: any) => ({
      id: item['itemId'],
      jobCardStatus: 4,
      jobcardId: item['jobcardId']
    }));
  
    const dataUpdate = {
      jobcardId: this.request.jobCard.id,
      customerQuoteItems, // Multiple items passed here
      customerQuoteId: this.quoteDetails['customerQuoteId']
    };
  
    this.myJobCardsDetailsService.updateStatus(dataUpdate).subscribe(result => {
      if(result['code']== 200)
        window.location.reload();
    });
    this.PopupOpen=false;
    this.openSideShow=false;
  }
  
  
  getQuoteDetails() {
    this.quoteDetails = [];
    const userKey = localStorage.getItem('spareUserKey');
    const garageKey = localStorage.getItem('garageKey');
  console.log("request1",this.request1);
    this.aspQuotationDetailsService.getQuoteDetails(garageKey, this.request1.jobCard.customerQuoteId,  this.request1.jobCard.id, userKey).subscribe(
      result => {
        const res = JSON.parse(JSON.stringify(result));
        console.log("resres",res)
        this.quoteDetails = res.data;
        Object.values(this.quoteDetails['labourQuoteItemResponses']).forEach(quote => { 
            this.quote = quote;
        });
        this.customerQuotation=res.data.items ? res.data.items.filter(item => item.jobCardStatus < 2) : [];
        console.log("items",this.items)
        this.customerQuotation = res.data.items ? res.data.items.filter(item => item.jobCardStatus === 3||item.jobCardStatus === 4) : [];
        this.itemLength = res.data.items ? res.data.items.filter(item => item.jobCardStatus === 3||item.jobCardStatus === 4) : [];
        this.selectedItemsLabourQuote=res.data.labourQuoteItemResponses ? res.data.labourQuoteItemResponses.filter(item => item.jobCardStatus < 2) : [];

        this.selectedItemsLabourQuote=res.data.labourQuoteItemResponses ? res.data.labourQuoteItemResponses.filter(item => item.jobCardStatus === 3||item.jobCardStatus === 4) : [];

        this.getQuoteGrandTotal()
      },
      error => {
        console.error('Error fetching quote details:', error);
      }
    );
  }
  initializeDummyData() {
    this.quotationReceived = [
      { name: 'Lower Arm - LH', price: 1000, quantity: 1, selected: false, brand: 'Brand A', availability: 'In Stock', mrpUnit: 1200, mrpTotal: 1200, sellingPriceUnit: 1000, totalSellingPrice: 1000, discount: 0, partNumber: '12345' },
      { name: 'Lower Arm - RH', price: 1200, quantity: 2, selected: false, brand: 'Brand B', availability: 'In Stock', mrpUnit: 1300, mrpTotal: 1300, sellingPriceUnit: 1200, totalSellingPrice: 2400, discount: 5, partNumber: '54321' },
    ];

    this.quotationApproved = [
      { name: 'Suspension Kit', price: 3000, quantity: 1 }
    ];
  }

  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }

  openSidebar(item: any): void {
    this.sidebarOpen = true;
    this.itemDetails = item;
  }

  closeSidebar(): void {
    this.sidebarOpen = false;
  }



  updateTotalSellingPrice(): void {
    this.selectedItemsCount = this.quotationReceived.filter(item => item.selected).length;
    this.totalSelectedPrice = this.quotationReceived.reduce((acc, item) => item.selected ? acc + item.price * item.quantity : acc, 0);
  }

  approveQuote(): void {
  }

  getMakeImageUrl(make: string | undefined | null): string {
    if (!make) {
      console.warn('Car make is undefined or null. Returning default image.');
      return 'https://cube.getpitstop.com/assets/img/default.png'; // Ensure this URL points to a valid default image
    }
    const makeWithoutSpaces = make.toLowerCase().replace(/\s+/g, '');
    return `https://cube.getpitstop.com/assets/img/${makeWithoutSpaces}.png`;
  }


  shareOnWhatsApp(): void {
    if (!this.request) {
      // console.warn('Request object is undefined or null:', this.request);  
      alert('No job card data available to share.');
      return;
    }
  
    if (!this.request.jobCard) {
      // console.warn('Job card is missing:', this.request.jobCard); 
      alert('No job card data available to share.');
      return;
    }
    
  
    const jobCard = this.request.jobCard;
  
    const message = `
      👋 Hello ${jobCard.name},
      
      
      🛠 Your job card has been created successfully.
      
      📋 Job Card ID: ${jobCard.id}
      🚗 Odometer: ${jobCard.odometer}  
      📝 Registration Number: ${jobCard.registrationNumber}
      🏷 Make: ${jobCard.make}  
      🚙 Model: ${jobCard.model}  
      📅 Variant: ${jobCard.variant}  
      📆 MFG Year: ${jobCard.mfgYear || 0} 
      ⛽ Fuel: ${jobCard.fuelType} 
      🔑 VIN: ${jobCard.vin || ""} 
      
      We will soon send you a quotation for approval.
      
      Best regards,
      ${localStorage.getItem('spareSelectedGarageName') || 'Garage'}
    `;
  
    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
    // const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
    alert('Your job card details have been shared on WhatsApp.');
  }


  fetchJobCardDetails(): void {
    this.aspLoadingService.show();
    const garageKey = localStorage.getItem('garageKey');
      
    this.myJobCardsDetailsService.getJobCardDetails(this.bookingId , garageKey, this.jobCardId).subscribe(
      (result: any) => {
        this.aspLoadingService.hide();
  
        if (result && result.code === 200) {
          this.request = result.data;
          this.request1 = result.data;
          this.fetchPreFilledBooking();

          this.getQuoteDetails()
          // Check if request-id is present in the response
          const requestId = this.request?.jobCard?.requestId; // Update this path as per your actual response structure
          // const status = this.request?.jobCard?.jobcardStatus; 
          this.status = this.request?.jobCard?.jobcardStatus || '';  
          // Check if request-id is present in the response
          this.requestId = this.request?.jobCard?.requestId; // Update this path as per your actual response structure

       
        if(!this.invoiceOpenItem){
          this.loadComponentB(this.request?.jobCard); // Pass the request-id to Component B
        }
        } else {
          // console.warn('No "request-id" found in job card details');
        }
        
      },
      (error: HttpErrorResponse) => {
        this.aspLoadingService.hide();
        // console.error('Error fetching job card details:', error);
      }
    );
  }
  formatOrderDeliveryTime(confirmTime: string, deliveryTime: string) {
    try {
      const confirmDate = new Date(confirmTime.replace(" ", "T"));
      const deliveryDate = new Date(deliveryTime.replace(" ", "T"));
  
      if (isNaN(confirmDate.getTime()) || isNaN(deliveryDate.getTime())) {
        return '0 minutes';
        
      }
  
      const diffMs = deliveryDate.getTime() - confirmDate.getTime();
      const diffMins = Math.floor(diffMs / 60000);
  
      if (diffMins < 0) {
        return '0 minutes';
        
      }
  
      if (diffMins <= 180) {
        return `${diffMins} minutes`;
      } else {
        const hours = Math.floor(diffMins / 60);
        const minutes = diffMins % 60;
        return `${hours}H ${minutes} min`;
      }
  
    } catch (error) {
      return '0 minutes';
    }
  }
  // fetchMyRequest(): void {
  //   const spareUserKey = localStorage.getItem('spareUserKey');
  //   const garageKey = localStorage.getItem('garageKey');

  //   this.aspMyRequestService.myRequest(spareUserKey, garageKey).subscribe(
  //     result => {
  //       const res = JSON.parse(JSON.stringify(result));
  //       if (res.code === 200) {
  //         // Process the response data as needed
  //         console.log('My Request Data:', res.data);
  //       } else {
  //         console.warn('Error fetching My Request data:', res.message);
  //       }
  //     },
  //     (error: HttpErrorResponse) => {
  //       console.error('Error fetching My Request:', error);
  //     }
  //   );
  // loadComponentB(requestId: number) {
  //   // Create the factory for Component B
  //   const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AspRequestDetailsComponent);

  //   // Clear the container
  //   this.container.clear();

  //   // Add the component to the container
  //   this.container.createComponent(componentFactory);
  //   // Logic to dynamically load Component B and pass requestId
  // const componentRef = this.viewContainerRef.createComponent(AspRequestDetailsComponent);
  
  // // Pass the requestId to Component B
  // componentRef.instance.requestId = requestId;
  // }

  loadComponentB(jobCard): void {
    // this.container.clear(); // Just in case
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AspJobcardQuotationComponent);
    const componentRef = this.container.createComponent(componentFactory);
    const instance = componentRef.instance as AspJobcardQuotationComponent;
    instance.requestId = jobCard.requestId;
    instance.jobCard = jobCard;
  }

goBack(): void {
  this.location.back();
}

fetchPreFilledBooking(): void {
  // const registrationNumber = "registrationNumber";
  const garageKey = localStorage.getItem('garageKey');
  const registrationNumber = this.request.jobCard.registrationNumber;
  localStorage.setItem("registrationNumber", this.request.jobCard.registrationNumber);
  localStorage.setItem("selectedModel", this.request.jobCard.model);
  localStorage.setItem("variant", this.request.jobCard.variant);
  localStorage.setItem("selectedYear", this.request.jobCard.mfgYear);
  localStorage.setItem("fuelType", this.request.jobCard.fuelType);
  localStorage.setItem("vin", this.request.jobCard.vin);
  localStorage.setItem("selectedMake", this.request.jobCard.make);

  
  this.myJobCardsDetailsService.getPreFilledBooking(registrationNumber, garageKey).subscribe(
    res => {
      if (res && res.data) {
        // this.customer.name = res.data.name;
        // this.customer.mobile = res.data.mobile;
        // this.customer.email = res.data.email;
        // this.car.vin = res.data.vin;
        // this.car.engineNumber = res.data.engineNumber;
        this.showServiceHistory = true; 
      } else {
        this.showServiceHistory = false; 
      }
    },
    error => {
      console.error('Error fetching booking data:', error);
      this.showServiceHistory = false;
    }
  );
}
openServiceHistory(): void {
  // Dynamically create the component
  const factory = this.resolver.resolveComponentFactory(AspVehicleServiceHistoryComponent);
  const componentRef = this.historyContainer.createComponent(factory);

  // Access the instance and call the closeSidebar or similar method
  const serviceHistoryInstance = componentRef.instance as AspVehicleServiceHistoryComponent;
  serviceHistoryInstance.openSidebar(true);
  
}

sendFeedback() {
  this.checkInDetails();
  // console.log("customerName---",this.data.customerName);
  // console.log("garageName------",this.data.garageName);
  // const customerName = "Customer"; 
  // const garageName = "Autoverse Garage";
  const localBaseUrl = 'http://localhost:4200';
  const feedbackLink = `${GlobalVariable.AUTOVERSE_WEB}/customer-feedback/` + this.jobCardId + '/' +  this.status
  // const feedbackLink = `${localBaseUrl}/customer-feedback/` + this.jobCardId + '/' + this.status;
  // Construct WhatsApp Message
  const message = `*🚗 Service Feedback Request*  

  Dear ${this.customerName}, 👋  
  
  Your car is ready for delivery! 🚗  
  
  We value your feedback to improve our services.  
  Kindly take a moment to share your experience by clicking the link below:  
  
  🔗 ${feedbackLink}  
  
  Thank you for choosing us! 😊  
  
  *Regards,*\n  
  *_${this.data.garageName}_*`;


  // Open WhatsApp with the pre-filled message
  // const whatsappUrl = `https://wa.me/?text=${message}`;
  // window.open(whatsappUrl, '_blank');
  const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
  window.open(whatsappUrl, '_blank');
}
redirectToFeedback() {
  this.router.navigate(['asp-feedback']);
}
invoiceOpen(){
  this.sidebarOpenInvoice=true;
  this.openSideShow=false;
}
CloseSidebar(){
  localStorage.removeItem('invoiceOpen');
  if (this.onClose) {
    this.onClose();
  }
  this.sidebarOpenInvoice=false;
}
toggleSidebar() {
  this.isSidebarOpen = !this.isSidebarOpen;
}
clearSearch() {
  this.searchWord = '';

  this.filteredItems = [];
}
sidebarToggleSelectAllQuote() {
  this.selectAllQuote = !this.selectAllQuote;

  // Ensure customerQuotation is defined before looping
  if (Array.isArray(this.customerQuotation)) {
    this.customerQuotation.forEach(quote => quote.selected = this.selectAllQuote);
  } else {
    console.error("Error: this.customerQuotation is undefined or not an array", this.customerQuotation);
  }
  if (Array.isArray(this.selectedItemsLabourQuote)) {
    this.selectedItemsLabourQuote.forEach(labour => {
      labour.selected = this.selectAllQuote;
    });
  } else {
    console.log('error select all labour');
  }

  // Ensure selectedItemsLabourQuote is defined before looping
  if (Array.isArray(this.selectedItemsLabourQuote)) {
    this.selectedItemsLabourQuote.forEach(labour => labour.selected = this.selectAllQuote);
  } else {
    console.error("Error: this.selectedItemsLabourQuote is undefined or not an array", this.selectedItemsLabourQuote);
  }


  // Ensure selectedLabours is defined
  this.selectedLaboursCount = this.selectedLabours ? this.selectedLabours.length : 0;

  // Call other functions
  // this.labourList();
  this.updateSelectedItemsQuote();
  this.updateSelectedLabourItems();



// this.anyItemSelected();
// this.toggleItemSelection();

}
ApprovedtoggleItemSelection(): void {
  this.selectedApprovedItemsCount = this.quotationapproved.filter(item => item.selected).length;
  this.totalSelectedApprovedPrice = this.quotationapproved
    .filter(item => item.selected)
    .reduce((total, item) => total + ((item.mrp - (item.mrp * item.sellingDiscount / 100)) * item.itemQuantity), 0);

  // Check if all items are selected, update selectAll accordingly
  this.selectAll = this.selectedApprovedItemsCount === this.quotationapproved.length;

  console.log("Selected Items Count:", this.selectedApprovedItemsCount);
  console.log("Total Selected Price:", this.totalSelectedApprovedPrice);

  this.updateSelectedItemsQuote();
  this.updateApprovedSelectedItems();
  this.anyItemSelected();
}
updateApprovedSelectedItems(): void {
  this.selectedApprovedItemsCount = this.quotationapproved.filter(item => item.selected).length;
  this.totalSelectedApprovedPrice = this.quotationapproved.filter(item => item.selected).reduce((total, item) => total+((item.mrp - (item.mrp* item.sellingDiscount /100))*item.itemQuantity), 0);
  this.totalSelectedApprovedPrice = this.quotationapproved.filter(item => item.selected).reduce((total, item) => total+((item.mrp - (item.mrp* item.sellingDiscount /100))*item.itemQuantity), 0);
  // this.selectedItemsCount=this.selectedLabours?.length+this.selectedItemsCountQuote
  // this.selectedLaboursCount=this.selectedLabours.length;
  // console.log("Selected Items Count:", this.selectedItemsCount);

}
toggleSelectAllQuote(): void {
  this.selectAllQuote = !this.selectAllQuote;
  this.customerQuotation.forEach(quote => quote.selected = this.selectAllQuote);
  this.selectedItemsLabourQuote.forEach(labour => labour.selected=this.selectedLabourCount);
  this.selectedLaboursCount=this.selectedLabours.length;
  this.updateSelectedItemsQuote();
  this.anyItemSelected();
  this.toggleItemSelection();
}
toggleSelectAll(): void {
  this.selectAll = !this.selectAll;
  this.quotationreceived.forEach(item => item.selected = this.selectAll);
   this.updateSelectedItems();
}
updateSelectedItems(): void {
  this.selectedItemsCount = this.quotationreceived.filter(item => item.selected).length;
  this.totalApprovedSelectedPrice = this.quotationapproved.filter(item => item.selected).reduce((total, item) => total+((item.mrp - (item.mrp* item.sellingDiscount /100))*item.itemQuantity), 0);
  this.totalSelectedPrice = this.quotationreceived.filter(item => item.selected).reduce((total, item) => total+((item.mrp - (item.mrp* item.sellingDiscount /100))*item.itemQuantity), 0);
  // this.selectedItemsCount=this.selectedLabours?.length +this.selectedItemsCountQuote;
  this.selectedItemsCount=this.selectedLabours.length +this.selectedItemsCountQuote;
  console.log("Selected Items Count1--------------:", this.selectedItemsCount);

}
isQuoteApproved(): boolean {
  return this.quotationapproved.length > 0;
}
addItemToQuotation(item: any) {
 console.log("addItemToQuotation",item);
  // Find an existing item with the same partNumber
  const existingItem = this.customerQuotation.find(quote => quote.partNumber === item.partNumber);

  if (existingItem) {
    
 console.log("existingItem",existingItem);
    // If found, increase the quantity
    existingItem.itemQuantity += 1;
     } else {
    // Otherwise, add the new item to the list
    const newItem = {
      itemId: item.itemId,
      itemName: item.name,
      brand: item.brand,
      partNumber: item.partNumber,
      sellingPrice: item.mrp,
      hsnCode:item.hsnCode,
      gstRate:item.gstRate,
      quantity: 1,
      selected: true,
      edt: new Date(),
    };
    this.customerQuotation.push(newItem);
    console.log("newItem",newItem);
    this.customerQuotation.forEach(quote => quote.selected = this.selectAllQuote);

    
  }
  this.isSidebarOpen = false;
  this.clearSearch();
  this.updateSelectedItemsQuote();
  this.getQuoteGrandTotal();
}
ApprovedtoggleSelectAll(): void {
  this.selectAll = !this.selectAll;
  this.quotationapproved.forEach(item => item.selected = this.selectAll);
  // console.log("this.selectedItemsCount-----------------",this.selectedItemsCount);
  this.updateApprovedSelectedItems();
  this.anyItemSelected();
  this.ApprovedtoggleItemSelection();
}
toggleItemSelection(): void {
  this.selectedItemsCount = this.quotationreceived.filter(item => item.selected).length;
  this.totalSelectedPrice = this.quotationreceived
    .filter(item => item.selected)
    .reduce((total, item) => total + ((item.mrp - (item.mrp * item.sellingDiscount / 100)) * item.itemQuantity), 0);

  // ✅ Check if all checkboxes are selected and update `selectAll`
  this.selectAll = this.selectedItemsCount === this.quotationreceived.length;

}
// updateSelectedItems(): void {
//   this.selectedItemsCount = this.quotationreceived.filter(item => item.selected).length;
//   this.totalApprovedSelectedPrice = this.quotationapproved.filter(item => item.selected).reduce((total, item) => total+((item.mrp - (item.mrp* item.sellingDiscount /100))*item.itemQuantity), 0);
//   this.totalSelectedPrice = this.quotationreceived.filter(item => item.selected).reduce((total, item) => total+((item.mrp - (item.mrp* item.sellingDiscount /100))*item.itemQuantity), 0);
//   // this.selectedItemsCount=this.selectedLabours?.length +this.selectedItemsCountQuote;
//   this.selectedItemsCount=this.selectedLabours.length +this.selectedItemsCountQuote;
//  console.log("this.selectedItemsCount",this.selectedItemsCount)
// }
// updateSelectedItemsQuote(): void {

//   this.selectedItemsQuote = this.customerQuotation.filter(quote => quote.selected);
//   this.selectedItemsCountQuote = this.customerQuotation.filter(quote => quote.selected).length;
//   console.log("123 items---",this.selectedItemsCountQuote);
  
//   this.selectedItemsCount1=(this.selectedItemsCountQuote || 0)+(this.selectedLabourLength || 0);
//   console.log("Items Length",this.selectedItemsCount1);
//   this.selectedItemsLabourQuotePrice=this.selectedItemsLabourQuote?.filter(quote => quote.selected)
//   .reduce((total, quote) => total + (quote.labourAmount *1), 0);
//   this.selectedLabourLength = this.selectedItemsLabourQuote?.filter(quote => quote.selected).length;
//   this.sparePartsTotal = this.customerQuotation
//     .filter(quote => quote.selected)
//     .reduce((total, quote) => total + (quote.sellingPrice * quote.quantity), 0);
//     console.log("totoaofkjfjfdf----",this.sparePartsTotal)
//   this.totalSelectedPriceQuoteTotal = this.sparePartsTotal +(this.selectedItemsLabourQuotePrice || 0);
//   this.customerQuotationPrice = this.sparePartsTotal +(this.selectedItemsLabourQuotePrice || 0);
//   this.selectedItemsCountTotal=(this.selectedLabourLength ) +(this.selectedItemsCountQuote);
//   console.log("456 labous------",this.selectedItemsCountTotal)
//  }

updateSelectedItemsQuote(): void {
  this.selectedItemsQuote = this.customerQuotation.filter(quote => quote.selected);
  this.selectedItemsCountQuote =  this.customerQuotation.filter(quote => quote.selected).length; // Ensure correct count
  console.log("this.customerQuotation--====",this.customerQuotation);
  this.sparePartsTotalcreation = this.selectedItemsQuote.reduce((total, quote) => 
      total + (quote.sellingPrice * quote.quantity), 0
  );

  this.sparePartsTotal = this.selectedItemsQuote.reduce((total, quote) => 
      total + (quote.sellingPrice * quote.quantity), 0
  );

  // Ensure this.selectedItemsCount is defined
  // this.selectedItemsCount = (this.selectedItemsCountQuote || 0)+ (this.selectedLabours.length || 0);

  this.selectedItemsCount = (this.selectedItemsCountQuote || 0) + (this.selectedLabourslength || 0);
  console.log("this.sparePartsTotalcreation----",this.sparePartsTotalcreation);
  console.log("==========================================================================")
  console.log("this.totallabourSelectedPrice------",this.totallabourSelectedPrice);
  // Update the total with selected labour
  this.totalSelectedPriceQuote = this.sparePartsTotalcreation + (this.totallabourSelectedPrice || 0);
  
  console.log("Selected Items:", this.selectedItemsQuote);


  console.log("Total Selected Parts Price:", this.sparePartsTotalcreation);
  this.getQuoteGrandTotal();
}



updateSelectedLabourItems(): void {
this.selectedLabours = this.selectedItemsLabourQuote?.filter(labour => labour.selected);
this.selectedLabourslength = this.selectedLabours?.length || 0;
console.log("this.selectedLaobours---",this.selectedLabourslength);
console.log("new labourlist----",this.labourListData);
this.selectedNewLabourSelected = this.labourListData.filter(labour => labour.selected)
this.selectedNewLabours = this.labourListData.filter(labour => labour.selected).length;
console.log("this.selecred New Labour----",this.selectedNewLabours);

this.totallabourListNewSelectedPrice = this.selectedNewLabourSelected?.reduce(
  (sum, labour) => sum + (Number(labour.labourAmount) * Number(labour.quantity || 1)), 0
);

// ✅ Multiply labour amount by quantity
this.totallabourSelectedPrice = this.selectedLabours?.reduce(
  (sum, labour) => sum + (Number(labour.labourAmount) * Number(labour.quantity || 1)), 0
);

this.totallabourListSelectedPrice = this.selectedItemsLabourQuote?.reduce(
  (sum, labour) => sum + (Number(labour.labourAmount) * Number(labour.quantity || 1)), 0
);

// ✅ Ensure total price includes updated labour costs
this.totalSelectedPriceQuote = Number(this.totallabourSelectedPrice) + Number(this.sparePartsTotalcreation || 0);
this.customerQuotationPrice = this.totalSelectedPriceQuote;

this.selectedItemsCount = (Number(this.selectedItemsCountQuote) || 0) + (Number(this.selectedLabourslength) || 0);

console.log("this.selectedlabourlength---", this.selectedLabourslength);
console.log("this.selectedItemsCount----", this.selectedItemsCountQuote);
console.log("Selected Items Count:", this.selectedItemsCount);
console.log("Total Selected Labour Price:", this.totalSelectedPriceQuote);
}
AddItems()
{
  this.isSidebarOpen = !this.isSidebarOpen;
  this.searchText = '';
  this.filteredItems = [];
}
sidebarToggleItemSelectionQuote(){
  this.toggleItemSelectionQuote();

}
openEditDialog(itemQuotes){
  this.itemQuote=[];
  this.itemQuote=itemQuotes;
  this.itemQuote['discountedPrice'] = this.calculateDiscountedPrice(itemQuotes.mrp, itemQuotes.discount);
  this.isPopupOpenEdit = true;
  this.selectedItemsCountQuote = 0;
  if (itemQuotes) {
    this.itemQuotesEdit = { ...itemQuotes };
   }
  // this.customerQuotation.forEach(quote => {
  //   quote.selected = false;
  // });
  // this.customerQuotation.forEach(quote => quote.selected = this.selectAllQuote=false);
}
calculateDiscountedPrice(mrp: number, discount: number): number {
  return mrp - (mrp * discount / 100);
}
decrementQuantity(quote: any): void {
  if (quote.quantity > 1) { 
    quote.quantity--;
    this.customerQuotationPrice = this.calculateTotalQuotationPrice();
    this.updateSelectedItemsQuote();
    this.getQuoteGrandTotal();  
  }
}
calculateTotalQuotationPrice(): number {
  // Recalculate total price by summing up all items' total amounts
  return this.customerQuotation.reduce((sum, item) => sum + (item.mrp * item.itemQuantity), 0);
}
incrementQuantity(quote: any): void {
  quote.quantity++;
  this.customerQuotationPrice = this.calculateTotalQuotationPrice();
  this.updateSelectedItemsQuote();
  this.getQuoteGrandTotal();  
}
reloadApprovedQuotes() {
  const newApprovedItems = this.quotationreceived.filter(item => !this.quotationapproved.some(approved => approved.itemId === item.itemId));
  
  // Add only new items to the approved list
  this.quotationapproved.push(...newApprovedItems);
console.log("this.quotationapproved.-----------",this.quotationapproved);
  // Recalculate total approved price
  this.quoteApprovedPrice = this.quotationapproved.reduce((total, item) => 
    total + ((item.mrp - (item.mrp * item.sellingDiscount / 100)) * item.itemQuantity), 0);
}
toggleItemSelectionQuote(): void {
  if (Array.isArray(this.customerQuotation)) {
    this.selectAllQuote = this.customerQuotation.every(quote => quote.selected);
  } else {
    this.selectAllQuote = false;
  }
  if (Array.isArray(this.selectedItemsLabourQuote)) {
    this.selectedItemsLabourQuote.forEach(labour => {
      labour.selected = labour.selected ? true : false;
    });

    this.selectedLabourCount = this.selectedItemsLabourQuote.filter(labour => labour.selected).length;
  }
   else {
    this.selectedLabourCount = 0;
  }
  // this.labourList();
  this.updateSelectedItemsQuote();
  this.updateSelectedLabourItems();
  this.cdr.detectChanges();
}
openSidebar1(itemdata): void {
  // console.log('Item Data:', itemdata);
  this.itemDetails=[];
  this.itemDetails=itemdata;
  this.itemDetails.discountedPrice = this.calculateDiscountedPrice(itemdata.mrp, itemdata.discount);
  this.sidebarOpen = true;


  // this.itemDetails.brandName = itemdata.bidDetails?.[0]?.estimateItem?.Battery?.[0]?.brand;

  // this.sidebarOpen = true;
  

}
// updateSelectedLabourItems(): void {
//   this.selectedLabours = this.selectedItemsLabourQuote.filter(labour => labour.selected);
//   this.selectedLabourslength = this.selectedItemsLabourQuote.filter(labour => labour.selected).length;
//   // this.selectedItemsLabourQuote = this.selectedLabours;

//   // this.selectedItemsCount = this.selectedLabours.length;
//   this.totallabourSelectedPrice = this.selectedItemsLabourQuote.reduce((sum, labour) => {
//     return sum + (labour.labourAmount || 0);
//   }, 0);
//   // this.selectedLabourCount = this.selectedItemsLabourQuote.every(labour => labour.selected);
//   // this.selectedLabourCount=this.selectedLabourCount.length;
//   this.totalSelectedPriceQuote = this.totallabourSelectedPrice + (this.sparePartsTotal || 0);
//   this.customerQuotationPrice = this.totalSelectedPriceQuote;
//   this.selectedLaboursCount=this.selectedLabours.length || 0;
//   this.selectedItemsCount=this.selectedLaboursCount.length +this.selectedItemsCountQuote
//   console.log("total selected labour price-----------------", this.totallabourSelectedPrice); 
//  }
labourList() {
  const garageKey = localStorage.getItem('garageKey');
  console.log("garagekey", garageKey);

  if (garageKey) {
      this.aspRequestDetailsService.displayLabourList(garageKey).subscribe(
          (result) => {
              const res = JSON.parse(JSON.stringify(result));
              if (res.code === 200) {
                  console.log("labourres", res);
                  console.log("this.selectedItemsLabourQuote labourlist----", this.selectedItemsLabourQuote);

                  if (!Array.isArray(this.selectedItemsLabourQuote)) {
                      this.selectedItemsLabourQuote = [];
                  }
console.log("selcted labour items---",this.selectedItemsLabourQuote);
                  // Ensure labours in selectedItemsLabourQuote remain selected in the list
                  this.fullLabourList = res.data.map((labour: any) => {
                      const existingItem = this.selectedItemsLabourQuote.find(
                          (selectedItem: any) => selectedItem.id === labour.id
                      );

                      return {
                          ...labour,
                          selected: !!existingItem, // Mark as selected if present in selectedItemsLabourQuote
                          quantity: existingItem?.quantity ?? 1, // Use existing quantity, otherwise default to 1
                      };
                  });

                  // Sorting: Move selected items to the top
                  this.fullLabourList.sort((a, b) => Number(b.selected) - Number(a.selected));

                  this.labourListData = [...this.fullLabourList]; // Copy sorted list to labourListData
                  console.log("Updated labourListData:", this.labourListData);

                  this.updateSelectedLabourItems();
                  this.updateSelectedItemsQuote();
              }
          },
          (error) => {
              console.error('Error fetching labour list:', error);
          }
      );
  } else {
      console.warn('Garage key not found in localStorage.');
  }
}


invoiceOpen123(){
  this.side1235=true
}
openLabourEditSidebar(item: any = null) {
  this.isEditLabourSidebarOpen = true;
  // this.isAddLabour = item ? false : true;
  this.isSidebarOpen = false;
// console.log("sdjfjsdfjisdf")
  if (item) {
    this.selectedLabour = { ...item };
    this.labourName = this.selectedLabour.labourName;
    this.labourAmount = this.selectedLabour.labourAmount;
    this.labourId = this.selectedLabour.labourId;
    this.id = this.selectedLabour.id;
  } else {
    this.selectedLabour = { name: '', price: null };
  }
}
anyItemSelected() {
  return this.selectedLabourslength > 0;
}
anyItemSelectedQuote() {
  return this.selectedItemsCountQuote > 0;
}
onSearch(item: any) {
  if (!this.searchWord.trim()) {
    return;
  }
  const garageKey=localStorage.getItem('garageKey');
  const variantKey=this.request.jobCard['variantKey'];
  this.aspRequestDetailsService
    .searchItems(garageKey, this.searchWord,variantKey)
    .subscribe(
      (result) => {
        const res = JSON.parse(JSON.stringify(result));
        
        if (res.code === 200) {
          this.filteredItems = res.data;
          this.partNumber= res.data.map((item: any) => item.partNumber);
        } else {
          console.error('Search failed with code:', res.code);
        }
      },
      (error) => {
        console.error('Error during search:', error);
      }
    );
}
addLabourSidebar(){
  this.isAddLabour = !this.isAddLabour; 
  this.labourList();
  
}
labourRedirectSidebar()
{
  this.isAddLabour=false;
  this.selectedLabourCount=0;
  }
  toggleSelect() {
    this.selectAll = !this.selectAll;
  }
  onLabourSelectionChange(labour: any): void {
    if (!labour) {
      console.error('Labour object is undefined');
      return;
    }
  
    // Ensure labour is selected
    labour.selected = labour.selected || false;
  
    // ✅ Set default quantity to 1 if not already set
    if (!labour.quantity || labour.quantity < 1 || labour.quantity == '') {
      labour.quantity = 1;
    }
  
    // Sync the selected items list
    this.selectedItemsLabourQuote = this.labourListData.filter(l => l.selected);
  
    // ✅ Calculate total selected price including quantity
    this.totallabourListSelectedPrice = this.selectedItemsLabourQuote.reduce(
      (sum, labour) => sum + (Number(labour.labourAmount) * labour.quantity || 0), 
      0
    );
  
    console.log(this.selectedItemsLabourQuote);
    console.log("this.selectedlabourprice----", this.totallabourListSelectedPrice);
  
    // ✅ Update the selected labour count
    this.selectedLabourCount = this.selectedItemsLabourQuote.length;
  
    // ✅ Calculate total price
    this.totalSelectedPriceQuote1 = this.selectedItemsLabourQuote.reduce(
      (sum, item) => sum + (Number(item.labourAmount) * item.quantity || 0), 
      0
    );
  
    // Update UI
    this.updateSelectedLabourItems();
    this.updateSelectedItemsQuote();
  }
closeLabourEditSidebar() {
  this.isEditLabourSidebarOpen = false;
  this.selectedLabour = { labourName: '', labourAmount: null };
  // this.isAddLabour=false;
}
onCheckboxChange(labour: any, event: Event): void {
  if (!labour) {
    console.error('Labour object is undefined');
    return;
  }
  labour.selected = (event.target as HTMLInputElement).checked;
  this.updateSelectedLabourItems();
  this.updateSelectedItemsQuote(); // Sync after updating labour
}
openSkipConfirmation(){
  this.showSkipConfirmation = true;
}
closeSkipConfirmation(): void {
  this.showSkipConfirmation = false;
}
confirmSkip(): void {
  this.showSkipConfirmation = false;
  this.isAddLabour = false;
}
openPopUp(){
  this.showCancelConfirmation = true;
  this.isAddLabour = false;
}
closeCancelPopup(): void {
  this.showCancelConfirmation = false;
}
openPopup(): void {
  this.isPopupOpen = true;
  this.showSkipConfirmation = false;
  this.isAddLabour = false;
  this.CloseSidebarQuote();
  this.submitData();
  this.CloseSidebar(); 
}
submitData() {
    // Filter selected items
    this.selectedItems = this.customerQuotation.filter(quote => quote.selected);


    // Generate random 7-digit ID for items with null or undefined `itemId`
    this.selectedItems.forEach(item => {
      if (!item.itemId) {
        item.itemId = Math.floor(1000000 + Math.random() * 9000000);
           }
    });

    const selectedPartNumbers = this.filteredItems.map(item => item.partNumber);
  
    let labour = [];
    
     
    if (this.selectedItemsLabourQuote && this.selectedItemsLabourQuote.length > 0) {
      labour = this.selectedItemsLabourQuote.map(labour => ({
        labourId: labour.labourId ,
        labourName: labour.labourName,
        labourAmount: labour.labourAmount || 0, // Ensure labourAmount is not undefined
        customerQuoteId: this.request.jobCard.customerQuoteId,
        itmQty:1,
      }));
    }
     const cleanData = {
 
      quoteId: this.request.jobCard.customerQuoteId,
      garageKey: localStorage.getItem('garageKey'),
      jobcardId: this.request?.jobCard?.id,
      quoteVersion: 1,
      gstNumber: "",
      billingAddress: "",
      shippingAddress: "",
  
      customerQuoteItems: this.selectedItems.map(item => ({
        id: item.itemId,
        partName: item.itemName,
        itmQty: item.quantity,
        partNumber: item.partNumber,
        brand: item.brand,
        sellingPrice: item.sellingPrice,
        hsnCode:item.hsnCode,
        gstRate:item.gstRate

      })),
      labourQuoteItems: labour, // Use the correctly mapped labour array
      // registrationNumber:  this.request?.jobCard?.registrationNumber?? null,
    };


    // Submit the data via the service
    this.myJobCardsDetailsService.submitData1(cleanData).subscribe(
      response => {
        this.resData = response['data'];
        this.dataid = response['data'].id;

       },
      error => {
        console.error('Error occurred:', error);
      }
    );

  // else {
  //   this.submitDataUpdate();
  // }
}
submitDataUpdate() {
  // if (this.quoteId) {
  
  // Create a clean data object with only the required fields
  this.selectedItems = this.customerQuotation.filter(quote => quote.selected)
  this.selectedItems.forEach(item => {
    this.item=item.bidId;
  });
  
  const cleanData1 = {
    
    bidId: this.item,
    garageKey: localStorage.getItem('garageKey'),
    items: this.selectedItems.map(item => ({
      id: item.itemId,
      part_name: item.itemName,
      partNumber: item.partNumber,
      sellingPrice: item.mrp
    })),
    quoteId: this.request.jobCard.customerQuoteId,
    registrationNumber: this.request?.jobCard?.registrationNumber ?? null,
    requestId: this.id,
    userKey:localStorage.getItem('spareUserKey'),
  };
  
  this.aspRequestDetailsService.submitDataUpdate(cleanData1).subscribe(response => {
    this.resData=response['data']
    console.error('response11:',response );
  });
}
CloseSidebarQuote(): void {
  this.sidebarOpenQuote = false;  
}
onLabourSearch(): void {
  const query = this.searchText?.toLowerCase();
  if (query) {
    this.labourListData = this.labourListData.filter(item =>
      item.labourName?.toLowerCase().includes(query)
    );
  // if(this.searchText=''){
  //   this.labourList();
  // }
  } else {
    this.filteredItems1 = [...this.labourListData]; // Reset to full list
  }
}

clearLabourSearch(){
  this.searchText='';
  this.labourList();
}
addLabour() {
  console.log(this.ld.labourAmount.value);
  const updatedLabourName = this.ld.labourName.value;
  const updatedLabourAmount = this.ld.labourAmount.value;
  const garageKey = localStorage.getItem('garageKey');

  this.aspRequestDetailsService.addLabourList(garageKey, updatedLabourName, updatedLabourAmount)
    .subscribe((result) => {
      const res = JSON.parse(JSON.stringify(result));
      if (res.code === 200) {
        console.log("addLabour--", res);
        const newLabour = {
          labourName: updatedLabourName,
          labourAmount: updatedLabourAmount,
          selected: true, 
          quantity: 1,
          id: res.data?.id,
          labourId : res.data?.labourId,
          
        };
        this.labourListData.unshift(newLabour);
        this.selectedItemsLabourQuote.unshift(newLabour);
        this.labourList();
        this.updateSelectedItemsQuote();
        this.updateSelectedLabourItems();
        this.toastr.success("Labour Added Successfully");
        this.ngOnInit();
      }
      else{
        this.toastr.error("Duplicate Names are not allowed")
      }

      this.closeLabourEditSidebar();
      this.ngOnInit();
    });
}
saveLabour(): void {
  console.log("Labour Name:", this.ld.labourName.value);
  console.log("Labour Amount:", this.ld.labourAmount.value);

  const updatedLabourName = this.ld.labourName.value;
  const updatedLabourAmount = this.ld.labourAmount.value;
  const garageKey = localStorage.getItem('garageKey');

  this.aspRequestDetailsService
      .editLabour(this.id, this.labourId, garageKey, updatedLabourName, updatedLabourAmount)
      .subscribe(
          (result) => {
              console.log('API Response:', result);
              const res = JSON.parse(JSON.stringify(result));

              if (res.code === 200) {
                  console.log('Labour edited successfully:', res);
                  this.toastr.success("Labour saved Successfully");

                  let labourExists = false;
                  this.selectedItemsLabourQuote = this.selectedItemsLabourQuote.map(item => {
                      if (item.id === this.id) {  // Ensure matching by correct ID
                          labourExists = true;
                          return {
                              ...item,
                              labourName: updatedLabourName,
                              labourAmount: updatedLabourAmount,
                              selected: true // Ensure it stays selected
                          };
                      }
                      return item;
                  });

                  // If the labour does not exist, add it as selected
                  if (!labourExists) {
                      this.selectedItemsLabourQuote.push({
                          id: this.id,
                          labourName: updatedLabourName,
                          labourAmount: updatedLabourAmount,
                          selected: true
                      });
                  }

                  console.log("Updated selectedItemsLabourQuote:", this.selectedItemsLabourQuote);

                  // Refresh the labour list to reflect changes
                  this.labourList();
              } else {
                  console.warn('Unexpected response code:', res.code);
              }
          },
          (error) => {
              console.error('Error editing labour:', error);
          }
      );

  this.closeLabourEditSidebar();
}




// toggleSelectItem(): void {

//   this.selectedItemsLabourQuote = this.labourListData.filter(item => item.selected);
//   this.totalSelectedPriceQuote1 = this.selectedItemsLabourQuote.reduce(
//     (sum, item) => sum + (item.labourAmount || 0),0);
//    this.isAddLabour = false;
//    this.getQuoteGrandTotal();
// }


toggleSelectItem(): void {
  // console.log("selectedItemsLabourQuote---",this.selectedItemsLabourQuote)
  this.selectedItemsLabourQuote = this.labourListData.filter(item => item.selected);
  this.totalSelectedPriceQuote1 = this.selectedItemsLabourQuote.reduce(
    (sum, item) => sum + (item.labourAmount || 0),0);
  // console.log('Selected Items:', this.selectedItemsLabourQuote);
  // console.log('Total Selected Price:', this.totalSelectedPriceQuote1);
  this.isAddLabour = false;
  this.getQuoteGrandTotal();
}
closeAll(): void{
  this.isAddLabour = false;
  this.isEditLabourSidebarOpen = false;
  this.sidebarOpenQuote = false; 
}
save(): void {
  const index = this.customerQuotation.findIndex(i => i.partNumber === this.itemQuote['partNumber']);

   // If the item exists, update it
   if (index !== -1 ) {
     this.itemQuotesEdit=this.up.unitprice.value
       // Update the item in the list with the new unit price and recalculate total amount
       this.customerQuotation[index].mrp =Number(this.up.unitprice.value);
       this.customerQuotation[index].sellingPrice =Number(this.up.unitprice.value);
       this.customerQuotation[index].totalAmount = this.up.unitprice.value * this.itemQuote['quantity'];
       }
         // Recalculate the total quotation price
   this.customerQuotationPrice = this.calculateTotalQuotationPrice();
   // Close the popup after saving
   this.closePopupEdit();
   this.updateSelectedItemsQuote();
   this.updateSelectedLabourItems();
   this.getQuoteGrandTotal();
 }
closePopupEdit(): void {
  this.isPopupOpenEdit = false;
}
  @ViewChild('dynamicContainer1', { read: ViewContainerRef }) container1!: ViewContainerRef;
  loadorderQuote(id) {
    const factory = this.resolver.resolveComponentFactory(AspInvoiceDetailsComponent);
    const componentRef = this.container1.createComponent(factory);
    const componentBInstance = componentRef.instance as AspInvoiceDetailsComponent;
    this.closePopup();
    componentBInstance.resId(id);
    this.isPopupOpen=false;
  }
navigateToDetails(id: string) {
  console.log('Navigating with ID:', id);
  this.router.navigate(['/invoice-details'], { queryParams: {"id": id, "jobcardId":this.request?.jobCard?.id,"QuoteId":this.request1.jobCard.customerQuoteId}})
  this.isPopupOpen=false;
  this.sidebarOpenInvoice=false;
}

toggle() {
  this.isOpen = !this.isOpen;
}

getQuoteGrandTotal(){
  // this.updateSelectedLabourItems()
  let partsTotal = 0;
  console.log("saikiran customer Quotation---",this.customerQuotation);
  
  if(this.customerQuotation){
    console.log("this.customerQuotation123",this.customerQuotation)
    console.log("this.selectedItemsLabourQuote123",this.selectedItemsLabourQuote)
    partsTotal = this.customerQuotation?.reduce((sum, item) => sum + (item.sellingPrice * item.quantity),0);
  }

  let labourTotal = 0;
  if(this.selectedItemsLabourQuote){
    labourTotal = this.selectedItemsLabourQuote?.reduce((sum, item) => sum + (Number(item.labourAmount) * item.quantity),0);
  }
  this.quoteGrandTotal = partsTotal + labourTotal;
}
// Example usage

createNewItems(){
  this.newItem=!this.newItem;
}
createItemSidebar(){
  this.newItem= false;
  this.newItems=!this.newItems;
  this.isSidebarOpen=false;
}
onSubmit() {
     if (this.itemForm.invalid) {
    Object.keys(this.itemForm.controls).forEach(field => {
      const control = this.itemForm.get(field);
      control?.markAsTouched({ onlySelf: true });
      control?.updateValueAndValidity(); // Ensure validation messages appear
    });

    this.scrollToFirstInvalidField();
    return;
  }

  console.log('Form submitted successfully', this.itemForm.value);

  if (this.itemForm.valid) {
    console.log('Form Submitted createNewItem', this.itemForm.value);
    const cleanData1 = {
      "garageKey": localStorage.getItem('garageKey'),
      "garageName": localStorage.getItem('spareSelectedGarageName'),
      "userName": localStorage.getItem('spareSelectedGarageName'),
      "userRole":localStorage.getItem('userType'),
      "partName": this.itemForm.value['itemName'],
      "partNumber":this.itemForm.value['partNumber'],
      "mrp": (this.itemForm.value.unitPrice / 100).toFixed(2),
      "gstRate": this.itemForm.value['gst'],
      "brandName":this.itemForm.value['brand'],
      "hsnCode": this.itemForm.value['hsn'],
      "make": this.request?.jobCard?.make,
      "model": this.request?.jobCard?.model,
      "variant":this.request?.jobCard?.variant,
      "rowKey": this.request?.jobCard?.rowKey,
      "variantKey": this.request?.jobCard?.variantKey
    };
  
    // Call the API with updated data
    this.aspCustomerQuoteService.createNewItem(cleanData1).subscribe(response => {
      const res = JSON.parse(JSON.stringify(response));
      console.log("res123456789",res);
      this.originalData=res.data;
      const transformedData = {
        ...this.originalData, 
        brand:this.originalData.brandName,
        itemName: this.originalData.partName, 
        itemId: undefined, 
        quantity: 1, 
        sellingPrice:this.originalData.mrp
      };
      this.customerQuotation.push(transformedData);
      console.log("this.customerQuotation",this.customerQuotation);
      // this.customerQuotationListCount = this.customerQuotation.length
      // console.log('Item added successfully:', this.customerQuotationListCount);
      this.customerQuotation.forEach(quote => quote.selected = this.selectAllQuote);  


      // this.customerQuotationCount=this.customerQuotation.length;
      this.isSidebarOpen = false;
      this.clearSearch();
      this.updateSelectedItemsQuote();
      this.getQuoteGrandTotal();
      
    }
  )
  }
  this.newItems=false;
}
scrollToFirstInvalidField() {
  const firstInvalidControl: HTMLElement = document.querySelector(
    ".ng-invalid.ng-touched"
  ) as HTMLElement;

  if (firstInvalidControl) {
    firstInvalidControl.scrollIntoView({ behavior: "smooth", block: "center" });
    firstInvalidControl.focus(); // Optional: Focus on the field
  }
}

incrementLabourQuantity(quote: any): void {
  if (quote.quantity >= 1) {
    quote.quantity++;
  }

  // ✅ Recalculate labour price and update UI
  this.labourQuotationPrice = this.calculateTotalLabourPrice();
  this.updateSelectedItemsQuote();
  this.updateSelectedLabourItems();
  this.getQuoteGrandTotal();  
  this.cdr.detectChanges();
}

decrementLabourQuantity(quote: any): void {
  if (quote.quantity > 1) { // ✅ Prevents quantity from going below 1
    quote.quantity--;
  }

  // ✅ Recalculate labour price and update UI
  this.labourQuotationPrice = this.calculateTotalLabourPrice();
  this.updateSelectedItemsQuote();
  this.updateSelectedLabourItems();
  this.getQuoteGrandTotal();  
  this.cdr.detectChanges();
}


calculateTotalLabourPrice(): number {
  if (!Array.isArray(this.selectedItemsLabourQuote) || this.selectedItemsLabourQuote.length === 0) {
    return 0; // Return 0 if the array is undefined or empty
  }

  return this.selectedItemsLabourQuote.reduce(
    (sum, item) => sum + (Number(item.labourAmount) * Number(item.quantity || 1)),
    0
  );
}
}
