import { Component, OnInit,ViewChild,ViewContainerRef,ComponentFactoryResolver, ChangeDetectorRef, HostListener } from '@angular/core';
import { AspCustomerPaymentsListService } from './asp-customer-payments-list.service';
import { Router } from '@angular/router';
import { AspRecordPaymentComponent } from '../main/auto-spare-parts/asp-record-payment/asp-record-payment.component';
import { AspRecordPaymentService } from '../../app/main/auto-spare-parts/asp-record-payment/asp-record-payment.service';
import { AspLoadingService } from 'app/main/auto-spare-parts/asp-loading/asp-loading.service';

import { AspLoadingComponent } from '../../app/main/auto-spare-parts/asp-loading/asp-loading.component';
import { AspSparesListService } from 'app/main/auto-spare-parts/asp-spares-list/asp-spares-list.service';
import { AnimationOptions } from 'ngx-lottie';

// 1️⃣ Add interfaces at the top of the file
interface FilterOption {
  value: any;
  checked: boolean;
  children?: FilterOption[];
}

interface Filters {
  paymentStatus: any;
  status: FilterOption[];
  jobTime: FilterOption[];
}

interface JobCard {
  createdDate: string | number | Date;
  id: number;
  bookingId: number;
  phoneNumber: string;
  name: string;
  odometer: number;
  rowKey: string;
  variantKey: string;
  make: string;
  model: string;
  carSize: string;
  variant: string;
  fuelType: string;
  registrationNumber: string;
  createdAt: any;
  requestId: number;
  
}
interface FilterOption {
  label: string;
  value: any;
  checked: boolean;
  children?: FilterOption[];
}

interface Filters {
  jobCardStatus: FilterOption[];
  jobTime: FilterOption[];
  
}

@Component({
  selector: 'app-asp-customer-payments-list',
  templateUrl: './asp-customer-payments-list.component.html',
  styleUrls: ['./asp-customer-payments-list.component.scss']
})
export class AspCustomerPaymentsListComponent implements OnInit {
  @ViewChild('dynamicContainers', { read: ViewContainerRef }) containers!: ViewContainerRef;
  

  isReceiptSidebarOpen: boolean;
  invoiceData: any;
  data: any;
  customerPayments: any;
  receiptNumber: string = '';
  cashReceiptNumber: string;  
  invoiceAmount: number = 0;  
  amount: number = this.invoiceAmount; 
  paymentStatus!: any;
  transactionDate: string = '';
  invoice: any;
  amountPaid: any;
  amountDue: any;
  searchText: string = '';
  loading: boolean = false;
  successIcon: string;
  Messages: string[] = []; 
  emptyList: any = 'assets/images/no-cart.png';
  // randomMessage: string = '';
   options: AnimationOptions = {
      path: './assets/images/loader.json' // Replace with your JSON animation file URL
    };
    
    selectedPaymentStatus: string = '';

    filters: Filters = {
      jobCardStatus: [
        { label: 'Open', value: 1, checked: false },
        { label: 'In Progress', value: 2, checked: false },
        { label: 'Closed', value: 3, checked: false }
      ],
      jobTime: [
        { label: 'Today', value: 'TODAY', checked: false },
        { label: 'Yesterday', value: 'YESTERDAY', checked: false },
        { label: 'Last 7 days', value: 'LAST_7_DAYS', checked: false },
        { label: 'Last 30 days', value: 'LAST_30_DAYS', checked: false },
        { label: 'This month', value: 'THIS_MONTH', checked: false },
        { label: 'Last Month', value: 'LAST_MONTH', checked: false },
        { label: 'Any Time', value: 'ANY_TIME', checked: false }
      ],
      paymentStatus: [
        { label: 'Paid', value: 1, checked: false },
        { label: 'Partially Paid', value: 2, checked: false }
      ],
      status: []
    };
    
    selectedJobCardStatus: any = '';
    selectedJobCardTime: string = '';
  isMobile: boolean;
  isFilterSidebarOpen: boolean;
  originalCustomerPayments: any[];
  filteredPaymentListData: any;
  paymentListData: any;
    
  constructor(
     private aspCustomerPaymentsListService: AspCustomerPaymentsListService,
     private router: Router,
     private resolver: ComponentFactoryResolver,
     private aspRecordPaymentService: AspRecordPaymentService,
     private aspLoadingService: AspLoadingService,
     private cdr: ChangeDetectorRef,
     private aspSparesListService: AspSparesListService
  ) { }

  ngOnInit(): void {
    this.fetchInvoices();
    this.getLoadingMsg();
    this.successIcon = '../../../../assets/icons/icons8-ok.gif';
    
  }
   ngAfterViewInit() {
      this.loadingMsg();
      this.cdr.detectChanges();
    }
    
    loadingMsg() {
      
      const factory = this.resolver.resolveComponentFactory(AspLoadingComponent);
      
     
    
      if (this.containers) {
        const componentRef = this.containers.createComponent(factory);
        
    
        const componentBInstance = componentRef.instance as AspLoadingComponent;
        componentBInstance.getLoadingMsg();
      } else {
        console.error('containers is undefined');
      }
    }
  @ViewChild('dynamicContainer', { read: ViewContainerRef }) recordpaymentcontainer!: ViewContainerRef;
 
  fetchInvoices(): void {
    this.aspLoadingService.show();
    this.loading = true;
    this.aspCustomerPaymentsListService.getAllInvoices().subscribe(
      (res: any) => {
        this.aspLoadingService.hide();
        if (res?.data?.content?.length > 0) {
          this.loading = false;
          this.paymentListData = res.data.content;
          this.customerPayments = res.data.content.sort((a, b) => b.paymentId - a.paymentId);
          this.originalCustomerPayments = [...this.customerPayments]; // Store original data
          if (res?.data?.content) {
            res?.data?.content?.sort((a: JobCard, b: JobCard) => {
              return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
            });
            this.filteredPaymentListData = res?.data?.content;

          }
        } 
        
        else {
          console.error('No invoice data found in response');
          this.customerPayments = [];
        }
      },
      (error) => {
        console.error('Error fetching invoices:', error);
      }
    );
  }
  
  
  
  redirectToReceipt() {
    this.isReceiptSidebarOpen = true; // Opens the sidebar
    this.router.navigate(['/view-receipt']); // Redirects to the view-receipt page
  }

openRecordPaymentSidebar(paymentId,jobcardId) {
  // Fetch receipt details before opening the sidebar
  // this.aspRecordPaymentService.getReceiptDetail(paymentId,this.customerPayments.jobCardId).subscribe(
  //   (res: any) => {
  //     if (!res?.data) {
  //       console.error('No data found in API response');
  //       return;
  //     }

  //     // Extract required details
  //     const invoiceId = res.data.receiptNumber || 'N/A'; // Default to 'N/A' if not found
  //     const amount = res.data.amount || 0;
  //     const name = res.data.name || 'Unknown';
  //     const phoneNumber = res.data.phoneNumber || 'N/A';
  //     const email = res.data.email || 'N/A';

  //     // Determine status
  //     let status = 'Unknown Status';
  //     if (res.data.status == 1) {
  //       status = 'Paid';
  //     } else if (res.data.status == 2) {
  //       status = 'Partially Paid';
  //     }

      // Open the sidebar and pass the values
      const factory = this.resolver.resolveComponentFactory(AspRecordPaymentComponent);
      const componentRef = this.recordpaymentcontainer.createComponent(factory);
      const componentInstance = componentRef.instance as AspRecordPaymentComponent;

      componentInstance.isReceiptSidebarOpen = true;
      componentInstance.openReceiptSidebar();
      componentInstance.jobcardId = jobcardId;
      componentInstance.paymentpopupId = paymentId;
      
      }
      getLoadingMsg() {
        this.aspSparesListService.getLoadingMsg().subscribe(
          result => {
            let res = JSON.parse(JSON.stringify(result));
            
            // Ensure the response contains an array
            if (res.data && Array.isArray(res.data.appLoadingMessages)) {
              this.Messages = res.data.appLoadingMessages;
            } else {
              this.Messages = []; // Default to empty array to avoid errors
            }

            // Call getRandomMessage only if messages exist
            this.getRandomMessage();
            this.cdr.detectChanges();
          },
          error => {
            console.error('Error fetching loading messages', error);
            this.Messages = []; // Default to empty array in case of error
          }
        );
      }

    getRandomMessage() {
      if (this.Messages && this.Messages.length > 0) {
        const randomIndex = Math.floor(Math.random() * this.Messages.length);
        // this.randomMessage = this.Messages[randomIndex];
      } else {
        // console.error('No messages available to pick from');
      }
    }

    animationCreated(animationItem: any): void {
      // Animation is created and can be interacted with here
      }

// End loading


clearSearch() {
  this.searchText = '';
  this.fetchPayments(); 
   this.cdr.detectChanges();
  console.log("Search cleared");
  this.fetchInvoices();
}

@HostListener('window:resize', ['$event'])
onResize(event: any) {
  this.checkMobileView();
}

checkMobileView() {
  this.isMobile = window.innerWidth <= 768; // Adjust as needed
  this.cdr.detectChanges(); // Force Angular to update the view
}
toggleFilterSidebar() {
  this.isFilterSidebarOpen = !this.isFilterSidebarOpen;
}
// -----------------------------SEARCH--------------------------
onSearchInput(): void {
  console.log("User is typing:", this.searchText);
  this.searchText = this.searchText.trim();
}

filterPaymentList() {
  const searchValue = this.searchText?.trim().toLowerCase() || '';

  this.filteredPaymentListData = this.paymentListData.filter(list => {
    const statusMatch = this.selectedJobCardStatus ? list.paymentStatus === this.selectedJobCardStatus : true;
    const timeMatch = this.selectedJobCardTime ? this.filterByTime(list.createdDate, this.selectedJobCardTime) : true;


    const searchMatch = searchValue ? 
      (
        list.paymentId?.toString().toLowerCase().includes(searchValue) || 
        list.invoiceId?.toString().toLowerCase().includes(searchValue) ||
        list.jobCardId?.toString().toLowerCase().includes(searchValue) ||
        list.registrationNumber?.toString().toLowerCase().includes(searchValue) ||
        list.make?.toString().toLowerCase().includes(searchValue) ||
        list.model?.toString().toLowerCase().includes(searchValue) ||
        list.paymentStatus?.toString().toLowerCase().includes(searchValue) ||
        list.amountPaid?.toString().toLowerCase().includes(searchValue) ||
        list.amountDue?.toString().toLowerCase().includes(searchValue)
      ) 
      : true;

    return statusMatch  && timeMatch && searchMatch;
  });

  console.log("Filtered Data:", this.filteredPaymentListData);
}
filterByTime(createdDate: string, filter: string): boolean {
  const now = new Date();
  const jobDate = new Date(createdDate);

  switch (filter) {
    case 'TODAY':
      // Check if the jobDate is the same day as today
      return jobDate.toDateString() === now.toDateString();
      
    case 'YESTERDAY':
      // Check if the jobDate is the same day as yesterday
      const yesterday = new Date(now);
      yesterday.setDate(now.getDate() - 1);
      return jobDate.toDateString() === yesterday.toDateString();
      
    case 'LAST_7_DAYS':
      // Check if the jobDate is within the last 7 days
      const weekAgo = new Date(now);
      weekAgo.setDate(now.getDate() - 7);
      return jobDate >= weekAgo && jobDate <= now;
      
    case 'LAST_30_DAYS':
      // Check if the jobDate is within the last 30 days
      const monthAgo = new Date(now);
      monthAgo.setDate(now.getDate() - 30);
      return jobDate >= monthAgo && jobDate <= now;
      
    case 'LAST_MONTH':
      // Check if the jobDate is within the last month
      const lastMonthStart = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const lastMonthEnd = new Date(now.getFullYear(), now.getMonth(), 0);
      return jobDate >= lastMonthStart && jobDate <= lastMonthEnd;
      
    case 'ANY_TIME':
    default:
      return true;
  }
}
toggleSelection(filterType: keyof Filters, option: FilterOption) {
  if (filterType === 'status') {
      // Update request status filters
      this.filters.paymentStatus.forEach(status => {
        status.checked = (status.value === option.value);
      });
      this.selectedJobCardStatus = option.value;
  } 
  else if (filterType === 'jobTime') {
    // Update request time filters
    this.filters['jobTime'].forEach(time => {
        time.checked = (time.value === option.value);
        if (time.children) {
            time.children.forEach(child => {
                child.checked = (child.value === option.value);
            });
        }
    });
    // this.selectedJobCardTime = option.value;
  }

  // Save selected request status and request time to localStorage
  localStorage.setItem('selectedJobCardStatus', this.selectedJobCardStatus);
  localStorage.setItem('selectedJobCardTime', this.selectedJobCardTime);

  this.filterPaymentList();
}


clearFilters() {
  // Reset selected filter values
  this.selectedJobCardStatus = '';
  this.selectedJobCardTime = '';

  // Function to uncheck all options recursively
  const clearOptions = (options: FilterOption[]) => {
      options.forEach(option => {
          option.checked = false;
          if (option.children) {
              clearOptions(option.children);
          }
      });
  };

  // Clear all filter options in filters object
  (Object.keys(this.filters) as (keyof Filters)[]).forEach(jobTimeKey => {
      clearOptions(this.filters[jobTimeKey]);
  });

  // Clear filters in localStorage
  localStorage.removeItem('selectedJobCardStatus');
  localStorage.removeItem('selectedJobCardTime');
  this.searchText = '';

  // Call filterPaymentList to update the displayed data
  this.filterPaymentList();
}


hasAppliedFilters(): boolean {
  return this.selectedJobCardStatus !== '' || this.selectedJobCardTime !== '';
}


// getAppliedFilters(): { status: string; option: { label: string; value: number; checked: boolean } }[] {
// const appliedFilters: { status: string; option: { label: string; value: number; checked: boolean } }[] = [];

// const addFilter = (option: { label: string; value: number; checked: boolean }) => {
//   if (option.checked) {
//     appliedFilters.push({ status: 'status', option });
//   }
// };

// this.filters.paymentStatus.forEach(option => {
//   addFilter(option);
// });

getAppliedFilters(): { jobTime: keyof Filters, option: FilterOption }[] {
  const appliedFilters: { jobTime: keyof Filters, option: FilterOption }[] = [];

  const addFilter = (jobTimeKey: keyof Filters, option: FilterOption) => {
    if (option.checked) {
      appliedFilters.push({ jobTime: jobTimeKey, option });
    }
  };

  (Object.keys(this.filters) as (keyof Filters)[]).forEach(jobTimeKey => {
    this.filters[jobTimeKey].forEach(option => {
      addFilter(jobTimeKey, option);
      if (option.children) {
        option.children.forEach(child => {
          addFilter(jobTimeKey, child);
        });
      }
    });
  });


return appliedFilters;
}

removeFilter(jobTimeKey: keyof Filters, option: FilterOption) {
  // this.selectedJobCardStatus = '';
  option.checked = false;
  if (jobTimeKey === 'paymentStatus') {
    this.selectedPaymentStatus = '';
  } else if (jobTimeKey === 'jobTime') {
    this.selectedJobCardTime = '';
  }
  this.filterPaymentList();
}
// End Filters
fetchPayments() {
  this.aspCustomerPaymentsListService.getAllInvoices().subscribe(
    (data: any) => {
      this.customerPayments = Array.isArray(data) ? data : [];
    },
    (error) => {
      console.error("Error fetching payments:", error);
      this.customerPayments = [];
    }
  );
}

onEnterPress(event: any): void {
  this.filterPaymentList();

}
}
