import { Component, OnInit } from '@angular/core';
import { AspVehicleServiceService } from './asp-vehicle-service.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-asp-vehicle-service-history',
  templateUrl: './asp-vehicle-service-history.component.html',
  styleUrls: ['./asp-vehicle-service-history.component.scss']
})
export class AspVehicleServiceHistoryComponent implements OnInit {
  vehicleDetails: any;
  AspVehicleServiceService: any;
  registrationNumber: string;
  vehicleHistoryData: any[] = [];
  isSidebarOpen = true;
  
  constructor(
    private aspVehicleServiceService: AspVehicleServiceService, 
    private router: Router,
  ) { }
  

  ngOnInit(): void {
    this.loadVehicleDetails();
    this.fetchVehicleHistory();
  }

  openSidebar(isOpen: boolean): void {
    console.log('Sidebar open status:', isOpen);
    this.isSidebarOpen = isOpen;
    if (isOpen) {
      this.loadVehicleDetails(); 
    }
  }
  closeSidebar(): void{
    this.openSidebar(false);
  }
  loadVehicleDetails(): void {
    this.vehicleDetails = {
      make: localStorage.getItem('selectedMake'),
      model: localStorage.getItem('selectedModel'),
      year: localStorage.getItem('selectedYear'),
      fuelType: localStorage.getItem('fuelType'),
      vin: localStorage.getItem('vin'),
      variant: localStorage.getItem('variant'),
      registrationNumber: localStorage.getItem('registrationNumber')
    };
  }
  getMakeImageUrl(make: string): string {
    const makeWithoutSpaces = make.toLowerCase().replace(/\s+/g, '');
    return `https://cube.getpitstop.com/assets/img/${makeWithoutSpaces}.png`;
  }
  fetchVehicleHistory(): void {
    const garageKey = localStorage.getItem('garageKey');
    const registrationNumber = localStorage.getItem('registrationNumber');
    if (garageKey && registrationNumber) {
      this.aspVehicleServiceService.getVehicleHistoryList(registrationNumber, garageKey).subscribe(
        (response) => {
          if (response && response.data) {
            this.vehicleHistoryData = response.data; 
            console.log('Vehicle History Response:', response);

             // Convert 'createdAt' from GST (UTC+4) to IST (UTC+5:30)
          this.vehicleHistoryData = response.data.map((data) => {
            let createdAt = new Date(data.createdAt);
            createdAt.setHours(createdAt.getHours() + 5);  
            createdAt.setMinutes(createdAt.getMinutes() + 30); 

            return {
              ...data,
              createdAt: createdAt.toISOString() // Store converted IST time
            };
          });

          // Sort by IST timestamp
          this.vehicleHistoryData.sort((a, b) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
          });
        }
        },
        (error) => {
          console.error('Error fetching vehicle history:', error);
        }
      );
    } else {
      console.error('Garage Key or Registration Number is missing.');
    }
  }
  redirectToJobCardDetails(jobCardId: string, bookingId: string) {
    this.router.navigate([`my-job-cards-details/${jobCardId}/${bookingId}`]).then(() => {
    window.location.reload();
   });
  }
  logAndRedirect(jobCardId: string, bookingId: string) {
    console.log('Redirecting with:', jobCardId, bookingId);
    this.redirectToJobCardDetails(jobCardId, bookingId);
    this.isSidebarOpen=false;
  }
  
}
