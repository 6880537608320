import { NgModule } from '@angular/core';
import { AspMakeModule } from './asp-make/asp-make.module';
import { AspModelModule } from './asp-model/asp-model.module';
import { AspYearModule } from './asp-year/asp-year.module';
import { AspVariantModule } from './asp-variant/asp-variant.module';
import { AspSparesListModule } from './asp-spares-list/asp-spares-list.module';
import { AspReplaceCartPopupComponent } from './asp-replace-cart-popup/asp-replace-cart-popup.component';
import { AspCartModule } from './asp-cart/asp-cart.module';
import { AspPaymentModule } from './asp-payment/asp-payment.module';
import { AspConfirmOrderPopupComponent } from './asp-confirm-order-popup/asp-confirm-order-popup.component';
import { AspOrderListModule } from './asp-order-list/asp-order-list.module';
import { AspLoginModule } from './asp-login/asp-login.module';
import { AspSwitchGarageModule } from './asp-switch-garage/asp-switch-garage.module';
import { AspShippingChargesPopupComponent } from './asp-shipping-charges-popup/asp-shipping-charges-popup.component';
import { AspOrderSummaryModule } from './asp-order-summary/asp-order-summary.module';
import { AspCouponDiscountPopupComponent } from './asp-coupon-discount-popup/asp-coupon-discount-popup.component';
import { AspHomeModule } from './asp-home/asp-home.module';
import { AspBrandsModule } from './asp-brands/asp-brands.module';
import { AspOrderDetailsComponent } from './asp-order-details/asp-order-details.component';
import { CommonModule } from '@angular/common';
import { AspProductDetailsModule } from './asp-product-details/asp-product-details.module';
import { AspMyRequestModule} from './asp-my-request/asp-my-request.module';
import { AspRequestDetailsModule} from './asp-request-details/asp-request-details.module';
import { AspPrductCompatibilityPopupComponent } from './asp-prduct-compatibility-popup/asp-prduct-compatibility-popup.component';
import {MatTabsModule} from '@angular/material/tabs';
import { AspGarageSidebarComponent } from './asp-garage-sidebar/asp-garage-sidebar.component';
import { FormsModule } from '@angular/forms';
import { AspPlannedInventoryPopupComponent } from './asp-planned-inventory-popup/asp-planned-inventory-popup.component';
import { AspCustomerQuoteComponent } from './asp-customer-quote/asp-customer-quote.component';
import { AspPrductBottomsheetComponent } from './asp-prduct-bottomsheet/asp-prduct-bottomsheet.component';
import { AspMyReportsModule } from './asp-my-reports/asp-my-reports.module';
import { AspQuotationListModule } from './asp-quotation-list/asp-quotation-list.module';
import { AspMyBookingsModule} from './asp-my-bookings/asp-my-bookings.module';
import{AspMyJobCardsModule} from './asp-my-job-cards/asp-my-job-cards.module';
import{MyJobCardsDetailsModule} from './my-job-cards-details/my-job-cards-details.module';
// import { AspPaymentComponent } from './asp-payment/asp-payment.component';
import { AspPaymentlistModule } from './asp-paymentlist/asp-paymentlist.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list'; 
import { MatDividerModule } from '@angular/material/divider';
import { AspDynamicPopupComponent } from './asp-dynamic-popup/asp-dynamic-popup.component';
import { SideBarJobcardComponent } from './side-bar-jobcard/side-bar-jobcard.component';
// import { AspLoadingComponent } from './asp-loading/asp-loading.component';

// import { LottieModule } from 'ngx-lottie';
// import player from 'lottie-web';
import { AspSearchComponent } from './asp-search/asp-search.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AspMyItemListModule } from './asp-my-item-list/asp-my-item-list.module';
import { AspJoinAutoverseModule } from './asp-join-autoverse/asp-join-autoverse.module'
import { AspViewDocumentModule } from './asp-view-document/asp-view-document.module';
import { AspMyDocumentsModule } from './asp-my-documents/asp-my-documents.module';
// import { AspMyDocumentsModule } from './asp-my-documents/asp-my-documents.module'
import { AspMyEmployeeModule } from './asp-my-employee/asp-my-employee.module';
import { AspCheckinReportsModule } from './asp-checkin-reports/asp-checkin-reports.module'
// import { AspSpareKitListModule } from './asp-spare-kit-list/asp-spare-kit-list.module';
// import { AspSpareKitDetailsModule } from './asp-spare-kit-details/asp-spare-kit-details.module';
// import { SpareKitSidebarComponent } from './spare-kit-sidebar/spare-kit-sidebar.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { AspVehicleServiceHistoryComponent } from './asp-vehicle-service-history/asp-vehicle-service-history.component';
// import { AspQuotationDetailsModule } from '../customer/asp-quotation-details/asp-quotation-details.module';
import { AspAddItemSidebarComponent } from 'app/main/auto-spare-parts/asp-add-item-sidebar/asp-add-item-sidebar.component';
import { AspFeedbackModule } from '../customer/asp-feedback/asp-feedback.module';
import { AspJobcardQuotationComponent } from './asp-jobcard-quotation/asp-jobcard-quotation.component';
import { AspInvoiceDetailsModule } from './asp-invoice-details/asp-invoice-details.module';
import { AspRecordPaymentComponent } from './asp-record-payment/asp-record-payment.component';
import { MatSelectModule } from '@angular/material/select';
import { AspCustomerPaymentsListModule } from 'app/asp-customer-payments-list/asp-customer-payments-list.module';
import { AspInvoiceListModule } from './asp-invoice-list/asp-invoice-list.module'

// export function playerFactory() {
//   return player;
// }
@NgModule({
  imports: [
    ReactiveFormsModule,
    AspMakeModule,
    AspModelModule,
    AspYearModule,
    AspVariantModule,
    AspSparesListModule,
    AspCartModule,
    AspPaymentModule,
    AspOrderListModule,
    AspLoginModule,
    AspSwitchGarageModule,
    AspOrderSummaryModule,
    AspHomeModule,
    AspBrandsModule,
    CommonModule,
    AspProductDetailsModule,
    AspMyRequestModule,
    MatTabsModule,
    AspRequestDetailsModule,
    FormsModule,
    AspMyReportsModule,
    AspQuotationListModule,
    AspMyBookingsModule,
    AspMyJobCardsModule,
    MyJobCardsDetailsModule,
    AspPaymentlistModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    AspMyDocumentsModule,
    AspViewDocumentModule,
    MatDividerModule,
    CommonModule,
    MatTooltipModule,
    AspMyItemListModule,
    AspJoinAutoverseModule,
    AspMyEmployeeModule,
    // AspQuotationDetailsModule,
    AspFeedbackModule,

    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    AspInvoiceDetailsModule,
    MatSelectModule,
    AspCustomerPaymentsListModule,
    AspInvoiceListModule,
    
    AspCheckinReportsModule,
    // LottieModule.forRoot({ player: playerFactory }) 

  ],
  declarations: [AspReplaceCartPopupComponent, AspConfirmOrderPopupComponent, AspShippingChargesPopupComponent, AspCouponDiscountPopupComponent, AspOrderDetailsComponent, AspPrductCompatibilityPopupComponent, AspGarageSidebarComponent, AspPlannedInventoryPopupComponent, AspCustomerQuoteComponent, AspPrductBottomsheetComponent, AspDynamicPopupComponent, SideBarJobcardComponent,AspSearchComponent,AspVehicleServiceHistoryComponent,AspAddItemSidebarComponent,AspJobcardQuotationComponent,AspRecordPaymentComponent],

})
export class AutoSparePartsModule { }
