import { ChangeDetectorRef, Component, ComponentFactoryResolver, Input, NgZone, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { FuseConfigService } from "@fuse/services/config.service";
import { AspRequestDetailsService } from "../asp-request-details/asp-request-details.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { SideBarJobcardService } from "../side-bar-jobcard/side-bar-jobcard.service";
import { MyJobCardsDetailsService } from "../my-job-cards-details/my-job-cards-details.service";
import { S3ImageUploadService } from "app/shared/s3-image-upload.service";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { AspCustomerQuoteComponent } from "../asp-customer-quote/asp-customer-quote.component";
import { AspOrderDetailsComponent } from "../asp-order-details/asp-order-details.component";
import { AspCheckinReportsComponent } from "../asp-checkin-reports/asp-checkin-reports.component";
import {AspCheckinReportsService} from '../asp-checkin-reports/asp-checkin-reports.service'
import { AspRequestDetailsPopUpComponent } from "../asp-request-details-pop-up/asp-request-details-pop-up.component";
import { Location } from '@angular/common';
import { AspCustomerQuoteService } from "../asp-customer-quote/asp-customer-quote.service";
import { AspQuotationDetailsService } from "app/main/customer/asp-quotation-details/asp-quotation-details.service";
import { AspInvoiceDetailsComponent } from "../asp-invoice-details/asp-invoice-details.component";
import { MyJobCardsDetailsComponent } from "../my-job-cards-details/my-job-cards-details.component";
import { AspLoadingService } from "../asp-loading/asp-loading.service";
import{AspAddItemSidebarComponent} from 'app/main/auto-spare-parts/asp-add-item-sidebar/asp-add-item-sidebar.component';
interface FilterOption {
  label: string;
  value: string;
  checked: boolean;
  children?: FilterOption[];
}

interface Filters {
  jobCardStatus: FilterOption[];
}
@Component({
  selector: 'app-asp-jobcard-quotation',
  templateUrl: './asp-jobcard-quotation.component.html',
  styleUrls: ['./asp-jobcard-quotation.component.scss']
})
export class AspJobcardQuotationComponent implements OnInit {
    activeTab: string = 'requests'; 
    activeTabs: string = 'Information'; 
    selectAll: boolean = false; 
    items = [];
    items2 = [];
    sidebarOpen: boolean = false; 
    requestDetails: any; 
    spareRequestItemList: any; 
    quotationreceived: any[] = []; 
    quotationapproved: any[] = []; 
    quoteApproved: boolean = false; 
    id: any;
    itemDetails: any;
    quoteReceivedPrice: number=0;
    quoteApprovedPrice: number=0;
    selectedItemsCount: any;
    totalSelectedPrice: number = 0;
    bitModule: number;
    bidItems: any;
    quoteReceived: boolean;
    totalMRP: number = 0;
    Quotereceived: any;
    sidebarOpenQuote: boolean = false;
    
    
    
    customerQuotation: any[]=[];
    customerQuotationPrice: number=0;
    totalSelectedPriceQuote: number=0;
    selectedItemsCountQuote: number=0;
    selectAllQuote:boolean = false; 
    isPopupOpen = false;
    successIcon: any = '../../../../assets/icons/icons8-ok.gif';
    itemQuote: any;
    isPopupOpenEdit: boolean;
    selectedItems: any[];
    loading: boolean = false;
    orderList: any[] = [];
    filteredOrderList: any[]=[];
    orderDeliveryTimeFormatted: string;
    item: any;
    resData: any;
    quoteShipping: any;
    regno: any;
    @Input() quoteId: string | null = null;  
    @Input() requestId: number | null = null;  
    @Input() jobCard: number | null = null;  
    itemDetail: any;
    selectedItemsQuote: any[];
    dataid: any;
    isMobile: boolean;
    orders: any;
    isSidebarOpen = false;
    searchText: string = '';
    searchQuery: string = '';
    filteredItems: any[] = [];
    isAddLabour = false;
    filteredItems1: any[] = [];
    isEditLabourSidebarOpen=false;
    selectedLabour: any = { name: '', price: '' };
    formSubmitted: boolean = false;
    showCancelConfirmation: boolean = false;
    showCancelSuccess: boolean = false;
    labourselectedItems = 0;
    totallabourSelectedPrice :number=0;
    showSkipConfirmation = false;
    laborDetails: FormGroup;
    labourItemsCount: number = 0;
    searchWord: string = '';
    labourListData: any;
    // labourName: any;
    labourAmount: any;
    labourId: any;
    labourName: any;
    totalSelectedPriceQuote1: number=0;
    partNumber: any;
    selectedItemsLabourQuote: any;
    unitPrice: any;
    selectedLabourCount:any;
    sparePartsTotal: any;
    selectedLabours: any;
    
    @ViewChild('myDynamicSec1', { read: ViewContainerRef, static: true }) container11: ViewContainerRef;
    @ViewChild('aspAddItemSidebar') aspAddItemSidebar!: AspAddItemSidebarComponent;
    
    checkinComponentRef: any;
    activeUrl: string;
    isRequestDetails: boolean;
    isJobCardDetails: boolean;
    data: any;
    accessoriesYes: any;
    isDropdownOpen = false;
    serviceList: any;
    accessories: any;
    filteredIssues: any;
    car =  {
      regNumber: '',
      vin: localStorage.getItem('vin') || '',
      engineNumber: '',
      odometerReading: '',
      lastServiceDate: '',
      selectedServices: [] as string[],
    }
    fuelAndAccessories: {
      fuelLevel: number;
      checkedItems: string[];
    } = {
      fuelLevel: 0, // Default fuel level
      checkedItems: [] // Initially no items selected
    }
    isSearchOpen = false;
    issuesData: any;
    selectedIssues: string[] = [];
    images: any;
    request: any;
    formData: { engineNumber: any; odometer: any; lastServiceDate: any; customerName: any; customerMobile: any; customerEmail: any; };
    damageImages: [];
    variantKey: any;
    imagesS3: any;
    QuoteId: any;
    registrationNumber: any;
    TagImages: { [key: string]: string } = {
      front: '',
      engine: '',
      battery: '',
      right: '',
      back: '',
      left: '',
      'fuel-level':'',
    };
    newImages: { tag: string; url: string }[] = [];
    isViewerOpen = false;
  currentIndex = 0;
  currentTag: string = '';
  tags: string[] = ['front', 'engine', 'battery', 'right', 'back', 'left','fuel-level'];
  frontImage: string | null = null;
  engineImage: string | null = null;
  batteryImage: string | null = null;
  rightImage: string | null = null;
  backImage: string | null = null;
  leftImage: string | null = null;
  additionalImages: { tag: string; url: string }[] = [];
  
  newTag = '';
  isUploading = false;
  selectedFiles: { file: File, tag: string }[] = [];
  statusList = [
    { label: 'Quote Due', value: '1', checked: false, statusclass: 'quote-due'},
    { label: 'Approval Due', value: '2', checked: false, statusclass: 'approval-due'},
    { label: 'In Progress', value: '3', checked: false, statusclass: 'inprogress'},
    { label: 'Ready', value: '4', checked: false, statusclass: 'ready'},
    { label: 'Invoice', value: '5', checked: false, statusclass: 'invoice'},
  ];
  filters: Filters = {
    jobCardStatus: this.statusList,
  }
    selectedLaboursCount: any;
    quoteItemStatus: any;
    selectedApprovedItemsCount: number;
    totalApprovedSelectedPrice: any;
    totalSelectedApprovedPrice: any;
    customerquoteid: any;
    selectedLabourslength: any;
    customerQuotationCount: number;
  jobCardId: number;
  jobcardId: any;
  bookingId: any;
  sparePartsTotalcreation: any;
  customerQuotationPricecreation: any;
  totalSelectedPriceQuoteCreation: any;
  customerQuotation1: any;
  itemsLength: any;
  selectedLabourlengthItems: any;
  recievedLengthItems: any;
  type: any;
  jobcardId1: any;
  bookingId1: any;
  selectedItemsCountQuote1: any;
  tagError: boolean;
  invoice: any;
  editLabour: any;
  selectedItemsLabourQuoteLength: any;
  totallabourListSelectedPrice: any;
  customerQuotationListCount: number;
  labourQuotationPrice: number = 0;

  quoteGrandTotal: number = 0;

  newItem: boolean;
  newItems: boolean;
  itemForm: FormGroup;
  gstOptions: number[] = [5, 12, 18, 28]; 
  originalData: any;
  fullLabourList: any;
  itemQuotesEdit: any;
  labourCount: any;
  labourCountNewList: any;
  totallabourSelectedNewPrice: any;
  selectedLaboursNewCount: any;
  totalSelectedPriceQuote123: any;
  fuelImage: string | null = null;;
  

  
    constructor(
      private _fuseConfigService: FuseConfigService,
      public dialog: MatDialog,
      private aspRequestDetailsService: AspRequestDetailsService,
       private aspQuotationDetailsService: AspQuotationDetailsService,
      private zone: NgZone,
      private route: ActivatedRoute,
      private resolver: ComponentFactoryResolver,
      private location: Location,
      private toastr: ToastrService,
      private _formBuilder: FormBuilder,
      private breakpointObserver: BreakpointObserver,
      private viewContainerRef: ViewContainerRef,
      private componentFactoryResolver: ComponentFactoryResolver,
      private router: Router,
      private AspCheckinReportsService:AspCheckinReportsService,
      private sideBarJobcardService:SideBarJobcardService,
      private myJobCardsDetailsService:MyJobCardsDetailsService,
      private s3ImageUploadService: S3ImageUploadService,
      private http: HttpClient,
      private aspCustomerQuoteService:AspCustomerQuoteService,
      private cdr: ChangeDetectorRef,
      private aspLoadingService: AspLoadingService,
    ) {
        this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      }); 
  this.itemForm = new FormGroup({
    itemName: new FormControl('', Validators.required),
    partNumber: new FormControl('', Validators.required),
    brand: new FormControl('', Validators.required),
    hsn: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{4,8}$')]), // HSN validation (4-8 digits)
    gst: new FormControl('', Validators.required), // Dropdown selection required
    unitPrice: new FormControl('', [Validators.required, Validators.min(0)]) // Unit price must be > 0
  });
}
  
  
    ngOnInit(): void {
      localStorage.removeItem('invoiceOpen')
      
      if (this.activeTabs === 'quotation') {
        this.activeTab = 'quote-received';
      }
      this.activeUrl = this.router.url;
      this.isRequestDetails = this.activeUrl.includes('/request-details');
      this.isJobCardDetails = this.activeUrl.includes('/my-job-cards-details');
      if(this.requestId && this.requestId!==null){
        this.id = this.requestId;
      }else{ 
        this.route.queryParams.subscribe(params => {
       
        // this.id = params.get('id');
          this.id = params['id'];        
          this.regno = params['regNo'];   
          if (params['activeTabs']) {
            this.activeTabs = params['activeTabs']; // Set the active tab
            if (this.activeTabs=='quotation'){
              this.setActiveTabs('quotation',this.type, this.jobcardId,this.bookingId,this.quoteId);
            }
          }   
        });
      }
  
      this.filteredItems1 = this.items2;
      this.fetchJobCardDetails();
      this.laborDetails = this._formBuilder.group({
        labourName: ['', [Validators.required, Validators.minLength(2)]], 
        labourAmount: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
      });
      this.unitPrice=this._formBuilder.group({
        unitprice:['',[Validators.required, Validators.pattern(/^[0-9]{10}$/)]]
      });
      if( this.isJobCardDetails) {
        this.checkInDetails();
        this.fetchServices();
        this.fetchJobCardDetails();
      }  
      if (this.data && this.data.serviceType && this.data.serviceType.length > 0) {
        this.data.serviceType.forEach(service => {
          // Find the checkbox in service list and set it checked
          const checkbox = this.serviceList.find(item => item === service);
          if (checkbox) {
            this.car.selectedServices.push(checkbox);
          }
        });
      }
      if (this.data && this.accessoriesYes && this.accessoriesYes.length > 0) {
        this.accessoriesYes.forEach(accessory => {
          // Find the checkbox in service list and set it checked
          const checkbox = this.accessories.find(item => item === accessory);
          if (checkbox) {
            this.fuelAndAccessories.checkedItems.push(checkbox);
          }
        });
      }
      if (this.data && this.data.issues && this.data.issues.length > 0) {
        this.data.issues.forEach(issue => {
          // Find the checkbox in service list and set it checked
          const checkbox = this.issuesData.find(item => item === issue);
          if (checkbox) {
            this.selectedIssues.push(checkbox);
          }
        });
      }
      let activeTabData = JSON.parse(localStorage.getItem('activeTab'));
        if (activeTabData.name == 'quotation' && activeTabData.type=='create')
        {
          this.activeTabs = 'quotation';
          localStorage.removeItem('activeTab');
        }
        
    }
  get up() {
    return this.unitPrice.controls;
  }
    
    get ld() {
      return this.laborDetails.controls;
    }
    @ViewChild('dynamicContainer1', { read: ViewContainerRef }) container1!: ViewContainerRef;
    @ViewChild('invoiceContainer', { read: ViewContainerRef }) containerInvoice!: ViewContainerRef; 
    @ViewChild('dynamicContainer', { read: ViewContainerRef }) container!: ViewContainerRef;  
    @ViewChild('invoiceTab', { read: ViewContainerRef }) createInvoice!: ViewContainerRef;
    loadorderQuote(id) {
      const factory = this.resolver.resolveComponentFactory(AspCustomerQuoteComponent);
      const componentRef = this.container1.createComponent(factory);
      const componentBInstance = componentRef.instance as AspCustomerQuoteComponent;
      componentBInstance.closeSidebar(true);
      componentBInstance.resId(id)
      this.isPopupOpen=false;
      this.ngOnInit();
      this.closePopup();
  }
//   labourList() {
//     const garageKey = localStorage.getItem('garageKey');
//     console.log("garagekey", garageKey);
//     console.log("savedItems---======------",this.selectedItemsLabourQuote);

//     if (garageKey) {
//         this.aspRequestDetailsService.displayLabourList(garageKey).subscribe(
//             (result) => {
//                 const res = JSON.parse(JSON.stringify(result));
//                 if (res.code === 200) {
//                     console.log("labourres", res);

//                     if (!Array.isArray(this.selectedItemsLabourQuote)) {
//                         this.selectedItemsLabourQuote = [];
//                     }

//                     this.fullLabourList = res.data.map((labour: any) => ({
//                         ...labour,
//                         selected: this.selectedItemsLabourQuote.some(
//                             (selectedItem: any) =>
//                                 // selectedItem.labourId === labour.labourId &&
//                                 selectedItem.labourName === labour.labourName 
//                                 // selectedItem.labourAmount === labour.labourAmount
//                         ),
//                     }));

//                     this.labourListData = [...this.fullLabourList]; // Copy full list to labourListData
//                     console.log("Updated labourListData:", this.labourListData);

//                     this.updateSelectedLabourItems();
//                     this.updateSelectedItemsQuote();
//                 }
//             },
//             (error) => {
//                 console.error('Error fetching labour list:', error);
//             }
//         );
//     } else {
//         console.warn('Garage key not found in localStorage.');
//     }
// }

  
labourList() {
  const garageKey = localStorage.getItem('garageKey');
  console.log("garagekey", garageKey);
  console.log("savedItems---======------", this.selectedItemsLabourQuote);

  if (garageKey) {
      this.aspRequestDetailsService.displayLabourList(garageKey).subscribe(
          (result) => {
              const res = JSON.parse(JSON.stringify(result));
              if (res.code === 200) {
                  console.log("labourres", res);

                  if (!Array.isArray(this.selectedItemsLabourQuote)) {
                      this.selectedItemsLabourQuote = [];
                  }

                  this.fullLabourList = res.data
                      .map((labour: any) => ({
                          ...labour,
                          selected: this.selectedItemsLabourQuote.some(
                              (selectedItem: any) =>
                                  selectedItem.labourId === labour.labourId
                          ),
                      }))
                      .sort((a, b) => Number(b.selected) - Number(a.selected)); // Sort selected items first

                  this.labourListData = [...this.fullLabourList]; // Copy full list to labourListData
                  console.log("Updated labourListData:", this.labourListData);

                  this.updateSelectedLabourItems();
                  this.updateSelectedItemsQuote();
              }
          },
          (error) => {
              console.error('Error fetching labour list:', error);
          }
      );
  } else {
      console.warn('Garage key not found in localStorage.');
  }
}

  
  
addLabour() {
  console.log(this.ld.labourAmount.value);
  const updatedLabourName = this.ld.labourName.value;
  const updatedLabourAmount = this.ld.labourAmount.value;
  const garageKey = localStorage.getItem('garageKey');

  this.aspRequestDetailsService.addLabourList(garageKey, updatedLabourName, updatedLabourAmount)
    .subscribe((result) => {
      const res = JSON.parse(JSON.stringify(result));
      
      if (res.code === 200) {
        console.log("addLabour--", res);
        
        // Create a new labour object but DO NOT add it to selectedItemsLabourQuote
        const newLabour = {
          labourId:res.data?.labourId,
          labourName: updatedLabourName,
          labourAmount: updatedLabourAmount,
          selected: true, // Set selected to false initially
          quantity: 1,
          id: res.data?.id
        };

        // Add the new labour item at the beginning of the labour list
        this.labourListData.unshift(newLabour);
        // this.labourCount=this.labourListData.filter(labour => labour.selected).length;
        console.log("this.labourcount----",this.labourCount);
        this.updateSelectedLabourItems();
        this.closeLabourEditSidebar();
        this.toastr.success("Labour Added Successfully");

        this.ngOnInit();
      }
    });
}


  
  onCheckboxChange(labour: any, event: Event): void {
    if (!labour) {
      console.error('Labour object is undefined');
      return;
    }
    labour.selected = (event.target as HTMLInputElement).checked;
    this.updateSelectedLabourItems();
    this.updateSelectedItemsQuote();
  }
  onLabourSelectionChange(labour: any): void {
    if (!labour) {
      console.error('Labour object is undefined');
      return;
    }
  
    // Ensure labour is selected
    labour.selected = labour.selected || false;
  
    // ✅ Set default quantity to 1 if not already set
    if (!labour.quantity || labour.quantity < 1 || labour.quantity == '') {
      labour.quantity = 1;
    }
  
    // Sync the selected items list
    this.selectedItemsLabourQuote = this.labourListData.filter(l => l.selected);
  
    // ✅ Calculate total selected price including quantity
    this.totallabourListSelectedPrice = this.selectedItemsLabourQuote.reduce(
      (sum, labour) => sum + (Number(labour.labourAmount) * labour.quantity || 0), 
      0
    );
  
    console.log(this.selectedItemsLabourQuote);
    console.log("this.selectedlabourprice----", this.totallabourListSelectedPrice);
  
    // ✅ Update the selected labour count
    this.selectedLabourCount = this.selectedItemsLabourQuote.length;
  
    // ✅ Calculate total price
    this.totalSelectedPriceQuote1 = this.selectedItemsLabourQuote.reduce(
      (sum, item) => sum + (Number(item.labourAmount) * item.quantity || 0), 
      0
    );
  
    // Update UI
    this.updateSelectedLabourItems();
    this.updateSelectedItemsQuote();
  }
  
  
  
  
  
  openSkipConfirmation(){
    this.showSkipConfirmation = true;
  }
  closeSkipConfirmation(): void {
    this.showSkipConfirmation = false;
  }
  confirmSkip(): void {
    this.showSkipConfirmation = false;
    this.isAddLabour = false;
    console.log('Skip confirmed!');
  }
  
  AddItems()
  {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.searchText = '';
    this.filteredItems = [];
  }
  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }
  
  onLabourSearch(): void {
    console.log('Search Triggered:', this.searchText);
    const query = this.searchText?.toLowerCase();

    if (query) {
        this.labourListData = this.fullLabourList.filter(item =>
            item.labourName?.toLowerCase().includes(query)
        );
    } else {
        // Reset to full list when search is cleared
        this.labourListData = [...this.fullLabourList];
    }

    console.log('Filtered Items:', this.labourListData);
}

  
clearLabourSearch() { 
  this.searchText = '';
  this.labourListData = [...this.fullLabourList]; // Restore full list
}

  
  clearSearch() {
    this.searchWord = '';
    this.labourList();
    // this.filteredItems = [];
  }
  addLabourSidebar(){
    this.isAddLabour = !this.isAddLabour; 
    this.labourList();
    
  }
  labourRedirectSidebar()
  {
    this.isAddLabour=false;
    this.selectedLabourCount=0;
    }
  toggleSelect() {
    this.selectAll = !this.selectAll;
  }
  
  openLabourEditSidebar(item: any = null) {
    this.isEditLabourSidebarOpen = true;
    // this.isAddLabour = item ? false : true;
    this.isSidebarOpen = false;
  // console.log("sdjfjsdfjisdf")
  console.log("item----",item);
    if (item) {
      this.selectedLabour = { ...item };
      this.labourName = this.selectedLabour.labourName;
      this.labourAmount = this.selectedLabour.labourAmount;
      this.labourId = this.selectedLabour.labourId;
      this.id = this.selectedLabour.id;
      console.log("Editing Labour:", this.selectedLabour);
    } else {
      this.selectedLabour = { name: '', price: null };
      console.log("Adding New Labour:", this.selectedLabour);
    }
  }
  
  
  closeLabourEditSidebar() {
    this.isEditLabourSidebarOpen = false;
      this.selectedLabour = { labourName: '', labourAmount: null };
    // this.isAddLabour=false;
    
  
  }
  // saveLabour(): void {
  //   console.log("Labour Name:", this.ld.labourName.value);
  //   console.log("Labour Amount:", this.ld.labourAmount.value);
  
  //   const updatedLabourName = this.ld.labourName.value;
  //   const updatedLabourAmount = this.ld.labourAmount.value;
  //   const garageKey = localStorage.getItem('garageKey');
  
  //   this.aspRequestDetailsService
  //     .editLabour(this.id, this.labourId, garageKey, updatedLabourName, updatedLabourAmount)
  //     .subscribe(
  //       (result) => {
  //         console.log('API Response:', result);
  //         const res = JSON.parse(JSON.stringify(result));
  //         if (res.code === 200) {
  //           console.log('Labour edited successfully:', res);
  //           this.toastr.success("Labour saved Successfully");
  
  //           // First, update the selectedItemsLabourQuote list
  //           this.selectedItemsLabourQuote = this.selectedItemsLabourQuote.map(item =>
  //             item.labourName === this.labourName
  //               ? { ...item, labourName: updatedLabourName, labourAmount: updatedLabourAmount ,selected:true}
  //               : item
  //           );
  
  //           console.log("Updated selectedItemsLabourQuote:", this.selectedItemsLabourQuote);
  
  //           // Now refresh the labour list
  //           this.labourList();


  //         } else {
  //           console.warn('Unexpected response code:', res.code);
  //         }
  //       },
  //       (error) => {
  //         console.error('Error editing labour:', error);
  //       }
  //     );
  
  //   this.closeLabourEditSidebar();
  // }
  
  saveLabour(): void {
    console.log("Labour Name:", this.ld.labourName.value);
    console.log("Labour Amount:", this.ld.labourAmount.value);
  
    const updatedLabourName = this.ld.labourName.value;
    const updatedLabourAmount = this.ld.labourAmount.value;
    const garageKey = localStorage.getItem('garageKey');
  console.log("before labourId-----",this.labourId);
    this.aspRequestDetailsService
        .editLabour(this.id, this.labourId, garageKey, updatedLabourName, updatedLabourAmount)
        .subscribe(
            (result) => {
                console.log('API Response:', result);
                const res = JSON.parse(JSON.stringify(result));
  
                if (res.code === 200) {
                    console.log('Labour edited successfully:', res);
                    this.toastr.success("Labour saved Successfully");
                console.log("this. this.labourId",this.labourId);
                    let labourExists = false;
                    this.selectedItemsLabourQuote = this.selectedItemsLabourQuote.map(item => {
                        if (item.labourId === this.labourId) {  // Ensure matching by correct ID
                            labourExists = true;
                            return {
                                ...item,
                                labourName: updatedLabourName,
                                labourId:this.labourId,
                                labourAmount: updatedLabourAmount,
                                selected: true 
                            };
                        }
                        return item;
                    });
                    if (!labourExists) {
                        this.selectedItemsLabourQuote.push({
                            id: this.id,
                            labourId : this.labourId,
                            labourName : updatedLabourName,
                            labourAmount: updatedLabourAmount,
                            selected: true
                        });
                    }
  
                    console.log("Updated selectedItemsLabourQuote:", this.selectedItemsLabourQuote);
                    this.getQuoteGrandTotal();  
                    // Refresh the labour list to reflect changes
                    this.labourList();
                } else {
                    console.warn('Unexpected response code:', res.code);
                }
            },
            (error) => {
                console.error('Error editing labour:', error);
            }
        );
  
    this.closeLabourEditSidebar();
  }



  
  
  openPopUp(){
    this.showCancelConfirmation = true;
    this.isAddLabour = false;
  }
  closeCancelPopup(): void {
    this.showCancelConfirmation = false;
  }


  toggleSelectItem(): void {
    if (Array.isArray(this.labourListData)) {
      this.labourListData.forEach(labour => {
        if (labour.selected) {
          // Only add if it's not already in selectedItemsLabourQuote
          const alreadySelected = this.selectedItemsLabourQuote.some(item => item.labourId === labour.labourId);
          if (!alreadySelected) {
            this.selectedItemsLabourQuote.push(labour);
          }
        }
      });
    }
    
  
    // Ensure quantity is at least 1
    this.selectedItemsLabourQuote.forEach(labour => {
      if (!labour.quantity || labour.quantity == 0) {
        labour.quantity = 1;
      }
    });
  
    // Filter to keep only selected items
    this.selectedItemsLabourQuote = this.selectedItemsLabourQuote.filter(item => item.selected);
    this.selectedItemsLabourQuoteLength = this.selectedItemsLabourQuote.length;
  
    // Calculate total price
    this.totalSelectedPriceQuote1 = this.selectedItemsLabourQuote.reduce(
      (sum, item) => sum + (item.labourAmount || 0), 0
    );
  
    console.log('Selected Items labour:', this.selectedItemsLabourQuote);
    console.log('Selected Items labour length:', this.selectedItemsLabourQuoteLength);
    console.log('Total Selected Price labour:', this.totalSelectedPriceQuote1);
  
    this.isAddLabour = false;
    this.getQuoteGrandTotal();
    this.updateSelectedLabourItems();
  }
  
  closeAll(): void{
    this.isAddLabour = false;
    this.isEditLabourSidebarOpen = false;
    this.sidebarOpenQuote = false; 
  }
  

  loadComponentB(): void {
    if (!this.checkinComponentRef) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AspCheckinReportsComponent);
      this.checkinComponentRef = this.container11.createComponent(componentFactory);
      
      // Pass the requestId and bookingId to the component
      this.checkinComponentRef.instance.bookingId = this.bookingId1;
      this.checkinComponentRef.instance.jobCard = this.jobcardId1;
    }
  }
  loadCreateInvoice(): void {
    console.log("localStorage.setItem('invoiceOpen'",localStorage.getItem('invoiceOpen'))
    this.createInvoice.clear();
    const factory = this.resolver.resolveComponentFactory(MyJobCardsDetailsComponent);
    const componentRef = this.createInvoice.createComponent(factory);
    const componentBInstance = componentRef.instance as MyJobCardsDetailsComponent;
    componentBInstance.invoiceOpen();
    componentBInstance.onClose = () => {
      this.activeTabs = 'Information'; // Switch back to the information tab when closed
    };
  }

    openPopup(): void {
      this.showSkipConfirmation = false;
      this.isAddLabour = false;
      this.CloseSidebarQuote();
      this.submitData(); 
      // this.ngOnInit();
    }
  
    toggleDropdown(): void {
      this.isDropdownOpen = !this.isDropdownOpen;
        
        // Fetch services when dropdown is opened
        if (this.isDropdownOpen) {
          this.fetchServices(); // Call the API to fetch services
        }
    }
  
    closePopup(): void {
      this.isPopupOpen = false;
    }
    getMakeImageUrl(make: string):string{
      if(make){
        const makeWithoutSpaces = make.toLowerCase().replace(/\s+/g, '');
        return `https://cube.getpitstop.com/assets/img/${makeWithoutSpaces}.png`;
      }else {
        return
      }
    }
    onSearch(item: any) {
      if (!this.searchWord.trim()) {
        return;
      }
      const garageKey=localStorage.getItem('garageKey');
      this.variantKey=localStorage.getItem('VariantKey');
      if( this.isRequestDetails){
       this.variantKey=localStorage.getItem('VariantKey');
       console.log("this.isRequestDetails",this.variantKey)
      }
      if ( this.isJobCardDetails){
        // this.variantKey=this.jobCard['variantKey'];
        this.variantKey=localStorage.getItem('VariantKey');
        console.log("this.isJobCardDetails",this.variantKey)
      }

      this.aspRequestDetailsService
        .searchItems(garageKey, this.searchWord, this.variantKey)
        .subscribe(
          (result) => {
            const res = JSON.parse(JSON.stringify(result));
            
            if (res.code === 200) {
              console.log('Search results:', res.data);
              this.filteredItems = res.data;
              this.partNumber= res.data.map((item: any) => item.partNumber);
            } else {
              console.error('Search failed with code:', res.code);
            }
          },
          (error) => {
            console.error('Error during search:', error);
          }
        );
    }
    
    addItemToQuotation(item: any) {
      this.customerQuotationCount=this.customerQuotation.length;
    
      // Find an existing item with the same partNumber
      const existingItem = this.customerQuotation.find(quote => quote.partNumber === item.partNumber);
    
      if (existingItem) {
        // If found, increase the quantity
        existingItem.itemQuantity += 1;
      } else {
        // Otherwise, add the new item to the list
        const newItem = {
          itemId: item.itemId,
          itemName: item.name,
          brand: item.brand,
          partNumber: item.partNumber,
          mrp: item.mrp,
          hsnCode:item.hsnCode,
          gstRate:item.gstRate,
          itemQuantity: 1,
          selected: true,
          edt: new Date(),
        };
        this.customerQuotation.push(newItem);
        console.log("this.customerQuotation",this.customerQuotation);
        this.customerQuotationListCount = this.customerQuotation.length
        console.log('Item added successfully:', this.customerQuotationListCount);
        this.customerQuotation.forEach(quote => quote.selected = this.selectAllQuote);  
        
      }
      this.isSidebarOpen = false;
      this.clearSearch();
      this.updateSelectedItemsQuote();
      this.getQuoteGrandTotal();
    }
    getQuoteDetails() {
      const userKey = localStorage.getItem('spareUserKey');
      const garageKey = localStorage.getItem('garageKey');
    
      console.log("Fetching Quote Details for Quote ID:", this.quoteId);
    
      this.aspCustomerQuoteService.getQuoteDetails(garageKey, this.quoteId, userKey).subscribe(
        result => {
          const res = JSON.parse(JSON.stringify(result));
          console.log("API Response:", res);
    
          this.data = res.data;
          
          // Ensure jobCardStatus is treated correctly (string or number)
          this.customerQuotation = (this.data.items || []).filter(item => item.jobCardStatus !== 3 && item.jobCardStatus !== '3');
          this.customerQuotation1 = (this.data.items || []).filter(item => item.jobCardStatus === 3 || item.jobCardStatus === '3');
    
          this.selectedItemsLabourQuote = this.data.labourQuoteItemResponses || [];
          this.itemsLength= this.data.items.length,
          this.selectedLabourlengthItems=this.selectedItemsLabourQuote.length
          this.recievedLengthItems=this.itemsLength || 0+this.selectedLabourlengthItems || 0;
          console.log("recievedLengthItems---",this.recievedLengthItems);
          console.log("Filtered Labour Items:", this.selectedItemsLabourQuote);
          console.log("Filtered Spare Parts:", this.customerQuotation);
          console.log("Filtered JobCardStatus == 3 Items:", this.customerQuotation1);
    
          this.cdr.detectChanges();
        },
        error => {
          console.error('Error fetching quote details:', error);
        }
      );
    }
    
    
    
    
    
    getRequestDetails() {

          this.loading = true;
          this.aspLoadingService.show();
          const garageKey = localStorage.getItem('garageKey');
            
          this.myJobCardsDetailsService.getJobCardDetails(this.bookingId , garageKey, this.jobCardId).subscribe(
            (result: any) => {
              console.log("my-jobcard-details-serrvice",result);
              this.loading = false;
              this.aspLoadingService.hide();
        
              if (result && result.code === 200) {
                this.request = result.data;
                // this.fetchPreFilledBooking();
                console.log("this.request--",this.request);
      
                // Check if request-id is present in the response
                const requestId = this.request?.jobCard?.requestId; // Update this path as per your actual response structure
                this.getQuoteDetails();
             
              
                // this.loadComponentB(this.request?.jobCard); // Pass the request-id to Component B
              } else {
                // console.warn('No "request-id" found in job card details');
              }
              
            },
            (error: HttpErrorResponse) => {
              this.loading = false;
              this.aspLoadingService.hide();
              // console.error('Error fetching job card details:', error);
            }
          );
        
    }

  incrementQuantity(quote: any): void {
    quote.quantity++;
    this.customerQuotationPrice = this.calculateTotalQuotationPrice();
    this.updateSelectedItemsQuote();
  
  }
  incrementjobcardQuantity(quote:any):void {
    quote.itemQuantity++;
    this.customerQuotationPrice = this.calculateTotalQuotationPrice();
    this.updateSelectedItemsQuote();
    this.updateSelectedLabourItems();
    this.getQuoteGrandTotal();  
  }
  
  decrementQuantity(quote: any): void {
    if (quote.quantity > 1) { 
      quote.quantity--;
      this.customerQuotationPrice = this.calculateTotalQuotationPrice();
      this.updateSelectedItemsQuote();
    }
  }
  decrementjobcardQuantity(quote:any): void{
    if (quote.itemQuantity > 1) { 
      quote.itemQuantity--;
      this.customerQuotationPrice = this.calculateTotalQuotationPrice();
      this.updateSelectedItemsQuote();
      this.getQuoteGrandTotal();  
    }
  }
  incrementLabourQuantity(quote: any): void {
    if (quote.quantity >= 1) {
      quote.quantity++;
    }
  
    // ✅ Recalculate labour price and update UI
    this.labourQuotationPrice = this.calculateTotalLabourPrice();
    this.updateSelectedItemsQuote();
    this.updateSelectedLabourItems();
    this.getQuoteGrandTotal();  
    this.cdr.detectChanges();
  }
  
  decrementLabourQuantity(quote: any): void {
    if (quote.quantity > 1) { // ✅ Prevents quantity from going below 1
      quote.quantity--;
    }
  
    // ✅ Recalculate labour price and update UI
    this.labourQuotationPrice = this.calculateTotalLabourPrice();
    this.updateSelectedItemsQuote();
    this.updateSelectedLabourItems();
    this.getQuoteGrandTotal();  
    this.cdr.detectChanges();
  }
  

  calculateTotalLabourPrice(): number {
    if (!Array.isArray(this.selectedItemsLabourQuote) || this.selectedItemsLabourQuote.length === 0) {
      return 0; // Return 0 if the array is undefined or empty
    }
  
    return this.selectedItemsLabourQuote.reduce(
      (sum, item) => sum + (Number(item.labourAmount) * Number(item.quantity || 1)),
      0
    );
  }
  
  

    isQuoteApproved(): boolean {
      return this.quotationapproved.length > 0;
    }
    setActiveTab(tab: string): void {
      this.activeTab = tab;
      console.log('activeTab:', this.activeTab);
      console.log('activeTabs:', this.activeTabs);
   
    }
    setActiveTabs(tab: string, type, jobcardId, bookingId, quoteId): void {
      console.log('Tab changed to:', tab);
      this.activeTabs = tab;
      this.jobCardId=jobcardId;
      this.bookingId=bookingId;
      this.quoteId=quoteId;
      this.type = type;

      console.log("this.activeTabs",this.activeTabs)
      // localStorage.setItem('jobcard',this.jobCard['id']);
      if (this.activeTabs === 'quotation' && !this.QuoteId) {
        this.openSidebarQuote()
        this.activeTab = 'quote-received';
      } 
      // if(this.activeTabs === 'quotation' && this.QuoteId) {
      //   this.loadorderQuote(this.QuoteId);
      //   this.ngOnInit();
      // }
      if (this.activeTabs === 'invoice' && this.invoice) {
        this.loadorderInvoice()
        this.activeTab = 'quote-received';
      } 
      console.log("this.invoice",this.invoice)
      if (this.activeTabs === 'invoice' && !this.invoice) {
        localStorage.setItem('invoiceOpen',"yes");
        this.loadCreateInvoice();
        // this.activeTabs = 'quotation';
      } 
       if (this.checkinComponentRef) {
      this.checkinComponentRef.location.nativeElement.style.display = tab === 'Check-in' ? 'block' : 'none';
    }
    }
    
  navigateToDetails() {
    this.router.navigate(['/invoice-details'], { queryParams: {"id": this.invoice, "jobcardId":this.jobcardId1,"QuoteId":this.QuoteId}})
  }
  loadorderInvoice() {
    if (!this.containerInvoice) {
      console.error("containerInvoice is not initialized yet!");
      return;
    }
    this.containerInvoice.clear();
    const factory = this.resolver.resolveComponentFactory(AspInvoiceDetailsComponent);
    const componentRef = this.containerInvoice.createComponent(factory);
    const componentInstance = componentRef.instance as AspInvoiceDetailsComponent;
    componentInstance.resIds = this.invoice;
    componentInstance.jobcardId = this.jobcardId1;
    componentInstance.QuoteId = this.QuoteId;
  }
  
  toggleSelectAll(): void {
    this.selectAll = !this.selectAll;
    this.quotationreceived.forEach(item => item.selected = this.selectAll);
    console.log("this.quotationrecieved----",this.quotationreceived);
    this.updateSelectedItems();
    this.updateSelectedLabourItems();
  }
  
  toggleItemSelection(): void {
    this.selectedItems = this.quotationreceived.filter(item => item.selected);
    this.selectedItemsCount = this.quotationreceived.filter(item => item.selected).length;
    this.totalSelectedPrice = this.quotationreceived
      .filter(item => item.selected)
      .reduce((total, item) => total + ((item.mrp - (item.mrp * item.sellingDiscount / 100)) * item.itemQuantity), 0);
  
    // ✅ Check if all checkboxes are selected and update `selectAll`
    this.selectAll = this.selectedItemsCount === this.quotationreceived.length;
    console.log("this.selectedItems----",this.selectedItems);
    console.log("Selected Items Count:", this.selectedItemsCount);
    console.log("Total Selected Price:", this.totalSelectedPrice);
  }
  
  toggleItemSelectionQuote(): void {
    if (Array.isArray(this.customerQuotation)) {
      this.selectAllQuote = this.customerQuotation.every(quote => quote.selected);
    } else {
      this.selectAllQuote = false;
    }
    if (Array.isArray(this.selectedItemsLabourQuote)) {
        this.selectedItemsLabourQuote.forEach(labour => {
          labour.selected = !!labour.selected; // Ensure it's boolean
      });
      this.selectedLabourCount = this.selectedItemsLabourQuote.filter(labour => labour.selected).length;
    }else {
      this.selectedLabourCount = 0;
    }
    this.updateSelectedItemsQuote();
    this.updateSelectedLabourItems();
    this.cdr.detectChanges();
  }
  
    sidebarToggleItemSelectionQuote(){
      this.toggleItemSelectionQuote();
  
    }
    ApprovedtoggleSelectAll(): void {
      this.selectAll = !this.selectAll;
      this.quotationapproved.forEach(item => item.selected = this.selectAll);
      // console.log("this.selectedItemsCount-----------------",this.selectedItemsCount);
      this.updateApprovedSelectedItems();
      this.anyItemSelected();
      this.ApprovedtoggleItemSelection();
    }
    
    toggleSelectAllQuote(): void {
      this.selectAllQuote = !this.selectAllQuote;
    
      // Ensure customerQuotation exists before applying forEach
      if (this.customerQuotation) {
        this.customerQuotation.forEach(quote => quote.selected = this.selectAllQuote);
      }
    
      // Ensure selectedItemsLabourQuote exists before applying forEach
      if (this.selectedItemsLabourQuote && Array.isArray(this.selectedItemsLabourQuote)) {
        this.selectedItemsLabourQuote.forEach(quote => quote.selected = this.selectAllQuote);
      }
    
      console.log("this.selected Items Labour Quote--", this.selectedItemsLabourQuote);
    
      this.updateSelectedItemsQuote();
      this.anyItemSelected();
      this.updateSelectedLabourItems();
    }
    
    // toggleSelectAllQuote(): void {
    //   this.selectAllQuote = !this.selectAllQuote;
    //   this.customerQuotation.forEach(quote => quote.selected = this.selectAllQuote);

    //   this.updateSelectedItemsQuote();
    //   this.updateSelectedLabourItems();
  
      
    // }
    sidebarToggleSelectAllQuote() {
      this.selectAllQuote = !this.selectAllQuote;
      if (Array.isArray(this.customerQuotation)) {
        this.customerQuotation.forEach(quote => {
          quote.selected = this.selectAllQuote;
        });
      } else {
        console.error("Error: customerQuotation is undefined or not an array", this.customerQuotation);
      }
      if (Array.isArray(this.selectedItemsLabourQuote)) {
        this.selectedItemsLabourQuote.forEach(labour => {
          labour.selected = this.selectAllQuote;
        });
      } else {
        console.error("Error: selectedItemsLabourQuote is undefined or not an array", this.selectedItemsLabourQuote);
      }
      console.log("Updated selectedItemsLabourQuote:", this.selectedItemsLabourQuote);
      console.log("Updated customerQuotation:", this.customerQuotation);
      this.selectedLaboursCount = this.selectedItemsLabourQuote.filter(labour => labour.selected).length;
      this.updateSelectedItemsQuote();
      this.updateSelectedLabourItems();
    }
    
    // toggleItemSelection(): void {
    //   this.selectedItemsCount = this.quotationreceived.filter(item => item.selected).length;
    //   this.totalSelectedPrice = this.quotationreceived.filter(item => item.selected).reduce((total, item) => total+((item.mrp - (item.mrp* item.sellingDiscount /100))*item.itemQuantity), 0);
    //   console.log("this.selectedItems-----------",this.selectedItemsCount);
    //   console.log("this.totalSelectedPrice---",this.totalSelectedPrice);
    //   this.updateSelectedItemsQuote();
    //   this.updateSelectedItems();
    //   this.anyItemSelected();
    // }
    ApprovedtoggleItemSelection(): void {
      this.selectedApprovedItemsCount = this.quotationapproved.filter(item => item.selected).length;
      this.totalSelectedApprovedPrice = this.quotationapproved
        .filter(item => item.selected)
        .reduce((total, item) => total + ((item.mrp - (item.mrp * item.sellingDiscount / 100)) * item.itemQuantity), 0);
    
      // Check if all items are selected, update selectAll accordingly
      this.selectAll = this.selectedApprovedItemsCount === this.quotationapproved.length;
    
      console.log("Selected Items Count:", this.selectedApprovedItemsCount);
      console.log("Total Selected Price:", this.totalSelectedApprovedPrice);
    
      this.updateSelectedItemsQuote();
      this.updateApprovedSelectedItems();
      this.anyItemSelected();
    }
    
    updateApprovedSelectedItems(): void {
      this.selectedApprovedItemsCount = this.quotationapproved.filter(item => item.selected).length;
      this.totalSelectedApprovedPrice = this.quotationapproved.filter(item => item.selected).reduce((total, item) => total+((item.mrp - (item.mrp* item.sellingDiscount /100))*item.itemQuantity), 0);
      this.totalSelectedApprovedPrice = this.quotationapproved.filter(item => item.selected).reduce((total, item) => total+((item.mrp - (item.mrp* item.sellingDiscount /100))*item.itemQuantity), 0);
      // this.selectedItemsCount=this.selectedLabours?.length+this.selectedItemsCountQuote
      // this.selectedLaboursCount=this.selectedLabours.length;
      // console.log("Selected Items Count:", this.selectedItemsCount);
    
    }
  
    updateSelectedItems(): void {
      this.selectedItemsCount = this.quotationreceived.filter(item => item.selected).length;
      this.totalApprovedSelectedPrice = this.quotationapproved.filter(item => item.selected).reduce((total, item) => total+((item.mrp - (item.mrp* item.sellingDiscount /100))*item.itemQuantity), 0);
      this.totalSelectedPrice = this.quotationreceived.filter(item => item.selected).reduce((total, item) => total+((item.mrp - (item.mrp* item.sellingDiscount /100))*item.itemQuantity), 0);
      // this.selectedItemsCount=this.selectedLabours?.length +this.selectedItemsCountQuote;
      this.selectedItemsCount=this.selectedLabours.length +this.selectedItemsCountQuote;
      console.log("Selected Items Count1--------------:", this.selectedItemsCount);
    
    }

      updateSelectedItemsQuote(): void {
        this.selectedItemsQuote = this.customerQuotation.filter(quote => quote.selected);
        this.selectedItemsCountQuote =  this.customerQuotation.filter(quote => quote.selected).length; // Ensure correct count
    
        this.sparePartsTotalcreation = this.selectedItemsQuote.reduce((total, quote) => 
            total + (quote.mrp * quote.itemQuantity), 0
        );
    
        this.sparePartsTotal = this.selectedItemsQuote.reduce((total, quote) => 
            total + (quote.sellingPrice * quote.quantity), 0
        );
    
        // Ensure this.selectedItemsCount is defined
        // this.selectedItemsCount = (this.selectedItemsCountQuote || 0)+ (this.selectedLabours.length || 0);

        this.selectedItemsCount = (this.selectedItemsCountQuote || 0) + (this.selectedLabourslength || 0);
        // Update the total with selected labour

        
        console.log("Selected Items:", this.selectedItemsQuote);
      

        console.log("Total Selected Parts Price:", this.sparePartsTotalcreation);
    }
    
  
  
    updateSelectedLabourItems(): void {
      this.selectedLabours = this.selectedItemsLabourQuote.filter(labour => labour.selected);
      this.selectedLabourslength = this.selectedLabours.length;
      this.labourCount=this.labourListData.filter(labour => labour.selected).length;
      this.labourCountNewList=this.labourListData.filter(labour => labour.selected);
      console.log("this.labourCountNewList-----",this.labourCountNewList);
      this.selectedLaboursNewCount = this.labourCount
console.log("selectedLabourCount---",this.selectedLabourCount);
      this.totallabourSelectedNewPrice = this.labourCountNewList.reduce(
        (sum, labour) => sum + (Number(labour.labourAmount) * Number(labour.quantity || 1)), 0
      );
      console.log("this.totalPrice----",this.totallabourSelectedNewPrice);


      this.totallabourSelectedPrice = this.selectedLabours.reduce(
        (sum, labour) => sum + (Number(labour.labourAmount) * Number(labour.quantity || 1)), 0
      );
    
      this.totallabourListSelectedPrice = this.selectedItemsLabourQuote.reduce(
        (sum, labour) => sum + (Number(labour.labourAmount) * Number(labour.quantity || 1)), 0
      );
      this.totalSelectedPriceQuote123= this.sparePartsTotalcreation + (this.totallabourSelectedPrice || 0);
      // ✅ Ensure total price includes updated labour costs
      this.totalSelectedPriceQuote = Number(this.totallabourListSelectedPrice) + Number(this.sparePartsTotalcreation || 0) + Number(this.totallabourSelectedNewPrice || 0);
      this.totalSelectedPriceQuote1 = Number(this.totallabourSelectedNewPrice);
      this.customerQuotationPrice = this.totalSelectedPriceQuote;
    
      this.selectedItemsCount = (Number(this.selectedItemsCountQuote) || 0) + (Number(this.selectedLabourslength) || 0);
      console.log("new price---",this.totallabourSelectedNewPrice );
      console.log("this.selectedlabourlength---", this.selectedLabourslength);
      console.log("this.selectedItemsCount----", this.selectedItemsCountQuote);
      console.log("Selected Items Count:", this.selectedItemsCount);
      console.log("Total Selected Labour Price:", this.totalSelectedPriceQuote);
    }
    
  
    
    anyItemSelected() {
      return this.selectedLabourslength > 0;
    }
    anyItemSelectedQuote() {
      return this.selectedItemsCountQuote > 0;
    }
    totalAmount(): number {
      return this.items.filter(item => item.selected).reduce((total, item) => total + item.price, 0);
    }
    totalAmountQuote(): number {
      return this.items.filter(quote => quote.selected).reduce((total, item) => total + item.price, 0);
    }
  
    // totalApprovedAmount(): number {
    //   return this.quotationapproved.reduce((total, item) => total + item.mrp, 0);
    // }
    
    calculateDiscountedPrice(mrp: number, discount: number): number {
      return mrp - (mrp * discount / 100);
    }
    
    openSidebar(itemdata): void {
      // console.log('Item Data:', itemdata);
      this.itemDetails=[];
      this.itemDetails=itemdata;
      this.itemDetails.discountedPrice = this.calculateDiscountedPrice(itemdata.mrp, itemdata.discount);
      this.sidebarOpen = true;
  
  
      // this.itemDetails.brandName = itemdata.bidDetails?.[0]?.estimateItem?.Battery?.[0]?.brand;
  
      // this.sidebarOpen = true;
      
  
    }
    openEditDialog(itemQuotes){
      this.itemQuote=[];
      this.itemQuote=itemQuotes;
      this.itemQuote.discountedPrice = this.calculateDiscountedPrice(itemQuotes.mrp, itemQuotes.discount);
      this.isPopupOpenEdit = true;
      this.selectedItemsCountQuote = 0;
      // this.customerQuotation.forEach(quote => {
      //   quote.selected = false;
      // });
      // this.customerQuotation.forEach(quote => quote.selected = this.selectAllQuote=false);
      if (itemQuotes) {
        this.itemQuotesEdit = { ...itemQuotes };
       }
    }
    closePopupEdit(): void {
      this.isPopupOpenEdit = false;
    }
   
    closeSidebar(): void {
      this.sidebarOpen = false;
    }
  
   
    getAllRequestPopup = (id, approvedAt) => {
      this.zone.run(() => {
        this.dialog.open(AspRequestDetailsPopUpComponent,{
          "data":{
            "id":id,
            "approvedAt":approvedAt,
          }
         });
       });
     }
  
    calculateMRP(item: any): number {
      this.quotationapproved.forEach
      return (item.mrp - (item.mrp * item.sellingDiscount / 100)) * item.itemQuantity;
    }
  
    calculateTotalMRP(): void {
      this.totalMRP = this.items.reduce((total, item) => {
        return total + this.calculateMRP(item);
      }, 0);
    }
  

  
  
    reloadApprovedQuotes() {
      const newApprovedItems = this.quotationreceived.filter(item => !this.quotationapproved.some(approved => approved.itemId === item.itemId));
      
      // Add only new items to the approved list
      this.quotationapproved.push(...newApprovedItems);
    console.log("this.quotationapproved.-----------",this.quotationapproved);
      // Recalculate total approved price
      this.quoteApprovedPrice = this.quotationapproved.reduce((total, item) => 
        total + ((item.mrp - (item.mrp * item.sellingDiscount / 100)) * item.itemQuantity), 0);
    }
    
    
    openSidebarQuote(): void {
      this.sidebarOpenQuote = true; 
      // this.submitDataUpdate();   

    }
    openSidebarQuote1(): void 
    {
      this.sidebarOpenQuote = true; 
    } 
     CloseSidebarQuote(): void {
      this.sidebarOpenQuote = false;  
      if(this.activeTabs === 'quotation'){
        // this.sidebarOpen = false;
        this.activeTabs = 'Information';
      }   
    }
    approveNow(){
      this.activeTab = 'quote-received';
    }
  
    save(): void {
      // Find the index of the current item in the customerQuotation array
      const index = this.customerQuotation.findIndex(i => i.partNumber === this.itemQuote.partNumber);
  
      // If the item exists, update it
      if (index !== -1) {

          this.customerQuotation[index].mrp = Number(this.up.unitprice.value);
          this.customerQuotation[index].totalAmount = Number(this.up.unitprice.value) * this.itemQuote.itemQuantity;
          this.openEditDialog(this.customerQuotation);
          // console.log("dsjfjshfjk-----",this.customerQuotation[index].selected) 
        }
            // Recalculate the total quotation price
      this.customerQuotationPrice = this.calculateTotalQuotationPrice();
      this.labourQuotationPrice = this.calculateTotalLabourPrice();
      this.getQuoteGrandTotal();
      // Close the popup after saving
      this.closePopupEdit();
      this.updateSelectedItemsQuote();
      this.updateSelectedLabourItems();
  
  }
  calculateTotalQuotationPrice(): number {
    // Recalculate total price by summing up all items' total amounts
    return this.customerQuotation.reduce((sum, item) => sum + (item.sellingPrice * item.quantity), 0);
  }
  
  submitData() {
    if (!this.quoteId) {
      this.aspLoadingService.show(); 
      // Filter selected items
      this.selectedItems = this.customerQuotation.filter(quote => quote.selected);
  
      // Generate random 7-digit ID for items with null or undefined `itemId`
      this.selectedItems.forEach(item => {
        if (!item.itemId) {
          item.itemId = Math.floor(1000000 + Math.random() * 9000000);
          // console.log('Generated random itemId:', item.itemId);
        }
      });
  
      const selectedPartNumbers = this.filteredItems.map(item => item.partNumber);
      // console.log("Part Numbers:", selectedPartNumbers);
  
      let labour = [];
      
      // console.log("Selected Labour Quotes:", this.selectedLabours);
      
      if (this.selectedLabours && this.selectedLabours.length > 0) {
        labour = this.selectedLabours.map(labour => ({
          // id:labour.id,
          labourId: labour.labourId,
          labourName: labour.labourName,
          labourAmount: labour.labourAmount || 0, // Ensure labourAmount is not undefined
          itmQty: labour.quantity
        }));
      }
      // console.log("this.selectedItems--",this.selectedItems);
      const cleanData = {
   
        bidId: this.selectedItems[0]?.bidId,
        garageKey: localStorage.getItem('garageKey'),
        
        items: this.selectedItems.map(item => ({
          id: item.itemId,
          partName: item.itemName,
          itmQty: item.itemQuantity,
          brand:item.brand,
          partNumber: item.partNumber,
          sellingPrice: item.mrp,
          mrp:item.mrp,
          hsnCode:item.hsnCode,
          gstRate:item.gstRate
        })),
        labourItems: labour, // Use the correctly mapped labour array
        registrationNumber:  this.regno ?? this.registrationNumber ?? null,
        requestId: this.id,
        userKey: localStorage.getItem('spareUserKey'),
        ...(this.isJobCardDetails ? { jobcardId: this.jobCard['id'] } : {})
        
  
      };
  
      // console.log("Payload being sent to API:", cleanData);
      // console.log("")
  
      // Submit the data via the service
      this.aspRequestDetailsService.submitData(cleanData).subscribe(
        response => {
          this.aspLoadingService.hide(); 
          this.resData = response['data'];
          this.dataid = response['data'].id;
          this.isPopupOpen = true;
        },
        error => {
          console.error('Error occurred:', error);
        }
      );
    } else {
      this.submitDataUpdate();
    }
  }
  
  
  
  // getList() {
  //   this.loading = true;
  // this.aspLoadingService.show();
  //   let spareUserKey = localStorage.getItem('spareUserKey');
  //   let garageKey = localStorage.getItem('garageKey');
  //   this.aspRequestDetailsService.orderList(spareUserKey, garageKey).subscribe(
  //     result => {
  //       console.log("result",result);
  //       let res = JSON.parse(JSON.stringify(result)); 
                
  //       if(res.code === 200){ 
  //         this.loading = false;
  // this.aspLoadingService.hide();
  //         res.data.orders.data.forEach(item => {
  //           item.titleName = this.formatOrderDeliveryTime(item.orderConfirmTime, item.orderDeliveryTime);
  //         });
  //         this.orderList = res.data.orders.data;
  
  //         this.orderList.forEach(order => {
  //           // if (this.id==order.requestId){
  //             this.filteredOrderList.push(order)
  //             console.log(this.filteredOrderList)
  //           // }
            
  //         });
  //       } else {
  //         this.loading = false;
  // this.aspLoadingService.hide();
  //       }
  //     }
  //   );
  // }
  
  formatOrderDeliveryTime(confirmTime: string, deliveryTime: string) {
    try {
      const confirmDate = new Date(confirmTime.replace(" ", "T"));
      const deliveryDate = new Date(deliveryTime.replace(" ", "T"));
  
      if (isNaN(confirmDate.getTime()) || isNaN(deliveryDate.getTime())) {
        return '0 minutes';
        
      }
  
      const diffMs = deliveryDate.getTime() - confirmDate.getTime();
      const diffMins = Math.floor(diffMs / 60000);
  
      if (diffMins < 0) {
        return '0 minutes';
        
      }
  
      if (diffMins <= 180) {
        return `${diffMins} minutes`;
      } else {
        const hours = Math.floor(diffMins / 60);
        const minutes = diffMins % 60;
        return `${hours}H ${minutes} min`;
      }
  
    } catch (error) {
      return '0 minutes';
    }
  }
  submitDataUpdate() {
    this.aspLoadingService.show(); 
    // if (this.quoteId) {
    
    // Create a clean data object with only the required fields
    this.selectedItems = this.customerQuotation.filter(quote => quote.selected)
    this.selectedItems.forEach(item => {
      this.item=item.bidId;
    });
    
    const cleanData1 = {
      
      bidId: this.item,
      garageKey: localStorage.getItem('garageKey'),
      items: this.selectedItems.map(item => ({
        id: item.itemId,
        part_name: item.itemName,
        partNumber: item.partNumber,
        sellingPrice: item.mrp
      })),
      quoteId: this.quoteId,
      registrationNumber: this.regno ?? this.registrationNumber ?? null,
      requestId: this.id,
      userKey:localStorage.getItem('spareUserKey'),
    };
    
    this.aspRequestDetailsService.submitDataUpdate(cleanData1).subscribe(response => {
      this.aspLoadingService.hide(); 
      this.resData=response['data'];      
      this.isPopupOpen = true;
    });
  }
  markAsCompleteUpdate(){
    console.log("this.quoationsdfnsdjnjk----",this.quotationapproved);
   let itemData = [];
   this.quotationapproved.forEach(approved => {
    itemData.push({"id": approved.itemId, "quoteItemStatus": 1});
   });
  
    let param = {
      "customerQuoteItems": itemData,
      "labourQuoteItems": [],
      "customerQuoteId": this.quotationapproved[0].spareQuotationId
    }
  
    this.aspRequestDetailsService.markAsComplete(param).subscribe(response => {
      // this.resData = response['data'];
      console.error('response complete:',response );
    });
  }
  
  goBack(): void {
    this.location.back();
  }
  
  openImageUpload() {
    this.isUploading = true;
  }
  closeBottomSheet(){
  this.isUploading = false;
  }
  
  // onFileChange(event: any) {
  //   const files = event.target.files;
  
  //   for (let i = 0; i < files.length; i++) {
  //     const file = files[i];
  //     const reader = new FileReader();
  
  //     // Read the file and convert it to a URL for preview
  //     reader.onload = (e: any) => {
  //       // Push the image URL and tag to the array
  //       this.images.push({
  //         url: e.target.result,
  //         tag: this.newTag
  //       });
  //     };
  
  //     reader.readAsDataURL(file);
  //   }
  
  //   // Clear the tag input field after upload
  //   this.newTag = '';
  // }
  
  // // Upload the images and save the tag
  // uploadImages() {
  //   if (!this.newTag.trim()) {
  //     alert('Please enter a tag for the images.');
  //     return;
  //   }
  
  //   this.selectedFiles.forEach((selectedFile) => {
  //     const reader = new FileReader();
  
  //     reader.onload = (e: any) => {
  //       // Save the image and the tag
  //       this.images.push({
  //         url: e.target.result,
  //         tag: selectedFile.tag
  //       });
  //     };
  
  //     reader.readAsDataURL(selectedFile.file);
  //   });
  
  //   // Clear input and selected files after upload
  //   this.newTag = '';
  //   this.selectedFiles = [];
  //   this.isUploading = false;
  // }
  
   // 📌 Handle image selection
   onImageSelected(tag: string, event: any) {
    const file = event.target.files[0];
    if (!file) return;
  
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const imageUrl = reader.result as string;
      this.setImage(tag, imageUrl);
  
      // Push file and tag after file reading is complete
      this.selectedFiles.push({
        file: file,
        tag: imageUrl, // Store the correct tag instead of e.target.result
      });
  
      console.log("Updated selectedFiles:", this.selectedFiles);
  
      // Call upload only after all processing is done
      this.uploadImages();
    };
    reader.readAsDataURL(file);
  }
loadOrderDetails(){
   
this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
  this.router.navigate(['my-job-cards-details/'+this.jobcardId1+'/'+this.bookingId1]);
});}
  
  onFileChange(event: any) {
    const files = event.target.files;
    this.selectedFiles = []; // Reset selected files
  
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
  
      // Read file and store URL
      reader.onload = (e: any) => {
        this.selectedFiles.push({
          file: file,
          tag: e.target.result // Preview URL
        });
        console.log("this.selectedFiles",this.selectedFiles)
      };
  
      reader.readAsDataURL(file);
    }
  }
  
  // Upload the selected images with the tag
  uploadImages() {
    if (!this.newTag.trim()) {
      this.tagError = true;
      return;
    }
    this.tagError = false;
  console.log("this.newTag",this.newTag)
    // Move selected files to final images list with the tag
  let imagess3=[]
    this.selectedFiles.forEach(fileObj => {
      imagess3.push({
        tag: this.newTag,
        url: fileObj.tag
      });
      console.log("imagess3",imagess3)
      Object.entries(imagess3).forEach(([key, images]) => {
            
        if(images != null && images !=undefined){
          let timestamp =  Math.floor(Date.now() / 1000);
          let fileName = timestamp+"_"+images.tag.toLowerCase()+'.png';
  
          this.s3ImageUploadService.getPresignedUrl(fileName).subscribe({
            next: (response) => {
              console.log("response",response)
              const presignedUrl = response[0]?.signedUrl;
              const link = response[0]?.link;
                this.images.push({
                  tag: images.tag,
                  url: link
                });
                
                this.checkInUpdate();
                console.log("this.images",this.images)
              if (presignedUrl || link) {
                this.uploadFileToS3(presignedUrl, images); 
                imagess3.push({"url": link, "tag": images.tag.toLowerCase()});
              } else {
                console.error('Invalid presigned URL or link:', response);
                this.loading = false;
                this.aspLoadingService.hide();
              }
              
            },
            error: (err) => {
              console.error('Error fetching presigned URL:', err);
            },
          });
        }
    });
    });
   
  
    // Clear inputs after upload
    this.selectedFiles = [];
    this.newTag = '';
    this.isUploading = false;
  }
  uploadFileToS3(presignedUrl,file): void {  
    // console.log('Presigned URL:', presignedUrl);
    // const contentType = file.type;
    const blobData = this.base64ToBlob(file);
  
    const contentType = 'image/png';
    this.http.put(presignedUrl, blobData, {
      headers: { 'Content-Type': contentType },
    }).subscribe({
      next: () => {
        console.log('File uploaded successfully.');
      },
      error: (err) => {
        console.error('Error uploading file:', err);
      },
      complete: () => {
        this.loading = false;
        this.aspLoadingService.hide();
      },
    });
  }
  private base64ToBlob(base64Obj: { tag: string; url: string }): Blob | null {
    if (!base64Obj || typeof base64Obj !== 'object' || !base64Obj.url) {
      console.error('Invalid base64 input:', base64Obj);
      return null;
    }
  
    // Extract the base64 string from `url`
    const base64Data = base64Obj.url.includes(',')
      ? base64Obj.url.split(',')[1]
      : base64Obj.url;
    
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length).fill(0).map((_, i) => slice.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    
  
    return new Blob(byteArrays, { type: 'image/png' });
  }
  removeImage(tag: string) {
    const index = this.images.findIndex(image => image.tag === tag);
    
    if (index !== -1) {
      this.images.splice(index, 1); // Remove the image associated with the tag
      this.checkInUpdate(); // Ensure updates are reflected
    }
  }
  
  
  
  
  closeSidebar1() {
    this.sidebarOpen = false;
    this.activeTabs = 'Information';
  }
  
  checkInDetails(){
  
    let userKey = localStorage.getItem('spareUserKey');
    let garageKey = localStorage.getItem('garageKey');
    this.AspCheckinReportsService.checkInDetails(garageKey, userKey, this.jobCard['bookingId'], this.jobCard['id']).subscribe(
      result => {
        
        const res = JSON.parse(JSON.stringify(result));
        console.log(result);
        this.data = res['data'];
        this.accessoriesYes = res['data'].accessories;
        this.images = this.data.images;
        this.frontImage = this.engineImage = this.batteryImage = this.rightImage = 
        this.backImage = this.leftImage = this.fuelImage =  null;
        this.additionalImages = [];
  
        // Assign images based on tag
        this.images.forEach(image => {
          switch (image.tag) {
            case 'front': this.frontImage = image.url; break;
            case 'engine': this.engineImage = image.url; break;
            case 'battery': this.batteryImage = image.url; break;
            case 'right': this.rightImage = image.url; break;
            case 'back': this.backImage = image.url; break;
            case 'left': this.leftImage = image.url; break;
            case 'fuel-level': this.fuelImage = image.url; break;
            default: 
              // If the tag is not predefined, push it to additional images
              this.additionalImages.push(image);
              break;
          }
        });
        this.damageImages=this.data.damageImages
       
        if (this.data.serviceType && this.data.serviceType.length > 0) {
          // Call selectService for each service in serviceType
          this.data.serviceType.forEach(service => {
            const checkbox = { checked: true } as HTMLInputElement; // Simulate the checkbox being checked
            const fakeEvent = { target: checkbox } as unknown as Event; // Cast to unknown first, then to Event
            this.selectService(service, fakeEvent); // Call selectService for each service
          });
        }
        if (this.accessoriesYes && this.accessoriesYes.length > 0) {
            this.accessoriesYes.forEach(accessory => {
            const checkbox = { checked: true } as HTMLInputElement; // Simulate the checkbox being checked
            const fakeEvent = { target: checkbox } as unknown as Event; // Cast to unknown first, then to Event
            this.selectAccessory(accessory, fakeEvent); // Call selectService for each service
          });
        }
        if (this.data.issues && this.data.issues.length > 0) {
          // Call selectService for each service in issues
          this.data.issues.forEach(issue => {
            const checkbox = { checked: true } as HTMLInputElement; // Simulate the checkbox being checked
            const fakeEvent = { target: checkbox } as unknown as Event; // Cast to unknown first, then to Event
            this.toggleIssue(issue, fakeEvent); // Call selectService for each service
          });
        }
        this.car.selectedServices = [...new Set(this.car.selectedServices)];
        this.fuelAndAccessories.checkedItems = [...new Set(this.fuelAndAccessories.checkedItems)];
        this.selectedIssues = [...new Set(this.selectedIssues)];
    this.initializeFormData();
      }
    );
    
    // this.getAccessoriesList();
  }
  formatDate(dateString: string): string {
    if (!dateString) return '';
    return dateString.split(' ')[0]; // Extract only YYYY-MM-DD
  }
  fetchServices(): void {
    this.sideBarJobcardService.getServiceList().subscribe(
      (response) => {
        if (response.code === 200) {
          // this.data=response.data;
          // console.log("data",this.data)
          // Populate service list with response data
          this.serviceList = response.data.serviceTypes; // Assuming response has a 'serviceTypes' field
          this.accessories = response.data.accessories || []; 
          this.issuesData = response.data.symptoms || [];
          this.filteredIssues = this.issuesData;     
  
        } else {
          console.error('Error fetching service list:', response.message);
        }
      },
      (error) => {
        console.error('API Error:', error);
      }
    );
  }
  
    selectService(service: string, event: Event) {
      // This method will handle the checkbox change event if necessary
      const isChecked = (event.target as HTMLInputElement).checked;
      if (isChecked) {
        this.car.selectedServices.push(service);
      } else {
        const index = this.car.selectedServices.indexOf(service);
        if (index > -1) {
          this.car.selectedServices.splice(index, 1);
        }
      }
    }
  
  
  
  
  removeService(service: string) {
    const index = this.car.selectedServices.indexOf(service);
    if (index > -1) {
      this.car.selectedServices.splice(index, 1);
    } else {
    }
  }
  
  updateFuelLevel(event: any): void {
    this.fuelAndAccessories.fuelLevel = event;
  }
  selectAccessory(accessory: string, event: any): void {
    if (event.target.checked) {
      this.fuelAndAccessories.checkedItems.push(accessory);
    } else {
      const index = this.fuelAndAccessories.checkedItems.indexOf(accessory);
      if (index > -1) {
        this.fuelAndAccessories.checkedItems.splice(index, 1);
      }
    }
  }
  toggleSearch() {
    this.isSearchOpen = !this.isSearchOpen;
  }
  filterIssues() {
    if (!this.searchText || this.searchText.length < 3) {
      this.filteredIssues = this.issuesData;  // No filtering if search text is less than 3
    } else {
      const query = this.searchText.toLowerCase();
  
      this.filteredIssues = this.issuesData.filter(issue => {
        // Ensure the issue description is not undefined or null
        const description = issue ? issue.toLowerCase() : '';
        return description.includes(query);  // Filter based on description field
      });
  
    }
  }
  clearSearch1() {
    this.searchText = '';  // Reset search text
    this.filteredIssues = this.issuesData;
    // Add any other logic to reset filters if needed
  }
  highlightMatch(text: string, search: string): string {
    if (!search) return text;
  
    const regex = new RegExp(`(${search})`, 'gi');
    return text.replace(regex, match => `<span class="highlight">${match}</span>`);
  }
  toggleIssue(issue: string, event: Event) {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.selectedIssues.push(issue);  // Add to selectedIssues
    } else {
      this.selectedIssues = this.selectedIssues.filter(i => i !== issue);  // Remove from selectedIssues
    }
  }
  removeIssue(issueToRemove: string): void {
    const index = this.selectedIssues.indexOf(issueToRemove);
    if (index > -1) {
      this.selectedIssues.splice(index, 1);
    }
  }
  
  
  // Get index of the image for a specific tag
  getImageIndex(tag: string): number {
    const images = this.getImagesForTag(tag);
    return images.indexOf(this.getImage(tag)!);
  }
  
  // Close image viewer
  closeViewer(): void {
    this.isViewerOpen = false;
  }
  
  openImageViewer(tag: string): void {
    this.currentTag = tag;
    const images = this.getImagesForTag(tag);
    this.currentIndex = 0;  // Initialize the index for the first image
    if (images.length > 0) {
      this.isViewerOpen = true;  // Open the viewer if images exist
    }
  }
  
  prevImage(): void {
    const images = this.getImagesForTag(this.currentTag);
    if (this.currentIndex > 0) {
      this.currentIndex--;  // Move to the previous image
    } else {
      this.currentIndex = images.length - 1;  // Loop back to the last image
    }
    console.log('Previous image:', images[this.currentIndex], 'Index:', this.currentIndex); // Debugging
  }
  
  nextImage(): void {
    const images = this.getImagesForTag(this.currentTag);
    if (this.currentIndex < images.length - 1) {
      this.currentIndex++;  // Move to the next image
    } else {
      this.currentIndex = 0;  // Loop back to the first image
    }
    console.log('Next image:', images[this.currentIndex], 'Index:', this.currentIndex); // Debugging
  }
  
  
  // Get the images array for a given tag (including default and additional images)
  getImagesForTag(tag: string): string[] {
    const images = [];
    const defaultImage = this.getImage(tag);
    if (defaultImage) {
      images.push(defaultImage);
    }
    this.additionalImages.forEach(img => {
      if (img.tag === tag) {
        images.push(img.url);
      }
    });
    console.log(`Images for tag ${tag}:`, images); // Debugging
    return images;
  }
  
  
  
  // Get the current image URL for the selected tag
  getImage(tag: string): string | null {
    switch (tag) {
      case 'front': return this.frontImage;
      case 'engine': return this.engineImage;
      case 'battery': return this.batteryImage;
      case 'right': return this.rightImage;
      case 'back': return this.backImage;
      case 'left': return this.leftImage;
      case 'fuel-level' : return this.fuelImage;
      default:
        const additional = this.additionalImages.find(img => img.tag === tag);
        return additional ? additional.url : null;
    }
  }
  
    fetchJobCardDetails(): void {
      this.loading = true;
      this.aspLoadingService.show();
      const garageKey = localStorage.getItem('garageKey');
        
      this.myJobCardsDetailsService.getJobCardDetails(this.jobCard['bookingId'], garageKey, this.jobCard['id']).subscribe(
        (result: any) => {
        
          console.log("resultresult",result)
          this.loading = false;
          this.aspLoadingService.hide();
    
          if (result && result.code === 200) {
            this.request = result.data;
            console.log("this.requestData-------",this.request);
            this.invoice=this.request.invoiceId;
            console.log("this.invoice",this.invoice)
            this.QuoteId=this.request.jobCard.customerQuoteId;
            this.registrationNumber=this.request.jobCard.registrationNumber;
            this.jobcardId1=this.request.jobCard.id;
            this.bookingId1=this.request.jobCard.bookingId;
            console.log('bookingId ID----:', this.bookingId1);
            console.log('jobCard ID:---', this.jobcardId1);
            console.log("this.QuoteId",this.QuoteId)
          const requestId = this.request?.jobCard?.requestId; // Update this path as per your actual response structure
          console.log("varianrkEY-details--",this.request.jobCard.variantKey);
          localStorage.setItem("VariantKey",this.request.jobCard.variantKey);
          } else {
            // console.warn('Unexpected response format or error code:', result);
          }
        }
      );
    }
    initializeFormData(): void {
      // Pre-fill formData with existing data (or empty if not available)
      this.formData = {
        engineNumber: this.data?.engineNumber || '',
        odometer: this.data?.odometer || '',
        lastServiceDate: this.formatDate(this.data?.lastServiceDate)|| '',
        customerName: this.data?.customerName || '',
        customerMobile: this.data?.customerMobile || '',
        customerEmail: this.data?.customerEmail || '',
      };
  
    }
    checkInUpdate() {
      // if (this.quoteId) {
      
      // Create a clean data object with only the required fields
      this.selectedItems = this.customerQuotation.filter(quote => quote.selected)
      this.selectedItems.forEach(item => {
        this.item=item.bidId;
      });
      
      const checkIn = {
        
        garageKey: localStorage.getItem('garageKey'),
        bookingId: this.jobCard['bookingId'],
        jobCardId:this.jobCard['id'],
        customerName:  this.formData.customerName || this.data.customerName,
        customerEmail:this.formData.customerEmail || this.data.customerEmail,
        customerMobile:this.formData.customerMobile || this.data.customerMobile,
        vinNumber:this.data?.vinNumber,
        engineNumber: this.formData.engineNumber || this.data.engineNumber,
        odometer:  this.formData.odometer || this.data.odometer,
        lastServiceDate: this.formData.lastServiceDate || this.data.lastServiceDate,
        fuelLevel: String(this.fuelAndAccessories['fuelLevel'] || this.data['fuelLevel']),
        serviceType :  [...new Set(this.car.selectedServices)],
            accessories:  [...new Set(this.fuelAndAccessories.checkedItems)],
            issues:[...new Set(this.selectedIssues)],
            images: this.images,
            damageImages: [...(this.damageImages || [])]
  
          }
      this.aspRequestDetailsService.updateCheckIn(checkIn).subscribe(response => {
        this.resData=response['data']
        if(response['code']==200){
          // if(this.activeTabs== 'Information'){
          //   window.location.reload();
          // }
          this.toastr.success(response['message']);
          this.checkInDetails();
        }
        else {
          this.toastr.error(response['message']);
        }
      });
    }
     // 📌 Trigger file input when clicking the image box
     triggerFileInput(tag: string) {
      this.newTag=tag;
      const inputElement = document.getElementById(`${tag}FileInput`) as HTMLInputElement;
      if (inputElement) {
        inputElement.click();
      }
    }
  
   
  
    // 📌 Set uploaded image
    setImage(tag: string, url: string) {
      switch (tag) {
        case 'front': this.frontImage = url; break;
        case 'engine': this.engineImage = url; break;
        case 'battery': this.batteryImage = url; break;
        case 'right': this.rightImage = url; break;
        case 'back': this.backImage = url; break;
        case 'left': this.leftImage = url; break;
        case 'fuel-level' : this.fuelImage =url; break
        default:
          this.additionalImages.push({ tag, url });
          break;
      }
    }
  
    // 📌 Delete an image
    deleteImage(tag: string) {
      switch (tag) {
        case 'front': this.frontImage = null; break;
        case 'engine': this.engineImage = null; break;
        case 'battery': this.batteryImage = null; break;
        case 'right': this.rightImage = null; break;
        case 'back': this.backImage = null; break;
        case 'left': this.leftImage = null; break;
        case 'fuel-level' : this.fuelImage = null; break;
        default:
          this.additionalImages = this.additionalImages.filter(img => img.tag !== tag);
          break;
      }
    }
    approveQuote()
    {
      let customerQuoteItems: any = [];
      let jobcardId:number = 0;
      console.log("quotationrecieved----",this.selectedItemsQuote);
      this.selectedItemsQuote.forEach(received => {
        
        if (received.selected === true) {
          let data = {};
          data['id'] = received.itemId;
          data['jobCardStatus'] = 3;
          data['jobcardId'] = received.jobcardId;
          this.jobcardId=received.jobcardId
          customerQuoteItems.push(data);
        }
    });
    let param = {
      "jobcardId":this.jobcardId,
      "customerQuoteItems": customerQuoteItems,
      "customerQuoteId": this.quoteId
    }
    // private aspQuotationDetailsService: AspQuotationDetailsService,
    this.aspQuotationDetailsService.approveQuotation(param).subscribe(
      result => {
        const res = JSON.parse(JSON.stringify(result));
        console.log(res);

        this.isPopupOpen = true;
      },
      error => {
        console.error('Error approving quotation:', error);
      }
    );

    }
    closeViewDetails(){
      localStorage.setItem('activeTab', JSON.stringify({"name":"quotation", "type": "create"}));
      this.loadOrderDetails();
    }

    getQuoteGrandTotal(){
      let partsTotal = 0;
      if(this.customerQuotation){
        partsTotal = this.customerQuotation?.reduce((sum, item) => sum + (item.mrp * item.itemQuantity),0);
      }

      let labourTotal = 0;
      if(this.selectedItemsLabourQuote){
        labourTotal = this.selectedItemsLabourQuote?.reduce((sum, item) => sum + (Number(item.labourAmount) * item.quantity),0);
      }
      this.quoteGrandTotal = partsTotal + labourTotal;
    }  
    
    createNewItems(){
      this.newItem=!this.newItem;
    }
    createItemSidebar(){
      this.newItem= false;
      this.newItems=!this.newItems;
    }
    onSubmit() {
         if (this.itemForm.invalid) {
        Object.keys(this.itemForm.controls).forEach(field => {
          const control = this.itemForm.get(field);
          control?.markAsTouched({ onlySelf: true });
          control?.updateValueAndValidity(); // Ensure validation messages appear
        });

        this.scrollToFirstInvalidField();
        return;
      }
    
      console.log('Form submitted successfully', this.itemForm.value);
    
      if (this.itemForm.valid) {
        console.log('Form Submitted createNewItem', this.itemForm.value);
        const cleanData1 = {
          "garageKey": localStorage.getItem('garageKey'),
          "garageName": localStorage.getItem('spareSelectedGarageName'),
          "userName": localStorage.getItem('spareSelectedGarageName'),
          "userRole":localStorage.getItem('userType'),
          "partName": this.itemForm.value['itemName'],
          "partNumber":this.itemForm.value['partNumber'],
          "mrp": (this.itemForm.value.unitPrice / 100).toFixed(2),
          "gstRate": this.itemForm.value['gst'],
          "brandName":this.itemForm.value['brand'],
          "hsnCode": this.itemForm.value['hsn'],
          "make": this.request?.jobCard?.make,
          "model": this.request?.jobCard?.model,
          "variant":this.request?.jobCard?.variant,
          "rowKey": this.request?.jobCard?.rowKey,
          "variantKey": this.request?.jobCard?.variantKey
        };
      
        // Call the API with updated data
        this.aspCustomerQuoteService.createNewItem(cleanData1).subscribe(response => {
          const res = JSON.parse(JSON.stringify(response));
          console.log("res123456789",res);
          this.originalData=res.data;
          const transformedData = {
            ...this.originalData, 
            brand:this.originalData.brandName,
            itemName: this.originalData.partName, 
            itemId: undefined, 
            itemQuantity: 1, 
          };
          this.customerQuotation.push(transformedData);
          console.log("this.customerQuotation",this.customerQuotation);
          this.customerQuotationListCount = this.customerQuotation.length
          console.log('Item added successfully:', this.customerQuotationListCount);
       
          this.customerQuotation.forEach(quote => quote.selected = this.selectAllQuote);  


          this.customerQuotationCount=this.customerQuotation.length;
          this.isSidebarOpen = false;
          this.clearSearch();
          this.updateSelectedItemsQuote();
          this.getQuoteGrandTotal();
          
        }
      )
      }
      this.newItems=false;
    }
    scrollToFirstInvalidField() {
      const firstInvalidControl: HTMLElement = document.querySelector(
        ".ng-invalid.ng-touched"
      ) as HTMLElement;
    
      if (firstInvalidControl) {
        firstInvalidControl.scrollIntoView({ behavior: "smooth", block: "center" });
        firstInvalidControl.focus(); // Optional: Focus on the field
      }
    }
    // ----------------------------adding new tab Request ------------------------
    aspAddItem() {
      localStorage.setItem('addItem',"1")
      // console.log('Loading order details for:', this.selectedGarage);
          const factory = this.resolver.resolveComponentFactory(AspAddItemSidebarComponent);
          const componentRef = this.container.createComponent(factory);
      
          // Access the instance and call the method
          const componentBInstance = componentRef.instance as AspAddItemSidebarComponent;
          componentBInstance.openSidebar();
      localStorage.removeItem('addItem');
      
        }
  }
