import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspInvoiceListService {

  constructor(private http: HttpClient) { }

    getInvoice(garageKey: string){
      let paramData = {  
        garageKey: garageKey,
        source: "web" 
    };
        // console.log('Calling API with data:', paramData); // Log the data being sent
  
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/garageManagement/v1/invoice/getAll?page=0&size=10000', paramData);
  
  }
}