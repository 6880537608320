<div class="container">  
  <div class="heading"><h2 class="title">My Job Cards</h2></div>
  <div class="main-job-card">
    <!-- <div class="left-sec" *ngIf="!isMobile" [ngClass]="{'sidecontainerMobile': isMobile}"> -->
    <div class="left-sec" *ngIf="!isMobile" >
      <!-- <div class="sidebar" [ngClass]="{'sidebarMobile': isMobile}"> -->
      <!-- <div class="sidebar"> -->
        <div class="filandclr">
          <h3 >FILTERS</h3>
          <div *ngIf="hasAppliedFilters()" (click)="clearFilters()" class="clear-filters-btn">CLEAR ALL</div>
        </div>
        <div class="clear">
          <div *ngIf="hasAppliedFilters()" class="applied-filters">
            <!-- <h4>Applied Filters</h4> -->
            <div *ngFor="let appliedFilter of getAppliedFilters()" class="apply">
              <span>{{ appliedFilter.option.label }}</span>
              <mat-icon (click)="removeFilter(appliedFilter.jobTime, appliedFilter.option)" class="apply1">cancel</mat-icon>
            </div>
          </div>
          <!-- <div *ngIf="hasAppliedFilters()" (click)="clearFilters()" class="clear-filters-btn">CLEAR ALL</div> -->
        </div>
        <!-- <hr style="width: 106%;
        left: -10px;
        /* border: 1px; */
        position: relative;
        box-sizing: content-box;
        height: 0;
        overflow: visible;"/> -->

        <div class="filter-sectionMobile">
          <h4 >JOBCARD STATUS</h4>
          <div *ngFor="let status of filters.jobCardStatus" class="filter-option">
            <label >
              <span>{{ status.label }}</span>
              <span class="radio-container">
                <input type="radio" name="jobCardStatus" [(ngModel)]="selectedJobCardStatus" [value]="status.value" (change)="toggleSelection('jobCardStatus', status)" class="filter-radio">
              </span>
            </label>
          </div>
        </div>
        <!-- <hr style="width: 106%;
        left: -10px;
        /* border: 1px; */
        position: relative;
        box-sizing: content-box;
        height: 0;
        overflow: visible;" /> -->

        <div class="filter-sectionMobile-jobcard-time">
          <h4 >JOBCARD TIME</h4>
          <div *ngFor="let time of filters.jobTime">
            <div *ngIf="time.children; else singleRadio" class="filter-option">
              <h5>{{ time.label }}</h5>
              <div *ngFor="let child of time.children" class="filter-option">
                <label>
                  <span>{{ child.label }}</span>
                  <span class="radio-container">
                    <input type="radio" name="jobTime" [(ngModel)]="selectedJobCardTime" [value]="child.value" (change)="toggleSelection('jobTime', child)" class="filter-radio">
                  </span>
                </label>
              </div>
            </div>
            <ng-template #singleRadio>
              <label class="filter-option">
                <span>{{ time.label }}</span>
                <span class="radio-container">
                  <input type="radio" name="jobTime" [(ngModel)]="selectedJobCardTime" [value]="time.value" (change)="toggleSelection('jobTime', time)" class="filter-radio">
                </span>
              </label>
            </ng-template>
          </div>
        </div>
      <!-- </div> -->
    </div>

    <div class="right-sec">    
      <!-- Search Bar Section -->
      <div class="SearchBarPlaceholder">
        <div class="search">
          <div class="input-container">
            <span *ngIf="searchText" class="close-button" (click)="clearSearch()">✖</span>
            <div class="scroll-text-container" *ngIf="!searchText">
              <div class="SearchBarIcon">
                <i class="material-icons-search" >search</i>
              </div>
              <div class="scroll-text">
                <div id="animationText-1" class="SearchBarAnimationText">Search By "Job Card Id"</div>
                <div id="animationText-2" class="SearchBarAnimationText">Search By "Status"</div>
                <div id="animationText-3" class="SearchBarAnimationText">Search By "Name"</div>
                <div id="animationText-4" class="SearchBarAnimationText">Search By "Mobile"</div>
                <div id="animationText-5" class="SearchBarAnimationText">Search By "Make"</div>
                <div id="animationText-6" class="SearchBarAnimationText">Search By "Model"</div>
                <div id="animationText-7" class="SearchBarAnimationText">Search By "Registration Number"</div>
              </div>
            </div>
            <input type="text" class="search-input" [(ngModel)]="searchText" (keyup)="onEnterPress($event)" (input)="filterRequest()">
          </div>
          <button #myButton class="create-booking" (click)="onCreateJobCardClick()">Create Job Card</button>
           <!-- Mobile Header with Search & Filter Button -->
           <div class="mobile-header" *ngIf="isMobile">
            <!-- <input type="text" placeholder="Search By 'Car Model'" class="search-input" /> -->
            <div (click)="toggleFilterSidebar()" class="filter-btn1">
              <mat-icon>filter_list</mat-icon>
            </div>
          </div>
          <!-- // --------------------------mobile-view-filter------------------- -->
      <!-- Sidebar Overlay for Mobile -->
      <div class="sidebar-overlay" *ngIf="isFilterSidebarOpen && isMobile" (click)="toggleFilterSidebar()"></div>

      <!-- Mobile Filter Sidebar -->
      <div class="sidebar-sidebarMobile" *ngIf="isFilterSidebarOpen && isMobile">
        <div class="sidebar-header">
          <h3>FILTERS</h3>
          <mat-icon (click)="toggleFilterSidebar()">close</mat-icon>
        </div>
        <div class="filandclr">
           <div class="clear">
          <div *ngIf="hasAppliedFilters()" class="applied-filters">
            <div *ngFor="let appliedFilter of getAppliedFilters()" class="apply">
              <span>{{ appliedFilter.option.label }}</span>
              <mat-icon (click)="removeFilter(appliedFilter.jobTime, appliedFilter.option)" class="apply1">cancel</mat-icon>
            </div>
          </div>
        </div>
          <div *ngIf="hasAppliedFilters()" (click)="clearFilters()" class="clear-filters-btn">CLEAR ALL</div>
        </div>

        <div class="filter-sectionMobile">
          <h4>JOBCARD STATUS</h4>
          <div *ngFor="let status of filters.jobCardStatus" class="filter-option">
            <label class="label">
              <span>{{ status.label }}</span>
              <span class="radio-container">
                <input type="radio" name="jobCardStatus" [(ngModel)]="selectedJobCardStatus" [value]="status.value" (change)="toggleSelection('jobCardStatus', status)" class="filter-radio">
              </span>
            </label>
          </div>
        </div>

        <div class="filter-sectionMobile-jobcard-time">
          <h4>JOBCARD TIME</h4>
          <div *ngFor="let time of filters.jobTime">
            <div *ngIf="time.children; else singleRadio" class="filter-option">
              <h5>{{ time.label }}</h5>
              <div *ngFor="let child of time.children" class="filter-option">
                <label>
                  <span>{{ child.label }}</span>
                  <span class="radio-container">
                    <input type="radio" name="jobTime" [(ngModel)]="selectedJobCardTime" [value]="child.value" (change)="toggleSelection('jobTime', child)" class="filter-radio">
                  </span>
                </label>
              </div>
            </div>
            <ng-template #singleRadio>
              <label class="filter-option">
                <span>{{ time.label }}</span>
                <span class="radio-container">
                  <input type="radio" name="jobTime" [(ngModel)]="selectedJobCardTime" [value]="time.value" (change)="toggleSelection('jobTime', time)" class="filter-radio">
                </span>
              </label>
            </ng-template>
          </div>
        </div>
      </div>
      <!-- ----------------------------filter-mobile=view--end--------------- -->
        </div>
      </div>    

      <!-- Jobcard List -->
      <div *ngIf="!loading && filteredJobCards.length > 0">
        <div class="header" *ngFor="let jobCard of filteredJobCards">
          <div>
            <div class="jobcard-new-tag" *ngIf="jobCard?.isExistingCustomer === true">
              Repeat Customer
            </div>
            <div class="jobcard-tag" *ngIf="jobCard?.isExistingCustomer === false">
              New Customer
            </div>
            <!-- -----------READY TAG----------- -->
          </div>
          <!-- --------------------------------------------------------------------------------------------------------------------------- -->
          <div class="header-row" >

            <div class="request-info">
              <div class="header-top-row">
                <div class="order-icon">
                  <div class="car-img">
                    <img [src]="getMakeImageUrl(jobCard.make)" alt="Car Make Image" class="model-icon">
                  </div>
                </div>
                <div class="name-mbl-num">
                  <div class="book-id">Job Card ID # {{ jobCard.id }}</div>
                  <div class="name">{{ jobCard.model }}, {{ jobCard.fuelType }}</div>
                  <div class="mobile-number">{{ jobCard.variant }}</div>
                  <div class="mobile-number">{{ jobCard.registrationNumber }}</div>
                </div>
                <div class="newlyaddedcls">
                  <div class="right-container">
                    <div *ngFor="let status of filters.jobCardStatus">
                      <label class="rightcontainerlabel"class="{{status.statusclass}}" *ngIf="jobCard.jobcardStatus && status.value==jobCard.jobcardStatus">{{status.label}}</label>                    
                    </div>
                    <!-- <mat-icon (click)="openBottomSheet(jobCard)"> attach_file</mat-icon> -->
                    <!-- <div class="right-container-icon" (click)="shareOnWhatsApp()">
                      <i class="share-icon">share</i>
                    </div> -->
                  </div>
                 
                <div *ngIf="jobCard.rating > 0; else getFeedback" class="food-rating">
                  <div class="ratingss">Your Service Rating</div>
                  <div class="rating">
                      <span class="star">&#9733;</span>
                      <span class="score">{{ jobCard.rating }}</span>
                      <div *ngIf="jobCard.feedback !== null" class="toolbar-separator"></div>
                      <!-- <span class="review">{{ jobCard.feedback }}</span> -->
                      <span class="review">
                          {{ jobCard.rating === 1 ? 'Terrible' : 
                             jobCard.rating === 2 ? 'Bad' : 
                             jobCard.rating === 3 ? 'Disappointing' : 
                             jobCard.rating === 4 ? 'Pleasant' : 
                             jobCard.rating === 5 ? 'Awesome' : 'Not Rated' }}
                      </span>
                  </div>
              </div>
              
              <!-- Show "Get Feedback" button only when rating is 0 or less -->
              <ng-template #getFeedback>
                <button mat-button class="ghost-button" matTooltip="Get Feedback" 
                    *ngIf="(jobCard?.jobcardStatus === 4 || jobCard?.jobcardStatus === 5) && !jobCard?.rating" (click)="sendFeedback(jobCard.id,jobCard.jobcardStatus)">
                    Get Feedback
                </button>
            </ng-template>
                        
              </div>
                <!-- <div class="right-container"> -->
                  <!-- <div *ngFor="let status of filters.jobCardStatus">
                    <label class="{{status.statusclass}}" *ngIf="jobCard.jobcardStatus && status.value==jobCard.jobcardStatus">{{status.label}}</label>                    
                  </div> -->
                  <!-- <mat-icon (click)="openBottomSheet(jobCard)"> attach_file</mat-icon> -->
                  <!-- <div class="right-container-icon" (click)="shareOnWhatsApp()">
                    <i class="share-icon">share</i>
                  </div> -->
                <!-- </div> -->

              </div>
            </div>
          </div>
          <ng-template #bottomSheetTemplate >
            <div class="bottom-sheet-content">
              <i class="material-icons" (click)="closeBottomSheet()">cancel</i>                    
              <h3 style="font-weight: bold;">Select Actions</h3>
              <div>
                <a
                  [routerLink]="['/my-job-cards-details', jobCard.id, jobCard.bookingId]" 
                  [queryParams]="{ activeTabs: 'Check-in' }"
                  (click)="closeBottomSheet()">
                  <div class="icons checkin" title="Check In" ><mat-icon>notes</mat-icon></div>
                </a>
                <a
                  [routerLink]="['/my-job-cards-details', jobCard.id, jobCard.bookingId]" 
                  [queryParams]="{ activeTabs: 'quotation' }"
                  (click)="closeBottomSheet()">
                  <div class="icons quotation" title="Quotation"><mat-icon>monetization_on</mat-icon></div>
                </a>
                <a
                  [routerLink]="['/my-job-cards-details', jobCard.id, jobCard.bookingId]" 
                  [queryParams]="{ activeTabs: 'images' }"
                  (click)="closeBottomSheet()">
                  <div class="icons images" title="Images"><mat-icon>image</mat-icon></div>
                </a>

                <div *ngIf="this.curRegistrationNumber" class="icons history" title="Service History" (click)="openServiceHistory()"><mat-icon>history</mat-icon></div>
              </div>
            </div>
          </ng-template> 
          <div class="header-row2">
            <div class="placed-on-date">{{ jobCard.name }}</div>
            <a class="view-btn">
              <span class="phone-number">{{ jobCard.phoneNumber }}</span>
            </a>
          </div>
          <div class="header-row3">
            <div class="placed-on-date"> Placed On {{ jobCard.createdAt | date:'dd MMM yyyy, h:mm a' }}</div>
            <a class="view-btn" [routerLink]="['/my-job-cards-details', jobCard.id, jobCard.bookingId]">
              <span class="job-card">View Details</span>
              <i class="material-icons" style="color: #4caf50; float: left;">arrow_right</i> 
            </a>
          </div>
        </div>
      </div>

      <div class="no-image-car">
        <div class="no-img" *ngIf="!loading && filteredJobCards.length === 0">
          <img [src]="emptyList" alt="No data available" />
          <h1 class="no-request-text">No Jobcard Found</h1>
          <div class="eidt-text">Edit filter or go back to my jobcards page</div>
          <button class="go-back-btn" (click)="clearFilters()" >Go to My Jobcards</button>
        </div>
      </div>

    </div>
  </div>
</div>




<!-- Sidebar Section -->
<div class="overlay" [class.visible]="isVisible" (click)="toggleSidebar()"></div>
<div class="sidebar" *ngIf="isSidebarOpen">
<div class="sidebar-content">
    <div class="sidebarheading">
    <h2 class="crtbkng">Create Job Card</h2>
    <button class="close-sidebar" (click)="toggleSidebar()">×</button>
    </div>
    <form>
      <div class="form-group"></div>
        <label for="name" style="font-weight: bolder;">Enter Customer Name <span class="required">*</span></label>
        <div class="input-group">
            <span class="small-img">
                <img src="../../../../assets/images/my-job-card-images/carkm.png" class="input-icon" alt=""> NM
            </span>
            <input type="text" id="name" placeholder="enter customer name" >
        </div>
        <small>Enter Your Name</small>




        <div class="form-group"></div>
        <label for="name" style="font-weight: bolder;">Enter PhoneNumber <span class="required">*</span></label>
        <div class="input-group">
            <span class="small-img">
                <img src="../../../../assets/images/my-job-card-images/carkm.png" class="input-icon" alt=""> +91
            </span>
            <input type="text" id="name" placeholder="enter phoneNumber" >
        </div>
        <small>Enter Your PhoneNumber</small>


    <div class="form-group">
        <label for="odometer" style="font-weight: bolder;">Enter Odometer Reading <span class="required">*</span></label>
        <div class="input-group">
            <span class="small-img">
                <img src="../../../../assets/images/my-job-card-images/carkm.png" class="input-icon" alt="Car KM"> KM
            </span>
            <input type="text" id="odometer" placeholder="10000" >
        </div>
        <small>Your Odometer Reading</small>
        <!-- <div *ngIf="(odometer.invalid && odometer.touched) || (odometer.invalid && formSubmitted)" style="color: red;">
        Enter Odometer Reading
        </div> -->
        
    </div>
    <div class="form-group">
        <label for="odometer" style="font-weight: bolder;">We recommend to enter car number <span class="required">*</span></label>
        <div class="input-group">
            <span class="small-img">
                <img src="../../../../assets/images/my-job-card-images/circle.png" class="input-icon" alt="Car KM"> IND
            </span>                
            <input type="text" id="car-number" placeholder="KA 04 JA54**" >
        </div>
        <small>Your Car Number</small>
        <!-- <div *ngIf="(carNumberInput.invalid && carNumberInput.touched) || (carNumberInput.invalid && formSubmitted)" style="color: red;">
        Please enter car number
        </div> -->
    </div>
    <ul class="info-list">
        <li>
            <mat-icon class="circle">check_circle</mat-icon>
            <span class="get">Get accurate details</span>
        </li>
        <li>
            <mat-icon class="circle">check_circle</mat-icon>
            <span class="get">Get 2X faster results</span>
        </li>
    </ul>
    <a class="view-btn" style="float: right; border: none; background-color: white; color: #4caf50;">
      <span class="job-card" style="float: left;">Create Job Card</span>
    </a>
    <p class="manual-selection">Or select car manually</p>
    </form>
    <div #dynamicContainer></div>
</div>
</div>
<div class="popup" *ngIf="isPopupOpen">
  <div class="popup-content order-success-sec">
    <img src="{{successIcon}}" alt="">
    <button class="close-popup" (click)="closePopup()">×</button>
    <h3>Job Card Created</h3>
    <p class="order-label"><span class="order-id">Job Card ID</span></p>
    <p class="date"><span>date and time</span></p>
    <p class="thank-you">Let us deliver an awesome experience!</p>
    <div class="image-share">
      <button class="goto-order" (click)="shareOnWhatsApp()">
        <img src="../../../../assets/images/my-job-card-images/img_share.png" alt="Share"> SHARE JOB CARD
      </button>
    </div>
  </div>
</div>



<!-- Confirmation Sidebar -->
<div class="overlay" [class.visible]="isVisible" (click)="toggleConfirmationSidebar()"></div>
<div class="confirmation-sidebar" *ngIf="isConfirmationSidebarOpen">
<div class="confirmation-content">
  <div class="header-container">
    <mat-icon class="back-arrow" (click)="toggleConfirmationSidebar()">arrow_back</mat-icon>
    <mat-icon style="color: #144579;">directions_car</mat-icon>
    <img src="../../../../assets/images/my-job-card-images/img_share.png" alt="">
  </div>
  <h3>Confirm your car details</h3>
  <!-- <div *ngFor="let jobCard of request?.jobCards"> -->
    <div class="details-item">
      <img src="../../../../assets/images/my-job-card-images/ic_check.png" class="circle-img" alt="">
      <div class="label">Make:</div>
      <div class="value">{{ jobCard.make }}</div>
    </div>
    <div class="details-item">
      <img src="../../../../assets/images/my-job-card-images/ic_check.png" alt="">
      <div class="label">Model:</div>
      <div class="value">{{ jobCard.model }}</div>
    </div>
    <div class="details-item">
      <img src="../../../../assets/images/my-job-card-images/ic_check.png" alt="">
      <div class="label">Variant:</div>
      <div class="value">0.8L LX (TYPE 2) (47 h.p.){{ jobCard.variant }}</div>
    </div>
    <div class="details-item">
      <img src="../../../../assets/images/my-job-card-images/ic_check.png" alt="">
      <div class="label">MFG Year:</div>
      <div class="value">2010</div>
    </div>
    <div class="details-item">
      <img src="../../../../assets/images/my-job-card-images/ic_check.png" alt="">
      <div class="label">Fuel:</div>
      <div class="value">{{ jobCard.fuelType }}</div>
    </div>
    <div class="details-item">
      <img src="../../../../assets/images/my-job-card-images/ic_check.png" alt="">
      <div class="label">VIN:</div>
      <div class="value">MA3EAA61S01699103</div>
    </div>
  </div>
  <button class="confirm-btn" (click)="confirmCarDetails()">Confirm</button>
  <button class="change-car-btn" (click)="toggleConfirmationSidebar()">Change Car</button>
</div>

<ng-template #dynamicContainer></ng-template>

<!-- <ng-template #bottomSheetTemplate >
  <div class="bottom-sheet-content">
    <i class="material-icons" (click)="closeBottomSheet()">cancel</i>                    
    <h3 style="font-weight: bold;">Select Actions</h3>
    <div>
      <div class="icons checkin" title="Check In"><mat-icon>notes</mat-icon></div>
      <div class="icons quotation" title="Quotation"><mat-icon>monetization_on</mat-icon></div>
      <div class="icons images" title="Images"><mat-icon>image</mat-icon></div>
      <div *ngIf="this.curRegistrationNumber" class="icons history" title="Service History" (click)="openServiceHistory()"><mat-icon>history</mat-icon></div>
    </div>
  </div>
</ng-template>  -->



<ng-container #vehicleServiceHistoryContainer></ng-container>




