import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class AspMyItemListService {

  constructor(
    private http: HttpClient
  ) { }

  getItemList(names, variantKey, garageKey): Observable<any>{
    const headers = new HttpHeaders({
      'x-api-key': `${GlobalVariable.AUTOVERSE_PARTS_KEY}`,
    });

    console.log('-----Get Item List-----------');    
    return this.http.get(`${GlobalVariable.BASE_API_URL_AUTOVERSE_PARTS}` + '/spare/product/search/?names='+names+'&variantKey='+variantKey+'&garageKey='+garageKey, { headers: headers });
  }
}
