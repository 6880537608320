<div class="page-layout blank fullwidth p-12" style="height: 1px; overflow-y: scroll; top: 1px;"> 
  <div class="order-card">
        <h1>My Customer Payments List</h1>
        <div class="main-tab">
            <div class="left-sec" *ngIf="!isMobile">
                <div class="filandclr">
                    <div style="display: flex;  justify-content: space-between; padding: 0px 15px;">
                        <h3>FILTERS</h3>
                        <div *ngIf="hasAppliedFilters()" (click)="clearFilters()" class="clear-filters-btn">CLEAR ALL</div>
                    </div>
                    <div class="clear">
                        <div *ngIf="hasAppliedFilters()" class="applied-filters">
                            
                            <!-- <div *ngFor="let appliedFilter of getAppliedFilters()" class="apply"> -->
                            <div *ngFor="let appliedFilter of filters.paymentStatus" >
                                <div class="apply" *ngIf="selectedJobCardStatus && selectedJobCardStatus===appliedFilter.value">
                                    <span>{{ appliedFilter.label }}</span>
                                    <mat-icon (click)="removeFilter(appliedFilter.filterKey, appliedFilter.option)" class="apply1">cancel</mat-icon>
                                </div>
                            </div>
                            <div *ngFor="let appliedFilter of filters.jobTime">
                                <div class="apply" *ngIf="selectedJobCardTime && selectedJobCardTime === appliedFilter.value">
                                    <span>{{ appliedFilter.label }}</span>
                                    <mat-icon (click)="removeFilter('jobTime', appliedFilter.value)" class="apply1">cancel</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            
                <!-- Status Filter Section -->
                <div class="filter-section">
                    <h4>PAYMENT STATUS</h4>
                    <div *ngFor="let status of filters.paymentStatus" class="filter-option">
                        <label>
                            <span>{{ status.label }}</span>
                            <span class="radio-container">
                                <input type="radio" name="paymentStatus" [(ngModel)]="selectedPaymentStatus" [value]="status.value"
                                    (change)="toggleSelection('status',status)" class="filter-radio">
                            </span>
                        </label>
                    </div>
                </div>
                <div class="filter-sectionMobile-jobcard-time">
                    <h4 >PAYMENT TIME</h4>
                    <div *ngFor="let time of filters.jobTime" style="padding: 0px 15px;">
                      <div *ngIf="time.children; else singleRadio" class="filter-option">
                        <h5>{{ time.label }}</h5>
                        <div *ngFor="let child of time.children" class="filter-option">
                          <label >
                            <span>{{ child.label }}</span>
                            <span class="radio-container">
                              <input type="radio" name="jobTime" [(ngModel)]="selectedJobCardTime" [value]="child.value" (change)="toggleSelection('jobTime', child)" class="filter-radio">
                            </span>
                          </label>
                        </div>
                      </div>
                      <ng-template #singleRadio>
                        <label class="filter-option">
                          <span>{{ time.label }}</span>
                          <span class="radio-container">
                            <input type="radio" name="jobTime" [(ngModel)]="selectedJobCardTime" [value]="time.value" (change)="toggleSelection('jobTime', time)" class="filter-radio">
                          </span>
                        </label>
                      </ng-template>
                    </div>
                </div>
            </div>
            
            <div class="newsearch-and-list">
                <div class="SearchBarPlaceholder">
                    <div class="search">
                    <div class="input-container">
                        <span *ngIf="searchText" class="close-button" (click)="clearSearch()">✖</span>
                        
                        <div class="scroll-text-container">
                        <div class="SearchBarIcon">
                            <i class="material-icons-search" >search</i>
                        </div>
                        <div class="scroll-text" *ngIf="!searchText">
                            <div id="animationText-1" class="SearchBarAnimationText">Search By "Payment Id"</div>
                            <div id="animationText-2" class="SearchBarAnimationText">Search By "Invoice Id"</div>
                            <div id="animationText-3" class="SearchBarAnimationText">Search By "Jobcard Id"</div>
                            <div id="animationText-4" class="SearchBarAnimationText">Search By "Registration Number"</div>
                            <div id="animationText-5" class="SearchBarAnimationText">Search By "Make"</div>
                            <div id="animationText-6" class="SearchBarAnimationText">Search By "Model"</div>
                            <div id="animationText-7" class="SearchBarAnimationText">Search By "Status"</div>
                            <div id="animationText-8" class="SearchBarAnimationText">Search By "Total Amount Paid"</div>
                            <div id="animationText-9" class="SearchBarAnimationText">Search By "Total Amount Due"</div>
                        </div>
                        </div>
                        <!-- <input type="text" class="search-input" [(ngModel)]="searchText" (keyup)="onEnterPress($event)" (input)="filterRequest()"> -->
                        <input type="text" class="search-input" [(ngModel)]="searchText" (input)="filterPaymentList()">


                    </div>
                    <!-- Mobile Header with Search & Filter Button -->
                    <!-- Mobile Header with Search & Filter Button -->
<div class="mobile-header" *ngIf="isMobile">
    <div (click)="toggleFilterSidebar()" class="filter-btn1">
        <mat-icon>filter_list</mat-icon>
        <div class="filter_list-filter">Filters</div>
    </div>
</div>

<!-- Sidebar Overlay for Mobile -->
<div class="sidebar-overlay" *ngIf="isFilterSidebarOpen && isMobile" (click)="toggleFilterSidebar()"></div>

                    <!-- Mobile Filter Sidebar -->
                    <div class="sidebar-sidebarMobile" *ngIf="isFilterSidebarOpen && isMobile">
                        <div class="sidebar-header">
                            <h3>FILTERS</h3>
                            <mat-icon (click)="toggleFilterSidebar()">close</mat-icon>
                        </div>

                        <div class="filandclr">
                            <div class="clear">
                                <div *ngIf="hasAppliedFilters()" class="applied-filters">
                                    <div *ngFor="let appliedFilter of filters.paymentStatus">
                                        <div class="apply" *ngIf="selectedPaymentStatus === appliedFilter.value">
                                            <span>{{ appliedFilter.label }}</span>
                                            <mat-icon (click)="removeFilter('status', appliedFilter.value)" class="apply1">cancel</mat-icon>
                                        </div>
                                    </div>
                                    <div *ngFor="let appliedFilter of filters.jobTime">
                                        <div class="apply" *ngIf="selectedJobCardTime === appliedFilter.value">
                                            <span>{{ appliedFilter.label }}</span>
                                            <mat-icon (click)="removeFilter('jobTime', appliedFilter.value)" class="apply1">cancel</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="hasAppliedFilters()" (click)="clearFilters()" class="clear-filters-btn">CLEAR ALL</div>
                        </div>

                        <!-- Payment Status Filter -->
                        <div class="filter-sectionMobile">
                            <h4>PAYMENT STATUS</h4>
                            <div *ngFor="let status of filters.paymentStatus" class="filter-option">
                                <label class="label">
                                    <span>{{ status.label }}</span>
                                    <span class="radio-container">
                                        <input type="radio" name="paymentStatus" [(ngModel)]="selectedPaymentStatus" [value]="status.value"
                                            (change)="toggleSelection('status', status)" class="filter-radio">
                                    </span>
                                </label>
                            </div>
                        </div>

                        <!-- Payment Time Filter -->
                        <div class="filter-sectionMobile-jobcard-time">
                            <h4>PAYMENT TIME</h4>
                            <div *ngFor="let time of filters.jobTime">
                                <div *ngIf="time.children; else singleRadio" class="filter-option">
                                    <h5>{{ time.label }}</h5>
                                    <div *ngFor="let child of time.children" class="filter-option">
                                        <label>
                                            <span>{{ child.label }}</span>
                                            <span class="radio-container">
                                                <input type="radio" name="jobTime" [(ngModel)]="selectedJobCardTime" [value]="child.value"
                                                    (change)="toggleSelection('jobTime', child)" class="filter-radio">
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <ng-template #singleRadio>
                                    <label class="filter-option">
                                        <span>{{ time.label }}</span>
                                        <span class="radio-container">
                                            <input type="radio" name="jobTime" [(ngModel)]="selectedJobCardTime" [value]="time.value"
                                                (change)="toggleSelection('jobTime', time)" class="filter-radio">
                                        </span>
                                    </label>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                <!-- ----------------------------filter-mobile=view--end--------------- -->
                    </div>
                </div> 
                
                <div class="main-content" *ngFor="let item of filteredPaymentListData">
                    <div class="second-main">
                        <div class="order-header">
                            <div class="order-status">Payment ID # {{ item.paymentId || 'N/A' }}</div>
                            <div class="status-badge">
                                {{ item.paymentStatus == 1 ? 'Paid' : item.paymentStatus == 2 ? 'Partially Paid' : 'Pending' }}
                            </div>                
                            
                        </div>
                        <div class="order-details">
                            <p class="order-number">Invoice No # {{ item.invoiceId || 'N/A' }}</p>
                            <p class="total-amount">Job Card # {{ item.jobCardId || 'N/A' }}</p>
                            <div class="vehicle-details">
                                <div class="logo-container">
                                    <img [src]="'https://cube.getpitstop.com/assets/img/' + (item.make ? item.make.toLowerCase().replace(' ', '') : 'N/A') + '.png'" 
                                        alt="Make Image" class="car-icon" />
                                </div>
                                <div class="vhclnameandinfo">
                                    <div class="vehicle-name"><strong>{{ item.model || 'N/A' }}, {{ item.fuelType || 'N/A' }}</strong></div>
                                    <div class="vehicle-info">{{ item.variant || 'N/A' }} [{{ item.year || 'N/A' }}]</div>
                                    <div class="vehicle-regNo">{{ item.registrationNumber || 'N/A' }}</div>
                                    <div class="vehicle-regNo">{{ item.vinNumber || 'N/A' }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="amountpaid-amountdue">
                        <div class="item-list">Total Amount Paid: ₹{{ item.amountPaid || 'N/A' }}</div>
                        <div class="amount-due" *ngIf="item.paymentStatus == 2">
                            Total Amount Due: ₹{{ (item.amountDue / 100) | number:'1.2-2' || 'N/A' }}
                          </div>
                                              </div>

                    <div class="dateandview">
                        <div class="placed-date">Paid on: {{ item.paymentDate || 'N/A' }}</div>
                        <button class="Viewbtn" (click)="openRecordPaymentSidebar(item.paymentId,item.jobCardId)">
                            <span class="view" >View Details</span>
                            <i class="material-icons">arrow_right</i> 
                        </button>           
                    </div>
                    
                </div>
                <div class="no-image-car">
                    <div class="no-img" *ngIf="!loading && filteredPaymentListData.length <= 0">
                      <img [src]="emptyList" alt="No data available" />
                      <h1 class="no-request-text">No Payment Found</h1>
                      <div class="eidt-text">Edit filter or go back to my payments page</div>
                      <button class="go-back-btn" (click)="clearFilters()" >Go to My Payments</button>
                    </div>
                  </div>
            </div>
        </div>
    </div>
    <div #dynamicContainer></div>


    <!-- ----------------loading message-------- -->

    <div class="loading-container" *ngIf="loading">
        <div class="loading-background"></div>
        
        <ng-lottie
        class="animation"
        [options]="options"
        (animationCreated)="animationCreated($event)">
    </ng-lottie>
    <!-- <p class="message">{{ randomMessage }}</p> -->
    <!-- <p class="message">Loading</p> -->
    </div>
</div>
