import { Component, EventEmitter, Input, OnInit, Output, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {AspCheckinReportsService} from './asp-checkin-reports.service'
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-asp-checkin-reports',
  templateUrl: './asp-checkin-reports.component.html',
  styleUrls: ['./asp-checkin-reports.component.scss']
})
export class AspCheckinReportsComponent implements OnInit {
  isBottomSheetOpen= false;
  currentPage: number = 1;
  totalPages: number = 2; 
  images: string[] = [];

  @Input() bookingId!: string;
  @Input() jobCard!: string;

 @ViewChild('bottomSheetTemplate') bottomSheetTemplate!: TemplateRef<any>;
  data: any;
  accessories: any;
  accessoriesYes: any;
  damageImages: any;
  constructor(
     private bottomSheet: MatBottomSheet,
     private renderer: Renderer2,
     private AspCheckinReportsService:AspCheckinReportsService,
     private http: HttpClient
  ) { }

  ngOnInit(): void {
    console.log('bookingId ID:', this.bookingId);
    console.log('jobCard ID:', this.jobCard);
    this.checkInDetails();
  }

  @Output() changeTab = new EventEmitter<string>();

  openInformationTab() {
    this.changeTab.emit('Information'); 
    this.closeBottomSheet();
  }
  openMenu() {
    this.isBottomSheetOpen = true;
    
  }
   closeMenu(): void {
    this.isBottomSheetOpen = false;
  }
  openBottomSheet(): void {
    this.bottomSheet.open(this.bottomSheetTemplate,{
     panelClass: 'bottom-sheet-checkin'
    }
    );
  }
  closeBottomSheet(): void {
    if (this.bottomSheet) {
      this.bottomSheet.dismiss();
    }
  }
 
  shareForApproval(): void {
    const spareSelectedGarageName= localStorage.getItem('spareSelectedGarageName');
    const message = `Hello ${this.data.customerName}, \n\n Your *Job Card ID:${this.jobCard}*. \n Here is your vehicle check-in report. \n\n A quotation will be shared with you shortly for approval. \n\nRegards,\n${spareSelectedGarageName}`;
   
    // WhatsApp API or Intent to share
    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`, '_blank');

    this.closeMenu();
  }

  // Go to the next page
  goToNextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  // Go to the previous page
  goToPreviousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }
 
 
  async downloadPDF() {
    // Temporarily render both pages
    const originalPage = this.currentPage;
    this.currentPage = 0;
  
    setTimeout(async () => {
      const doc = new jsPDF('p', 'mm', 'a4');
      const options = { scale: 1, useCORS: true };
  
      // Wait for images to load before capturing
      await this.waitForImagesToLoad();
  
      const pages = ['page1', 'page2','page3'];
      for (let i = 0; i < pages.length; i++) {
        const element = document.getElementById(pages[i]);
        if (element) {
          const canvas = await html2canvas(element, options);
          const imgData = canvas.toDataURL('image/png');
          const pdfWidth = doc.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
  
          if (i > 0) doc.addPage();
          doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        }
      }
  
      // Save the PDF
      doc.save('CheckInReport.pdf');
  
      // Restore the original page
      this.currentPage = originalPage;
    }, 0);
  }
  
  // Helper method to wait for images to load
  waitForImagesToLoad(): Promise<void> {
    return new Promise((resolve) => {
      const images = Array.from(document.images);
      let loadedImagesCount = 0;
  
      images.forEach((img) => {
        if (img.complete) {
          loadedImagesCount++;
          if (loadedImagesCount === images.length) resolve();
        } else {
          img.onload = () => {
            loadedImagesCount++;
            if (loadedImagesCount === images.length) resolve();
          };
          img.onerror = () => {
            loadedImagesCount++;
            if (loadedImagesCount === images.length) resolve();
          };
        }
      });
  
      if (images.length === 0) resolve();
    });
  }
  
  checkInDetails(){
    let userKey = localStorage.getItem('spareUserKey');
    let garageKey = localStorage.getItem('garageKey');
    this.AspCheckinReportsService.checkInDetails(garageKey, userKey, this.bookingId, this.jobCard).subscribe(
      result => {
        const res = JSON.parse(JSON.stringify(result));
        console.log(result);
        this.data = res['data'];
        this.accessoriesYes = res['data'].accessories;
        this.damageImages = this.data.damageImages;
        this.images= this.data.images;
      }
    );
    // this.getAccessoriesList();
}

// getAccessoriesList(){
//   this.AspCheckinReportsService.getAccessoriesList().subscribe(
//     res => {
//       console.log("res", res);
//       this.accessories = res['data'].accessories;

//       // After getting the full list of accessories, match with the "Yes" accessories
//       this.accessories.forEach(item => {
//         // Check if this item is in the accessoriesYes list by comparing the description
//         item.isYes = this.accessoriesYes.some(yesItem => yesItem === item.description);
//       });

//       console.log(this.accessories); // Check the updated list
//     }
//   );
// }

}
