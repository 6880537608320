<!-- Sidebar Overlay -->
<div class="sidebar-overlay" *ngIf="sidebarOpen || isPopupVisible || isReceiptSidebarOpen" (click)="closeSidebar()"></div>
  <div class="sidebar" *ngIf="sidebarOpen">
      <div class="sidebar-header">
        <mat-icon class="back-btn" (click)="closeSidebar()">keyboard_backspace</mat-icon>
        <div class="header">
          <div class="headerandclosebtn">
            <h3>Record Payment</h3>
            <p class="subtitle"> INV-{{ resIds }}, {{ customerName }}</p>
          </div>          
          <mat-icon class="back-btn" (click)="closeSidebar()">close</mat-icon>
        </div>
      </div>
      <div class="payment-details">
        <div class="payment-heading"> Payment Details</div>

        <!-- Amount to be Recorded -->
        <div class="amount-class">
          

          <form #amountForm = "ngForm">
            <!-- <mat-form-field appearance="outline" class="full-width">
              <mat-label>Amount to be Recorded (₹)</mat-label>
              <input matInput type="number" [(ngModel)]="totalAmounts" name="totalAmounts" #amountInput="ngModel" required>
            </mat-form-field> -->
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Amount to be Recorded</mat-label>
              <span matPrefix class="bold-prefix">₹</span>
              <input matInput type="number" [(ngModel)]="totalAmounts" name="totalAmounts"
                     #amountInput="ngModel" [max]="totalAmounts" required (input)="updateAmountPaid()" 
                     class="bold-input">
            </mat-form-field>              
          </form>
  
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Payment Date</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="paymentDate" class="datepicker">
            <mat-datepicker-toggle matSuffix [for]="picker" class="picker" style="margin-left:-31px; top: -29px; position: absolute;"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <!-- <p *ngIf="paymentDate">Selected Date: {{ getFormattedDate() }}</p> -->


          <div class="section">
            <label>Payment Type</label>
            <div class="payment-options">
              <button 
                *ngFor="let type of paymentTypes" 
                (click)="selectPaymentType(type)" 
                [class.selected]="selectedPaymentType === type">
                {{ type }}
              </button>
            </div>
          </div>
          
          
          
          <!-- Notes Section -->
          <div class="notes-section" *ngIf="selectedPaymentType">
            <label>Notes</label>
            <mat-form-field *ngIf="selectedPaymentType === 'UPI' || selectedPaymentType === 'Card' || selectedPaymentType === 'Net Banking' || selectedPaymentType === 'EMI'" appearance="outline" class="full-width">
              <mat-label>Transaction ID</mat-label>
              <input matInput [(ngModel)]="transactionId">
            </mat-form-field>
            
            <mat-form-field *ngIf="selectedPaymentType === 'Cash'" appearance="outline" class="full-width">
              <mat-label>Cash Receipt Number</mat-label>
              <input matInput [(ngModel)]="cashReceiptNumber">
            </mat-form-field>
            
            <mat-form-field *ngIf="selectedPaymentType === 'Cheque'" appearance="outline" class="full-width">
              <mat-label>Cheque Number</mat-label>
              <input matInput [(ngModel)]="chequeNumber">
            </mat-form-field>
            
            <mat-form-field *ngIf="selectedPaymentType === 'Card'" appearance="outline" class="full-width">
              <mat-label>Selected Card Type</mat-label>
              <mat-select [(ngModel)]="selectedCardType">
                <mat-option *ngFor="let card of cardTypes" [value]="card">{{ card }}</mat-option>
              </mat-select>
            </mat-form-field>
            
            <mat-form-field *ngIf="selectedPaymentType === 'Net Banking' || selectedPaymentType === 'Cheque'" appearance="outline" class="full-width" style="cursor: pointer;">
              <mat-label style="cursor: pointer;">Bank Name</mat-label>
              <input matInput [value]="selectedBank" (click)="openBankSidebar()" readonly style="cursor: pointer;">
            </mat-form-field>
            
            <mat-form-field *ngIf="selectedPaymentType === 'EMI'" appearance="outline" class="full-width">
              <mat-label>Approval Number</mat-label>
              <input matInput [(ngModel)]="approvalNumber">
            </mat-form-field>
            
            
          </div>

          <!-- Adding Other Notes Section Below -->
          
        <!-- Open Sidebar Button -->
      <!-- <button class="open-sidebar-btn" (click)="openSidebar()">Open Payment Sidebar</button> -->
      </div>
      <div class="other-notes-section">
        <label class="labeled">Other Details</label>
        <div class="otherdetailscls">
          <div class="internalnote">Internal Notes</div>
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Enter your notes...</mat-label>
            <textarea matInput [(ngModel)]="otherNotes"></textarea>
          </mat-form-field>
          <div class="attachment-text">Attachments</div>
          <div class="upload-container">
            <!-- <label for="image-upload">Upload Image (Optional):</label> -->
            <div *ngFor="let image of images" class="imageuploaded">
                <img [src]="image" alt="uploaded image" class="uploaded-image" />
                <mat-icon (click)="deleteImage(i)" class="delete-icon">close</mat-icon>
            </div>
        
            <!-- Add new camera icon for uploading more images -->
            <!-- <mat-icon *ngIf="images.length < 5" (click)="document.getElementById('image-upload')?.click()">camera_alt</mat-icon> -->
        </div>
        <input type="file" id="image-upload" (change)="onImageUpload($event)" />   
      </div>     
    </div>
      <div *ngIf="amountInput.invalid && amountInput.touched" class="errormessage" >
        Please fill mandatory field ( Amount to be Recorded field )
      </div>
      <div class="payment-summary">
        <div class="amount-info">
          <p class="amount-label">Pay in Amount</p>
          <p class="amount-value">₹{{ (totalAmounts / 100) | number:'1.2-2' }}</p>
        </div>
        <button class="record-btn" (click)="validateAndOpenPopup(amountForm)">Record</button>
      </div>
    </div>
  </div>


<!-- Trigger Button -->

<!-- Bank Name Sideslider -->
<div class="sideslider-overlay" *ngIf="bankSidebarOpen">
  <div class="sideslider">
    <div class="sideslider-header">
      <mat-icon class="back-btn1" (click)="closeBankSidebar()">keyboard_backspace</mat-icon>
      <h3>Select Bank</h3>
      <mat-icon (click)="closeBankSidebar()">close</mat-icon>
    </div>

    <!-- Search Bar -->
    <div class="SearchBarContainerNew">
      <div class="SearchBarButton">
        <div class="SearchBarIcon">
          <i class="material-icons-search">search</i>
        </div>
        <input
          type="text"
          placeholder="Search for Banks"
          [(ngModel)]="searchText"
          (input)="filterBanks()"
          class="SearchBarInput"
        />
        <div class="remove-search-suggestion" *ngIf="filteredBanks.length" (click)="clearSearch()">
          <mat-icon>close</mat-icon>
        </div>
      </div>
    </div>

    <!-- Filtered Bank List -->
    <div class="bank-list">
      <div *ngFor="let bank of filteredBanks" class="bank-item" (click)="selectBank(bank)">
        {{ bank }}
      </div>
    </div>
  </div>
</div>

<!-- =====================pop-up================== -->
 <!-- Payment Popup -->
<div class="popup-overlay" *ngIf="isPopupVisible">
  <div>
    <div>
      <mat-icon (click)="closePopup()" class="popupclose">close</mat-icon>
    </div>
    <div class="popup-container">
      <div class="popup-header">
        <div class="success-icon">
          <img src="{{successIcon}}" alt="successIcon" style="width: 45%;">
        </div>
        <h2>Payment Recorded</h2>
      </div>

      <div class="popup-body">
        <!-- <div class="popup-body">
          <p><strong>Payment ID:</strong> {{ paymentpopupId  }}</p>
          <p><strong>Amount Paid:</strong> ₹{{ amountPaid || '0' }}</p>
          <p><strong>Payment Type:</strong> {{ selectedPaymentType || 'Not specified' }}</p>
          <p>You have successfully recorded the payment.</p>
        </div> -->
        <div class="popup-body">
          <p><strong>Payment ID # : </strong> <span > {{ paymentpopupId }} </span></p>
          <p><strong>Amount Paid: </strong> <span> ₹{{ (totalAmounts / 100) | number:'1.2-2' || '0.00' }}</span></p>
          <p><strong>Payment Type : </strong> <span> {{ selectedPaymentType || 'Not specified' }}</span></p>
          <p class="successful-note">You have successfully recorded the payment.</p>
      </div>
      
        
      </div>

      <div class="popup-actions" (click)="openReceiptSidebar()" style="cursor: pointer;">
        <button mat-button color="primary" style="cursor: pointer;" (click)="openReceiptSidebar()"  >VIEW RECEIPT</button>
      </div>
    </div>
  </div>
</div>

<!-- ============================view receipt================ -->
 <!-- Receipt Sidebar -->
 <div class="sidebar-overlay" *ngIf="isReceiptSidebarOpen" (click)="closeReceiptSidebar()"></div>
<div class="receipt-sidebar" *ngIf="isReceiptSidebarOpen" >
  
  <div class="receipt-header">
    <div class="receipt-close-btn">
      <mat-icon  (click)="closeReceiptSidebar()" style="float: right; cursor: pointer;">keyboard_backspace</mat-icon>
    </div>
    <div class="payment-receipt-new">Payment Receipt</div>
    <mat-icon class="download-icon" (click)="downloadReceipt()" style="cursor: pointer;">cloud_download</mat-icon>
  </div>
  <div class="receipt-content" id="receiptContent">
    <div>
      <div class="cknwldgmnt">PAYMENT ACKNOWLEDGEMENT</div>
      <div class="officename">Autoverse Mobility Private Limited</div>
      <div class="garagename">{{ garageName }}</div>
      <div class="mobileandemail"> <strong> Mobile :{{ garagePhoneNumber }} | Email :  {{ garageEmail }} </strong> </div>
    </div>
    <!-- <hr> -->
    <div class="invoicemethod">
      <p><span class="label">PaymentID # :</span> <span class="value"><strong>{{ paymentpopupId || 'Not Available' }}</strong></span></p>
      <p><span class="label">InvoiceID # :</span> <span class="value"><strong>{{ resIds }}</strong></span></p>
      <p><span class="label">Payment Date :</span> <span class="value"><strong>{{ paymentDate ? (paymentDate | date:'MM-dd-yyyy') : 'Not Available' }}</strong></span></p>
      <!-- <p><span class="label">Status:</span> <span class="value"><strong>{{ paymentStatus }}</strong></span></p> -->
      <p><span class="label">Status :</span> <span class="value"><strong>
        {{ paymentStatus === 'Paid' ? 'Paid' : paymentStatus === 'Partially Paid' ? 'Partially Paid' : 'Unknown Status' }}
      </strong></span></p>      
      <p><span class="label">Mode :</span> <span class="value"><strong>{{ selectedPaymentType || 'Not specified' }}</strong></span></p>
      <p>
        <span class="label">Total Amount Paid:</span> 
        <span class="value"><strong>₹ {{ (totalAmounts / 100) | number:'1.2-2' || '0.00' }}</strong></span>
      </p>
      <p><span class="label" style="color: red;">  Total Amount Due :</span> <span class="value"  style="color: red;"><strong>₹ {{ amountDue || '0' }}</strong></span></p>

    </div>
    

    <!-- <hr> -->
    <div class="cstmrphnueml">
      <div class="cstmrdtls">Customer Details:</div>
      <p><strong>Name:</strong> {{ customerName }}</p>
      <p><strong>Phone:</strong> {{ customerPhoneNumber }}</p>
      <p><strong>Email:</strong> {{ customerEmail }}</p>
    </div>

    <p class="note">
      This is a computer-generated document and does not require a signature.
    </p>
  </div>
</div>