<!-- <cdk-virtual-scroll-viewport  itemSize="50" style="width: 100%; "> -->
    
        <!-- <div class="scroll-container" (scroll)="onScroll()">         -->
        <!-- <div style="overflow-y: scroll;     height: calc(100vh - 60px);top: 4px;position: relative; touch-action: pan-y;"> -->
        <!-- <div class="page-layout blank " (scroll)="onScroll()"  style="height:1px;  overflow-y: scroll; -webkit-overflow-scrolling: touch;touch-action: pan-y;" >    -->
        <div class="page-layout blank fullwidth p-12" (scroll)="onScroll()"  style="height: 1px; overflow-y: scroll;    top: 1px;z-index: 99999;">
            <div class="spares-list" #ScrollTop> 
                
                <div class="spare-list-header" #targetDiv1>
                    <!-- On Demand Heading -->
                    <h2 class="spares-list-title" *ngIf="(selectedSparesList.type=='allSpares' && !searchKey)">On Demand Spares</h2>  
                    
                    <!-- Part Name -->
                    <h2 class="spares-list-title" *ngIf="selectedSparesList.type=='allSpares' && searchKey">{{searchKey}}</h2>   

                    <!-- Category Heading -->
                    <h2 class="spares-list-title" *ngIf="selectedSparesList && (selectedSparesList.type=='categorySpares')">{{selectedSparesList.name}}</h2>  

                    <!-- Brand Heading -->
                    <div class="brand-details" *ngIf="selectedSparesList && selectedSparesList.type=='brandSpares' && selectedSparesList.name">
                        <div class="img-sec" *ngIf="img">
                            <img [src]="img" [alt]="selectedSparesList.name" />
                        </div>
                        <div class="info-sec" >
                            <span>Welcome to </span>
                            <span>{{selectedSparesList.name}}'s store</span>
                        </div>
                    </div>
                    
                    
                        
                </div> 
                
                 <fuse-card *ngIf="bannerImages?.length > 0">
                     <div *ngIf="bannerImages?.length" class="banner-section">
                    <div class="image-carousel"  (mouseleave)="startAutoScroll()" >
                        <button *ngIf="bannerImages && bannerImages.length > 1" class="prev" (click)="scrollLeft()">&#10094;</button>
                        <div fuseScrollbar [fuseScrollbarOptions]="{ suppressScrollX: true}">
                            <div class="carousel-track" [style.transform]="'translateX(' + (-currentIndex * 100) + '%)'">
                                <img *ngFor="let image of bannerImages" [src]="image" alt="Banner Image" />
                            </div>
                            <div *ngIf="bannerImages && bannerImages.length > 1" class="carousel-indicators">
                                <span *ngFor="let image of bannerImages.slice(0, bannerImages.length); let i = index"
                                      [class.active]="i === currentIndex"></span>
                            </div>
                        </div>
                        <button *ngIf="bannerImages && bannerImages.length > 1" class="next" (click)="scrollRight()">&#10095;</button>
                    </div>
                </div>
                <div class="image-carousel" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()"></div>
                </fuse-card> 
               
                

                <div *ngIf="makeImage && model && year && variant" class="vehicle-details-sec">
                    <div style="width: 70%">
                        <div class="make-sec">
                            <img class="img-fluid make-image" style="margin-top: 6px;" src="{{makeImage}}" alt="make" />
                        </div>
                        <div class="model-sec" style="width:80%;line-height: 2.4px;">
                            <p style="font-weight:bold">{{model}},{{selectedFuel}}</p>
                            <p>{{variant}},[{{ year}}]</p>
                            <p>{{registrationNumber}}</p>
                        </div>
                        <div >
                          
                        </div>
                        <div >
                           
                        </div>
                    </div>
                    <div class="change-vehicle-sec">
                        <button mat-stroked-button (click)="changeVehicle()">Change</button>                 
                    </div>
                                
                </div>

                <!-- Search By Category or Brand Common Heading -->
                <div *ngIf="(((vehicleType=='universal' && !make) || selectedSparesList?.type=='categorySpares' || selectedSparesList?.type=='brandSpares' || (selectedSparesList?.type=='allSpares' && searchKey))&& !variant)" class="vehicle-details-sec">
                                    
                    <div class="make-sec">
                        <mat-icon style="font-size: 35px;"> directions_car</mat-icon>
                    </div>
                    <div *ngIf="(selectedSparesList?.type!='categorySpares' && selectedSparesList?.type!='brandSpares' && selectedSparesList?.type!='allSpares')" class="model-sec universal-sec">Universal</div>
                    <div *ngIf="(selectedSparesList?.type=='categorySpares' || selectedSparesList?.type=='brandSpares' || (selectedSparesList?.type=='allSpares' && searchKey))" class="model-sec universal-sec">
                        <p class="title">Select your car first</p>
                        <p class="sub-title">For a more refined result</p>
                       
                    </div>
                    <div class="change-vehicle-sec">
                        <button mat-stroked-button (click)="changeVehicle()">Change</button>                 
                    </div>
                                
                </div>

                <!-- Search -->
                <!-- <form class="searchSpares-form">        
                    <input type="text" class="search-bar" placeholder="Search for spare item" [(ngModel)]='searchText' name="searchText" autocomplete="off" (keyup)="searchFilterType()">
                    <mat-icon *ngIf="openSearch" style="float: right; margin-top: 10px; z-index: 9999999; position: absolute; margin-left: -35px;">search</mat-icon>
                    <mat-icon *ngIf="clearSearch" style="float: right; margin-top: 10px; z-index: 9999999; position: absolute; margin-left: -35px; cursor: pointer;" (click)="clearSearchFilter()">close</mat-icon>
                </form>-->
                
                <div class="filter-btn target-div" #targetDiv [ngClass]="{'fixed': isFixed1}" *ngIf="isMobile" (click)="mobileShowFilters()"  >
                    <span>Filters</span>
                    <mat-icon>filter_list</mat-icon>
                  </div>

                <!-- Spares Added List -->
                <div class="spares-added"> 
                    <div class="col-xs-12 col-md-3">
                        <div class="cat-list-body" >
                            
                            <div  class="tab-left" [ngClass]="isMobile ? isFilterShow:''"  
                            #targetLeftMenu
                           >   
                                <!-- <div class="condition-render-verticalFilters condition-render-boundary-top" style="top: 0px; bottom: auto;"> -->
                                <!-- Filter -->
                                <div #sidebarEl>
                                    <div class="filter" #sidebarContentEl >
                                        <mat-list >                                
                                            <mat-list-item style="font-weight: bold;">
                                                <span style="float: left; width: 95%;">Filters</span>                                   
                                                <mat-icon *ngIf="isMobile" style="float: left; width: 5%; cursor: pointer;" (click)="closeFilter()">close</mat-icon>
                                            </mat-list-item>  
                                            <mat-divider></mat-divider>
                                            <div *ngIf="hasAppliedFilters()" class="applied-filters">
                                                <span>
                                                    <h4>Applied Filters</h4>
                                                    <button (click)="clearFilters()" class="clear-filters-btn">Clear Filters</button>
                                                </span>
                                                <div *ngFor="let appliedFilter of getAppliedFilters()" class="apply">
                                                    <span>{{ appliedFilter.category | titlecase }}: {{ appliedFilter.option.label }}</span>
                                                    <mat-icon (click)="removeFilter(appliedFilter.category, appliedFilter.option)" class="apply1">cancel</mat-icon>

                                                </div>
                                                <mat-divider></mat-divider>
                                            </div>  
                                                            
                                                    <label class="filter-checkbox-assured" >
                                                        <div class="imgassured">
                                                            <img src="../../../../assets/images/assured/assured.png" alt="assured" class="assuredImage" >
                                                        </div>
                                                    <mat-checkbox 
                                                        type="checkbox" 
                                                        style="height: 20px; width: 10%; "
                                                        [checked]="isAssured"
                                                        (change)="onAssuredCheckboxChange($event)"
                                                        >
                                                    </mat-checkbox>
                                                    </label>
                                                    <div class="text-wrapper">
                                                        <p>Select <strong>Autoverse Assured Parts</strong> now!</p>
                                                        <p>Fast <strong>delivery</strong> from your nearby warehouse in just <strong>45 minutes</strong></p>
                                                    </div>
                                                    <mat-divider></mat-divider>
                                                    
                                                
                                                                    
                                            <div *ngIf="selectedSparesList?.type!='brandSpares'">
                                                <div class="filtersHead">
                                                    <mat-list-item>BRAND</mat-list-item>
                                                    <mat-divider></mat-divider>
                                                </div>
                                                <div class="searchBrand">
                                                    <span>
                                                        <i class="material-icons" style="font-size: 17px; padding: 17px 0px 0px;">search</i>
                                                        <input type="search" placeholder="Search Brand"  [(ngModel)]='searchBrand' name="searchBrand" (keyup)="searchFilterBrand()"> 
                                                    </span>
                                                </div>
                                                <div class="mat-expansion-panel-body">                                    
                                                    <div *ngFor="let brand of filters.brands" class="filter-option" [ngClass]="{'checked-label': brand.checked}">                   
                                                        <label class="filter-checkbox" >
                                                        <span>{{ brand.label }}</span>
                                                        <mat-checkbox 
                                                            type="checkbox" 
                                                            [(ngModel)]="brand.checked" 
                                                            (change)="toggleSelection('brands', brand)"
                                                            style="height: 20px; width: 20px;">
                                                        </mat-checkbox>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-list>  

                                        <div class="filtersHead">CATEGORIES</div> 
                                        <div class="side-menu-container bottom-content" >                               
                                            <mat-divider></mat-divider>
                                            <mat-list>
                                            <ng-container *ngFor="let category of filters.category; let i = index">                                    
                                                <mat-list-item 
                                                (click)="selectCategory(category)" 
                                                [class.active]="selectedCategory1 === category"
                                                [ngClass]="{'checked-label': category.checked || areAllSelected(category.children)}" 
                                                >
                                                    <span style="text-wrap: wrap; width: 80%;">{{ category.label }}</span>
                                                    <i class="material-icons" style="font-size: 12px;float: right;width: 20%;margin-top: 12px;text-align: right;">arrow_forward_ios</i>
                                                </mat-list-item>
                                            </ng-container>
                                            </mat-list>
                                        </div>
                                    
                                        <!-- <div class="mat-expansion-panel-body">
                                            <div *ngFor="let category of filters.category; let i = index">
                                                <div *ngIf="category.children; else singleCheckbox">
                                                    <mat-card>
                                                        <div (click)="selectCategory(i)" [ngClass]="{'selected-card': selectedCardIndex === i}" [matMenuTriggerFor]="CATEGORIES" class="filter-option" [ngClass]="{'checked-label': category.checked || areAllSelected(category.children)}" >
                                                            <span  > 
                                                                <span style="text-wrap: wrap; width: 80;">{{ category.label }}</span>
                                                                <i class="material-icons" style="font-size: 14px; float: right; width: 10%;">arrow_forward_ios</i>
                                                            </span>
                                                        </div>                                    

                                                        <mat-menu #CATEGORIES="matMenu" class="categoryChild">
                                                            <p mat-menu-item class="subCatText">
                                                                <label style="width: 100%;" class="subcategory" [ngClass]="{'checked-label': areAllSelected(category.children)}" >
                                                                    <span style="width: 90%; text-align: left; display: block; float: left; text-wrap: wrap;">Select All</span>
                                                                    <span style="width: 10%; display: block; float: left; text-align: right;">                                            
                                                                        <p><mat-checkbox 
                                                                        [checked]="areAllSelected(category.children)" 
                                                                        (change)="isSelectAllChecked(category.label, $event.checked)">
                                                                        </mat-checkbox></p>
                                                                    </span>
                                                                </label>
                                                            </p>
                                                            
                                                            <div *ngFor="let child of category.children">
                                                                <div *ngIf="child.label">
                                                                <p mat-menu-item class="subCatText">
                                                                    <label style="width: 100%;" class="subcategory" [ngClass]="{'checked-label': child.checked}">
                                                                    <span style="width: 90%; text-align: left; display: block; float: left; text-wrap: wrap;">{{ child.label }}</span>
                                                                    <span style="width: 10%; display: block; float: left; text-align: right;">
                                                                        <p><mat-checkbox 
                                                                        type="checkbox" 
                                                                        [(ngModel)]="child.checked" 
                                                                        (change)="toggleSelection('category', category.label, child)">
                                                                        </mat-checkbox></p>
                                                                    </span>
                                                                    </label>
                                                                </p>
                                                                </div>
                                                            </div>
                                                        </mat-menu>
                                                    </mat-card>
                                                </div>
                                            </div>
                                        </div>                           -->
                                    </div>
                                </div>
                                <!-- End Filter -->
                                <!-- </div>  -->
                            </div>
                            
                            <div class="tab-right">
                                <div class="subcategory-container" *ngIf="selectedCategory1">
                                    <div class="closefitr">
                                    <mat-icon (click)="closeFilters()">close</mat-icon>
                                </div>
                                    <mat-list style="margin-top: 5%;">
                                        <mat-list-item class="subcategory-item" [ngClass]="{'checked-label': areAllSelected(selectedCategory1.children)}">
                                            <div style="width: 100%">
                                                <label style="width: 100%;" class="subcategory" >
                                                    <span style="width: 90%; text-align: left; display: block; float: left; text-wrap: wrap;">Select All</span>
                                                    <span style="width: 10%; display: block; float: left; text-align: right;">                                            
                                                        <mat-checkbox 
                                                        [checked]="areAllSelected(selectedCategory1.children)" 
                                                        (change)="isSelectAllChecked(selectedCategory1.label, $event.checked)">
                                                        </mat-checkbox>
                                                    </span>
                                                </label>
                                            </div>
                                        </mat-list-item>
                                        <div *ngFor="let subcategory of selectedCategory1.children" style="width: 100%">
                                            <mat-list-item class="subcategory-item"  *ngIf="subcategory.label" [ngClass]="{'checked-label': subcategory.checked}">
                                                
                                                    
                                                        <label style="width: 100%;" class="subcategory" >
                                                        <span style="width: 90%; text-align: left; display: block; float: left; text-wrap: wrap;">{{ subcategory.label }}</span>
                                                        <span style="width: 10%; display: block; float: left; text-align: right;">
                                                            <p><mat-checkbox 
                                                            type="checkbox" 
                                                            [(ngModel)]="subcategory.checked" 
                                                            (change)="toggleSelection('category', selectedCategory1.label, subcategory)">
                                                            </mat-checkbox></p>
                                                        </span>
                                                        </label>
                                                    
                                                
                                            </mat-list-item>
                                        </div>
                                    </mat-list>
                                </div>

                                <ng-container *ngIf="sparesList && sparesList!=undefined">
                                    <div class="cat-list-items" *ngFor="let catList of sparesList">
                                        <div *ngFor="let list of catList|filter:searchText;  let i = index">
                                            <div class="items" *ngIf="((!list.code && list.spareStocks) || isReplacePopup==true)">
                                                <div class="mat-elevation-z8 mt-8 spares-list-instock"> 
                                                    <a [routerLink]="['/product-details/key/',list.key]">
                                                        <div class="spares-part"> 
                                                            <!-- <span *ngIf="calculateDiscount(list.spareStocks.mrp, list.spareStocks.sellingPrice) > 0" class="offerpercentage-top-right">
                                                                {{ calculateDiscount(list.spareStocks.mrp, list.spareStocks.sellingPrice) }} % OFF
                                                            </span> -->
                                                            <!-- {{list.itemSummary|json}} -->
                                                            <div>
                                                                <div *ngIf="list?.itemSummary?.orderedCount > 0" class="sku-tag-top-right ordered-count tag ordered-count">
                                                                    {{ list?.itemSummary?.orderedCount }} {{ list?.itemSummary?.orderedCount === 1 ? 'Unit' : 'Units' }} Sold
                                                                </div>
                                                            
                                                                <div *ngIf="list?.itemSummary?.previouslyBought" class="sku-tag-top-right previously-bought tag ordered-count">
                                                                Previously Bought
                                                                </div>

                                                                <!-- <span *ngIf="!list?.itemSummary?.previouslyBought && list?.itemSummary?.orderedCount !== null" class="sku-tag-top-right ordered-count">
                                                                {{ list?.itemSummary?.orderedCount }} units sold
                                                                </span> -->

                                                                <!-- <span *ngIf="!list?.itemSummary?.previouslyBought && list?.itemSummary?.orderedCount !== null && !list?.itemSummary?.previouslyBought && list?.itemSummary?.orderedCount > 0 " class="sku-tag-top-right ordered-count">
                                                                    {{ list?.itemSummary?.orderedCount }} {{ list?.itemSummary?.orderedCount === 1 ? 'Unit' : 'Units' }} Sold
                                                                </span>
                                                                
                                                                <span *ngIf="!list?.itemSummary?.previouslyBought && list?.itemSummary?.orderedCount === null && list?.itemSummary?.garageCount !== null && !list?.itemSummary?.previouslyBought && list?.itemSummary?.orderedCount === null && list?.itemSummary?.garageCount >0 "  class="sku-tag-top-right garage-count">
                                                                Bought by {{ list?.itemSummary?.garageCount }} Garages
                                                                </span> -->
                                                            </div>
                                                            
                                                            
                                                            
                                                            <!-- <div class="offer" *ngIf="list.spareStocks.discountAmount > 0">{{ calculateDiscount(list.spareStocks.mrp, list.spareStocks.sellingPrice) }} % OFF</div>  -->
                                                            <span *ngIf="calculateDiscount(list.spareStocks.mrp, list.spareStocks.sellingPrice) > 0" class="offerpercentage1">
                                                                {{ calculateDiscount(list.spareStocks.mrp, list.spareStocks.sellingPrice) }} % OFF
                                                            </span>
                                                            <img *ngIf="list.imageUrl" class="img-fluid part-image" src="{{list.imageUrl}}" alt="../../../../assets/images/logos/logo.png" onerror="this.src='../../../../assets/images/logos/logo.png'" />
                                                            <img *ngIf="!list.imageUrl" class="img-fluid part-image" src="{{defaultImg}}"  />
                                                        </div>
                                                        <div class="spare-details-sec">
                                                            <div class="spare-part-name">{{list.name}}</div>
                                                            <div class="spare-price" *ngIf="list.spareStocks.discountAmount>0; else onlyMRP">
                                                                <span class="selling-price" >&#8377;{{list.spareStocks.sellingPrice}}</span>
                                                                <span class="mrp-price">&#8377;{{list.spareStocks.mrp}}</span>
                                                                <!-- <span class="discount-price">(You save &#8377;{{list.spareStocks.discountAmount}})</span> -->
                                                                <div class="toolbar-separator"></div>
                                                                <!-- <span class="offerpercentage" *ngIf="list.spareStocks.discountAmount > 0">{{ calculateDiscount(list.spareStocks.mrp, list.spareStocks.sellingPrice) }} % OFF</span> -->
                                                                <span *ngIf="calculateDiscount(list.spareStocks.mrp, list.spareStocks.sellingPrice) > 0" class="offerpercentage">
                                                                    {{ calculateDiscount(list.spareStocks.mrp, list.spareStocks.sellingPrice) }} % OFF
                                                                </span>
                                                                
                                                            </div>
                                                            
                                                            <div class="ratings">                                                        
                                                                <!-- <i class="material-icons star-icon">star</i>5 Ratings -->
                                                                <span><i class="material-icons star-icon">star</i>
                                                                <span style="top: -3px;position: relative;">5 Ratings</span>
                                                                </span>
                                                                <span *ngIf="list.plannedSku===1" class="assuredIcon">
                                                                    <img *ngIf="list.plannedSku===1" src="../../../../assets/images/assured/assured.png" alt="assured" class="assuredimg">
                                                                </span>
                                                            </div>

                                                            <div class="partNumber-img">
                                                                <img src="../../../../assets/images/spares-list-images/unnamed (1).png" alt="PN" style="width: 18px;
                                                                height: 18px;">
                                                                <div class="spare-brand part-number">{{list.partNumber}}</div>
                                                            </div>

                                                            <div class="brand-name-img">
                                                                <img src="../../../../assets/images/spares-list-images/unnamed.png" alt="BR" style="width: 18pxpx;
                                                                height: 18px;">
                                                                <div class="spare-brand brand-name">{{list.brand.name}}</div>
                                                            </div>

                                                            <div class="delivery-img">
                                                                <img src="../../../../assets/images/spares-list-images/unnamed (2).png" alt="T" style="width: 18px;
                                                                height: 18px; ">
                                                                <div class="delivery" style="color: black; margin-left: 10px;"><span >{{ list.deliveryTime.replace('Delivered in ',' ' )}}</span></div>
                                                            </div>



                                                            <ng-template #onlyMRP>
                                                                <div class="spare-price-mrp">
                                                                    &#8377;{{list.spareStocks.mrp}}
                                                                </div>
                                                            </ng-template>
                                                        
                                                        </div>
                                                        
                                                    </a>
                            
                            
                                                    <!-- <div class="spare-add-cart">
                                                        <button mat-raised-button (click)="addCart(list, 1)">
                                                            <mat-icon>control_point</mat-icon>
                                                            Add
                                                        </button> 
                                                    </div> -->
                                                    
                                                </div>   
                                                <div class="spare-add-cart" >
                                                    <button  *ngIf="cartList.length<=0" (click)="addCart(list, 1)" class="add-control">
                                                        <mat-icon style="top: 3px; position: relative;">control_point</mat-icon>
                                                        Add
                                                    </button> 
                                                    <button *ngIf="cartList.length>0 && !cartList[0][list.key]"  (click)="addCart(list, 1)" class="add-control">
                                                        <mat-icon style="top: 3px; position: relative;">control_point</mat-icon>
                                                        Add
                                                    </button> 
                                                   
                                                  
                                                    <div class="addtocartcompatiblecar1" *ngIf="cartList[0] && cartList[0][list.key]" >
                                                        <div class="spare-add-cart1">
                                                            <div class="btn-group" *ngIf="list.key==cartList[0][list.key].key && cartList[0][list.key].cart_qty>0" >
                                                                <button class="button1" mat-raised-button (click)="removeItem(cartList[0][list.key].cart_qty, list.key)" style="min-width: 8px;">
                                                                    <mat-icon class="sidebuttons1">remove</mat-icon>
                                                                </button>
                                                                <input type="text" [(ngModel)]="cartList[0][list.key].cart_qty" readonly style="height:38px;;text-align: center; width: 40%;">
                                                                <button  class="button1" mat-raised-button (click)="addItem(cartList[0][list.key].cart_qty, list.key)" style="min-width: 8px;">
                                                                    <mat-icon class="sidebutton1">add</mat-icon>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                                                    
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Spares Added List -->

            </div>
            
            <div class="shipping-charges-sec" *ngIf="isCartCount && isShippingCharges" (click)="shippingChargesPopup()" >
                Add services worth Rs.2000 to get FREE delivery !
            </div>
            <div class="cart-sec" *ngIf="isCartCount && isMobile">
                <div class="cartsecscount">
                    <!-- <div class="cart-count">{{cartCount}} items</div> -->
                    <div class="grand-total">{{cartCount}} {{cartCount > 1 ? 'items' : 'item'}}</div>
                    <div class="grand-total">&#8377;{{cartGrandTotal | number:'1.2-2'}}</div>
                </div>
                <button class="view-cart-btn" (click)="goToCart()">View Cart</button>
            </div>
        </div>
    <!-- </div> -->
    <!-- </div>  -->
<!-- </cdk-virtual-scroll-viewport> -->

