import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspInvoiceDetailsService {

  constructor(
    private http: HttpClient,

  ) { }
  
  invoiceDetails(invoiceId,jobcardId){
    let paramData = {  
      invoiceId: invoiceId,
      jobcardId:jobcardId,
  };
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/garageManagement/v1/getInvoice', paramData);
    }
  submitDataUpdate(payload: any){
    const paramData=payload;
    
    console.log('-----submitDataUpdate-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/garageManagement/v1/updateInvoice`, paramData);
  
  }
  confirmInvoice(payload: any){
    const paramData=payload;
    
    console.log('-----ConfirmStatus-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/garageManagement/v1/invoice/confirm`, paramData);
  
  }
}

