import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AspCustomerPaymentsListComponent } from './asp-customer-payments-list.component';
import { RouterModule, Routes } from '@angular/router';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'; // Import FormsModule
import { HttpClientModule } from '@angular/common/http';  // Make sure HttpClientModule is imported
import { MatProgressBarModule } from '@angular/material/progress-bar';


export function playerFactory() {
  return player;
}

const routes: Routes = [
  {
    path: 'customer-payments',
    component: AspCustomerPaymentsListComponent,
  },
];

@NgModule({
  declarations: [AspCustomerPaymentsListComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    MatIconModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MatProgressBarModule,
    LottieModule.forRoot({ player: playerFactory }) 
    
  ],
  exports: [AspCustomerPaymentsListComponent, RouterModule],
})
export class AspCustomerPaymentsListModule { }
