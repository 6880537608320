import { Injectable } from '@angular/core';
import { GlobalVariable } from 'app/main/globals';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { AspMyRequestService } from '../asp-my-request/asp-my-request.service'


@Injectable({
  providedIn: 'root'
})
export class MyJobCardsDetailsService {


  constructor(
    private http: HttpClient,
    // private aspMyRequestService: AspMyRequestService // Inject the aspMyRequestService

  ) { }


  getJobCardDetails(bookingId: number, garageKey: string, jobCardId: number) {
    const paramData = {
      bookingId,
      garageKey,
      jobCardId,
      source: "web"
    };
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/booking/v1/jobCard/detail', paramData);
  }
  // getMyRequest(spareUserKey: string, garageKey: string): Observable<any> {
  //   return this.aspMyRequestService.myRequest(spareUserKey, garageKey);
  // }

  getPreFilledBooking(registrationNumber: string, garageKey: string): Observable<any> {
    console.log('-----Fetching Pre-Filled Booking-----------');
    const requestBody = {
      registrationNumber: registrationNumber,
      garageKey: garageKey
    };
  
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/booking/v1/preFilledBooking`, requestBody);
  }
  updateStatus(payload: any){
    const paramData=payload;
  
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/garage/jobcardListStatus`, paramData);
  }
  submitData1(payload: any) {
    const paramData=payload;
    console.log("paramData",JSON.stringify(paramData))
    return this.http.post(`${GlobalVariable. BASE_API_URL_SPARES}/garageManagement/v1/createInvoice`, paramData);
}
submitDataUpdate(payload: any){
  const paramData=payload;
  console.log('-----submitDataUpdate-----------');    
  return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/garage/customer/quote/update`, paramData);

}
}