import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AspInvoiceListComponent } from './asp-invoice-list.component';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'; // Import FormsModule
import { HttpClientModule } from '@angular/common/http';  // Make sure HttpClientModule is imported
import { MatProgressBarModule } from '@angular/material/progress-bar';

const routes: Routes = [
  {
      path: 'invoice-list',
      component: AspInvoiceListComponent,
  },
];

@NgModule({
  declarations: [AspInvoiceListComponent],
  imports: [
    CommonModule,
    MatIconModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MatProgressBarModule,
    RouterModule.forChild(routes),
  ]
})
export class AspInvoiceListModule { }
