import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspRecordPaymentService {
  getPaymentDetails: any;

  constructor(private http: HttpClient) { }

  getPaymentMethods(): Observable<any> {
    const garageKey = localStorage.getItem('garageKey');

    if (!garageKey) {
      console.error('Garage key not found in localStorage');
      return;
    }

    const requestData = {
      garageKey: garageKey
    };

    console.log('Fetching Payment Methods:', requestData);
    // return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/garageManagementPayment/v1/paymentMethod`, requestData);
    return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}/garageManagementPayment/v1/paymentMethod`);

  }
  recordPayment(paymentData: any): Observable<any> {
    const url = 'https://iapi.testpitstop.com/v1.0/pitstopspare/garageManagementPayment/v1/recordPayment';
    return this.http.post(url, paymentData, {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      }
    });
  }
  getReceiptDetail(paymentId: number,jobcardId: number): Observable<any> {
    const requestData = {
      paymentId: paymentId,
      jobcardId: jobcardId,
    };

    console.log('Fetching Receipt Detail:', requestData);

    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/garageManagementPayment/v1/receiptDetail`,
      requestData
      // { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    );
  }

}
