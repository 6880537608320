import { ChangeDetectorRef, Component, OnInit,ViewContainerRef,ViewChild,ComponentFactoryResolver, Input } from '@angular/core';
import { AspInvoiceDetailsService } from './asp-invoice-details.service';
import { ActivatedRoute,Router } from '@angular/router';
import { AspRequestDetailsService } from '../asp-request-details/asp-request-details.service';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { AspRecordPaymentComponent } from '../asp-record-payment/asp-record-payment.component';
import { Location } from '@angular/common';
import { AspLoadingService } from '../asp-loading/asp-loading.service'; 

import { AspCustomerQuoteService } from '../asp-customer-quote/asp-customer-quote.service';
interface FilterOption {
  label: string;
  value: string;
  checked: boolean;
  children?: FilterOption[];
}

interface Filters {
  jobCardStatus: FilterOption[];
}
@Component({
  selector: 'app-asp-invoice-details',
  templateUrl: './asp-invoice-details.component.html',
  styleUrls: ['./asp-invoice-details.component.scss']
})
export class AspInvoiceDetailsComponent implements OnInit {
  isBottomSheetOpen= false;
  data: any;
  details: any;
  items: any;
  openInvoice: boolean;
  selectAllQuote: boolean;
  customerQuotation: any = [];
  customerQuotation1: any=[];
  selectedItemsLabourQuote: any;
  selectedItemsQuote: any;
  selectedItemsCountQuote: any;
  selectedItemsapprovedQuote: any;
  selectedItemsCountapprovedQuote: number;
  selectedAlreadyApprovedQuoteLabourlength: number;
  selectedAlreadyApprovedQuote: any;
  selectedAlreadyApprovedQuotelength: number;
  totalSelectedPriceQuote: any;
  totalapproveSelectedPriceQuote: any;
  totalEditSelectedPriceQuote: any;
  selectedLabours: any;
  selectedLabourslength: any;
  totallabourSelectedPrice: any;
  totalSelectedApprovedriceQuote: any;
  customerQuotationPrice: any;
  selectedLaboursCount: any;
  selectedItemsCount: any;
  totalSelectedEditPriceQuote: any;
  selectedItemsLabour: any;
  isSidebarOpen: boolean;
  searchText: string;
  filteredItems: any[];
  selectedLabourCount: number;
  itemQuote: any;
  isPopupOpenEdit: boolean;
  isAddLabour: boolean;
  labourListData: any;
  filteredItems1: any[];
  isEditLabourSidebarOpen: boolean;
  selectedLabour: any;
  labourName: any;
  labourAmount: any;
  labourId: any;
  id: any;
  customerquoteId: any;
  EdititemsCount: any;
  searchWord: string;
  customerQuotationCount: any;
  totalSelectedPriceQuote1: any;
  totalMRP: any;
 laborDetails: FormGroup;
  unitPrice: FormGroup;
  variantKey: string;
  partNumber: any;
  showCancelConfirmation: boolean;
  isPopupOpen: boolean;
  showSkipConfirmation: boolean;
  selectedItems: any;
  resData: any;
  totalQuantity: any;
  totalAmounts: any;
  taxableAmount: any;
  isVisible: boolean;
  cgst: any;
  sgst: any;
  items123: any;
  successIcon: any = '../../../../assets/icons/icons8-ok.gif';
  container: any;
  sidebarOpen: boolean;
  @Input() resIds!: string;
  @Input() jobcardId!: string;
  @Input() QuoteId!: string;
  activeUrl: string;
  isJobCardDetails: boolean;
  labourQuotationPrice: number;
  quoteGrandTotal: number;
  itemQuotesEdit: any;
  confirmedInvoicePopup: boolean;
  date: any;
  fullLabourList: any;
  totalSelectedPriceQuote2: any;
  newItem: any;
  newItems: any;
  itemForm: FormGroup;
  originalData: any;
  gstOptions: number[] = [5, 12, 18, 28];

  constructor(
    private aspInvoiceDetailsService:AspInvoiceDetailsService,
    private route:ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private aspRequestDetailsService:AspRequestDetailsService,
    private toastr: ToastrService,
    private _formBuilder: FormBuilder,
    private router:Router,
    private resolver: ComponentFactoryResolver,
    private location: Location,    
    private aspLoadingService: AspLoadingService,
    // private aspRecordPaymentComponent: AspRecordPaymentComponent,
    private aspCustomerQuoteService:AspCustomerQuoteService
  ) { 
    this.itemForm = new FormGroup({
      itemName: new FormControl('', Validators.required),
      partNumber: new FormControl('', Validators.required),
      brand: new FormControl('', Validators.required),
      hsn: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{4,8}$')]), // HSN validation (4-8 digits)
      gst: new FormControl('', Validators.required), // Dropdown selection required
      unitPrice: new FormControl('', [Validators.required, Validators.min(0)]) // Unit price must be > 0
    });
  }

  statusList = [
    { label: 'Draft', value: '1', checked: false, statusclass: 'delivered-status'},
    { label: 'Confirmed', value: '2', checked: false, statusclass: 'confirmed-status'},
     ];
  filters: Filters = {
    jobCardStatus: this.statusList,
  }

  ngOnInit(): void {
    this.activeUrl = this.router.url;
    this.isJobCardDetails = this.activeUrl.includes('/my-job-cards-details');
  
    this.route.queryParams.subscribe(params => {
      this.resIds = this.resIds || params['id'];
      this.jobcardId = this.jobcardId || params['jobcardId'];
      this.QuoteId = this.QuoteId || params['QuoteId'];
      this.invoiceDetails();
      this.laborDetails = this._formBuilder.group({
        labourName: ['', [Validators.required, Validators.minLength(2)]], 
        labourAmount: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
      });
      this.unitPrice=this._formBuilder.group({
        unitprice:['',[Validators.required, Validators.pattern(/^[0-9]{10}$/)]]
      });
    });
  }

    @ViewChild('dynamicContainer', { read: ViewContainerRef }) recordpaymentcontainer!: ViewContainerRef;
  
  openSidebarQuote(){
    this.openInvoice=true;
    this.isBottomSheetOpen=false;
    this.getQuoteGrandTotal();
  }
  CloseSidebarQuote(){
    this.openInvoice=false;
  }
  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }
  getMakeImageUrl(make: string): string {
    if(make){
      const makeWithoutSpaces = make.toLowerCase().replace(/\s+/g, '');
      return `https://cube.getpitstop.com/assets/img/${makeWithoutSpaces}.png`;
    }else {
      return
    }
  }
  AddItems()
  {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.searchText = '';
    this.filteredItems = [];
  }
  resId(id){
    this.resIds=id;
  }
  openMenu() {
    this.isBottomSheetOpen = true;
  }
  closeMenu(): void {
    this.isBottomSheetOpen = false;
  }
    shareForApproval(): void {
      let garageName = localStorage.getItem('spareSelectedGarageName');
      const message = `Hello *${this.details.customerName}*,\n\n` +
      `We hope you're doing well! 😊\n\n` +
      `Your invoice for Registration Number: *${this.details?.registrationNumber}* is ready.\n\n` +
      // `You can view the invoice here:\n${this.invoiceImageUrl}\n\n` +
      // `To make the payment, please use the link below:\n${this.paymentLink}\n\n` +
      `Thank you for choosing *${garageName}*! We appreciate your business. If you have any questions, feel free to reach out.\n\n` +
      `Best regards,\n*${garageName}* Team 🙏`;
  
      window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`, '_blank');
  
      this.closeMenu();
    }
  
    async downloadPDF() {
      const html2pdfModule = await import('html2pdf.js');
      const html2pdf = html2pdfModule.default || html2pdfModule; // Ensure correct import
    
      const element = document.getElementById('invoiceContent');
    
      if (!element) {
        console.error('Element not found!');
        return;
      }
    
      const options = {
        margin: [5, 5, 5, 5], // Small margin to prevent shifting
        filename: 'invoice.pdf',
        image: { type: 'jpeg', quality: 1.0 },
        html2canvas: {
          scale: 2, 
          useCORS: true, 
          logging: false,
          scrollX: 0,
          scrollY: 0,
          windowWidth: document.documentElement.scrollWidth, // Ensures correct width
          windowHeight: document.documentElement.scrollHeight, // Ensures full content
        },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      };
    
      // Apply styles to prevent alignment issues
      // element.style.width = '800px'; // Adjust width to match the original design
      // element.style.padding = '20px'; // Ensure proper spacing
      // element.style.boxSizing = 'border-box'; // Prevent overflow
      element.style.top='20px'
      
      // Delay execution to ensure rendering is completed
      await new Promise((resolve) => setTimeout(resolve, 100));
    
      html2pdf().from(element).set(options).save();
    }
invoiceDetails(){
  this.aspLoadingService.show();
  this.aspInvoiceDetailsService.invoiceDetails(this.resIds, this.jobcardId).subscribe(
    result => {
      this.aspLoadingService.hide();
      if (result['code'] === 200){
        this.data=result['data'];
        this.details=result['data'].bookingJobCardResponse;
        this.items123=(result['data'].invoiceResponseList || []);
        this.customerQuotation=(result['data'].invoiceResponseList || []).filter(labour => labour.itemType == 'spare');
        this.EdititemsCount=(result['data'].invoiceResponseList || []).filter(labour => labour.itemType == 'spare').length;
        this.customerQuotation1=(result['data'].invoiceResponseList || []).filter(labour => labour.itemType == 'spare');
        
        this.selectedItemsLabourQuote = (result['data'].invoiceResponseList || []).filter(
          labour => labour.itemType == 'labour'
        );
        
        this.selectedItemsLabourQuote.forEach(labour => {
          labour.labourName = labour.itemName;
          labour.labourAmount = labour.sellingPrice;
        });
        
      
        this.selectedItemsLabour=(result['data'].invoiceResponseList || []).filter(labour => labour.itemType == 'labour');
        this.totalQuantity = this.data.invoiceResponseList.reduce((sum, item) => sum + Number(item.quantity), 0);
        this.labourList();
      }
    });  
}
openSkipConfirmation(){
  this.showSkipConfirmation = true;
}
updateSelectedItemsQuote(): void {
  this.selectedItemsQuote = this.customerQuotation.filter(quote => quote.selected)
  this.selectedItemsCountQuote = this.customerQuotation.filter(quote => quote.selected).length;
  this.selectedItemsapprovedQuote = this.customerQuotation1.filter(quote => quote.selected);
  this.selectedItemsapprovedQuote = []; // Set a default empty array to prevent errors
  this.selectedItemsCountapprovedQuote = (this.customerQuotation1.filter(quote => quote.selected).length | 0)+(this.selectedItemsLabourQuote.filter(quote => quote.selected).length | 0);
  this.selectedAlreadyApprovedQuoteLabourlength=this.selectedItemsLabour.filter(quote => quote.selected).length | 0
  this.selectedAlreadyApprovedQuotelength=(this.customerQuotation1.filter(quote => quote.selected).length | 0)+(this.selectedItemsLabour.filter(quote => quote.selected).length | 0);
  this.selectedAlreadyApprovedQuote=(this.customerQuotation1.filter(quote => quote.selected).reduce((total, quote) => total+(quote.sellingPrice *quote.quantity), 0))+(this.selectedItemsLabour.filter(quote => quote.selected).reduce((total, quote) => total+(quote.labourAmount *quote.quantity),0))
  // this.totalSelectedPrice = this.totalSelectedPrice + ((bidItem.mrp - (bidItem.mrp* bidItem.sellingDiscount /100))*bidItem.itemQuantity);
  this.totalSelectedPriceQuote = this.customerQuotation.filter(quote => quote.selected).reduce((total, quote) => total+(quote.mrp *quote.itemQuantity), 0);
  this.totalapproveSelectedPriceQuote=this.customerQuotation.filter(quote => quote.selected).reduce((total, quote) => total+(quote.sellingPrice *quote.quantity), 0);
  this.totalEditSelectedPriceQuote=this.customerQuotation.filter(quote => quote.selected).reduce((total, quote) => total+(quote.sellingPrice *quote.quantity), 0);

}
sidebarToggleSelectAllQuote() {
 
  
  this.selectAllQuote = !this.selectAllQuote;

  // Ensure customerQuotation is defined before looping
  if (Array.isArray(this.customerQuotation)) {
    this.customerQuotation.forEach(quote => {
      quote.selected = this.selectAllQuote;
    });
  } else {
    console.log('error select all parts');   
   }
  // if (Array.isArray(this.customerQuotation1))
  if (Array.isArray(this.customerQuotation1)) {
    this.customerQuotation1.forEach(quote => {
      quote.selected = this.selectAllQuote;
    });
  } else {
    console.log('error select all parts');   
   }
  // Ensure selectedItemsLabourQuote is defined before looping
  if (Array.isArray(this.selectedItemsLabourQuote)) {
    this.selectedItemsLabourQuote.forEach(labour => {
      labour.selected = this.selectAllQuote;
    });
  } else {
    console.log('error select all labour');
  }
  
  if (Array.isArray(this.selectedItemsLabour)) {
    this.selectedItemsLabour.forEach(labour => {
      labour.selected = this.selectAllQuote;
    });
  } else {
    console.log('error select all labour');
  }


  // this.selectedLaboursCount = this.selectedItemsLabourQuote.filter(labour => labour.selected).length;

  // Call other functions
  // this.labourList();
  this.updateSelectedItemsQuote();
  this.updateSelectedLabourItems();

  // Force Angular to detect changes
  this.cdr.detectChanges();
}


  updateSelectedLabourItems(): void {
    // Filter selected labour items
    this.selectedLabours = this.selectedItemsLabourQuote.filter(labour => labour.selected);
    this.selectedLabourslength = this.selectedLabours.length;
  
    
    // Ensure the total price is calculated correctly
    this.totallabourSelectedPrice = this.selectedLabours.reduce((sum, labour) => {
      return sum + ((labour.labourAmount * labour.quantity)|| 0);
    }, 0);
  
    // Ensure total approved price is updated correctly
    this.totalSelectedApprovedriceQuote = (this.totallabourSelectedPrice || 0) + (this.totalapproveSelectedPriceQuote || 0);
    this.customerQuotationPrice = this.totalSelectedApprovedriceQuote;
  
    // Update counts
    this.selectedLaboursCount = this.selectedLabours.length || 0;
    this.selectedItemsCount = this.selectedLabourslength + (this.selectedItemsCountQuote || 0);
    console.log("total items----",this.totallabourSelectedPrice);
    console.log("labourPrice -------",this.totalEditSelectedPriceQuote);
    // this.totalEditItemsPrice=(this.totallabourSelectedPrice || 0)+(this.totalEditSelectedPriceQuote || 0);
    console.log("this.totalEditSelectedPriceQuote--",this.totalEditSelectedPriceQuote);
    this.totalSelectedEditPriceQuote = (this.totallabourSelectedPrice || 0) + (this.totalEditSelectedPriceQuote || 0);
  
  
    // Ensure UI reflects the changes
    this.cdr.detectChanges();
  }
  sidebarToggleItemSelectionQuote(){
    this.toggleItemSelectionQuote();

  }
  toggleItemSelectionQuote(): void {
    if (Array.isArray(this.customerQuotation)) {
      this.selectAllQuote = this.customerQuotation.every(quote => quote.selected);
    } else {
      this.selectAllQuote = false;
    }
    //  // if (Array.isArray(this.customerQuotation1))
    //  if (Array.isArray(this.customerQuotation1)) {alert("items")
    //   this.customerQuotation1.forEach(quote => {
    //     quote.selected = this.selectAllQuote;
    //   });
    // } else {
    //   this.selectAllQuote = false;  
    //  }
  
    if (Array.isArray(this.selectedItemsLabourQuote)) {
      this.selectedItemsLabourQuote.forEach(labour => {
        labour.selected = labour.selected ? true : false;
      });
  
      this.selectedLabourCount = this.selectedItemsLabourQuote.filter(labour => labour.selected).length;
    }
     else {
      this.selectedLabourCount = 0;
    }

    
  
    // Ensure selectedItemsLabourQuote is updated
    this.updateSelectedItemsQuote();
    this.updateSelectedLabourItems();
  
    // Force UI update
    this.cdr.detectChanges();
  }
   
  openEditDialog(itemQuotes){
    console.log("itemQuotes",itemQuotes)
    this.itemQuote = [];
    this.itemQuote=itemQuotes;
    this.itemQuote['discountedPrice'] = this.calculateDiscountedPrice(itemQuotes.mrp, itemQuotes.discount);
    this.isPopupOpenEdit = true;
    this.selectedItemsCountQuote = 0;
    if (itemQuotes) {
      this.itemQuotesEdit = { ...itemQuotes };
     }
    // this.customerQuotation.forEach(quote => {
    //   quote.selected = false;
    // });
    // this.customerQuotation.forEach(quote => quote.selected = this.selectAllQuote=false);
  }
  calculateDiscountedPrice(mrp: number, discount: number): number {
    return mrp - (mrp * discount / 100);
  }
  closePopupEdit(): void {
    this.isPopupOpenEdit = false;
  }
  save(): void {
   const index = this.customerQuotation.findIndex(i => i.partNumber === this.itemQuote['partNumber']);

    // If the item exists, update it
    if (index !== -1 ) {
      this.itemQuotesEdit=this.up.unitprice.value
        // Update the item in the list with the new unit price and recalculate total amount
        this.customerQuotation[index].mrp =Number(this.up.unitprice.value);
        this.customerQuotation[index].sellingPrice =Number(this.up.unitprice.value);
        this.customerQuotation[index].totalAmount = this.up.unitprice.value * this.itemQuote['quantity'];
        }
          // Recalculate the total quotation price
    this.customerQuotationPrice = this.calculateTotalQuotationPrice();
    // Close the popup after saving
    this.closePopupEdit();
    this.updateSelectedItemsQuote();
    this.updateSelectedLabourItems();
    this.getQuoteGrandTotal();
  }
calculateTotalQuotationPrice(): number {
  // Recalculate total price by summing up all items' total amounts
  return this.customerQuotation.reduce((sum, item) => sum + (item.mrp * item.itemQuantity), 0);
}
decrementQuantity(quote: any): void {
  if (quote.quantity > 1) { 
    quote.quantity--;
    this.customerQuotationPrice = this.calculateTotalQuotationPrice();
    this.updateSelectedItemsQuote();
    this.updateSelectedLabourItems();
  }
}
incrementQuantity(quote: any): void {
  quote.quantity++;
  this.customerQuotationPrice = this.calculateTotalQuotationPrice();
  this.updateSelectedItemsQuote();
  this.getQuoteGrandTotal();
  this.updateSelectedLabourItems();

}
incrementjobcardQuantity(quote:any):void {
  quote.itemQuantity++;
  this.customerQuotationPrice = this.calculateTotalQuotationPrice();
  this.getQuoteGrandTotal();
  this.updateSelectedItemsQuote();
  this.updateSelectedLabourItems();
}
decrementjobcardQuantity(quote:any): void{
  if (quote.itemQuantity > 1) { 
    quote.itemQuantity--;
    this.customerQuotationPrice = this.calculateTotalQuotationPrice();
    this.getQuoteGrandTotal();
    this.updateSelectedItemsQuote();
    this.updateSelectedLabourItems();
  }
}
toggleSelectItem(): void {
  this.selectedItemsLabourQuote = this.labourListData.filter(item => item.selected);
  this.totalSelectedPriceQuote2 = this.selectedItemsLabourQuote.reduce(
    (sum, item) => sum + (item.sellingPrice || 0),0);
  this.totalSelectedPriceQuote1 = this.selectedItemsLabourQuote.reduce(
    (sum, item) => sum + (item.labourAmount || 0),0);
  this.isAddLabour = false;
  this.getQuoteGrandTotal();
  this.updateSelectedItemsQuote();
  this.updateSelectedLabourItems();
}
addLabourSidebar(){
  this.isAddLabour = !this.isAddLabour; 
  this.labourList();
  
}
// addLabour() {
//   console.log(this.ld.labourAmount.value);
//   const updatedLabourName = this.ld.labourName.value;
//   const updatedLabourAmount = this.ld.labourAmount.value;
//   const garageKey = localStorage.getItem('garageKey');

//   this.aspRequestDetailsService.addLabourList(garageKey, updatedLabourName, updatedLabourAmount)
//     .subscribe((result) => {
//       const res = JSON.parse(JSON.stringify(result));
//       if (res.code === 200) {
//         console.log("addLabour--", res);
        
//         const newLabour = {
//           labourName: updatedLabourName,
//           labourAmount: updatedLabourAmount,
//           selected: true, // Ensure it's checked by default
//           quantity: 1,
//           id: res.data?.id 
//         };

//         // Add it to the displayed list immediately
//         this.labourListData.unshift(newLabour);
//         this.selectedItemsLabourQuote.unshift(newLabour);
        
//         // Ensure the selected state is preserved in the labour list
//         this.labourList();

//         this.updateSelectedItemsQuote();
//         this.updateSelectedLabourItems();
//         this.toastr.success("Labour Added Successfully");
//       }

//       this.closeLabourEditSidebar();
//     });
// }
addLabour() {
  console.log(this.ld.labourAmount.value);
  const updatedLabourName = this.ld.labourName.value;
  const updatedLabourAmount = this.ld.labourAmount.value;
  const garageKey = localStorage.getItem('garageKey');

  this.aspRequestDetailsService.addLabourList(garageKey, updatedLabourName, updatedLabourAmount)
    .subscribe((result) => {
      const res = JSON.parse(JSON.stringify(result));
      if (res.code === 200) {
        console.log("addLabour--", res);
        
        const newLabour = {
          labourId: res.data?.labourId,  // Ensure labourId is set
          labourName: updatedLabourName,
          labourAmount: updatedLabourAmount,
          id : res.data?.id,
          selected: true, // Ensure it's checked by default
          quantity: 1
        };

        // **Add to both lists**
        this.selectedItemsLabourQuote.unshift(newLabour);
        this.labourListData.unshift(newLabour);

        // Ensure selection consistency
        this.selectedItemsLabourQuote = [...this.selectedItemsLabourQuote];
        this.labourListData = [...this.labourListData];

        // **Force refresh labour list to sync selection**
        this.labourList();

        this.updateSelectedItemsQuote();
        this.updateSelectedLabourItems();
        this.toastr.success("Labour Added Successfully");
      }

      this.closeLabourEditSidebar();
    });
}

labourList() {  
  this.aspLoadingService.show();
  const garageKey = localStorage.getItem('garageKey');

  if (garageKey) {
      this.aspRequestDetailsService.displayLabourList(garageKey).subscribe(
          (result) => {            
              this.aspLoadingService.hide();
              const res = JSON.parse(JSON.stringify(result));
              if (res.code === 200) {
                  // console.log("labourres", res);
                  // console.log("this.selectedItemsLabourQuote labourlist----", this.selectedItemsLabourQuote);

                  if (!Array.isArray(this.selectedItemsLabourQuote)) {
                      this.selectedItemsLabourQuote = [];
                  }

                  // Ensure labours in selectedItemsLabourQuote remain selected in the list
                  this.fullLabourList = res.data.map((labour: any) => {
                      const existingItem = this.selectedItemsLabourQuote.find(
                          (selectedItem: any) => selectedItem.labourId === labour.labourId
                      );

                      return {
                          ...labour,
                          selected: !!existingItem, // Mark as selected if present in selectedItemsLabourQuote
                          quantity: existingItem?.quantity ?? 1, // Use existing quantity, otherwise default to 1
                      };
                  });

                  // Sorting: Move selected items to the top
                  this.fullLabourList.sort((a, b) => Number(b.selected) - Number(a.selected));

                  this.labourListData = [...this.fullLabourList]; // Copy sorted list to labourListData
                  // console.log("Updated labourListData:", this.labourListData);

                  this.updateSelectedLabourItems();
                  this.getQuoteGrandTotal();
                  this.updateSelectedItemsQuote();
              }
          },
          (error) => {            
            this.aspLoadingService.hide();
            console.error('Error fetching labour list:', error);
          }
      );
  } else {    
    this.aspLoadingService.hide();
    console.warn('Garage key not found in localStorage.');
  }
}
 
openLabourEditSidebar(item: any = null) {
  this.isEditLabourSidebarOpen = true;
  // this.isAddLabour = item ? false : true;
  this.isSidebarOpen = false;
  if (item) {
    this.selectedLabour = { ...item };
    this.labourName = this.selectedLabour.labourName;
    this.labourAmount = this.selectedLabour.labourAmount;
    this.labourId = this.selectedLabour.labourId;
    this.id = this.selectedLabour.id;
  } else {
    this.selectedLabour = { name: '', price: null };
  }
}
anyItemSelectedQuote() {
  return this.selectedItemsCount > 0;
}
markAsCompleteUpdate(){
 let itemData = [];
 this.customerQuotation1.forEach(approved => {
  itemData.push({"id": approved.itemId, "quoteItemStatus": 1});
 });
 let labourItems=[];
 this.selectedItemsLabour.forEach(approved => {
  labourItems.push({"labourName": approved.labourName, "quoteItemStatus": 1});
 });
  let param = {
    "jobcardId":this.jobcardId,
    "customerQuoteId": this.customerquoteId,
    "customerQuoteItems": itemData,
    "labourQuoteItems": labourItems
    
  }
  
  this.aspRequestDetailsService.markAsComplete(param).subscribe(response => {
    // this.resData = response['data'];
    console.error('response complete:',response );
    this.invoiceDetails();
    this.ngOnInit();
  });
}
toggleItemviewSelectionQuote(item, type) {
  if (type === 'view') {
    if (Array.isArray(this.customerQuotation1)) {
      let allSelected = true;
      this.customerQuotation1.forEach(quote => {
        if (quote === item) {
          quote.selected = !quote.selected; // Toggle the selected item
        }
        if (!quote.selected) {
          allSelected = false; // Check if all are selected
        }
      });
      this.selectAllQuote = allSelected;
    } else {
    }
  } else if (type === 'view') {
    if (Array.isArray(this.selectedItemsLabour)) {
      let allSelected = true;
      this.selectedItemsLabour.forEach(labour => {
        if (labour === item) {
          labour.selected = !labour.selected; // Toggle the selected item
        }
        if (!labour.selected) {
          allSelected = false; // Check if all are selected
        }
      });
      this.selectAllQuote = allSelected;
    } else {
    }
  }

  this.updateSelectedItemsQuote();
  this.updateSelectedLabourItems();
}
addItemToQuotation(item: any) {
  this.customerQuotationCount=this.customerQuotation.length;

  // Find an existing item with the same partNumber
  const existingItem = this.customerQuotation.find(quote => quote.partNumber === item.partNumber);

  if (existingItem) {
    // If found, increase the quantity
    existingItem.quantity += 1;
  } else {
    // Otherwise, add the new item to the list
    const newItem = {
      itemId: item.itemId,
      itemName: item.name,
      brand: item.brand,
      partNumber: item.partNumber,
      sellingPrice: item.mrp,
      mrp:item.mrp,
      quantity: 1,
      hsnCode:item.hsnCode,
      gstRate : item.gstRate,
      selected: true,
      edt: new Date(),
      itemType:"spare"
    };
    this.customerQuotation.push(newItem);
    this.customerQuotation.forEach(quote => quote.selected = this.selectAllQuote);

    
  }
  this.isSidebarOpen = false;
  this.clearSearch();
  this.updateSelectedItemsQuote();

}
onLabourSearch(): void {
  console.log('Search Triggered:', this.searchText);
  const query = this.searchText?.toLowerCase();

  if (query) {
      this.labourListData = this.fullLabourList.filter(item =>
          item.labourName?.toLowerCase().includes(query)
      );
  } else {
      // Reset to full list when search is cleared
      this.labourListData = [...this.fullLabourList];
  }

  console.log('Filtered Items:', this.labourListData);
}


clearLabourSearch() { 
this.searchText = '';
this.labourListData = [...this.fullLabourList]; // Restore full list
}


clearSearch() {
  this.searchWord = '';
  this.labourList();
  // this.filteredItems = [];
}
// clearSearch() {
//   this.searchWord = '';

//   this.filteredItems = [];
// }
onLabourSelectionChange(labour: any): void {
  if (!labour) {
    console.error('Labour object is undefined');
    return;
  }

  // Update the selected property
  labour.selected = labour.selected || false;
  const newItem = {
    itemId: labour.id,
    labourId : labour.labourId,
    itemName: labour.labourName,
    labourName : labour.labourName,
    garageKey: labour.garageKey,
    partNumber: labour.partNumber,
    sellingPrice: labour.labourAmount,
    labourAmount : labour.labourAmount,
    itmQty : labour.quantity,
    quantity : labour.quantity,
    mrp:labour.labourAmount,
    selected: true,
    edt: new Date(),
    itemType:"labour"
  };
  this.selectedItemsLabourQuote.push(newItem);
  console.log("saijkiran---",this.selectedItemsLabourQuote);
  // Sync the selected items list
  // this.selectedItemsLabourQuote = this.labourListData.filter(l => l.selected);
   this.selectedLabourCount = this.selectedItemsLabourQuote.every(labour => labour.selected).length;
   this.updateSelectedItemsQuote();
   this.updateSelectedLabourItems();
  
}
totalAmount(): number {
  return this.items.filter(item => item.selected).reduce((total, item) => total + item.price, 0);
}
totalAmountQuote(): number {
  return this.items.filter(quote => quote.selected).reduce((total, item) => total + item.price, 0);
}
toggleSelectAllQuote(): void {
  this.selectAllQuote = !this.selectAllQuote;
  this.customerQuotation.forEach(quote => quote.selected = this.selectAllQuote);
  this.updateSelectedItemsQuote();
}
calculateMRP(item: any): number {
  this.customerQuotation.forEach
  return (item.mrp - (item.mrp * item.sellingDiscount / 100)) * item.itemQuantity;
}
calculateTotalMRP(): void {
  this.totalMRP = this.items.reduce((total, item) => {
    return total + this.calculateMRP(item);
  }, 0);
}
get up() {
  return this.unitPrice.controls;
}
  
  get ld() {
    return this.laborDetails.controls;
  }
  closeLabourEditSidebar() {
    this.isEditLabourSidebarOpen = false;
      this.selectedLabour = { labourName: '', labourAmount: null };
    // this.isAddLabour=false;
    
  
  }
saveLabour(): void {
    console.log("Labour Name:", this.ld.labourName.value);
    console.log("Labour Amount:", this.ld.labourAmount.value);

    const updatedLabourName = this.ld.labourName.value;
    const updatedLabourAmount = this.ld.labourAmount.value;
    const garageKey = localStorage.getItem('garageKey');

    console.log("this.id-----", this.id);
    console.log("saikiran---- ", this.labourId);

    this.aspRequestDetailsService
        .editLabour(this.id, this.labourId, garageKey, updatedLabourName, updatedLabourAmount)
        .subscribe(
            (result) => {
                console.log('API Response:', result);
                const res = JSON.parse(JSON.stringify(result));

                if (res.code === 200) {
                    console.log('Labour edited successfully:', res);
                    let labourExists = false;
                    // ✅ Update `selectedItemsLabourQuote` immediately after editing
                    this.selectedItemsLabourQuote = this.selectedItemsLabourQuote.map(item => 
                        item.labourId === this.labourId
                            ? { 
                                ...item, 
                                id: this.id,
                                labourId: this.labourId,
                                labourName: updatedLabourName,
                                labourAmount: updatedLabourAmount,
                                selected: true
                              } 
                            : item
                    );
                                        // ✅ Ensure the edited labour is still selected
                                        if (!labourExists) {
                                          this.selectedItemsLabourQuote.push({
                                              id: this.id,
                                              labourId: this.labourId,
                                              labourName: updatedLabourName,
                                              labourAmount: updatedLabourAmount,
                                              selected: true
                                          });
                                      }
                  
                                      console.log("Updated selectedItemsLabourQuote:", this.selectedItemsLabourQuote);

                    // console.log("Updated selectedItemsLabourQuote:", this.selectedItemsLabourQuote);

                    this.toastr.success("Labour saved Successfully");

                    // ✅ Call `labourList()` to refresh UI and ensure everything is in sync
                    this.labourList();
                    this.updateSelectedItemsQuote(); 
                    // this.getQuoteGrandTotal(); 
                } else {
                    console.warn('Unexpected response code:', res.code);
                }
            },
            (error) => {
                console.error('Error editing labour:', error);
            }
        );

    this.closeLabourEditSidebar();
}

onSearch(item: any) {
  if (!this.searchWord.trim()) {
    return;
  }
  const garageKey=localStorage.getItem('garageKey');
  this.variantKey=localStorage.getItem('VariantKey');

  this.aspRequestDetailsService
    .searchItems(garageKey, this.searchWord, this.variantKey)
    .subscribe(
      (result) => {
        const res = JSON.parse(JSON.stringify(result));
        
        if (res.code === 200) {
          this.filteredItems = res.data;
          this.partNumber= res.data.map((item: any) => item.partNumber);
        } else {
          console.error('Search failed with code:', res.code);
        }
      },
      (error) => {
        console.error('Error during search:', error);
      }
    );
}
closeCancelPopup(): void {
  this.showCancelConfirmation = false;
}
openPopup(): void {
  this.showSkipConfirmation = false;
  this.isPopupOpen = true;
  this.isAddLabour = false;
  this.CloseSidebarQuote();
  this.submitDataUpdate(); 
}
closePopup(): void {
  this.isPopupOpen = false;
}
submitDataUpdate() {alert("submit")
  console.log("this.quoteid----",this.QuoteId)

  this.selectedItems = this.customerQuotation.filter(quote => quote.selected);
console.log("sai kiran----",this.selectedItems)
  const itemsData = this.selectedItems.map(item => ({
    id: item.id || 0,
    partName: item.itemName,
    partNumber: item.partNumber,
    sellingPrice: item.mrp,
    itmQty: item.quantity,
    mrp: item.mrp,
    hsnCode:item.hsnCode,
    gstRate : item.gstRate


  }));
  console.log("this.selectedLabours---",this.selectedLabours);

  // Prepare labourItems separately
  const labourItemsData = this.selectedLabours.map(labour => ({
    "labourId": labour.labourId,
    "labourName": labour.labourName || labour.itemName ,
    "labourAmount": labour.labourAmount || labour.sellingPrice,
    "itmQty": labour.quantity

 
  }));

  // Construct the final request payload
  const cleanData1 = {
    
    "invoiceId": this.resIds,
    "jobcardId": this.jobcardId,
    "garageKey":this.data.garageKey,
    "quoteId":this.QuoteId || 0,
    "gstNumber": '',
    "billingAddress": '',
    "shippingAddress": '',
    "customerQuoteItems": itemsData,
    "labourQuoteItems": labourItemsData,
     "registrationNumber": this.details?.registrationNumber,
  };
  // Call the API with updated data
  this.aspInvoiceDetailsService.submitDataUpdate(cleanData1).subscribe(response => {
    const res = JSON.parse(JSON.stringify(response));
    if (res.code === 200){
    this.resData = res['data'];
    }
  }, error => {
    console.error('Error submitting data:', error);
  });
  
}
anyItemSelected() {
  return this.selectedLabourslength > 0;
}
incrementLabourQuantity(quote: any): void {
  if (quote.quantity >= 1) {
    quote.quantity++;
  }

  // ✅ Recalculate labour price and update UI
  this.labourQuotationPrice = this.calculateTotalLabourPrice();
  this.updateSelectedItemsQuote();
  this.updateSelectedLabourItems();
  this.getQuoteGrandTotal();
  this.cdr.detectChanges();
}

decrementLabourQuantity(quote: any): void {
  if (quote.quantity > 1) { 
    quote.quantity--;
  }

  this.labourQuotationPrice = this.calculateTotalLabourPrice();
  this.updateSelectedItemsQuote();
  this.updateSelectedLabourItems();
  this.getQuoteGrandTotal();
  this.cdr.detectChanges();
}
getQuoteGrandTotal(){
  console.log("this.customerQuotation---",this.customerQuotation);
  let partsTotal = 0;
  if(this.customerQuotation){
    partsTotal = this.customerQuotation?.reduce((sum, item) => sum + (item.mrp * item.quantity),0);
  }
console.log("partsTotal------===----",partsTotal)
console.log("labour selected ----====----",this.selectedItemsLabourQuote)
  let labourTotal = 0;
  if(this.selectedItemsLabourQuote){
    labourTotal = this.selectedItemsLabourQuote?.reduce((sum, item) => sum + (Number(item.labourAmount) * item.quantity),0);
  }
  this.quoteGrandTotal = partsTotal + labourTotal;
  console.log("thiws.GrandTotal---=---=--",this.quoteGrandTotal);
}  


calculateTotalLabourPrice(): number {
  return this.selectedItemsLabourQuote.reduce(
    (sum, item) => sum + (Number(item.sellingPrice) * Number(item.quantity || 1)), 0
  );
}
labourRedirectSidebar()
{
  this.isAddLabour=false;
  this.selectedLabourCount=0;
}
closeSidebar1(){
    this.isPopupOpen = false;
    this.closeSidebar(true);
    // this.submitDataUpdate();
    this.invoiceDetails();
}
  closeSidebar(ishide: boolean) {
    this.isVisible = ishide;
  }
  closeAll(): void{
    this.isAddLabour = false;
    this.isEditLabourSidebarOpen = false;
  }  
  close(): void{
    this.openInvoice = false;
  }
  closeSkipConfirmation(): void {
    this.showSkipConfirmation = false;
  }
  // navigateToDetails() {
  //   this.router.navigate(['/my-job-cards-details', this.jobcardId, 0]);
  // }
  goBack(): void {
    this.location.back();
  }
  // ========record-payment================


  openRecordPaymentSidebar() {
    this.recordpaymentcontainer.clear();
  
    const factory = this.resolver.resolveComponentFactory(AspRecordPaymentComponent);
    const componentRef = this.recordpaymentcontainer.createComponent(factory);
  
    // Optional: Add a close method to remove the sidebar
    const componentInstance = componentRef.instance as AspRecordPaymentComponent;
    componentInstance.invoiceresponse(this.data);
    componentInstance.openSidebar(); 
    this.isBottomSheetOpen=false;
    // = () => {
    //   this.container.clear(); // Close sidebar when called
    // };
  }
  // closeSidebarnew(): void {
  //   this.sidebarOpen = false;
  // }
  confirmedInvoice(){
    const confirmData = {
      "invoiceId":  this.resIds,
      "confirmStatus": 2,
      "customerName": this.details.customerName,
      "customerPhoneNumber":this.details.customerPhoneNumber,
      "totalAmount": this.data.sumOfItemTotal,
      "garageKey": localStorage.getItem("garageKey")
    };
    this.aspInvoiceDetailsService.confirmInvoice(confirmData).subscribe(
      result => {
        if (result['code'] === 200){ 
          this.confirmedInvoicePopup=true;
          console.log("result",result);
          this.date=result['data'].invoiceConfirmDate;
        }
        else {
          this.toastr.error(result['message']);
        }
      }
    )
    this.isBottomSheetOpen=false;
  }
  closePopupConfirm(){
    this.confirmedInvoicePopup=false;
    this.invoiceDetails();
  }
  shareForPayment(): void {
    let garageName = localStorage.getItem('spareSelectedGarageName');
    const message = ` 
🚗 Payment Request  

Dear *${this.details?.customerName}*, 👋  

Your car service is complete, and the total amount due is ₹ *${this.data?.sumOfItemTotal}*.  

Please complete your payment by clicking the secure link below:  

🔗 *${this.data?.paymentLink}*  

Thank you for choosing us! 😊  

Regards,  
*${garageName}* Team 🙏
`;

    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`, '_blank');

    this.closeMenu();
  }

  createNewItems(){
    this.newItem=!this.newItem;
  }

  createItemSidebar(){
    this.newItem= false;
    this.newItems=!this.newItems;
    this.isSidebarOpen=false;
  }
  onSubmit() {
       if (this.itemForm.invalid) {
      Object.keys(this.itemForm.controls).forEach(field => {
        const control = this.itemForm.get(field);
        control?.markAsTouched({ onlySelf: true });
        control?.updateValueAndValidity(); // Ensure validation messages appear
      });
  
      this.scrollToFirstInvalidField();
      return;
    }
  
    console.log('Form submitted successfully', this.itemForm.value);
  
    if (this.itemForm.valid) {
      console.log('Form Submitted createNewItem', this.itemForm.value);
      const cleanData1 = {
        "garageKey": localStorage.getItem('garageKey'),
        "garageName": localStorage.getItem('spareSelectedGarageName'),
        "userName": localStorage.getItem('spareSelectedGarageName'),
        "userRole":localStorage.getItem('userType'),
        "partName": this.itemForm.value['itemName'],
        "partNumber":this.itemForm.value['partNumber'],
        "mrp": (this.itemForm.value.unitPrice / 100).toFixed(2),
        "gstRate": this.itemForm.value['gst'],
        "brandName":this.itemForm.value['brand'],
        "hsnCode": this.itemForm.value['hsn'],
        "make": this.details?.jobCard?.make,
        "model": this.details?.jobCard?.model,
        "variant":this.details?.jobCard?.variant,
        "rowKey": this.details?.jobCard?.rowKey,
        "variantKey": this.details?.jobCard?.variantKey
      };
    
      // Call the API with updated data
      this.aspCustomerQuoteService.createNewItem(cleanData1).subscribe(response => {
        const res = JSON.parse(JSON.stringify(response));
        console.log("res123456789",res);
        this.originalData=res.data;
        const transformedData = {
          ...this.originalData, 
          brand:this.originalData.brandName,
          itemName: this.originalData.partName, 
          itemId: undefined, 
          quantity: 1, 
          sellingPrice:this.originalData.mrp
        };
        this.customerQuotation.push(transformedData);
        console.log("this.customerQuotation",this.customerQuotation);
        // this.customerQuotationListCount = this.customerQuotation.length
        // console.log('Item added successfully:', this.customerQuotationListCount);
        this.customerQuotation.forEach(quote => quote.selected = this.selectAllQuote);  
  
  
        // this.customerQuotationCount=this.customerQuotation.length;
        this.isSidebarOpen = false;
        this.clearSearch();
        this.updateSelectedItemsQuote();
        this.getQuoteGrandTotal();
        
      }
    )
    }
    this.newItems=false;
  }
  scrollToFirstInvalidField() {
    const firstInvalidControl: HTMLElement = document.querySelector(
      ".ng-invalid.ng-touched"
    ) as HTMLElement;
  
    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({ behavior: "smooth", block: "center" });
      firstInvalidControl.focus(); // Optional: Focus on the field
    }
  }
}

