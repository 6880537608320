 <div class="overlay" [class.visible]="isSidebarOpen" (click)="closeSidebar()" ></div> 
 
<div class="history-sidebar" *ngIf="isSidebarOpen" >
    <div class="container">
        <div class="history-backwodkey" (click)="closeSidebar()">
            <mat-icon class="historybackbtn">keyboard_backspace</mat-icon>
            <div class="header">Service History</div>
            <mat-icon (click)="closeSidebar()"  style="margin-right: 10px;">close</mat-icon>
        </div>
        <div class="car-details">
            <div class="car-image">
                <img [src]="getMakeImageUrl(vehicleDetails.make)" alt="Car Make Image" class="model-icon">
            </div>
            <div>
                <div class="title">{{ vehicleDetails.make }}, {{ vehicleDetails.fuelType }}</div>
                <div class="info"> {{ vehicleDetails.model }} {{ vehicleDetails.variant }} <span *ngIf="vehicleDetails.year && vehicleDetails.year!='undefined'">[{{ vehicleDetails.year }}]</span></div>
                <div class="regnum">{{ vehicleDetails.registrationNumber }}</div>
                <div class="vinnum" *ngIf="vehicleDetails.vin && vehicleDetails.vin!='undefined'">{{ vehicleDetails.vin }}</div>
            </div>
        </div>

        <div class="main-card"> 
            <div class="card">
                <div *ngFor="let item of vehicleHistoryData" class="record-container">
                    <div  class="details" >
                        <div class="order-icon" >
                            <i class="top-material-icons">check_box</i>
                        </div>
                        
                        <div >
                            <div><span>Job Card ID:</span> {{ item.jobCardId }}</div>
                            <div><span>Name:</span> {{ item.name }}</div>
                            <div><span>Mobile Number:</span> {{ item.mobile }}</div>
                            <div style="display: flex;">
                            
                        </div>
                    </div>
                </div>
                <!-- <div style="    border-bottom: 1px dashed;   padding: 5px 0px 5px 0px;">Parts: </div> -->
                <div style="display: flex; justify-content: space-between;">
                    <div style="font-size: 12px;padding: 10px 10px 10px 0px;"><span style="padding: 10px 0px 0px 0px;">Placed On:</span> {{ item.createdAt | date:'dd MMM yyyy, h:mm a' }}</div>
                    <a class="view-btn" (click)="logAndRedirect(item.jobCardId, item.bookingId)" style="display: flex; align-items: center; color: #4caf50; cursor: pointer;">
                        <span class="job-card" style="font-size: 12px;">View Details</span>
                        <i class="material-icons" style="color: lightgreen;">arrow_right</i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
  
