import { ChangeDetectorRef, Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef,  } from '@angular/core';
import { AspCustomerQuoteService } from './asp-customer-quote.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { GlobalVariable } from 'app/main/globals';
import { AspJobcardQuotationComponent } from '../asp-jobcard-quotation/asp-jobcard-quotation.component';
import { MyJobCardsDetailsService } from '../my-job-cards-details/my-job-cards-details.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AspRequestDetailsService } from '../asp-request-details/asp-request-details.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { AspQuotationDetailsService } from 'app/main/customer/asp-quotation-details/asp-quotation-details.service';
import { MyJobCardsDetailsComponent } from '../my-job-cards-details/my-job-cards-details.component';
import { AspLoadingService } from '../asp-loading/asp-loading.service'; 

@Component({
  selector: 'app-asp-customer-quote',
  templateUrl: './asp-customer-quote.component.html',
  styleUrls: ['./asp-customer-quote.component.scss']
})
export class AspCustomerQuoteComponent implements OnInit {

  isVisible: boolean;
  isBottomSheetOpen = false;
  quoteShipping: any;
  resIds: any;
  data: any;
  email: string;
  phone: string;
  quoteId: any;
  resData: any;
  isPopupOpen = false;
  isApprovePopUpOpen=false;
  viewApprovePopUpOpen=false;
  selectedItems: any[];
  sidebarOpenQuote: boolean = false;
  requestDetails: any;
  customerQuotation: any = [];
  customerQuotationPrice: any = 0;
  item: any;
  regiNo: any;
  dataid: any;
  requestId: any;
  selectedItemsQuote: any[];
  selectedItemsCountQuote: any;
  totalSelectedPriceQuote: any;
  selectAllQuote:boolean = false;
  items: any;
  totalMRP: any;
  itemQuote: any = [];
  isPopupOpenEdit: boolean;
  successIcon: string;
  isMobile: boolean;
  isJobCardDetails: any;
  activeUrl: any;
  bookingId: any = 0;
  isEditLabourSidebarOpen: boolean = false;
  customerQuotation1: any;
  selectedItemsLabourQuote: any;
  // newly added properties
  type: string;
  loading: boolean;
  request: any;
  isSidebarOpen = false;
  searchText: string = '';
  filteredItems: any[] = [];
  searchWord: string = '';
  variantKey: string;
  partNumber: any;
  customerQuotationCount: any;
  selectedLaboursCount: any;
  labourListData: any[];
  filteredItems1: any[];
  selectedLabours: any[];
  selectedLabourslength: number;
  totallabourSelectedPrice: any;
  sparePartsTotal: number;
  selectedItemsCount: any;
  selectedLabourCount: number;
  isAddLabour = false;
  showSkipConfirmation = false;
  totalSelectedPriceQuote1: any;
   laborDetails: FormGroup;
  unitPrice: FormGroup;
  selectedLabour: any;
  labourName: any;
  labourAmount: any;
  labourId: any;
  id: any;
  showCancelConfirmation: boolean = false;
  totalSelectedPriceQuote2: any;
  totalapproveSelectedPriceQuote: any;
  totalSelectedLabourPriceQuote: any;
  totalSelectedApprovedriceQuote: any;
  selectitemsclick: any[];
  customerquoteId: any;
  totalApprovedItemsCount: any;
  itemsCount: any;
  editItemsCount: any;
  EdititemsCount: any;
  totalEditItemsCount: any;
  selectedItemsapprovedQuote: any;
  selectedItemsCountapprovedQuote: any;
  originalLabourQuotes: any[];
  totalEditSelectedPriceQuote: any;
  totalEditItemsPrice: any;
  totalSelectedEditPriceQuote: any;
  selectedItemsLabour: any;
  totalApprovingItemsCount: any;
  approvedItemsCount: any;
  selectedAlreadyApprovedQuote: any;
  selectedAlreadyApprovedQuotelength: any;
  selectedAlreadyApprovedQuoteLabourlength: number;
 @Input() QuoteId!: any;
@Input() jobcardId!:any;
  quoteItemStatus: any;
  selectAllLabourQuote: boolean;
  grageName: string;
  totallabourListSelectedPrice: any;
  labourQuotationPrice: number;
  estimatedTimeDeliveryValue: string = '';
  customerName: any;
  jobCardId: any;
  grandTotalApprovedAmount: any;
  grandLabourApprovedAmount: any;
  grandFinalApprovedTotalAmount: any;
  grandTotalApprovedViewAmount: any;
  grandLabourApprovedViewAmount: any;
  grandFinalApprovedViewTotalAmount: any;
  quoteGrandTotal: number;
  currentDate: Date = new Date();
  fullLabourList: any;
  finalDateTime: string | null = null;
  selectedTime: string | null = null;
  selectedDate: string;
  labourCount: number;
  labourCountNewList: any[];
  selectedLaboursNewCount: number;
  totallabourSelectedNewPrice: any;

  constructor(
    private aspCustomerQuoteService:AspCustomerQuoteService,
    private resolver: ComponentFactoryResolver,
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private myJobCardsDetailsService:MyJobCardsDetailsService,
    private aspRequestDetailsService: AspRequestDetailsService,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private aspQuotationDetailsService: AspQuotationDetailsService,
    private aspLoadingService: AspLoadingService,
  ) {
      this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); }

  openSidebarQuote(): void {
    this.clearOldData(); 
    this.getQuoteDetails();
    this.getRequestDetails();
    this.sidebarOpenQuote = true; 
    this.closeSidebar(true);
    this.closeMenu();
    this.submitDataUpdate();  
    
  }
      // @ViewChild('dynamicContainer1', { read: ViewContainerRef }) container1!: ViewContainerRef;
      // @ViewChild('dynamicContainer2', { read: ViewContainerRef }) container2: ViewContainerRef;  
      // approveQuote(type=''): void {
      //   // const factory = this.resolver.resolveComponentFactory(AspJobcardQuotationComponent);
      //   // const componentRef = this.container2.createComponent(factory);
      //   // const componentInstance = componentRef.instance as AspJobcardQuotationComponent;
      //   // // componentInstance.ngOnInit();
      //   // componentInstance.setActiveTabs('quotation',type,this.jobcardId,this.bookingId,this.quoteId);
      //   // this.closePopup();
      //   this.isEditLabourSidebarOpen = true;
      // }
  clearOldData(): void {
    this.customerQuotation = []; 
    this.sidebarOpenQuote = false;
    this.customerQuotationPrice=0;
    this.selectAllQuote=false;
    this.selectedItemsCountQuote=[];
}
  CloseSidebarQuote(): void {
    this.sidebarOpenQuote = false;    
    this.type = '';
    this.isVisible = true; 
  }
  viewPopUp(){
    
    this.viewApprovePopUpOpen=true;
    this.sidebarOpenQuote=false;
    this.currentDate = new Date();
    // Update every second (optional, if you want dynamic updates)
setInterval(() => {
this.currentDate = new Date();
}, 1000);
  
  }
  sendUpdateApproval(){
    this.isEditLabourSidebarOpen = true;
    
  }
  sendUpdate(): void {
    this.isEditLabourSidebarOpen = false;
    let garageName = localStorage.getItem('spareSelectedGarageName');

    // Filter completed and pending work
    let completedItems = this.customerQuotation1
        .filter(item => item.quoteItemStatus === 1)
        .map(item => `${item.itemName}`);
    let completedLabours = this.selectedItemsLabour
        .filter(labour => labour.quoteItemStatus === 1)
        .map(labour => `${labour.labourName}`);
    let pendingItems = this.customerQuotation1
        .filter(item => item.quoteItemStatus === 2)
        .map(item => `${item.itemName}`);
    let pendingLabours = this.selectedItemsLabour
        .filter(labour => labour.quoteItemStatus === 2)
        .map(labour => `${labour.labourName}`);

    let completedWorkSection = completedItems.length || completedLabours.length
        ? `✅*Completed Work*:\n${completedItems.concat(completedLabours).join('\n')}\n\n`
        : '';

    let pendingWorkSection = pendingItems.length || pendingLabours.length
        ? `🔄*Pending Work*:\n${pendingItems.concat(pendingLabours).join('\n')}\n\n`
        : '';

    // ✅ Build ETA message dynamically
    let etaMessage = '';
    if (this.selectedDate && this.selectedTime) {
        etaMessage = `🕒ETA for Delivery: Your car will be ready *${this.selectedDate}* at *${this.selectedTime}*\n\n`;
    } else if (this.selectedDate) {
        etaMessage = `🕒ETA for Delivery: Your car will be ready *${this.selectedDate}*\n\n`;
    } else if (this.selectedTime) {
        etaMessage = `🕒ETA for Delivery: Your car will be ready at *${this.selectedTime}*\n\n`;
    }

    const message = `📢 Car Service & Repair Update\n\n` +
        `Dear ${this.customerName},\n\n` +
        `We would like to update you about the progress of your service:\n\n` +
        `${completedWorkSection}` +
        `${pendingWorkSection}` +
        `${etaMessage}` + // ✅ Dynamically generated ETA
        `Thank you for choosing *${garageName}*! We appreciate your business. If you have any questions, feel free to reach out.\n\n` +
        `Best regards,\n*${garageName}* Team 🙏`;

    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`, '_blank');

    this.closeMenu();
}




  ngOnInit(): void {
    this.activeUrl = this.router.url;
    this.isJobCardDetails = this.activeUrl.includes('/my-job-cards-details');
    this.getQuoteDetails();
    this.email = localStorage.getItem('contactEmail');
    this.phone = localStorage.getItem('contactPhone');
    this.grageName = localStorage.getItem('spareSelectedGarageName');
    this.successIcon = '../../../../assets/icons/icons8-ok.gif';
          this.laborDetails = this._formBuilder.group({
            labourName: ['', [Validators.required, Validators.minLength(2)]], 
            labourAmount: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
            estimatedTimeDelivery: ['', [Validators.required]],
            estimatedTime: ['', Validators.required]
          });
          this.unitPrice=this._formBuilder.group({
            unitprice:['',[Validators.required, Validators.pattern(/^[0-9]{10}$/)]]
          });
         
            this.selectAllQuote = false;
 
  }
  
  closeSidebar(ishide: boolean) {
    this.isVisible = ishide;
    // this.loadOrderDetails();
    
    // this.getQuoteDetails();
    
  }
  opendetais(){
    // this.closeSidebar(true);
    this.loadOrderDetails();
    // this.closePopup();
    
  }
  openMenu() {
    this.isBottomSheetOpen = true;
    
  }
//   updateEstimatedTime(selectedDate: Date) {
//     if (selectedDate) {
//         this.estimatedTimeDeliveryValue = selectedDate.toISOString(); // Store in ISO format
        // console.log("Selected Estimated Time Delivery:", this.estimatedTimeDeliveryValue);
//     }
// }
// Combine Date & Time when submitting
// updateEstimatedTime(event: any) {
//   const dateObj = event instanceof Date ? event : this.laborDetails.value.estimatedTimeDelivery;
//   const time = this.laborDetails.value.estimatedTime;

//   if (!dateObj) {
//     console.warn("Date is missing!");
//     return;
//   }

//   // ✅ Extract only YYYY-MM-DD from Date object
//   const formattedDate = this.formatDate(dateObj);
//   console.log("Selected Date (Formatted):", formattedDate);

//   if (time) {
//     const [hours, minutes] = time.split(':');
//     const finalDateTime = new Date(dateObj);
//     finalDateTime.setHours(parseInt(hours, 10), parseInt(minutes, 10));

//     // ✅ Format final output as "YYYY-MM-DD HH:mm"
//     this.finalDateTime = `${formattedDate} ${hours}:${minutes}`;
//     console.log('Final Selected DateTime:', this.finalDateTime);
//   } else {
//     this.finalDateTime = `${formattedDate}`; // Store only date if time is missing
//   }
// }

// formatDate(date: Date): string {
//   const year = date.getFullYear();
//   const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure 2 digits
//   const day = String(date.getDate()).padStart(2, '0');

//   return `${year}-${month}-${day}`; // Format as YYYY-MM-DD
// }


// formatDateTime(date: Date): string {
//   const year = date.getFullYear();
//   const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure 2 digits
//   const day = String(date.getDate()).padStart(2, '0');
//   const hours = String(date.getHours()).padStart(2, '0');
//   const minutes = String(date.getMinutes()).padStart(2, '0');

//   return `${year}-${month}-${day} ${hours}:${minutes}`;
// }



updateEstimatedTime(event: any) {
  const dateObj = event instanceof Date ? event : this.laborDetails.value.estimatedTimeDelivery;
  const time = this.laborDetails.value.estimatedTime;

  if (!dateObj) {
    // console.warn("Date is missing!");
    return;
  }

  // ✅ Extract only YYYY-MM-DD from Date object
  this.selectedDate = this.formatDate(dateObj);
  // console.log("Selected Date:", this.selectedDate);

  if (time) {
    const [hours, minutes] = time.split(':');
    this.selectedTime = `${hours}:${minutes}`;
    // console.log("Selected Time:", this.selectedTime);

    // ✅ Combine date & time if both exist
    this.finalDateTime = `${this.selectedDate} ${this.selectedTime}`;
    // console.log('Final Selected DateTime:', this.finalDateTime);
  } else {
    this.selectedTime = null;
    this.finalDateTime = this.selectedDate; // Store only date if time is missing
  }
}

formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure 2 digits
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`; // Format as YYYY-MM-DD
}




  resId(id){
    this.resIds=id;
  }
  regNo(regNo){
    this.regiNo=regNo;
  }
  closeMenu(): void {
    this.isBottomSheetOpen = false;
  }


  async downloadPDF() {
    // Import html2pdf dynamically
    const html2pdf = await import('html2pdf.js');
    
    // Reference the container element
    const element = document.getElementById('quotationContent');
    
    if (!element) {
      console.error('Element not found!');
      return;
    }

    // Options for PDF generation
    const options = {
      margin: 1,
      filename: 'quotation.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };


    html2pdf.default().from(element).set(options).save();
  }
  openSidebarQuote1(type=''): void {
    this.ngOnInit();
    this.type=type;
    this.isBottomSheetOpen=false;
    this.closeSidebar(false);
    this.sidebarOpenQuote = true; 

    this.getJobcardDetails();
    this.getQuoteDetails();
    this.grandApprovedTotal();
    this.grandTotal();
    this.getQuoteGrandTotal();
    this.isPopupOpen=false;
    this.isApprovePopUpOpen=false;
  }
  closeSidebar1(){
    this.isPopupOpen = false;
    // this.loadOrderDetails();
    this.closeSidebar(true);
    this.getQuoteDetails();
    this.submitDataUpdate();
   

  }

  // markAsCompleteUpdate(){
  //   // console.log("this.quoationsdfnsdjnjk----",this.customerQuotation1);
  //   // console.log("labour items",this.selectedItemsLabour);
  //   // console.log("this.jobcardId--",this.jobcardId);
  //   // console.log("this.customerquoteId---",this.customerquoteId);
  //  let itemData = [];
  //  this.customerQuotation1.forEach(approved => {
  //   if(approved.selected == 'true' || approved.selected == true){
  //   itemData.push({"id": approved.itemId, "quoteItemStatus": 1});
  // }});
  //  let labourItems=[];
  //  this.selectedItemsLabour.forEach(approved => {
  //   if(approved.selected == 'true' || approved.selected == true){
  //   labourItems.push({"labourName": approved.labourName, "quoteItemStatus": 1});
  // }});
  //   let param = {
  //     "jobcardId":this.jobcardId,
  //     "customerQuoteId": this.customerquoteId,
  //     "customerQuoteItems": itemData,
  //     "labourQuoteItems": labourItems
      
  //   }
  //   // console.log("param-=====",param);
  
  //   this.aspRequestDetailsService.markAsComplete(param).subscribe(response => {
  //     // this.resData = response['data'];
  //     // console.error('response complete:',response );
  //     // console.log("aman---",response);
  //     // this.loadOrderDetails();

  //     this.getQuoteDetails();
  //     this.viewPopUp();
  //     (document.getElementsByClassName('approval-due')[0] as HTMLInputElement).innerHTML = "Ready";
  //     (document.getElementsByClassName('approval-due')[0] as HTMLInputElement).style.backgroundColor = "#3f9943";
  
   
  //     // this.isEditLabourSidebarOpen = true;
      
  //   });
  // }

  markAsCompleteUpdate() {
    let itemData = [];
    let allItemsCompleted = true; // Track if all items are marked as complete
    console.log("all items---=----",this.customerQuotation1);
    console.log("this.selectedLabour---",this.selectedItemsLabour)
    this.customerQuotation1.forEach(approved => {
        if (approved.selected == 'true' || approved.selected == true) {
            itemData.push({ "id": approved.itemId, "quoteItemStatus": 1 });
        }
        if (approved.quoteItemStatus !== 1) {
            allItemsCompleted = false; 
        }
    });

    let labourItems = [];
    this.selectedItemsLabour.forEach(approved => {
        if (approved.selected == 'true' || approved.selected == true) {
            labourItems.push({ "labourName": approved.labourName, "quoteItemStatus": 1 });
        }
        if (approved.quoteItemStatus !== 1) {
            allItemsCompleted = false; 
                  }
    });

    let param = {
        "jobcardId": this.jobcardId,
        "customerQuoteId": this.customerquoteId,
        "customerQuoteItems": itemData,
        "labourQuoteItems": labourItems
    };

    this.aspRequestDetailsService.markAsComplete(param).subscribe(response => {
        this.getQuoteDetails();
        this.viewPopUp();
        console.log("all items completed---",allItemsCompleted)
        if (allItemsCompleted) {
            const approvalElement = document.getElementsByClassName('approval-due')[0] as HTMLElement;
            if (approvalElement) {
                approvalElement.innerHTML = "Ready";
                approvalElement.style.backgroundColor = "#3f9943";
            }
        }
    });
}

  labourSideOpen()
  {
    this.sidebarOpenQuote=true;

    this.isEditLabourSidebarOpen = true;
    this.viewApprovePopUpOpen=false;
  }
  AddItems()
  {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.searchText = '';
    this.filteredItems = [];
  }
  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }
  onSearch(item: any) {
    if (!this.searchWord.trim()) {
      return;
    }
    const garageKey=localStorage.getItem('garageKey');
    this.variantKey=localStorage.getItem('VariantKey');
    // if( this.isRequestDetails){
    //  this.variantKey=localStorage.getItem('VariantKey');
    //  console.log("this.isRequestDetails",this.variantKey)
    // }
    if ( this.isJobCardDetails){
      // this.variantKey=this.jobCard['variantKey'];
      this.variantKey=localStorage.getItem('VariantKey');
      // console.log("this.isJobCardDetails",this.variantKey)
    }

    this.aspRequestDetailsService
      .searchItems(garageKey, this.searchWord, this.variantKey)
      .subscribe(
        (result) => {
          const res = JSON.parse(JSON.stringify(result));
          
          if (res.code === 200) {
            // console.log('Search results:', res.data);
            this.filteredItems = res.data;
            this.partNumber= res.data.map((item: any) => item.partNumber);
          } else {
            console.error('Search failed with code:', res.code);
          }
        },
        (error) => {
          console.error('Error during search:', error);
        }
      );
  }
  clearSearch() {
    this.searchWord = '';
  
    this.filteredItems = [];
  }
  addItemToQuotation(item: any) {
    // console.log('Attempting to add item:', item);
    // console.log('Current customerQuotation list:', this.customerQuotation);
    this.customerQuotationCount = this.customerQuotation.length;
    // console.log("customerquotecount-------------", this.customerQuotationCount);

    // Find an existing item with the same partNumber
    const existingItem = this.customerQuotation.find(quote => quote.partNumber === item.partNumber);

    if (existingItem) {
        // If found, increase the quantity and ensure it's selected
        existingItem.quantity += 1;
        existingItem.selected = true;
        // console.log('Item already exists, increased quantity:', existingItem);
    } else {
        // Otherwise, add the new item to the list
        const newItem = {
            itemId: item.itemId,
            itemName: item.name,
            brand: item.brand,
            partNumber: item.partNumber,
            sellingPrice: item.mrp,
            mrp: item.mrp,
            gstRate:item.gstRate,
            hsnCode:item.hsnCode,
            quantity: 1,
            selected: true, // Ensuring default selection
            edt: new Date(),
        };
        this.customerQuotation.push(newItem);
        // console.log('Item added successfully:', newItem);
    }

    // Ensure all items maintain the correct selection state
    this.customerQuotation.forEach(quote => quote.selected = true);

    this.isSidebarOpen = false;
    
    this.clearSearch();
    this.updateSelectedItemsQuote();
    this.getQuoteGrandTotal();
    this.sidebarToggleSelectAllQuote();

}
grandApprovedTotal()
{
  this.getQuoteDetails();
  console.log("saikiranitem----",this.customerQuotation1);
  // console.log("saikiran custo----",this.customerQuotation);
  console.log("saikiranLabour----",this.selectedItemsLabour);
  // this.grandTotalApprovedViewAmount = this.customerQuotation1
  // .filter(quote=>quote)
  // .reduce((total, quote) => total + (quote.mrp * quote.quantity), 0);

  this.grandTotalApprovedViewAmount = this.customerQuotation1
  .filter(quote=>quote)
  .reduce((total, quote) => total + (quote.sellingPrice * quote.quantity), 0);

// console.log("saikiran item---",this.grandTotalApprovedViewAmount);
  this.grandLabourApprovedViewAmount = this.selectedItemsLabour.reduce((sum, labour) => {
    return sum + (Number(labour.labourAmount) * Number(labour.quantity || 1) || 0);
  }, 0);
  console.log("saikiran item approved ---",this.grandTotalApprovedViewAmount);
  console.log("saikiran Labour approved---",this.grandLabourApprovedViewAmount);
  this.grandFinalApprovedViewTotalAmount=(this.grandTotalApprovedViewAmount || 0)+(this.grandLabourApprovedViewAmount || 0);
  console.log("saikiran price view price ------",this.grandFinalApprovedViewTotalAmount)

}
getQuoteGrandTotal(){
  // console.log("this.customerQuotation---",this.customerQuotation);
  let partsTotal = 0;
  if(this.customerQuotation){
    partsTotal = this.customerQuotation?.reduce((sum, item) => sum + (item.mrp * item.quantity),0);
  }
  let labourTotal = 0;
  if(this.selectedItemsLabourQuote){
    labourTotal = this.selectedItemsLabourQuote?.reduce((sum, item) => sum + (Number(item.labourAmount) * item.quantity),0);
  }
  // console.log("labour--total-----",labourTotal);
  this.quoteGrandTotal = ((partsTotal || 0) + (labourTotal || 0));
}  

grandTotal(){
  // console.log("saikiranparts price---",this.customerQuotation)

  this.grandTotalApprovedAmount = this.customerQuotation
  .filter(quote=>quote)
  .reduce((total, quote) => total + (quote.sellingPrice * quote.quantity), 0);
  this.selectedLabours = this.selectedItemsLabourQuote.filter(labour => labour.selected);

  // console.log("LaboursSelected----",this.selectedLabours);

  // ✅ Multiply labourAmount by quantity while keeping your existing logic
  this.grandLabourApprovedAmount = this.selectedItemsLabourQuote.reduce((sum, labour) => {
    return sum + (Number(labour.labourAmount) * Number(labour.quantity || 1) || 0);
  }, 0);
  // console.log("this.selectedItemsLabourQuote---",this.grandLabourApprovedAmount);
  this.grandFinalApprovedTotalAmount=(this.grandTotalApprovedAmount || 0)+(this.grandLabourApprovedAmount || 0);
  // console.log("saikiran price ------",this.grandFinalApprovedTotalAmount)

}

     getJobcardDetails() {
            this.aspLoadingService.show();
            const garageKey = localStorage.getItem('garageKey');
              
            this.myJobCardsDetailsService.getJobCardDetails(this.bookingId , garageKey, this.jobcardId).subscribe(
              (result: any) => {
                this.aspLoadingService.hide();
          
                if (result && result.code === 200) {
                  this.request = result.data;
                  // this.fetchPreFilledBooking();
                  if (this.request?.jobCard) {
                    this.customerName = this.request.jobCard.name;
                    // console.log("this.customerNmae---",this.customerName)
                    this.jobCardId = this.request.jobCard.jobCardId;
                    this.customerquoteId = this.request.jobCard.customerQuoteId;
                    // console.log("this.customerQuoteId:", this.customerquoteId);
                  } else {
                    console.error("Error: jobCard is undefined in this.request", this.request);
                  }
        
                  // Check if request-id is present in the response
                  const requestId = this.request?.jobCard?.requestId; // Update this path as per your actual response structure
                  // this.getQuoteDetails();
               
                
                  // this.loadComponentB(this.request?.jobCard); // Pass the request-id to Component B
                } else {
                  this.aspLoadingService.hide();
                }
                
              },
              (error: HttpErrorResponse) => {
                this.aspLoadingService.hide();
                // console.error('Error fetching job card details:', error);
              }
            );
          
      }
  
  getQuoteDetails() {
    const userKey = localStorage.getItem('spareUserKey');
    const garageKey = localStorage.getItem('garageKey');
    this.quoteId = this.QuoteId? this.QuoteId : this.resIds;
    // console.log("this.quoteId---",this.quoteId);
  
    this.aspCustomerQuoteService.getQuoteDetails(garageKey, this.quoteId, userKey).subscribe(
      result => {
        const res = JSON.parse(JSON.stringify(result));
        this.data = res.data;
          // Ensure jobCardStatus is treated correctly (string or number)
          this.customerQuotation = (this.data.items || []).filter(item => item.jobCardStatus < 3 && item.jobCardStatus < '3');
          this.EdititemsCount  = (this.data.items || []).filter(item => item.jobCardStatus < 3 && item.jobCardStatus < '3').length;
          this.customerQuotation1 = (this.data.items || []).filter(item => item.jobCardStatus === 3 || item.jobCardStatus === '3');
          this.quoteItemStatus= (this.customerQuotation1||[]).filter(item => item.quoteItemStatus== 2|| item.quoteItemStatus=='2');
          this.editItemsCount=(this.data.items || []).filter(item => item.jobCardStatus === 3 || item.jobCardStatus === '3').length;
          this.selectedItemsLabourQuote = (this.data.labourQuoteItemResponses || []).filter(labour => labour.jobCardStatus < 3 && labour.jobCardStatus < '3');
          this.selectedItemsLabour=(this.data.labourQuoteItemResponses || []).filter(labour => labour.jobCardStatus >= 3 && labour.jobCardStatus >= '3');
          // console.log("jobcardStatus of first item:", this.data.items[0]?.quoteItemStatus);
          // this.
          this.approvedItemsCount=(this.data.items || []).filter(item => item.jobCardStatus === 3 || item.jobCardStatus === '3').length;
          // console.log("kiram",this.customerQuotation);
          const labourItemsCount = (this.data.labourQuoteItemResponses || []).length;
        this.totalApprovedItemsCount=(this.approvedItemsCount | 0)+(this.selectedItemsLabour.length | 0);
          this.totalApprovingItemsCount = (this.EdititemsCount) + ( this.selectedItemsLabourQuote.length | 0);
          this.totalEditItemsCount=(this.EdititemsCount)+(this.selectedItemsLabourQuote?.length || 0);
          
        this.jobcardId = this.data?.items[0].jobcardId;
        this.cdr.detectChanges();
      },
      error => {
        console.error('Error fetching quote details:', error);
      }
    );
  }
  sidebarToggleItemSelectionQuote(){
    this.toggleItemSelectionQuote();

  }
  isAllItemsAndLabourCompleted(): boolean {
    const allItemsCompleted = this.customerQuotation1.every(quote => quote.quoteItemStatus === 1);
    const allLaboursCompleted = this.selectedItemsLabour.every(labour => labour.quoteItemStatus === 1);
    return allItemsCompleted && allLaboursCompleted;
  }
  
  closeAll() {
    this.isEditLabourSidebarOpen = false;
    this.isSidebarOpen = false;
    this.isAddLabour = false;
    this.showSkipConfirmation = false;
    this.ngOnInit();
  }
  
  // Select All for Approving Items
  sidebarToggleSelectAllQuote() {
    this.selectAllQuote = !this.selectAllQuote;
    if (Array.isArray(this.customerQuotation)) {
      this.customerQuotation.forEach(quote => {
        quote.selected = this.selectAllQuote;
      });
    } else {
      // console.log('error select all parts');   
     }
    if (Array.isArray(this.customerQuotation1)) {
      this.customerQuotation1.forEach(quote => {
        quote.selected = this.selectAllQuote;
      });
    } else {
      // console.log('error select all parts');   
     }
    if (Array.isArray(this.selectedItemsLabourQuote)) {
      this.selectedItemsLabourQuote.forEach(labour => {
        labour.selected = this.selectAllQuote;
      });
    } else {
      // console.log('error select all labour');
    }
    if (Array.isArray(this.selectedItemsLabour)) {
      this.selectedItemsLabour.forEach(labour => {
        labour.selected = this.selectAllQuote;
      });
    } else {
      // console.log('error select all labour');
    }
  
    // console.log("Select All customerQuotation:", this.customerQuotation);
    // console.log("Select All selectedItemsLabourQuote:", this.selectedItemsLabourQuote);
    
    this.updateSelectedItemsQuote();
    this.updateSelectedLabourItems();
  }


  addLabourSidebar(){
    this.isAddLabour = !this.isAddLabour; 
    this.labourList();
    
  }
  labourRedirectSidebar()
  {
    this.isAddLabour=false;
    this.selectedLabourCount=0;
    }
  
  // labourList() {
  //     const garageKey = localStorage.getItem('garageKey');
  //     console.log("garagekey", garageKey);
  
  //     if (garageKey) {
  //         this.aspRequestDetailsService.displayLabourList(garageKey).subscribe(
  //             (result) => {
  //                 const res = JSON.parse(JSON.stringify(result));
  //                 if (res.code === 200) {
  //                     console.log("labourres", res);
  
  //                     if (!Array.isArray(this.selectedItemsLabourQuote)) {
  //                         this.selectedItemsLabourQuote = [];
  //                     }
  
  //                     this.fullLabourList = res.data.map((labour: any) => ({
  //                         ...labour,
  //                         selected: this.selectedItemsLabourQuote.some(
  //                             (selectedItem: any) =>
  //                                 // selectedItem.labourId === labour.labourId &&
  //                                 selectedItem.labourName === labour.labourName 
  //                                 // selectedItem.labourAmount === labour.labourAmount
  //                         ),
  //                     }));
  
  //                     this.labourListData = [...this.fullLabourList]; // Copy full list to labourListData
  //                     console.log("Updated labourListData:", this.labourListData);
  
  //                     this.updateSelectedLabourItems();
  //                     this.updateSelectedItemsQuote();
  //                 }
  //             },
  //             (error) => {
  //                 console.error('Error fetching labour list:', error);
  //             }
  //         );
  //     } else {
  //         console.warn('Garage key not found in localStorage.');
  //     }
  // }
  
  // updateSelectedLabourItems(): void {
  //   // this.originalLabourQuotes = [...this.selectedItemsLabourQuote];

  //   this.selectedLabours = this.selectedItemsLabourQuote.filter(labour => labour.selected);
  //   this.selectedLabourslength = this.selectedLabours.length;
  
  //   // Do not overwrite the entire array, just calculate selected count and price
  //   this.totallabourSelectedPrice = this.selectedLabours.reduce((sum, labour) => {
  //     return sum + (labour.labourAmount || 0);
  //   }, 0);
  
  //   this.totalSelectedApprovedriceQuote = (this.totallabourSelectedPrice || 0) + (this.totalapproveSelectedPriceQuote || 0);
  //   this.customerQuotationPrice = this.totalSelectedApprovedriceQuote;
  
  //   this.selectedLaboursCount = this.selectedLabours.length || 0;
  //   this.selectedItemsCount = this.selectedLabourslength + this.selectedItemsCountQuote;
  //   // if (this.selectedLabours.length === 0) {
  //   //   this.selectedItemsLabourQuote = [...this.originalLabourQuotes];
  //   // }
    
  //   console.log("Total Selected Labour Price:", this.totallabourSelectedPrice);
  // }

//   labourList() {
//     const garageKey = localStorage.getItem('garageKey');
//     console.log("garagekey", garageKey);

//     if (garageKey) {
//         this.aspRequestDetailsService.displayLabourList(garageKey).subscribe(
//             (result) => {
//                 const res = JSON.parse(JSON.stringify(result));
//                 if (res.code === 200) {
//                     console.log("labourres", res);

//                     if (!Array.isArray(this.selectedItemsLabourQuote)) {
//                         this.selectedItemsLabourQuote = [];
//                     }

//                     this.fullLabourList = res.data
//                         .map((labour: any) => ({
//                             ...labour,
//                             selected: this.selectedItemsLabourQuote.some(
//                                 (selectedItem: any) =>
//                                     selectedItem.labourName === labour.labourName
//                             ),
//                         }))
//                         .sort((a, b) => Number(b.selected) - Number(a.selected)); // Sorting selected items first

//                     this.labourListData = [...this.fullLabourList]; // Copy sorted list to labourListData
//                     console.log("Updated labourListData:", this.labourListData);

//                     this.updateSelectedLabourItems();
//                     this.updateSelectedItemsQuote();
//                 }
//             },
//             (error) => {
//                 console.error('Error fetching labour list:', error);
//             }
//         );
//     } else {
//         console.warn('Garage key not found in localStorage.');
//     }
// }

labourList() {
  const garageKey = localStorage.getItem('garageKey');
  console.log("garagekey", garageKey);
  console.log("savedItems---======------", this.selectedItemsLabourQuote);

  if (garageKey) {
      this.aspRequestDetailsService.displayLabourList(garageKey).subscribe(
          (result) => {
              const res = JSON.parse(JSON.stringify(result));
              if (res.code === 200) {
                  console.log("labourres", res);

                  if (!Array.isArray(this.selectedItemsLabourQuote)) {
                      this.selectedItemsLabourQuote = [];
                  }

                  // ✅ Merge selected labours from previous state
                  this.fullLabourList = res.data.map((labour: any) => {
                      const existingItem = this.selectedItemsLabourQuote.find(
                          (selectedItem: any) => selectedItem.labourId === labour.labourId
                      );

                      return {
                          ...labour,
                          selected: !!existingItem, 
                          quantity: existingItem?.quantity ?? 1, 
                      };
                  });

                  // ✅ Sorting: Move selected items to the top
                  this.fullLabourList.sort((a, b) => Number(b.selected) - Number(a.selected));

                  this.labourListData = [...this.fullLabourList]; 
                  console.log("Updated labourListData:", this.labourListData);

                  this.updateSelectedLabourItems();
                  this.updateSelectedItemsQuote();
              }
          },
          (error) => {
              console.error('Error fetching labour list:', error);
          }
      );
  } else {
      console.warn('Garage key not found in localStorage.');
  }
}




saveLabourList() {
  const garageKey = localStorage.getItem('garageKey');
  console.log("garagekey", garageKey);
  console.log("savedItems---======------", this.selectedItemsLabourQuote);

  if (garageKey) {
      this.aspRequestDetailsService.displayLabourList(garageKey).subscribe(
          (result) => {
              const res = JSON.parse(JSON.stringify(result));
              if (res.code === 200) {
                  console.log("labourres", res);

                  if (!Array.isArray(this.selectedItemsLabourQuote)) {
                      this.selectedItemsLabourQuote = [];
                  }

                  this.fullLabourList = res.data
                      .map((labour: any) => ({
                          ...labour,
                          selected: this.selectedItemsLabourQuote.some(
                              (selectedItem: any) =>
                                  selectedItem.labourId === labour.labourId
                          ),
                      }))
                      .sort((a, b) => Number(b.selected) - Number(a.selected)); // Sort selected items first

                  this.labourListData = [...this.fullLabourList]; // Copy full list to labourListData
                  console.log("Updated labourListData:", this.labourListData);

                  this.updateSelectedLabourItems();
                  this.updateSelectedItemsQuote();
              }
          },
          (error) => {
              console.error('Error fetching labour list:', error);
          }
      );
  } else {
      console.warn('Garage key not found in localStorage.');
  }
}


  updateSelectedLabourItems(): void {
    this.selectedLabours = this.selectedItemsLabourQuote.filter(labour => labour.selected);
    this.selectedLabourslength = this.selectedLabours.length;
    // console.log("this.labourlistData-------",this.labourListData)
    // this.labourCount=this.labourListData.filter(labour => labour.selected).length;
    // this.labourCountNewList=this.labourListData.filter(labour => labour.selected);
    // console.log("this.labourCountNewList-----",this.labourCountNewList);
    // this.selectedLaboursNewCount = this.labourCount
    // console.log("selectedLabourCount---",this.selectedLabourCount);
    // this.totallabourSelectedNewPrice = this.labourCountNewList.reduce(
    //   (sum, labour) => sum + (Number(labour.labourAmount) * Number(labour.quantity || 1)), 0
    // );
    console.log("this.totalPrice----",this.totallabourSelectedNewPrice);
    this.totallabourSelectedPrice = this.selectedLabours.reduce((sum, labour) => {
      return sum + (Number(labour.labourAmount) * Number(labour.quantity || 1) || 0);
    }, 0);
  
    this.totalSelectedApprovedriceQuote = (this.totallabourSelectedPrice || 0) + (this.totalapproveSelectedPriceQuote || 0);
    this.customerQuotationPrice = this.totalSelectedApprovedriceQuote;
  
    // Update counts
    this.selectedLaboursCount = this.selectedLabours.length || 0;
    this.selectedItemsCount = this.selectedLabourslength + (this.selectedItemsCountQuote || 0);
  
    // Keep existing total edit price calculation
    this.totalSelectedEditPriceQuote = (this.totallabourSelectedPrice || 0) + (this.totalEditSelectedPriceQuote || 0);
    this.totalSelectedPriceQuote1 = Number(this.totallabourSelectedNewPrice);
    // console.log("Total Selected Labour Price:", this.totallabourSelectedPrice);
    // console.log("Total Selected edit Price:", this.totalSelectedEditPriceQuote);
    // console.log("Selected Items Count:", this.selectedItemsCount);
  
    // Ensure UI reflects the changes
    this.cdr.detectChanges();
  }
  
   anyItemSelectedQuote() {
    return this.selectedItemsCount > 0;
  }
  
  

  incrementjobcardQuantity(quote:any):void {
    quote.itemQuantity++;
    this.customerQuotationPrice = this.calculateTotalQuotationPrice();
    this.updateSelectedItemsQuote();
    this.updateSelectedLabourItems();
    this.getQuoteGrandTotal();
  }
  incrementQuantity(quote: any): void {
    quote.quantity++;
    this.customerQuotationPrice = this.calculateTotalQuotationPrice();
    this.updateSelectedItemsQuote();
    this.updateSelectedLabourItems();
    this.getQuoteGrandTotal();
  
  }
  decrementQuantity(quote: any): void {
    if (quote.quantity > 1) { 
      quote.quantity--;
      this.customerQuotationPrice = this.calculateTotalQuotationPrice();
      this.updateSelectedItemsQuote();
      this.updateSelectedLabourItems();
      this.getQuoteGrandTotal();
    }
  }
  decrementjobcardQuantity(quote:any): void{
    if (quote.itemQuantity > 1) { 
      quote.itemQuantity--;
      this.customerQuotationPrice = this.calculateTotalQuotationPrice();
      this.updateSelectedItemsQuote();
      this.updateSelectedLabourItems();
      this.getQuoteGrandTotal();
    }
  }
  incrementLabourQuantity(quote: any): void {
    if (quote.quantity >= 1) {
      quote.quantity++;
    }
  
    // ✅ Recalculate labour price and update UI
    this.labourQuotationPrice = this.calculateTotalLabourPrice();
    this.updateSelectedItemsQuote();
    this.updateSelectedLabourItems();
    this.getQuoteGrandTotal();
    this.cdr.detectChanges();
  }
  
  decrementLabourQuantity(quote: any): void {
    if (quote.quantity > 1) { // ✅ Prevents quantity from going below 1
      quote.quantity--;
    }
  
    // ✅ Recalculate labour price and update UI
    this.labourQuotationPrice = this.calculateTotalLabourPrice();
    this.updateSelectedItemsQuote();
    this.updateSelectedLabourItems();
    this.getQuoteGrandTotal();
    this.cdr.detectChanges();
  }
  

  calculateTotalLabourPrice(): number {
    return this.selectedItemsLabourQuote.reduce(
      (sum, item) => sum + (Number(item.labourAmount) * Number(item.quantity || 1)), 0
    );
  }
  openSkipConfirmation(){
    this.showSkipConfirmation = true;
  }
  closeSkipConfirmation(): void {
    this.showSkipConfirmation = false;
  }
  confirmSkip(): void {
    this.showSkipConfirmation = false;
    this.isAddLabour = false;
    // console.log('Skip confirmed!');
  }
  toggleSelectItem(): void {
    // console.log("selectedItemsLabourQuote---",this.selectedItemsLabourQuote)
    this.selectedItemsLabourQuote = this.labourListData.filter(item => item.selected);
    this.totalSelectedPriceQuote1 = this.selectedItemsLabourQuote.reduce(
      (sum, item) => sum + (item.labourAmount || 0),0);
    // console.log('Selected Items:', this.selectedItemsLabourQuote);
    // console.log('Total Selected Price:', this.totalSelectedPriceQuote1);
    this.isAddLabour = false;
    this.getQuoteGrandTotal();
  }
  // onLabourSelectionChange(labour: any): void {
  //   if (!labour) {
  //     console.error('Labour object is undefined');
  //     return;
  //   }

  //   labour.selected = labour.selected || false;

  //   this.selectedItemsLabourQuote = this.labourListData.filter(l => l.selected);
  //    this.selectedLabourCount = this.selectedItemsLabourQuote.every(labour => labour.selected).length;
  //   // Update counts and prices

  //   this.updateSelectedLabourItems();
  //   this.updateSelectedItemsQuote();
  // }
  onLabourSelectionChange(labour: any): void {
    // console.log("this.labourListData before change:", this.labourListData);
    // console.log("this.selectedItemsLabourQuote before change:", this.selectedItemsLabourQuote);
  
    if (!labour) {
      console.error('Labour object is undefined');
      return;
    }
  
    // ✅ Ensure labour is selected
    labour.selected = labour.selected || false;
  
    // ✅ Find if labour is already in selectedItemsLabourQuote
    let existingLabour = this.selectedItemsLabourQuote.find(l => l.id === labour.id);
  
    if (existingLabour) {
      // If already selected, retain its quantity
      labour.quantity = existingLabour.quantity;
    } else {
      // If new, set default quantity to 1
      labour.quantity = 1;
    }
  
    // ✅ Merge existing selected items with the newly selected ones
    this.selectedItemsLabourQuote = this.labourListData
      .filter(l => l.selected)
      .map(l => {
        let existingItem = this.selectedItemsLabourQuote.find(item => item.id === l.id);
        return existingItem ? { ...existingItem } : { ...l, quantity: 1 }; // Keep existing quantity or set default
      });
  
    // ✅ Calculate total selected price including quantity
    this.totallabourListSelectedPrice = this.selectedItemsLabourQuote.reduce(
      (sum, labour) => sum + (Number(labour.labourAmount) * (labour.quantity || 1)), 
      0
    );
  
    // console.log("Updated selectedItemsLabourQuote:", this.selectedItemsLabourQuote);
    // console.log("Updated selectedLabourPrice:", this.totallabourListSelectedPrice);
  
    // ✅ Update selected labour count
    this.selectedLabourCount = this.selectedItemsLabourQuote.length;
  
    // ✅ Calculate total price
    this.totalSelectedPriceQuote1 = this.selectedItemsLabourQuote.reduce(
      (sum, item) => sum + (Number(item.labourAmount) * (item.quantity || 1)), 
      0
    );
  
    // Update UI
    this.updateSelectedLabourItems();
    this.updateSelectedItemsQuote();
  }
  
  openLabourEditSidebar(item: any = null) {
    this.isEditLabourSidebarOpen = true;
    // this.isAddLabour = item ? false : true;
    this.isSidebarOpen = false;
  // console.log("sdjfjsdfjisdf")
    if (item) {
      this.selectedLabour = { ...item };
      this.labourName = this.selectedLabour.labourName;
      this.labourAmount = this.selectedLabour.labourAmount;
      this.labourId = this.selectedLabour.labourId;
      this.id = this.selectedLabour.id;
      // console.log("Editing Labour:", this.selectedLabour);
    } else {
      this.selectedLabour = { name: '', price: null };
      // console.log("Adding New Labour:", this.selectedLabour);
    }
  }
  
  // onLabourSearch(): void {
    // console.log('Search Triggered:', this.searchText);
  //   const query = this.searchText?.toLowerCase();
  //   if (query) {
  //     this.labourListData = this.labourListData.filter(item =>
  //       item.labourName?.toLowerCase().includes(query)
  //     );
  //   // if(this.searchText=''){
  //   //   this.labourList();
  //   // }
  //   } else {
  //     console.log('Resetting filteredItems1 to full list.');
  //     this.filteredItems1 = [...this.labourListData]; // Reset to full list
  //   }
  //   console.log('Filtered Items:', this.filteredItems1);
  // }

  onLabourSearch(): void {
    // console.log('Search Triggered:', this.searchText);
    const query = this.searchText?.toLowerCase();

    if (query) {
        this.labourListData = this.fullLabourList.filter(item =>
            item.labourName?.toLowerCase().includes(query)
        );
    } else {
        // Reset to full list when search is cleared
        this.labourListData = [...this.fullLabourList];
    }

    // console.log('Filtered Items:', this.labourListData);
}

  
  // clearLabourSearch(){
  //   this.searchText='';
  //   this.labourList();
  // }

  clearLabourSearch() { 
    this.searchText = '';
    this.labourListData = [...this.fullLabourList]; // Restore full list
}


approveQuote(){
  let customerQuoteItems: any = [];
  let labourQuoteItems: any = [];
  let jobcardId:number = 0;
  // console.log("quotationrecieved----",this.selectedItemsQuote);
  // console.log("abours---",this.selectedLabours);
  this.selectedItemsQuote.forEach(received => {
    
    if (received.selected === true) {
      let data = {};
      data['id'] = received.itemId;
      data['jobCardStatus'] = 3;
      data['jobcardId'] = received.jobcardId;
      this.jobcardId=received.jobcardId
      customerQuoteItems.push(data);
    }
});
this.selectedLabours.forEach(labour => {
  if (labour.selected === true) {
    let data = {
      labourId: labour.labourId,  
      jobCardStatus: 3,
      jobcardId: labour.jobcardId
    };
    this.jobcardId = labour.jobcardId;
    labourQuoteItems.push(data);
  }
});

let param = {
  "jobcardId":this.jobcardId,
  "customerQuoteItems": customerQuoteItems,
  "labourQuoteItems" : labourQuoteItems ,
  "customerQuoteId": this.quoteId
}
this.aspQuotationDetailsService.approveQuotation(param).subscribe(
  result => {
   
    const res = JSON.parse(JSON.stringify(result));
    this.sidebarOpenQuote = false;
    this.isApprovePopUpOpen = true;
    this.grandApprovedTotal();
    
    (document.getElementsByClassName('approval-due')[0] as HTMLInputElement).innerHTML = "In Progress";
    (document.getElementsByClassName('approval-due')[0] as HTMLInputElement).style.backgroundColor = "orange";

  },
  error => {
    this.toastr.error('Error approving quotation:', error);
  }
);

}
  get up() {
    return this.unitPrice.controls;
  }
    
    get ld() {
      return this.laborDetails.controls;
    }
  
  
  closeLabourEditSidebar() {
    this.isEditLabourSidebarOpen = false;
      this.selectedLabour = { labourName: '', labourAmount: null };
    // this.isAddLabour=false;
    
  
  }
//   saveLabour(): void {
//     console.log("Labour Name:", this.ld.labourName.value);
//     console.log("Labour Amount:", this.ld.labourAmount.value);
//     console.log("Selected Labour:", this.selectedLabour);

//     this.selectedItemsLabourQuote.forEach(labour => {
//       if(labour.labourId==this.selectedLabour.labourId){
//         labour.labourName = this.ld.labourName.value;
//         labour.labourAmount = this.ld.labourAmount.value;
//       }
//     });
//     console.log("selectedItemsLabourQuote:", this.selectedItemsLabourQuote);

//     const updatedLabourName = this.ld.labourName.value;
//     const updatedLabourAmount = this.ld.labourAmount.value;
//     const garageKey = localStorage.getItem('garageKey');

//     this.aspRequestDetailsService
//       .editLabour(this.id, this.labourId, garageKey, updatedLabourName, updatedLabourAmount)
//       .subscribe(
//         (result) => {
//           console.log('API Response:', result);
//           const res = JSON.parse(JSON.stringify(result));
//           if (res.code === 200) {
//             console.log('Labour edited successfully:', res);
//             this.toastr.success("Labour saved Successfully");
//             this.labourList(); 
//             const index = this.selectedItemsLabourQuote.findIndex(item => item.id === this.id);
//             if (index !== -1) {
//               this.selectedItemsLabourQuote[index].labourName = updatedLabourName;
//               this.selectedItemsLabourQuote[index].labourAmount = updatedLabourAmount;
//             }

//             console.log("Updated selectedItemsLabourQuote:", this.selectedItemsLabourQuote);
//           } else {
//             console.warn('Unexpected response code:', res.code);
//           }
//         },
//         (error) => {
//           console.error('Error editing labour:', error);
//         }
//       );
//       this.getQuoteGrandTotal();
//     this.closeLabourEditSidebar();
// }

saveLabour(): void {
  console.log("Labour Name:", this.ld.labourName.value);
  console.log("Labour Amount:", this.ld.labourAmount.value);

  const updatedLabourName = this.ld.labourName.value;
  const updatedLabourAmount = this.ld.labourAmount.value;
  const garageKey = localStorage.getItem('garageKey');

  console.log("before labourId-----", this.labourId);
  this.aspRequestDetailsService
      .editLabour(this.id, this.labourId, garageKey, updatedLabourName, updatedLabourAmount)
      .subscribe(
          (result) => {
              console.log('API Response:', result);
              const res = JSON.parse(JSON.stringify(result));

              if (res.code === 200) {
                  console.log('Labour edited successfully:', res);
                  this.toastr.success("Labour saved Successfully");
                  console.log("this.labourId", this.labourId);

                  let labourExists = false;
                  this.selectedItemsLabourQuote = this.selectedItemsLabourQuote.map(item => {
                      if (item.labourId === this.labourId) {
                          labourExists = true;
                          return {
                              ...item,
                              labourName: updatedLabourName,
                              labourAmount: updatedLabourAmount,
                              selected: true
                          };
                      }
                      return item;
                  });

                  // ✅ Ensure the edited labour is still selected
                  if (!labourExists) {
                      this.selectedItemsLabourQuote.push({
                          id: this.id,
                          labourId: this.labourId,
                          labourName: updatedLabourName,
                          labourAmount: updatedLabourAmount,
                          selected: true
                      });
                  }
                  console.log("Updated selectedItemsLabourQuote:", this.selectedItemsLabourQuote);
                  this.labourList();
                  this.getQuoteGrandTotal();  
              } else {
                  console.warn('Unexpected response code:', res.code);
              }
          },
          (error) => {
              console.error('Error editing labour:', error);
          }
      );

  this.closeLabourEditSidebar();
}



loadOrderDetails(){
   
this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
  this.router.navigate(['my-job-cards-details/'+this.jobcardId+'/'+this.bookingId]);
});}

  openPopUp(){
    this.showCancelConfirmation = true;
    this.isAddLabour = false;
  }
  addLabour() {
    console.log(this.ld.labourAmount.value);
    const updatedLabourName = this.ld.labourName.value;
    const updatedLabourAmount = this.ld.labourAmount.value;
    const garageKey = localStorage.getItem('garageKey');
  
    this.aspRequestDetailsService.addLabourList(garageKey, updatedLabourName, updatedLabourAmount)
      .subscribe((result) => {
        const res = JSON.parse(JSON.stringify(result));
  
        if (res.code === 200) {
          console.log("addLabour--", res);
          
          const newLabour = {
            labourId: res.data?.labourId,
            labourName: updatedLabourName,
            labourAmount: updatedLabourAmount,
            selected: true, 
            quantity: 1,
            id: res.data?.id
          };
  
          // ✅ Always add it to selectedItemsLabourQuote
          this.selectedItemsLabourQuote.push(newLabour);
  
          // ✅ Add to labourListData
          this.labourListData.unshift(newLabour);
          
          console.log("this.labourcount----", this.labourCount);
          this.updateSelectedLabourItems();
          this.closeLabourEditSidebar();
          this.toastr.success("Labour Added Successfully");
  
          // ✅ Instead of reloading everything, just update UI
          this.labourList();
        } else {
          this.toastr.error("Labour Name Already Exists");
        }
      });
  }
  
  

  closeCancelPopup(): void {
    this.showCancelConfirmation = false;
  }
  openPopup(): void {
    this.showSkipConfirmation = false;
    this.isAddLabour = false;
    this.CloseSidebarQuote();
    this.submitData(); 
  }
  closePopup(): void {
    this.isPopupOpen = false;
  }
  
  calculateTotalAmount(): number {
    return this.data?.items?.reduce((total, item) => {
      return total + (item.sellingPrice * item.quantity);
    }, 0) || 0; // Ensure a fallback value if items is undefined
  }

  calculateLabourAmount(): number {
    return this.data?.labourQuoteItemResponses?.reduce((total, item) => {
      return total + (item.labourAmount * item.quantity);
    }, 0) || 0; 
  }

  calculateTotalQuoteAmount(): number {
    const totalAmount = this.calculateTotalAmount();
    const labourAmount = this.calculateLabourAmount();
    return totalAmount + labourAmount;
  }
  


  

getRequestDetails() {
  const garageKey = localStorage.getItem('garageKey');
  const requestId = this.requestId;
  // console.log("this.requestId",requestId);
  const userKey = localStorage.getItem('spareUserKey');

  this.aspCustomerQuoteService.requestDetails(garageKey, requestId, userKey).subscribe(result => {
    const res = JSON.parse(JSON.stringify(result));
  
    
    if (res.code === 200) {
      this.requestDetails = res.data;
      // console.log("spare-request----",this.requestDetails);

      
      this.requestDetails.spareRequestItemList.forEach(item => {

        res.data.bidDetails.forEach(bid => {
          bid.estimateItem[item.itemName].forEach(bidItem => {
            
            bidItem.itemName = item.itemName;
            // bidItem.itemBrand=item.itemBrand;
            bidItem.itemQuantity = item.itemQuantity;                                
            bidItem.itemStatus = item.status; 
            bidItem.itemId = item.id;
            bidItem.lineitemId = item.lineitemId;
            bidItem.spareQuotationId = item.spareQuotationId;      
            if ([4, 5, 6, 7, 8, 9].includes(bidItem.itemStatus)) {                          
              this.customerQuotation.push(bidItem);            
              this.customerQuotationPrice = this.customerQuotationPrice + (bidItem.mrp *bidItem.itemQuantity);
            }

          });            
        });
        
      });
    }
  });
}


submitData() {
  if (!this.quoteId) {
    // console.log("this.quoteId")
    this.selectedItems = this.customerQuotation.filter(quote => quote.selected)
    this.selectedItems.forEach(item => {
      this.item=item.bidId;
    });

    const cleanData = {
      
      bidId: this.item,
      garageKey: localStorage.getItem('garageKey'),
      items: this.selectedItems.map(item => ({
        id: item.itemId,
        part_name: item.itemName,
        hsnCode:item.hsnCode,
        gstRate:item.gstRate,
        partNumber: item.partNumber,
        sellingPrice: item.mrp,
        mrp:item.mrp
      })),
      labourItems:[],
      registrationNumber: this.regiNo,
      requestId: this.requestId,
      userKey:localStorage.getItem('spareUserKey'),
    };

    this.aspCustomerQuoteService.submitData(cleanData).subscribe(response => {
      this.resData=response['data'];
      this.dataid=response['data'].id;
      console.error('response:',response );
    }, error => {
      console.error('Error occurred:', error);
    });
  }else{    
    if (this.quoteId) {
      // console.log(this.quoteId)
      this.submitDataUpdate();
    }
  }
}
get isAllCompleted(): boolean {
  return this.customerQuotation1.every(quote => quote.quoteItemStatus === 1) &&
         this.selectedItemsLabour.every(labour => labour.quoteItemStatus === 1);
}
submitDataUpdate() {
 
  this.aspLoadingService.show();
  // Filter selected spare parts
  this.selectedItems = this.customerQuotation.filter(quote => quote.selected);
  this.selectedLabours=this.selectedLabours.filter(labour=>labour.selected);
  // console.log("this.selectedLabour----",this.selectedLabours);
  // console.log("this.selectedItems---",this.selectedItems);
  // console.log("this.jobcardid--",this.jobcardId);

  // Store the bidId from selected spare parts (if applicable)
  if (this.selectedItems.length > 0) {
    this.item = this.selectedItems[0].bidId; // Assuming bidId is same for all selected parts
  }

  // Prepare items (spare parts)
  const itemsData = this.selectedItems.map(item => ({
    id: item.itemId,
    itemName: item.itemName,
    partNumber: item.partNumber,
    hsnCode:item.hsnCode,
    gstRate:item.gstRate,
    sellingPrice: item.mrp,
    mrp:item.mrp,
    brand:item.brand,
    itemQuantity: item.quantity

  }));


  const labourItemsData = this.selectedLabours.map(labour => ({
    labourId: labour.labourId,
    customerQuoteId: labour.customerQuoteId || 0, 
    labourName: labour.labourName,
    itmQty: labour.quantity || 1, 
    labourAmount: labour.labourAmount || 0
  }));

  // Construct the final request payload
  const cleanData1 = {
    
    bidId: this.item,
    garageKey: localStorage.getItem('garageKey'),
    items: itemsData,
    labourItems: labourItemsData,
    quoteId: this.quoteId,
    registrationNumber: this.regiNo,
    jobcardId:this.jobcardId,
    requestId: this.requestId,
    userKey: localStorage.getItem('spareUserKey'),
  };

  // Call the API with updated data
  this.aspCustomerQuoteService.submitDataUpdate(cleanData1).subscribe(response => {
    this.aspLoadingService.hide();
    const res = JSON.parse(JSON.stringify(response));
    this.data=res.data;
    this.resData = response['data'];    
    this.isPopupOpen = true;
    // console.log('Response:', response);
  }, error => {
    console.error('Error submitting data:', error);
  });
  
}

updateSelectedItemsQuote(): void {
  this.selectedItemsQuote = this.customerQuotation.filter(quote => quote.selected)
  this.selectedItemsCountQuote = this.customerQuotation.filter(quote => quote.selected).length;
  this.selectedItemsapprovedQuote = this.customerQuotation1.filter(quote => quote.selected)
  this.selectedItemsCountapprovedQuote = (this.customerQuotation1.filter(quote => quote.selected).length | 0)+(this.selectedItemsLabourQuote.filter(quote => quote.selected).length | 0);
  this.selectedAlreadyApprovedQuoteLabourlength=this.selectedItemsLabour.filter(quote => quote.selected).length | 0
  this.selectedAlreadyApprovedQuotelength=(this.customerQuotation1.filter(quote => quote.selected).length | 0)+(this.selectedItemsLabour.filter(quote => quote.selected).length | 0);
  this.selectedAlreadyApprovedQuote=(this.customerQuotation1.filter(quote => quote.selected).reduce((total, quote) => total+(quote.sellingPrice *quote.quantity), 0))+(this.selectedItemsLabour.filter(quote => quote.selected).reduce((total, quote) => total+(quote.labourAmount *quote.quantity),0))
  // this.totalSelectedPrice = this.totalSelectedPrice + ((bidItem.mrp - (bidItem.mrp* bidItem.sellingDiscount /100))*bidItem.itemQuantity);
  this.totalSelectedPriceQuote = this.customerQuotation.filter(quote => quote.selected).reduce((total, quote) => total+(quote.mrp *quote.quantity), 0);
  this.totalapproveSelectedPriceQuote=this.customerQuotation.filter(quote => quote.selected).reduce((total, quote) => total+(quote.sellingPrice *quote.quantity), 0);
  // this.totalEditSelectedPriceQuote=this.customerQuotation.filter(quote => quote.selected).reduce((total, quote) => total+(quote.sellingPrice *quote.quantity), 0);
  // console.log("thisuoaqwjdwedj",this.customerQuotation);
  // console.log("this.totalEditSelectedPriceQuote--",this.totalEditSelectedPriceQuote);
  this.totalEditSelectedPriceQuote = this.customerQuotation
  .filter(quote => quote.selected)
  .reduce((total, quote) => total + (quote.mrp * quote.quantity), 0);

// console.log("Updated customerQuotation:", this.customerQuotation);
// console.log("this.totalEditSelectedPriceQuote--", this.totalEditSelectedPriceQuote);

}
anyItemAlreadyApprovedQuote1(){
  return this.selectedAlreadyApprovedQuotelength > 0;
}

  anyItemSelectedApprovedQuote(){
    return this.selectedItemsCountapprovedQuote > 0;
  }
  anyItemApprovedQuote(){
    return this.totalApprovedItemsCount>0;
  }
  getMakeImageUrl(make: string): string {
    if(make){
      const makeWithoutSpaces = make.toLowerCase().replace(/\s+/g, '');
      return `https://cube.getpitstop.com/assets/img/${makeWithoutSpaces}.png`;
    }else {
      return
    }
  }
  totalAmount(): number {
    return this.items.filter(item => item.selected).reduce((total, item) => total + item.price, 0);
  }
  totalAmountQuote(): number {
    return this.items.filter(quote => quote.selected).reduce((total, item) => total + item.price, 0);
  }
  toggleSelectAllQuote(): void {
    this.selectAllQuote = !this.selectAllQuote;
    this.customerQuotation.forEach(quote => quote.selected = this.selectAllQuote);
    this.selectedItemsLabourQuote.forEach(quote=> quote.selected =this.selectAllQuote);
    // console.log("this.selected Labours---",this.selectedItemsLabourQuote);
    this.updateSelectedItemsQuote();
    this.updateSelectedLabourItems();
  }
  // toggleIndividualQuote(quote: any): void {
  //   quote.selected = !quote.selected;
  
  //   // If any checkbox is unchecked, unselect "Select All"
  //   this.selectAllQuote = this.customerQuotation.every(q => q.selected) && this.selectedItemsLabourQuote.every(q => q.selected);
  
  //   // Update calculations
  //   this.updateSelectedItemsQuote();
  //   this.updateSelectedLabourItems();
  // }
  
  calculateMRP(item: any): number {
    this.customerQuotation.forEach
    return (item.mrp - (item.mrp * item.sellingDiscount / 100)) * item.itemQuantity;
  }
  calculateTotalMRP(): void {
    this.totalMRP = this.items.reduce((total, item) => {
      return total + this.calculateMRP(item);
    }, 0);
  }

  toggleItemEditSelectionQuote(): void {
    const allSparesSelected = Array.isArray(this.customerQuotation) && this.customerQuotation.every(quote => quote.selected);
    const allLaboursSelected = Array.isArray(this.selectedItemsLabourQuote) && this.selectedItemsLabourQuote.every(labour => labour.selected);
    this.selectAllQuote = allSparesSelected && allLaboursSelected;
    this.selectedLabourCount = Array.isArray(this.selectedItemsLabourQuote)
      ? this.selectedItemsLabourQuote.filter(labour => labour.selected).length
      : 0;
    this.updateSelectedItemsQuote();
    this.updateSelectedLabourItems();
    this.cdr.detectChanges();
  }

  toggleItemApproveSelectionQuote(): void {
    const allSparesSelected = Array.isArray(this.customerQuotation) && this.customerQuotation.every(quote => quote.selected);
    const allLaboursSelected = Array.isArray(this.selectedItemsLabourQuote) && this.selectedItemsLabourQuote.every(labour => labour.selected);
    this.selectAllQuote = allSparesSelected && allLaboursSelected;
    this.selectedLabourCount = Array.isArray(this.selectedItemsLabourQuote)
      ? this.selectedItemsLabourQuote.filter(labour => labour.selected).length
      : 0;
    this.updateSelectedItemsQuote();
    this.updateSelectedLabourItems();
    this.cdr.detectChanges();
  }
  toggleItemViewApproveSelectionQuote(): void {
    const allSparesSelected = Array.isArray(this.customerQuotation1) && this.customerQuotation1.every(quote => quote.selected);
    const allLaboursSelected = Array.isArray(this.selectedItemsLabour) && this.selectedItemsLabour.every(labour => labour.selected);
    this.selectAllQuote = allSparesSelected && allLaboursSelected;
    this.selectedLabourCount = Array.isArray(this.selectedItemsLabour)
      ? this.selectedItemsLabour.filter(labour => labour.selected).length
      : 0;
    this.updateSelectedItemsQuote();
    this.updateSelectedLabourItems();
    this.cdr.detectChanges();
  }
  


  toggleItemSelectionQuote(): void {
    if (Array.isArray(this.customerQuotation)) {
      this.selectAllQuote = this.customerQuotation.every(quote => quote.selected);
    } else {
      this.selectAllQuote = false;
    }
    //  // if (Array.isArray(this.customerQuotation1))
    //  if (Array.isArray(this.customerQuotation1)) {alert("items")
    //   this.customerQuotation1.forEach(quote => {
    //     quote.selected = this.selectAllQuote;
    //   });
    // } else {
    //   this.selectAllQuote = false;  
    //  }
  
    if (Array.isArray(this.selectedItemsLabourQuote)) {
      this.selectedItemsLabourQuote.forEach(labour => {
        labour.selected = labour.selected ? true : false;
        
      });

  
      this.selectedLabourCount = this.selectedItemsLabourQuote.filter(labour => labour.selected).length;
    }
     else {
      this.selectedLabourCount = 0;
    }

    
  
    // Ensure selectedItemsLabourQuote is updated
    this.updateSelectedItemsQuote();
    this.updateSelectedLabourItems();
  
    // Force UI update
    this.cdr.detectChanges();
  }
  hasPendingQuotes(): void {
  this.customerQuotation1?.some(item => item.quoteItemStatus === 2);
}


  toggleItemviewSelectionQuote(item, type) {
    if (type === 'view') {
      if (Array.isArray(this.customerQuotation1)) {
        let allSelected = true;
        this.customerQuotation1.forEach(quote => {
          if (quote === item) {
            quote.selected = !quote.selected; // Toggle the selected item
          }
          if (!quote.selected) {
            allSelected = false; // Check if all are selected
          }
        });
        this.selectAllQuote = allSelected;
      } else {
        // console.log('error: select all quotes');
      }
    } else if (type === 'view') {
      if (Array.isArray(this.selectedItemsLabour)) {
        let allSelected = true;
        this.selectedItemsLabour.forEach(labour => {
          if (labour === item) {
            labour.selected = !labour.selected; // Toggle the selected item
          }
          if (!labour.selected) {
            allSelected = false; // Check if all are selected
          }
        });
        this.selectAllQuote = allSelected;
      } else {
        // console.log('error: select all labour');
      }
    }
  
    this.updateSelectedItemsQuote();
    this.updateSelectedLabourItems();
  }
  

  
  openEditDialog(itemQuotes){
    // console.log("itemsquote0--",itemQuotes)
    // console.log("this.customerQuotation",this.customerQuotation);
    
    this.itemQuote = [];
    this.itemQuote=itemQuotes;
    this.itemQuote.discountedPrice = this.calculateDiscountedPrice(itemQuotes.sellingPrice, itemQuotes.discount);
    this.isPopupOpenEdit = true;
    this.selectedItemsCountQuote = 0;
    this.customerQuotation.forEach(quote => {
      quote.selected = false;
    });
    this.customerQuotation.forEach(quote => quote.selected = this.selectAllQuote=false);
  }


  calculateDiscountedPrice(mrp: number, discount: number): number {
    return mrp - (mrp * discount / 100);
  }
  save(): void {
    // console.log("this.itemquote--",this.customerQuotation);
    
    const index = this.customerQuotation.findIndex(i => i.name === i.itemName);
    // console.log("index---", index);
    
    if (index !== -1) {  // Correct condition
      this.customerQuotation[index].mrp = Number(this.itemQuote.mrp);
      this.customerQuotation[index].totalAmount = Number(this.itemQuote.mrp) * this.itemQuote.itemQuantity;
        }
      this.customerQuotation = [...this.customerQuotation];  // Create a new reference

    
    // Recalculate the total quotation price
    this.customerQuotationPrice = this.calculateTotalQuotationPrice();

    this.updateSelectedItemsQuote();
    this.getQuoteGrandTotal();
    this.closePopupEdit();

}
closePopupEdit(): void {
  this.isPopupOpenEdit = false;
}
calculateTotalQuotationPrice(): number {
  // Recalculate total price by summing up all items' total amounts
  return this.customerQuotation.reduce((sum, item) => sum + (item.mrp * item.itemQuantity), 0);
}

// openPopup(): void {
//   this.isPopupOpen = true;
//   this.CloseSidebarQuote();
//   this.submitData(); 
// }

// closePopup(): void {
//   this.isPopupOpen = false;
// }

shareForApproval(): void {
  let garageName = localStorage.getItem('spareSelectedGarageName');
  const message = `*Approval Needed for Your Spare Parts Quotation*\n\n`+
  `Dear Customer\n\n`+
  `We've prepared the spare parts quotation for your car's repair. 🚗\n`+
  `Please review and approve it at your earliest convenience by clicking the link below:\n\n`+
  `👉 ${GlobalVariable.AUTOVERSE_WEB}/quote-details/`+this.quoteId+`/`+this.jobcardId+` \n\n`+
  `Your prompt approval will help us speed up the repair process. 🛠\n\n`+
  `Thank you for choosing `+garageName+`. We value your trust in us!\n\n`+
  `Best Regards,\n`+
  `*`+garageName+`*`;

  // WhatsApp API or Intent to share
  window.open(`https://web.whatsapp.com/send?text=${encodeURIComponent(message)}`, '_blank');

}
}
