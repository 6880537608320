import { Component, Input, OnInit, OnChanges, SimpleChanges, HostListener, ElementRef, ViewChild,ChangeDetectorRef,ComponentFactoryResolver,ViewContainerRef, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SideBarJobcardService } from './side-bar-jobcard.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { S3ImageUploadService } from 'app/shared/s3-image-upload.service';
import { HttpClient } from '@angular/common/http';
import { AspHomeService } from '../../auto-spare-parts/asp-home/asp-home.service';
import { AspLoadingComponent } from '../asp-loading/asp-loading.component';
import { AspVehicleServiceHistoryComponent } from '../asp-vehicle-service-history/asp-vehicle-service-history.component';
import { AspSparesListService } from '../asp-spares-list/asp-spares-list.service';
import { AnimationOptions } from 'ngx-lottie';
import { ThrowStmt } from '@angular/compiler';
import { AspLoadingService } from '../asp-loading/asp-loading.service'; 

@Component({
  selector: 'app-side-bar-jobcard',
  templateUrl: './side-bar-jobcard.component.html',
  styleUrls: ['./side-bar-jobcard.component.scss'],
})
export class SideBarJobcardComponent implements OnInit, OnChanges {
  @ViewChild('drawingCanvas', { static: false }) drawingCanvas!: ElementRef<HTMLCanvasElement>;
  canvasContext!: CanvasRenderingContext2D | null;
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef<HTMLInputElement>;
  @ViewChild('imageElement', { static: false }) imageElement!: ElementRef<HTMLImageElement>;
  @ViewChild('frontFileInput') frontFileInput!: ElementRef<HTMLInputElement>;
  @ViewChild('engineFileInput') engineFileInput!: ElementRef<HTMLInputElement>;
  @ViewChild('batteryFileInput') batteryFileInput!: ElementRef<HTMLInputElement>;
  @ViewChild('rightFileInput') rightFileInput!: ElementRef<HTMLInputElement>;
  @ViewChild('backFileInput') backFileInput!: ElementRef<HTMLInputElement>;
  @ViewChild('leftFileInput') leftFileInput!: ElementRef<HTMLInputElement>;
  @ViewChild('addFileInput') addFileInput!: ElementRef<HTMLInputElement>;
  @ViewChild('dynamicContainers', { read: ViewContainerRef }) containers!: ViewContainerRef;
  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  @ViewChild('vehicleServiceHistoryContainer', { read: ViewContainerRef }) historyContainer!: ViewContainerRef;
  @ViewChild('nextButton', { static: false }) nextButton!: ElementRef;
  @ViewChild('fuelFileInput') fuelFileInput!: ElementRef<HTMLInputElement>;
  private originalBottom: string = '0px';





  otherImages: Array<{ image: string; key: string }> = [];
  otherTag: string = '';
  otherImageFile: File | null = null;
  isDrawing = false;
  selectedColor = 'blue';
  carDetailsForm: FormGroup;
  isBottomSheetOpen: boolean = false;
  imageTag: string = ''; 
  canvasWidth = 1000; 
  canvasHeight = 600; 
  selectedTab = 'Front'; 
  selectedMake: string;
  selectedModel: string;
  selectedYear: string;
  vehicleData: any = [];
  selectedVariant: string;
  errorMessage: string = '';
  filteredIssues = [];
  searchText = ''; 
  vehicleInfo: boolean = false;
  selectedFile: File | null = null;
  vehicleForm1: FormGroup;
  formSubmitted = false;
  loading: boolean = false;
  submitted = false;
  jobCardDetails = true;
  isManualSelection: boolean = false;
  customerDetailsANDcarDetails = false;
  isVisible = false;
  isConfirmationSidebarOpen = false;
  jobCard: any = {}; // Store car details here
  // @Input() bookingId: number | null = null;
  @Input() bookingId!: string;
  @Input() name: string | null = null;
  @Input() phoneNumber: string | null = null;
  fuelAccessoriesContainer: boolean = false;
  selectedFilePreview: string | ArrayBuffer | null = null;
  accessories: any[] = [];
  carDetailsandcustomerDetails: boolean = true;
  issuesSection: boolean = false;
  imageSection: boolean = false;
  exteriorSection: boolean = false;
  selectedServices: string[] = [];
  isDropdownOpen = false;
  serviceList: string[] = [];
  isSearchOpen = false;
  selectedSection: string = '';
  damagedImagesData: { [key: string]: string | null } = {
    Front: null,
    Top: null,
    Right: null,
    Left: null,
    Rear: null
  };
  documentData: { name: string; type: string; fileURL: string | null } = {
    name: '',
    type: '',
    fileURL: null,
  };
  isImageSaved: boolean = false;
  selectedIssues: string[] = [];
  uploadedImages: string[] = [];
  frontImage: string | null = null;
  engineImage: string | null = null;
  batteryImage: string | null = null;
  rightImage: string | null = null;
  backImage: string | null = null;
  leftImage: string | null = null;
  fuelLevelImage: string | null = null;
  addImage: string | null = null;
  searchQuery = '';
  engineNumberForm: FormGroup;


  // Other properties
  garageKey: any;
  customerName: string;
  registrationNumber: string;
  variantKeyValue: any;
  currentUserId: any;
  rowKeyValue: any;
  isJobCardCreatedPopupVisible: boolean = false;
  successIcon: string = '../../../../assets/icons/icons8-ok.gif';
  date: any;
  jobCardId: any;
  isMobile: boolean;
  isSidebarOpen: boolean = true;
  isSideSliderOpen: boolean = false;
  drawingHistory: any[] = [];
  currentPath: { x: number, y: number }[] = [];
  selectedImages: { [key: string]: string } = {};

  car =  {
    regNumber: '',
    vin: localStorage.getItem('vin') || '',
    engineNumber: '',
    odometerReading: '',
    lastServiceDate: '',
    selectedServices: [] as string[],
  }
  customer = {
    name: '',
    mobile: '',
    email: '',
  }

  fuelAndAccessories: {
    fuelLevel: number;
    checkedItems: string[];
  } = {
    fuelLevel: 0, // Default fuel level
    checkedItems: [] // Initially no items selected
  };

  issuesData: any[] = [];
  data: any;
  newlyUploadedImages: any;
  uploadedDocuments: any;
  uploadedimage: any;
  SideBarJobcardService: any;
  selectedFileName: string;
  FinalJobcardDetails: any;
  regNo: string;
  carandcustomerDetails: any;
  isImageModified: boolean;
  showServiceHistory: boolean;
  customerTag: any;
  isExistingCustomer: any;
  getBookingId: any;
  Messages: string[] = []; // Change from boolean to an array
  isSearchSidebarOpen = false;

  
  // imageElement: any;
  // fileInput: any;
  options: AnimationOptions = {
    path: './assets/images/loader.json' // Replace with your JSON animation file URL
  };
  newImages: any[] = [];

  constructor(
    private fb: FormBuilder,
    private _formBuilder: FormBuilder,
    private sideBarJobcardService: SideBarJobcardService,
    private elementRef: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private s3ImageUploadService: S3ImageUploadService,
    private http: HttpClient,
    public toastr: ToastrService,
    private homeService: AspHomeService,
    private cdr: ChangeDetectorRef,
    private resolver: ComponentFactoryResolver,
    private aspSparesListService: AspSparesListService,
    private aspLoadingService: AspLoadingService,
  ) {
      this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => { 
      console.log("sidebarjobcard-response",result);
      this.isMobile = result.matches;
    });

    
    this.carDetailsForm = this._formBuilder.group({
      odometerReading: [
        '',
        [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)],
      ],
      selectedServices: ['', Validators.required],
      customerName: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)],
      ],
      customerMobile: [
        '',
        [
          Validators.required,
          Validators.pattern(/^-?(0|[1-9]\d*)?$/),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
    });

  }

  get engineNumber() {
    return this.engineNumberForm.get('engineNumber');
  }

  ngOnInit(): void {
    if(localStorage.getItem('jobcard') && localStorage.getItem('jobcard') === '1' ){
      this.isManualSelection = true;
    }
    
    // Initialize form with phoneNumber and name from Input properties
    this.vehicleForm1 = this._formBuilder.group({
       regNo: [this.regNo ||'', [Validators.required,Validators.minLength(12), Validators.maxLength(12)]],
      // odometer: ['', [Validators.required]],
      rowKey: [''],
      variantKey: [''],
      // phoneNumber: [this.phoneNumber || '', [Validators.required, Validators.minLength(10)]],
      // name: [this.name || '', Validators.required]
    });
    // Initialize vehicleForm1 instead of vehicleForm
    this.vehicleForm1.get('regNo')?.valueChanges.subscribe(value => {
      if (value) {
        // this.vehicleForm.get('regNo')?.setValue(value.toUpperCase(), { emitEvent: false });
        const formattedValue = this.formatRegNo(value);
        this.vehicleForm1.get('regNo')?.setValue(formattedValue, { emitEvent: false });
      }
    });
    this.vehicleData = JSON.parse(localStorage.getItem("vehicleData"));
    if(this.vehicleData){
      this.vehicleInfo = true;
    }else{
      this.vehicleInfo = false;
    }
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // Detect if it's a reload
        if (event.navigationTrigger === 'popstate') {
          // Clear localStorage if the page was reloaded
          this.clearLocalStorage();
        }
      }
    });


    if (!this.bookingId ){
      // Fetch details from localStorage
      this.selectedMake = localStorage.getItem('selectedMake') || '';
      this.selectedModel = localStorage.getItem('selectedModel') || '';
      this.selectedVariant = localStorage.getItem('variant') || '';
      this.selectedYear = localStorage.getItem('selectedYear') || '';

      this.jobCard = {
        make: this.selectedMake,
        model: this.selectedModel,
        variant: this.selectedVariant,
        mfgYear: this.selectedYear,
        fuelType: localStorage.getItem('fuelType') || '',
        vin: localStorage.getItem('vin') || ''
      };

      // Automatically populate the form
      this.vehicleForm1.patchValue({
        make: this.selectedMake,
        model: this.selectedModel,
        variant: this.selectedVariant,
        year: this.selectedYear
      });
      
    }
  }

  get f() { return this.vehicleForm1.controls; }
  onSubmit() {
    this.aspLoadingService.show();
    this.submitted = true;
    if (this.vehicleForm1.invalid) {
        return;
    }

    this.homeService.regDecode(this.f.regNo.value.replace(/\s/g, "")).subscribe(
      res => {
        if(res['code']==200){
          this.aspLoadingService.hide();
          this.vehicleInfo = true; 
          this.vehicleData = res['data'];
          console.log("vechileData",this.vehicleData);
          localStorage.setItem("vehicleData", JSON.stringify(this.vehicleData));  
          localStorage.setItem("registrationNumber",this.vehicleData.registrationNumber.replace(/"/g, ''));  

        }else{
          this.toastr.error(res['data']['description'], 'Error');          
          this.aspLoadingService.hide();
        }
      }
    );
  }

formatRegNo(value: string): string {
  value = value.replace(/\s+/g, '').toUpperCase();
  if (value.length > 2) {
      value = value.slice(0, 2) + ' ' + value.slice(2);
  }
  if (value.length > 5) {
      value = value.slice(0, 5) + ' ' + value.slice(5);
  }
  return value;
}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.phoneNumber && changes.phoneNumber.currentValue) {
      this.vehicleForm1.patchValue({
        phoneNumber: changes.phoneNumber.currentValue
      });
    }
    if (changes.name && changes.name.currentValue) {
      this.vehicleForm1.patchValue({
        name: changes.name.currentValue
      });
    }
  }

 @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside && this.isVisible) {
      this.closeSidebar(true);
      this.isVisible = true;
    }
  }
  closeAll(){
    this.closeSidebar(true);
    this.goBack();  
    this.isVisible = false;
    this.customerDetailsANDcarDetails=false;
  this.fuelAccessoriesContainer = false;

  }


  // onCreateJobCardClick(): void {
  //   this.isVisible = false;
  //   this.isSidebarOpen = true;
  // }


  onRegDecodeSubmit(): void {
    this.isManualSelection = false;
    this.formSubmitted = true;
    
    this.vehicleForm1.markAllAsTouched();
    if (this.vehicleForm1.valid) {
      this.aspLoadingService.show();
      const regNo = this.vehicleForm1.get('regNo')?.value.replace(/\s/g, '');
      const odometer = this.vehicleForm1.get('odometer')?.value;
      const phoneNumber = this.vehicleForm1.get('phoneNumber')?.value;
      const name = this.vehicleForm1.get('name')?.value;
      const garageKey=localStorage.getItem("garageKey")
      console.log("garageKey")
      this.sideBarJobcardService.regDecode(regNo,garageKey).subscribe(
        (res: any) => {
          console.log("sidebarjobcard-response",res);
          this.aspLoadingService.hide();
          this.jobCard = {
            make: res.data.make,
            registrationNumber: res.data.registrationNumber,
            model: res.data.model,
            variant: res.data.variant,
            mfgYear: res.data.year,
            fuelType: res.data.fuel,
            vin: res.data.vin,
            ExistingCustomer:res.data.isExistingCustomer
          };
          console.log("ExistingCustomer--",this.jobCard.ExistingCustomer);
           localStorage.setItem('selectedMake',res.data.make)
           localStorage.setItem('selectedModel',res.data.model)
           localStorage.setItem('selectedYear',res.data.year)
           localStorage.setItem('fuelType',res.data.fuel)
           localStorage.setItem('vin',res.data.vin)
           localStorage.setItem('variant',res.data.variant)
           localStorage.setItem('registrationNumber',res.data.registrationNumber)
          this.vehicleForm1.patchValue({
            rowKey: res.data.rowKey,
            variantKey: res.data.variantKey,
          });
          const bookingId = res.data.bookingId;
          this.fetchPreFilledBooking(res.data.registrationNumber, bookingId);
          this.isVisible = false;
          this.isConfirmationSidebarOpen = true;
          this.jobCardDetails=true;
        },
        
        (error: any) => {
          console.error('Error:', error);
          this.aspLoadingService.hide();
        }
      );
    } else {
      this.vehicleForm1.markAllAsTouched();
    }
  }  

  closeSidebar(ishide: boolean): void {
    this.isVisible = ishide;
    this.isConfirmationSidebarOpen = false;
    if (localStorage.getItem('jobcard') && localStorage.getItem('jobcard') === '1') {
      localStorage.removeItem('jobcard');
      localStorage.removeItem('rowKey');
      localStorage.removeItem('selectedMake');
      localStorage.removeItem('selectedModel');
      localStorage.removeItem('selectedYear');
      localStorage.removeItem('variant');
      localStorage.removeItem('variantKey');
      localStorage.removeItem('fuelType');
      localStorage.removeItem('name');
      localStorage.removeItem('odometer');
      localStorage.removeItem('phoneNumber');
    }
  }

  toggleConfirmationSidebar(): void {
    this.isConfirmationSidebarOpen = !this.isConfirmationSidebarOpen;
    if (!this.isConfirmationSidebarOpen) {
      this.isVisible = true;
      this.ngOnInit();
    }
  }

  confirmCarDetails(param): void {
    this.aspLoadingService.show();    
    this.sideBarJobcardService.createbookingId(param).subscribe(
      (response: any) => {
        if (response && response.code === 200) {
          console.log("confirmdetails,response",response);
          this.aspLoadingService.hide();
          this.jobCardId = response.data.jobCardId;
          this.date = response.data.createdAt;
          this.isConfirmationSidebarOpen = false;
          this.isJobCardCreatedPopupVisible = true;
          this.clearLocalStorage();
        } else {
          this.aspLoadingService.hide();
          console.error('Unexpected response from API:', response);
        }
      },
      (error: any) => {
        this.aspLoadingService.hide();
        console.error('Error:', error);
        alert('Failed to create booking due to a server error.');
      }
    );
  }

  clearLocalStorage(): void {
    localStorage.removeItem('jobcard');
    localStorage.removeItem('rowKey');
    localStorage.removeItem('selectedMake');
    localStorage.removeItem('selectedModel');
    localStorage.removeItem('selectedYear');
    localStorage.removeItem('variant');
    localStorage.removeItem('variantKey');
    localStorage.removeItem('fuelType');
    localStorage.removeItem('name');
    localStorage.removeItem('odometer');
    localStorage.removeItem('phoneNumber');
    if(localStorage.getItem("isFindMyCar")!='1'){
      localStorage.removeItem('selectedSparesList');
    }
    localStorage.removeItem('preSearchValue');
  };


  redirectToVehicleForm() {
    this.router.navigate(['vehicle']);
  
  }
  
  

  closeJobCardPopup() {
    this.isJobCardCreatedPopupVisible = false;
    this.ngOnInit();
    this.router.navigate(['my-job-cards']).then(() => {
      window.location.reload();
     });
  }

  shareOnWhatsApp(): void {
    if (this.jobCard || this.jobCardId) {
      const message = `
        👋 Hello ${this.vehicleForm1.get('name')?.value || 'Customer'},
        🛠️ Your job card has been created successfully.
        📋 Job Card ID: ${this.jobCardId || 'N/A'}
        🚗 Odometer: ${this.vehicleForm1.get('odometer')?.value || 'N/A'}  
        📝 Registration Number: ${this.vehicleForm1.get('regNo')?.value || 'N/A'}
        🏷️ Make: ${this.jobCard.make || 'N/A'}
        🚙 Model: ${this.jobCard.model || 'N/A'}
        📅 Variant: ${this.jobCard.variant || 'N/A'}
        📆 MFG Year: ${this.jobCard.mfgYear || 'N/A'}
        ⛽ Fuel: ${this.jobCard.fuelType || 'N/A'}
        🔑 VIN: ${this.jobCard.vin || 'N/A'}
        We will soon send you a quotation for approval.
        Best regards, ${localStorage.getItem('spareSelectedGarageName') || 'Garage'}
      `;
      const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
      window.open(url, '_blank');
    } else {
      alert('No booking data available to share.');
    }
  }


  // isManualSelectionValid(): boolean {
  //   const name = this.vehicleForm1.get('name')?.value;
  //   const phoneNumber = this.vehicleForm1.get('phoneNumber')?.value;
  //   const odometer = this.vehicleForm1.get('odometer')?.value;

  //   return !!(name && phoneNumber && odometer && phoneNumber.length === 10);
  // }

  redirectToManualSelection(): void {
    this.formSubmitted = true;
    this.vehicleForm1.markAllAsTouched();
    // if (this.isManualSelectionValid()) {

      localStorage.setItem('name', this.vehicleForm1.get('name')?.value);
      localStorage.setItem('phoneNumber', this.vehicleForm1.get('phoneNumber')?.value);
      // localStorage.setItem('odometer', this.vehicleForm1.get('odometer')?.value);
      localStorage.setItem('jobcard', '1');
      this.router.navigate(['/make']);
      this.isConfirmationSidebarOpen = true;
    // } 
    // else{
    //   this.vehicleForm1.markAllAsTouched(); 
    //   // }
  }
  openCarDetailsSidebar(): void {
    this.isSideSliderOpen = true; // Set the slider to open
    console.log('Side slider opened');
    // this.closeAllSidebars();
    this.jobCardDetails = false;

    
  }

  confirmDetails() {
    this.jobCardDetails = false; 
    this.customerDetailsANDcarDetails=true

  }
  // Navigate to Fuel and Accessories section
  goToAccessories(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.carDetailsandcustomerDetails = false;
    this.jobCardDetails = false; 
    this.fuelAccessoriesContainer = true;
    this.fetchAccessories();
  }

  // Navigate to Issues section
  goToIssuesSection(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.jobCardDetails = false; 
    this.carDetailsandcustomerDetails = false;
    this.fuelAccessoriesContainer = false;
    this.issuesSection = true;
    this.fetchServices();
  }
  // Navigate to Exterior section
  goToExteriorSection(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.jobCardDetails = false; 
    this.carDetailsandcustomerDetails = false;
    this.fuelAccessoriesContainer = false;
    this.issuesSection = false;
    this.exteriorSection = true;
  }
  // Navigate to Image section
  goToImageSection(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.jobCardDetails = false; 
    this.carDetailsandcustomerDetails = false;
    this.fuelAccessoriesContainer = false;
    this.issuesSection = false;
    this.exteriorSection = false;
    this.imageSection = true;
  }

  // back to the previous sidebar 
  goBack(): void {
    if (this.imageSection) {
      this.imageSection = false; // Turn off the current section
      this.exteriorSection = true; // Activate the next section
    } else if (this.exteriorSection) {
      this.exteriorSection = false;
      this.issuesSection = true;
    } else if (this.issuesSection) {
      this.issuesSection = false;
      this.fuelAccessoriesContainer = true;
    } else if (this.fuelAccessoriesContainer) {
      this.fuelAccessoriesContainer = false;
      this.carDetailsandcustomerDetails = true;
    } else if (this.customerDetailsANDcarDetails) {
      this.customerDetailsANDcarDetails = false;
      this.jobCardDetails = true;
    } else {
      console.warn("No section is currently active to switch from.");
    }
  }

  // back to the previous sidebar. end----------------------------------


  // --------------------------DROPDOWN IN SERVICE TYPE SECTION--------------------------
  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
      
      // Fetch services when dropdown is opened
      if (this.isDropdownOpen) {
        this.fetchServices(); // Call the API to fetch services
      }
  }

  // Fetch service list from the backend
  fetchServices(): void {
    this.sideBarJobcardService.getServiceList().subscribe(
      (response) => {
        if (response.code === 200) {
          this.data=response.data;
          console.log("data",this.data)
          // Populate service list with response data
          this.serviceList = response.data.serviceTypes; // Assuming response has a 'serviceTypes' field
          // console.log('Fetched Service List:', this.serviceList);
        } else {
          // console.error('Error fetching service list:', response.message);
        }
      },
      (error) => {
        console.error('API Error:', error);
      }
    );
    this.fetchAccessories();
    this.fetchIssues();
  }


  // Select or deselect a service
  // selectService(service: string, event: any): void {
  //   if (event.target.checked) {
  //     this.selectedServices.push(service); // Add service to selected list
  //   } else {
  //     const index = this.selectedServices.indexOf(service);
  //     if (index > -1) {
  //       this.selectedServices.splice(index, 1); // Remove service from selected list
  //     }
  //   }
  // }

  selectService(service: string, event: Event) {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      if (!this.car.selectedServices.includes(service)) {
        this.car.selectedServices.push(service);
      }
    } else {
      this.car.selectedServices =
        this.car.selectedServices.filter((s) => s !== service);
    }
  }

  // saveDetails() {
  //   const car = this.car;
  //   const customer = this.customer;

  //   this.errorMessage = '';

  //   // Validation flags
  //   let isValid = true;
  //   // Check if required car fields are filled
  //   if (!car.odometerReading || car.selectedServices.length === 0) {
  //     // this.errorMessage = 'Please fill mandatory fields';
  //     return;
  //   }

  //   // Check if required customer fields are filled
  //   if (!customer.name || !customer.mobile) {
  //     this.errorMessage = 'Please fill mandatory fields: Name and Mobile.';
  //     return;
  //   }

  //   // Check if phone number is valid (10 digits only)
  //   const phonePattern = /^[0-9]{10}$/;
  //   if (!customer.mobile || customer.mobile.length < 10) {
  //     this.errorMessage = 'Should be a minimum of 10 digits.';
  //     return;
  //   }
    
  //   if (!phonePattern.test(customer.mobile)) {
  //     this.errorMessage = 'Please enter a valid phone number (10 digits).';
  //     return;
  //   }

  //   // Check if the name contains only letters
  //   const namePattern = /^[a-zA-Z\s]+$/;
  //   if (customer.name && !namePattern.test(customer.name)) {
  //     this.errorMessage = 'Please enter a valid name (letters only).';
  //     return;
  //   }

  //   // Check if odometer reading contains only numbers
  //   const odometerPattern = /^[0-9]+$/;
  //   if (car.odometerReading && !odometerPattern.test(car.odometerReading)) {
  //     this.errorMessage = 'Please enter a valid odometer reading (numbers only).';
  //     return;
  //   }

  //   // Clear the error message if all validations pass
  //   this.errorMessage = '';

  //   // Proceed if all validations pass
  //   console.log('Saved details:', this.carandcustomerDetails);
  //   this.carDetailsandcustomerDetails = false;
  //   this.goToAccessories();
  // }

  saveDetails() {
    const car = this.car;
    const customer = this.customer;

    this.errorMessage = '';

    // Helper function to scroll to a specific element
    const scrollToField = (elementId: string) => {
        setTimeout(() => {
            const element = document.getElementById(elementId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                (element as HTMLElement).focus();
            }
        }, 100);
    };

    // Check if name is empty and scroll
    if (!customer.name) {
        this.errorMessage = 'Please fill mandatory fields: Name and Mobile.';
        scrollToField('customerNameInput'); // Scroll to name field
        return;
    }

    // Check if phone number is empty and scroll
    if (!customer.mobile) {
        this.errorMessage = 'Please fill mandatory fields: Name and Mobile.';
        scrollToField('customerMobileInput'); // Scroll to phone number field
        return;
    }

    // Check if service type is empty and scroll
    if (!car.selectedServices || car.selectedServices.length === 0) {
        this.errorMessage = 'Please select a service type.';
        scrollToField('serviceTypeInput'); // Scroll to service type dropdown
        return;
    }

    // Validation flags
    let isValid = true;

    // Check if phone number is valid (10 digits only)
    const phonePattern = /^[0-9]{10}$/;
    if (!customer.mobile || customer.mobile.length < 10) {
        this.errorMessage = 'Should be a minimum of 10 digits.';
        return;
    }

    if (!phonePattern.test(customer.mobile)) {
        this.errorMessage = 'Please enter a valid phone number (10 digits).';
        return;
    }

    // Check if the name contains only letters
    const namePattern = /^[a-zA-Z\s]+$/;
    if (customer.name && !namePattern.test(customer.name)) {
        this.errorMessage = 'Please enter a valid name (letters only).';
        return;
    }

    // Check if odometer reading contains only numbers
    const odometerPattern = /^[0-9]+$/;
    if (car.odometerReading && !odometerPattern.test(car.odometerReading)) {
        this.errorMessage = 'Please enter a valid odometer reading (numbers only).';
        return;
    }

    // Clear the error message if all validations pass
    this.errorMessage = '';

    // Proceed if all validations pass
    console.log('Saved details:', this.carandcustomerDetails);
    this.carDetailsandcustomerDetails = false;
    this.goToAccessories();
    this.scrollToTop();

}

scrollToTop() {
  if (this.scrollContainer) {
    this.scrollContainer.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
  } else {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

    // Remove a service from the selected services list
  removeService(service: string) {
    console.log('Selected services:', this.car.selectedServices); // Log the full array
    console.log('Service to remove:', service); // Log the specific service to be removed
    const index = this.car.selectedServices.indexOf(service);
    if (index > -1) {
      this.car.selectedServices.splice(index, 1);
    } else {
      console.log('Service not found in array');
    }
  }
// ----pendil color change-----------


  toggleDrawing(): void {
    this.isDrawing = !this.isDrawing; 
    if (this.isDrawing) {
      this.selectedColor = 'red'; 
    } else {
      this.selectedColor = 'black'; 
    }
  }

  // --------------------------DROPDOWN IN SERVICE TYPE SECTION ENDS HERE--------------------------

  // ---------------Update fuel level--------------
  updateFuelLevel(event: any): void {
    this.fuelAndAccessories.fuelLevel = event;
    console.log('Fuel Level:', this.fuelAndAccessories.fuelLevel);
  }

  // Fetch accessories data from API
  fetchAccessories(): void {
    this.sideBarJobcardService.getServiceList().subscribe(
      (response) => {
        // console.log('Fetched Accessories response:', response);
        if (response.code === 200) {
          this.accessories = response.data.accessories || [];       
          // console.log('Fetched Accessories:', this.accessories);
        } else {
          console.error('Error fetching accessories:', response.message);
        }
      },
      (error) => {
        console.error('API Error:', error);
      }
    );
  }


  // ---------------------Add or remove accessories----------------------
  selectAccessory(accessory: string, event: any): void {
    if (event.target.checked) {
      this.fuelAndAccessories.checkedItems.push(accessory);
    } else {
      const index = this.fuelAndAccessories.checkedItems.indexOf(accessory);
      if (index > -1) {
        this.fuelAndAccessories.checkedItems.splice(index, 1);
      }
    }
    console.log('Fuel and Accessories:', this.fuelAndAccessories);
  }
  // -----------------------selection tabs to mark damaged images ---------------------------
  // selectTab(tab: string) {
  //   this.selectedTab = tab;

  //   // Clear the canvas for the new tab
  //   const canvas = this.drawingCanvas.nativeElement;
  //   if (this.canvasContext) {
  //     this.canvasContext.clearRect(0, 0, canvas.width, canvas.height);
  //   }

  //   // Optionally restore previous drawings if needed (not specified in the requirement)
  // }

  selectTab(tab: string) {
    // Save the marked image before switching tabs
    this.saveMarkedImage(); 

    // Switch to the selected tab
    this.selectedTab = tab;

    setTimeout(() => {
      if (this.nextButton) {
        console.log('Scrolling to NEXT button'); // Log before scrolling
        this.nextButton.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        console.log('NEXT button not found'); // Log if the element is not found
      }
    }, 100); // Delay to ensure DOM updates
  
    // Clear the canvas for the new tab
    const canvas = this.drawingCanvas.nativeElement;
    if (this.canvasContext) {
        this.canvasContext.clearRect(0, 0, canvas.width, canvas.height);
    }

    // Restore the saved image for the selected tab
    // setTimeout(() => this.restoreMarkedImage(), 100);
}

  // -----------------------selection tabs to mark damaged images. END---------------------------

  // Method to open the hidden calendar
  openCalendar(): void {
    // Ensure the calendar input exists and is triggered
    const calendarInput = document.getElementById('calendar') as HTMLInputElement;
    if (calendarInput) {
      calendarInput.click();  // Open the calendar by simulating a click
    }
  }

  // Method to set the date when the calendar changes
  setDate(event: Event): void {
    const calendarInput = event.target as HTMLInputElement;
    const selectedDate = calendarInput.value;
    const dateInput = document.getElementById('last-service-date') as HTMLInputElement;

    // Set the selected date to the last-service-date text input
    if (dateInput && selectedDate) {
      dateInput.value = selectedDate;
    }
  }

  // -------------------------------ENABLING DRAWING----------------

  enableDrawing() {
    const canvas = this.drawingCanvas.nativeElement;
    const image = document.querySelector('.image-canvas-container img') as HTMLImageElement;
  
    if (!image) return; // Ensure an image exists
  
    // Match canvas size to image
    canvas.width = image.clientWidth;
    canvas.height = image.clientHeight;
    canvas.style.position = 'absolute';
    canvas.style.top = '0';
    canvas.style.left = '0';
    canvas.style.cursor = 'crosshair'; // Set pointer while marking
  
    canvas.style.pointerEvents = 'auto'; // Enable drawing
    this.canvasContext = canvas.getContext('2d')!;
    if (this.canvasContext) {
      this.canvasContext.strokeStyle = 'red';
      this.canvasContext.lineWidth = 2;
    }
  }
 
  // startDrawing(event: MouseEvent): void {
  //   this.isImageModified = true;
  //   const canvas = this.drawingCanvas.nativeElement;
  //   const rect = canvas.getBoundingClientRect();
  
  //   if (this.canvasContext) {
  //     const x = event.clientX - rect.left;
  //     const y = event.clientY - rect.top;
  
  //     this.canvasContext.beginPath();
  //     this.canvasContext.moveTo(x, y);
  //     this.isDrawing = true;
  //     this.currentPath = [{ x, y }]; // Start new path
  
  //     canvas.style.cursor = 'crosshair'; // Keep pointer while marking
  //   }
  // }
  
  // stopDrawing(): void {
  //   if (this.canvasContext) {
  //     this.canvasContext.closePath();
  //   }
  //   this.isDrawing = false;
  
  //   const canvas = this.drawingCanvas.nativeElement;
  //   canvas.style.cursor = 'default'; // Reset cursor when not drawing
  
  //   if (this.currentPath.length > 0) {
  //     this.drawingHistory.push(this.currentPath);
  //   } else {
  //     this.damagedImagesData[this.selectedTab] = null; // No drawing for this tab
  //   }
  // }
  
  //   draw(event: MouseEvent): void {
  //     if (!this.isDrawing || !this.canvasContext) return;
    
  //     const canvas = this.drawingCanvas.nativeElement;
  //     const rect = canvas.getBoundingClientRect();
  //     const x = event.clientX - rect.left;
  //     const y = event.clientY - rect.top;
    
  //     this.canvasContext.lineTo(x, y);
  //     this.canvasContext.stroke();
  //     this.currentPath.push({ x, y });
  //   }
  
  //   getDamagedTabs(): string[] {
  //     return Object.keys(this.damagedImagesData).filter(tab => this.damagedImagesData[tab]);
  //   }
  //   undoDrawing() {
  //     if (this.drawingHistory.length > 0) {
  //       this.drawingHistory.pop(); 
  //       this.redrawCanvas(); 
  //     } 
  //   }
  //    // Redraw the canvas with all previous paths
  //    redrawCanvas() {
  //     const canvas = this.drawingCanvas.nativeElement;
  //     if (this.canvasContext) {
  //       this.canvasContext.clearRect(0, 0, canvas.width, canvas.height); // Clear the entire canvas
  //     }
  //   }

    startDrawing(event: MouseEvent | TouchEvent): void {
      this.isImageModified = true;
      const canvas = this.drawingCanvas.nativeElement;
      const rect = canvas.getBoundingClientRect();
    
      let x, y;
      if (event instanceof TouchEvent) {
        const touch = event.touches[0];
        x = touch.clientX - rect.left;
        y = touch.clientY - rect.top;
      } else {
        x = (event as MouseEvent).clientX - rect.left;
        y = (event as MouseEvent).clientY - rect.top;
      }
    
      if (this.canvasContext) {
        this.canvasContext.beginPath();
        this.canvasContext.moveTo(x, y);
        this.isDrawing = true;
        this.currentPath = [{ x, y }];
        canvas.style.cursor = 'crosshair';
      }
    
      event.preventDefault(); // Prevent scrolling on touch
    }
    
    draw(event: MouseEvent | TouchEvent): void {
      if (!this.isDrawing || !this.canvasContext) return;
    
      const canvas = this.drawingCanvas.nativeElement;
      const rect = canvas.getBoundingClientRect();
    
      let x, y;
      if (event instanceof TouchEvent) {
        const touch = event.touches[0];
        x = touch.clientX - rect.left;
        y = touch.clientY - rect.top;
      } else {
        x = (event as MouseEvent).clientX - rect.left;
        y = (event as MouseEvent).clientY - rect.top;
      }
    
      this.canvasContext.lineTo(x, y);
      this.canvasContext.stroke();
      this.currentPath.push({ x, y });
    
      event.preventDefault(); // Prevent scrolling on touch
    }
    
    stopDrawing(): void {
      if (this.canvasContext) {
        this.canvasContext.closePath();
      }
      this.isDrawing = false;
    
      const canvas = this.drawingCanvas.nativeElement;
      canvas.style.cursor = 'default';
    
      if (this.currentPath.length > 0) {
        this.drawingHistory.push(this.currentPath);
      } else {
        this.damagedImagesData[this.selectedTab] = null;
      }
    }
  
    
    saveMarkedImage() {
      if (!this.isImageModified) {
        console.log(`No changes made to ${this.selectedTab}, skipping save.`);
        return; // Do not save if the image was not marked
      }
      const canvas: HTMLCanvasElement | null = document.querySelector('.drawing-canvas');
      const image: HTMLImageElement | null = document.querySelector('.image-canvas-container img');
    
      if (!canvas || !image) {
        console.error('Canvas or Image element is not available.');
        return;
      }
    
      const tempCanvas = document.createElement('canvas');
      const context = tempCanvas.getContext('2d');
    
      if (!context) {
        console.error('Failed to get temporary canvas context.');
        return;
      }
    
      tempCanvas.width = image.width;
      tempCanvas.height = image.height;
    
      // Combine the image and annotations
      context.drawImage(image, 0, 0, image.width, image.height);
      context.drawImage(canvas, 0, 0, canvas.width, canvas.height);
    
      // Convert the combined canvas to a Base64 string
      const markedImageData = tempCanvas.toDataURL('image/png');
    
      // Save the marked image data for the selected tab
      // console.log("markedImageData",markedImageData)
      // console.log("damagedImagesData",this.damagedImagesData)
      this.damagedImagesData[this.selectedTab] = markedImageData;
    
      this.isImageSaved = true;
    
      // console.log(`Marked image saved for ${this.selectedTab}:`, markedImageData);
    }
    
    activateDrawing(event: MouseEvent): void {
      if (!this.isDrawing) {
        this.enableDrawing(); // Activates the pencil mode
      }
      this.startDrawing(event); // Starts the drawing process
    }


  // ----SEARCH CHECKBOX----

  fetchIssues(): void {
    this.sideBarJobcardService.getServiceList().subscribe(
      (response) => {
        if (response.code === 200) {
          this.issuesData = response.data.symptoms || [];
          this.filteredIssues = this.issuesData;     
        } else {
          console.error('Error fetching issues:', response.message);
        }
      },
      (error) => {
        console.error('API Error:', error);
      }
    );
  }

  toggleSearch() {
    this.isSearchOpen = !this.isSearchOpen;
    this.isSearchSidebarOpen = !this.isSearchSidebarOpen;

  }

  // Toggle issue selection
  toggleIssue(issue: string, event: Event) {
    // console.log('Selected issue:', issue);  // Debugging line
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.selectedIssues.push(issue);  // Add to selectedIssues
    } else {
      this.selectedIssues = this.selectedIssues.filter(i => i !== issue);  // Remove from selectedIssues
    }
  }


  saveIssue(): void {
    // Store the selected issues into issuesData (or any other required variable)
    this.issuesData = [...this.selectedIssues];
    this.goToExteriorSection();
  }

  // Remove selected issue
  removeIssue(issueToRemove: string): void {
    const index = this.selectedIssues.indexOf(issueToRemove);
    if (index > -1) {
      this.selectedIssues.splice(index, 1);
    }
  }

  // selectIssues(service: string, event: any): void {
  //   if (event.target.checked) {
  //     this.selectedIssues.push(service); // Add service to selected list
  //   } else {
  //     const index = this.selectedIssues.indexOf(service);
  //     if (index > -1) {
  //       this.selectedIssues.splice(index, 1); // Remove service from selected list
  //     }
  //   }
  // }
  
  // ------------------SEARCH SUGGESTION------------
  filterIssues() {
    // console.log('Search text:', this.searchText);  // Debug searchText
    if (!this.searchText || this.searchText.length < 3) {
      // console.log('No filtering applied. Returning all issues.');
      this.filteredIssues = this.issuesData;  // No filtering if search text is less than 3
    } else {
      const query = this.searchText.toLowerCase();
      // console.log('Filtering issues with query:', query);  // Debug query

      this.filteredIssues = this.issuesData.filter(issue => {
        // Ensure the issue description is not undefined or null
        const description = issue ? issue.toLowerCase() : '';
        return description.includes(query);  // Filter based on description field
      });

      // console.log('Filtered Issues:', this.filteredIssues);  // Log filtered issues
    }
  }


  highlightMatch(text: string, search: string): string {
    if (!search) return text;

    const regex = new RegExp(`(${search})`, 'gi');
    return text.replace(regex, match => `<span class="highlight">${match}</span>`);
  }

  // highlightMatch(text: string): string {
  //   const query = this.searchQuery.toLowerCase();
  //   const matchIndex = text.toLowerCase().indexOf(query);
  //   if (matchIndex !== -1) {
  //     const beforeMatch = text.slice(0, matchIndex);
  //     const match = text.slice(matchIndex, matchIndex + query.length);
  //     const afterMatch = text.slice(matchIndex + query.length);
  //     return `${beforeMatch}<strong>${match}</strong>${afterMatch}`;
  //   }
  //   return text;
  // }


  // -----CLEAR SEARCH---------------
  clearSearch() {
    this.searchText = '';  // Reset search text
    this.filteredIssues = this.issuesData;
    // Add any other logic to reset filters if needed
  }

  // Trigger file input click programmatically
  triggerFileInput(section: string): void {
    this.selectedSection = section;

    // Map the section to the corresponding file input
    const fileInputMap: { [key: string]: ElementRef<HTMLInputElement> } = {
      front: this.frontFileInput,
      engine: this.engineFileInput,
      battery: this.batteryFileInput,
      right: this.rightFileInput,
      back: this.backFileInput,
      left: this.leftFileInput,
      add: this.addFileInput,
      'Fuel-Level': this.fuelFileInput, // Added Fuel Level
    };

    const fileInput = fileInputMap[section];
    if (fileInput) {
      fileInput.nativeElement.click();
    } else {
      console.error('File input not found for section:', section);
    }
  }



  // Handle image selection
  onImageSelected(section: string, event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.previewImage(file, section);

      // Save the uploaded image in the respective section variable
      const reader = new FileReader();
      reader.onload = (e: any) => {
        switch (section) {
          case 'front':
            this.frontImage = e.target.result;
            break;
          case 'engine':
            this.engineImage = e.target.result;
            break;
          case 'battery':
            this.batteryImage = e.target.result;
            break;
          case 'right':
            this.rightImage = e.target.result;
            break;
          case 'back':
            this.backImage = e.target.result;
            break;
          case 'left':
            this.leftImage = e.target.result;
            break;
          case 'fuel': 
            this.fuelLevelImage = e.target.result;
            break;
          case 'add':
            this.addImage = e.target.result;
            break;
          default:
            break;
        }
        console.log(`${section} image uploaded and saved to variable:`, e.target.result);
      };
      reader.readAsDataURL(file); // Read the file as a data URL (base64 encoded)
    } else {
      // console.error('No file selected');
    }
  }


  previewImage(file: File, section: string): void {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.selectedImages[section] = e.target.result;
      this.uploadedImages[section] = e.target.result;
      this.uploadedImages[section] = e.target.result;
    };
    reader.readAsDataURL(file); // Read file content
  }
  // Simulated image upload function
  uploadImage(file: File): void {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('section', this.selectedSection);

    // console.log('Uploading image...', formData);
  }
  
  // createJobCard() {
  //   this.isConfirmationSidebarOpen = false;
  //   this.isVisible = false;
  //   this.customerDetailsANDcarDetails=false;
  //   this.fuelAccessoriesContainer = false;
    
  //   // local starage data
  //   this.garageKey = localStorage.getItem('garageKey');
  //   const userId = localStorage.getItem('spareUserKey');
  //   const rowKey = localStorage.getItem('rowKey')||this.vehicleForm1.get('rowKey')?.value;
  //   const variantKey = localStorage.getItem('variantKey')||this.vehicleForm1.get('variantKey')?.value;

  //   //Formated form data
  //   this.FinalJobcardDetails = {};    
  //   this.FinalJobcardDetails['damagedImagesS3'] = [];
  //   this.FinalJobcardDetails['imagesS3'] = [];
  //   console.log("cardetails",this.car);
  //   this.FinalJobcardDetails['car'] = this.car;
  //   this.FinalJobcardDetails['vehicleinfo'] = this.jobCard;
  //   this.FinalJobcardDetails['customer'] = this.customer;
  //   this.FinalJobcardDetails['accessories'] = this.fuelAndAccessories?.checkedItems;
  //   this.FinalJobcardDetails['fuelLevel'] = this.fuelAndAccessories?.fuelLevel;
  //   this.FinalJobcardDetails['issues'] = this.issuesData;
  //   this.FinalJobcardDetails['damagedImages'] = this.damagedImagesData;
  //   this.FinalJobcardDetails['images'] = {
  //     front: this.frontImage || null,
  //     engine: this.engineImage || null,
  //     battery: this.batteryImage || null,
  //     right: this.rightImage || null,
  //     back: this.backImage || null,
  //     left: this.leftImage || null,
  //     add: this.addImage || null,
  //   };

  //   const input = event.target as HTMLInputElement;

  //   // Damaged Images
  //   if (
  //     this.FinalJobcardDetails['damagedImages']?.Front?.length > 0 || 
  //     this.FinalJobcardDetails['damagedImages']?.Top?.length > 0 || 
  //     this.FinalJobcardDetails['damagedImages']?.Right?.length > 0 || 
  //     this.FinalJobcardDetails['damagedImages']?.Left?.length > 0 || 
  //     this.FinalJobcardDetails['damagedImages']?.Rear?.length > 0
  //   ) {
      
  //     Object.entries(this.FinalJobcardDetails['damagedImages']).forEach(([key, damagedImage]) => {
          
  //         if(damagedImage != null && damagedImage !=undefined){
  //           let timestamp =  Math.floor(Date.now() / 1000);
  //           let fileName = timestamp+"_"+key.toLowerCase()+'.png';

  //           this.s3ImageUploadService.getPresignedUrl(fileName).subscribe({
  //             next: (response) => {
  //               // console.log("getPresignedUrl",response)
  //               const presignedUrl = response[0]?.signedUrl;
  //               const link = response[0]?.link;
      
  //               if (presignedUrl || link) {
  //                 this.uploadFileToS3(presignedUrl, damagedImage); 
  //                 // this.FinalJobcardDetails['damagedImagesS3'].push({"url": link, "tag": key.toLowerCase()});
  //                 this.FinalJobcardDetails['damagedImagesS3'].push({
  //                   "url": link,
  //                   "tag": `damage_${key.toLowerCase()}`
  //               });
                
  //               } else {
  //                 console.error('Invalid presigned URL or link:', response);
  //                 this.aspLoadingService.hide();
  //               }
                
  //             },
  //             error: (err) => {
  //               console.error('Error fetching presigned URL:', err);
  //             },
  //           });
  //         };
  //     } )
  //   }
   
  //   // Images
  //   if (
  //     this.FinalJobcardDetails['images']?.front?.length > 0 || 
  //     this.FinalJobcardDetails['images']?.engine?.length > 0 || 
  //     this.FinalJobcardDetails['images']?.battery?.length > 0 || 
  //     this.FinalJobcardDetails['images']?.right?.length > 0 || 
  //     this.FinalJobcardDetails['images']?.back?.length > 0 || 
  //     this.FinalJobcardDetails['images']?.left?.length > 0 || 
  //     this.FinalJobcardDetails['images']?.add?.length > 0
  //   ) {
      
  //     Object.entries(this.FinalJobcardDetails['images']).forEach(([key, images]) => {
          
  //         if(images != null && images !=undefined){
  //           let timestamp =  Math.floor(Date.now() / 1000);
  //           let fileName = timestamp+"_"+key.toLowerCase()+'.png';

  //           this.s3ImageUploadService.getPresignedUrl(fileName).subscribe({
  //             next: (response) => {
  //               const presignedUrl = response[0]?.signedUrl;
  //               const link = response[0]?.link;
      
  //               if (presignedUrl || link) {
  //                 this.uploadFileToS3(presignedUrl, images); 
  //                 this.FinalJobcardDetails['imagesS3'].push({"url": link, "tag": key.toLowerCase()});
  //               } else {
  //                 console.error('Invalid presigned URL or link:', response);
  //                 this.aspLoadingService.hide();
  //               }
                
  //             },
  //             error: (err) => {
  //               console.error('Error fetching presigned URL:', err);
  //             },
  //           });
  //         }
  //     });
  //   }

  //   setTimeout(() => {  
  //     let regNo:any;
  //     if(this.FinalJobcardDetails.vehicleinfo.registrationNumber) {
  //       regNo = this.FinalJobcardDetails.vehicleinfo.registrationNumber;
  //     } else{
  //       regNo = this.FinalJobcardDetails.car.regNumber;
  //     }
      
  //     let param = {
  //       "name": this.FinalJobcardDetails.customer.name,
  //       "phoneNumber": this.FinalJobcardDetails.customer.mobile,
  //       "odometer": this.FinalJobcardDetails.car.odometerReading,
  //       "registrationNumber": regNo,
  //       "engineNumber": this.FinalJobcardDetails.car.engineNumber,
  //       "lastServiceDate": this.FinalJobcardDetails.car.lastServiceDate,
  //       "userKey": userId,
  //       "checkDetails": [
  //         {
  //           "type": "service type",
  //           "services": this.FinalJobcardDetails.car.selectedServices
  //         },
  //         {
  //           "type": "accessories",
  //           "services": this.FinalJobcardDetails.accessories
  //         },
  //         {
  //           "type": "issues",
  //           "services": this.FinalJobcardDetails.issues
  //         }
  //       ],

  //       "damageImages": this.FinalJobcardDetails['damagedImagesS3'],
  //       "images": this.FinalJobcardDetails['imagesS3'],
  //       "fuelLevel": this.FinalJobcardDetails.fuelLevel,
  //       "rowKey": rowKey,
  //       "variantKey": variantKey,
  //       "garageKey": this.garageKey,
  //       "email": this.FinalJobcardDetails.customer.email,
  //       "fuelPercent": this.FinalJobcardDetails.fuelLevel,
  //       "vinNumber": this.FinalJobcardDetails.vehicleinfo.vin,
  //       "year":this.FinalJobcardDetails.vehicleinfo.mfgYear,
  //       "bookingId": 0
  //     };

  //     // console.log("Data",this.FinalJobcardDetails);
  //     // console.log("Data String",JSON.stringify(this.FinalJobcardDetails));
  //     // console.log("Data",param);
  //     // console.log("Data String",JSON.stringify(param));
      
  //     this.confirmCarDetails(param);
  //   }, 3000); 
  // } 
  createJobCard() {
    this.aspLoadingService.show(); 
    this.isConfirmationSidebarOpen = false;
    this.isVisible = false;
    this.customerDetailsANDcarDetails = false;
    this.fuelAccessoriesContainer = false;

    // Local Storage Data
    this.garageKey = localStorage.getItem('garageKey');
    const userId = localStorage.getItem('spareUserKey');
    const rowKey = localStorage.getItem('rowKey') || this.vehicleForm1.get('rowKey')?.value;
    const variantKey = localStorage.getItem('variantKey') || this.vehicleForm1.get('variantKey')?.value;

    // Formatted Form Data
    this.FinalJobcardDetails = {};    
    this.FinalJobcardDetails['damagedImagesS3'] = [];
    this.FinalJobcardDetails['imagesS3'] = [];
    this.FinalJobcardDetails['car'] = this.car;
    this.FinalJobcardDetails['vehicleinfo'] = this.jobCard;
    this.FinalJobcardDetails['customer'] = this.customer;
    this.FinalJobcardDetails['accessories'] = this.fuelAndAccessories?.checkedItems;
    this.FinalJobcardDetails['fuelLevel'] = this.fuelAndAccessories?.fuelLevel;
    this.FinalJobcardDetails['issues'] = this.issuesData;
    this.FinalJobcardDetails['damagedImages'] = this.damagedImagesData;
    this.FinalJobcardDetails['images'] = {
      front: this.frontImage || null,
      engine: this.engineImage || null,
      battery: this.batteryImage || null,
      right: this.rightImage || null,
      back: this.backImage || null,
      left: this.leftImage || null,
      add: this.addImage || null,
      'Fuel-Level': this.fuelLevelImage || null,

     };

     if (Array.isArray(this.newImages)) {
      this.newImages.forEach(imageObj => {
        if (imageObj.key) {
          this.FinalJobcardDetails['images'][imageObj.key] = imageObj.image || null;
        }
      });
    }
    console.log("this.newImages['key']",this.newImages)
    console.log("this.FinalJobcardDetails['images']",this.FinalJobcardDetails['images'])
    // console.log("Damaged Images Data:", this.FinalJobcardDetails['damagedImages']);
    // console.log("Regular Images Data:", this.FinalJobcardDetails['images']);

    // Handle Damaged Images Upload
    if (this.FinalJobcardDetails['damagedImages']) {
        Object.entries(this.FinalJobcardDetails['damagedImages']).forEach(([key, damagedImage]) => {
            if (damagedImage) {
                let timestamp = Math.floor(Date.now() / 1000);
                let fileName = `${timestamp}_damage_${key.toLowerCase()}.png`;

                this.s3ImageUploadService.getPresignedUrl(fileName).subscribe({
                    next: (response) => {
                        // console.log("Pre-signed URL Response:", response);
                        const presignedUrl = response[0]?.signedUrl;
                        const link = response[0]?.link;

                        if (presignedUrl && link) {
                            this.uploadFileToS3(presignedUrl, damagedImage);
                            this.FinalJobcardDetails['damagedImagesS3'].push({
                                "url": link,
                                "tag": `damage_${key.toLowerCase()}`
                            });
                        } else {
                            console.error('Invalid presigned URL or link:', response);
                        }
                    },
                    error: (err) => {
                        console.error('Error fetching presigned URL:', err);
                    }
                });
            }
        });
    }

    // Handle Regular Images Upload
    if (this.FinalJobcardDetails['images']) {
      console.log("this.FinalJobcardDetails['images']",this.FinalJobcardDetails['images'])
        Object.entries(this.FinalJobcardDetails['images']).forEach(([key, image]) => {
            if (image) {
                let timestamp = Math.floor(Date.now() / 1000);
                let fileName = `${timestamp}_${key.toLowerCase()}.png`;

                this.s3ImageUploadService.getPresignedUrl(fileName).subscribe({
                    next: (response) => {
                        // console.log("Pre-signed URL Response:", response);
                        const presignedUrl = response[0]?.signedUrl;
                        const link = response[0]?.link;

                        if (presignedUrl && link) {
                            this.uploadFileToS3(presignedUrl, image);
                            this.FinalJobcardDetails['imagesS3'].push({
                                "url": link,
                                "tag": key.toLowerCase()
                            });
                        } else {
                            console.error('Invalid presigned URL or link:', response);
                        }
                    },
                    error: (err) => {
                        console.error('Error fetching presigned URL:', err);
                    }
                });
            }
        });
    }

    // Wait for Uploads & Proceed to Next Step
    setTimeout(() => {
        let regNo: any = this.FinalJobcardDetails.vehicleinfo.registrationNumber || this.FinalJobcardDetails.car.regNumber;
        
        let lastServiceDateIST = new Date(this.FinalJobcardDetails.car.lastServiceDate)
        .toLocaleDateString("en-IN", { timeZone: "Asia/Kolkata" });

        let param = {
            "name": this.FinalJobcardDetails.customer.name,
            "phoneNumber": this.FinalJobcardDetails.customer.mobile,
            "odometer": this.FinalJobcardDetails.car.odometerReading,
            "registrationNumber": regNo,
            "engineNumber": this.FinalJobcardDetails.car.engineNumber,
            // "lastServiceDate": this.FinalJobcardDetails.car.lastServiceDate,
            "lastServiceDate": lastServiceDateIST,
            "userKey": userId,
            "checkDetails": [
          {
            "type": "service type",
            "services": this.FinalJobcardDetails.car.selectedServices
          },
          {
            "type": "accessories",
            "services": this.FinalJobcardDetails.accessories
          },
          {
            "type": "issues",
            "services": this.FinalJobcardDetails.issues
          }
        ],
            "damageImages": this.FinalJobcardDetails['damagedImagesS3'],
            "images": this.FinalJobcardDetails['imagesS3'],
            "fuelLevel": this.FinalJobcardDetails.fuelLevel,
            "rowKey": rowKey,
            "variantKey": variantKey,
            "garageKey": this.garageKey,
            "email": this.FinalJobcardDetails.customer.email,
            "fuelPercent": this.FinalJobcardDetails.fuelLevel,
            "vinNumber": this.FinalJobcardDetails.vehicleinfo.vin,
            "year": this.FinalJobcardDetails.vehicleinfo.mfgYear,
            "bookingId": 0
        };

        console.log("Final Job Card Data:", param);
        this.confirmCarDetails(param);
    }, 3000);
}

  // Upload file to S3
  uploadFileToS3(presignedUrl,file): void {  
    // console.log('Presigned URL:', presignedUrl);
    // const contentType = file.type;
    const blobData = this.base64ToBlob(file);

    const contentType = 'image/png';
    this.http.put(presignedUrl, blobData, {
      headers: { 'Content-Type': contentType },
    }).subscribe({
      next: () => {
        console.log('File uploaded successfully.');
      },
      error: (err) => {
        console.error('Error uploading file:', err);
      },
      complete: () => {
        this.aspLoadingService.hide();
      },
    });
  }

  private base64ToBlob(base64: string): Blob {
    // Ensure Base64 prefix is removed if present
    const base64Data = base64.split(',')[1];
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length).fill(0).map((_, i) => slice.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays, { type: 'image/png' });
  }



  getMakeImageUrl(make: string): string {
    const makeWithoutSpaces = make.toLowerCase().replace(/\s+/g, '');
    return `https://cube.getpitstop.com/assets/img/${makeWithoutSpaces}.png`;
  }

  // ----PREFILLED-----------
  fetchPreFilledBooking(registrationNumber, bookingId: string): void {
    // const registrationNumber = "registrationNumber";
    const garageKey = localStorage.getItem('garageKey');
    this.sideBarJobcardService.getPreFilledBooking(registrationNumber, garageKey, this.bookingId).subscribe(
      res => {
        console.log("bookingid", this.bookingId);
        console.log('Fetched pre-filled booking data:', res);
        if (res && res.data) {
          this.customer.name = res.data.name;
          this.customer.mobile = res.data.mobile;
          this.customer.email = res.data.email;
          this.car.vin = res.data.vin;
          this.car.engineNumber = res.data.engineNumber;
          this.showServiceHistory = true; // Show the button if response exists
          this.isExistingCustomer=res.data.existingCustomer;
          console.log("this.isExistingCustomer----",this.isExistingCustomer);
        } else {
          this.showServiceHistory = false; // Hide the button if no response data
        }
      },
      error => {
        console.error('Error fetching booking data:', error);
        this.showServiceHistory = false;
      }
    );
  }

  openBottomSheet(section: string): void {
    this.selectedSection = section;
    this.isBottomSheetOpen = true;  
  
    // Trigger the file input click if the section is 'add'
    // if (section === 'add' && this.addFileInput) {
    //   this.addFileInput.nativeElement.click();
    // }
  }

  
  onFileSelected(event: Event, section: string): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const reader = new FileReader();
  
      reader.onload = () => {
        if (section === 'add') {
          this.addImage = reader.result as string;
          this.isBottomSheetOpen = false; 
        }
      };
  
      reader.readAsDataURL(file);
    }
  }
  
  
  // Close the bottom sheet
  closeBottomSheet(): void {
    this.isBottomSheetOpen = false; 
  }
  
  // Save the tag and trigger the file input
  saveTag(): void {
    if (!this.otherImageFile || !this.otherTag) {
      console.error('Image or tag not provided');
      return;
    }
  
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const newImage = { image: e.target.result, key: this.otherTag };
  
      // ✅ Ensure arrays are initialized before pushing
      if (!Array.isArray(this.otherImages)) {
        this.otherImages = [];
      }
      this.otherImages.push(newImage);
  
      if (!Array.isArray(this.newImages)) {
        this.newImages = [];
      }
      this.newImages.push(newImage);
  
      console.log("✅ Updated otherImages:", this.otherImages);
      console.log("✅ Updated newImages:", this.newImages);
  
      // ✅ Update UI without duplicating elements
      const addedImgDiv = document.getElementById('added-img');
      let otherImagesDiv = document.getElementById('otherimages');
  
      if (!otherImagesDiv) {
        otherImagesDiv = document.createElement('div');
        otherImagesDiv.id = 'otherimages';
        otherImagesDiv.style.display = 'contents';
        addedImgDiv?.appendChild(otherImagesDiv);
      }
  
      const imageBox = document.createElement('div');
      imageBox.className = 'image-box';
  
      const imgElement = document.createElement('img');
      imgElement.src = newImage.image;
      imgElement.alt = newImage.key;
      imgElement.className = 'latest-image'; 
  
      const spanElement = document.createElement('span');
      spanElement.className = 'image-gridtext'; 
      spanElement.textContent = newImage.key;
  
      imageBox.appendChild(imgElement);
      imageBox.appendChild(spanElement);
      otherImagesDiv.appendChild(imageBox);
  
      // ✅ Reset inputs
      this.otherTag = '';
      this.otherImageFile = null;
    };
  
    reader.readAsDataURL(this.otherImageFile);
    this.isBottomSheetOpen = false;
  }
  
  
  onOtherImageSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.otherImageFile = input.files[0];
    }
  }
  goToNextTab() { 
    // Save the marked image for the current tab
    this.saveMarkedImage();

    // Clear the canvas for the current tab
    const canvas: HTMLCanvasElement | null = document.querySelector('.drawing-canvas');
    if (canvas) {
        const context = canvas.getContext('2d');
        if (context) {
            context.clearRect(0, 0, canvas.width, canvas.height);
        }
    }

    // Determine the next tab
    const tabs = ['Front', 'Rear', 'Right', 'Left', 'Top'];
    const currentIndex = tabs.indexOf(this.selectedTab);

    if (currentIndex < tabs.length - 1) {
        // Move to the next tab
        this.selectedTab = tabs[currentIndex + 1];
        this.isImageModified = false; // Reset modification state

        setTimeout(() => {
          const nextButton = document.querySelector('.create-order-btn') as HTMLElement;
          if (nextButton) {
              console.log('Scrolling to NEXT button'); // Debug log
              nextButton.scrollIntoView({ behavior: 'smooth', block: 'start' });
          } else {
              console.log('NEXT button not found'); // Debug log
          }
      }, 100); // Ensure DOM updates before scrolling

        // Restore the marked image for the new tab
        // setTimeout(() => this.restoreMarkedImage(), 100); // Slight delay to ensure tab update
    } else {
        // All tabs completed, navigate to the image section
        this.goToImageSection();
    }
}

  
  get rf() {
    return this.carDetailsForm.controls;
  }
  onChangeCarClick(): void {
    // this.carDetailsandcustomerDetails = false;
    this.customerDetailsANDcarDetails = false;
    // this.fuelAccessoriesContainer = false;
    // this.issuesSection = false;
    // this.exteriorSection = false;
    // this.imageSection = false;
    // this.jobCardDetails = false; 
    this.isVisible = true;  
    this.ngOnInit();
}

openServiceHistory(): void {
  // Dynamically create the component
  const factory = this.resolver.resolveComponentFactory(AspVehicleServiceHistoryComponent);
  const componentRef = this.historyContainer.createComponent(factory);

  // Access the instance and call the closeSidebar or similar method
  const serviceHistoryInstance = componentRef.instance as AspVehicleServiceHistoryComponent;
  serviceHistoryInstance.openSidebar(true);
  // this.carDetailsandcustomerDetails = false;
  //   this.customerDetailsANDcarDetails = false;
  //   this.fuelAccessoriesContainer = false;
  //   this.issuesSection = false;
  //   this.exteriorSection = false;
  //   this.imageSection = false;
  //   this.jobCardDetails = false; 
  //   this.isVisible = false; 
}

addSelectedIssue(issue: string) {
  if (!this.selectedIssues.includes(issue)) {
    this.selectedIssues.push(issue);
  }
}
navigateToDetails() {
  this.router.navigate(['/my-job-cards-details', this.jobCardId, 0]);
  }
onResize() {
  const viewportHeight = window.innerHeight;
  const documentHeight = document.documentElement.clientHeight;

  if (viewportHeight < documentHeight) {
    // Keyboard is likely open, move the button up
    this.nextButton.nativeElement.style.position = 'absolute';
    this.nextButton.nativeElement.style.bottom = '50px'; // Adjust as needed
  } else {
    // Keyboard is closed, reset position
    this.nextButton.nativeElement.style.position = 'sticky';
    this.nextButton.nativeElement.style.bottom = this.originalBottom;
  }
}
onFuelImageUpload(event: any): void {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.fuelAndAccessories.fuelLevel = e.target.result; // Store Base64 image
    };
    reader.readAsDataURL(file);
  }
}
handleFileUpload(event: Event, type: string): void {
  const file = (event.target as HTMLInputElement).files?.[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = () => {
      if (type === 'fuel') {
        this.fuelLevelImage = reader.result as string;
      }
    };
    reader.readAsDataURL(file);
  }
}

  // Adding other symptoms apart from list
  addTag() {
    if (this.searchText.trim()) {
      this.selectedIssues.push(this.searchText.trim());
      this.clearSearch();
    }
  }
  // End Adding other symptoms apart from list

}