<div class="overlay" [class.visible]="isSidebarOpen" (click)="closeSidebar()"></div>
<div *ngIf="isSidebarOpen && requestPage !='1'" class="sidebar">
    <div class="header">
      <mat-icon (click)="closeSidebar()"  class="headerclosebutton">keyboard_backspace</mat-icon>
      <h2>Enter Item List</h2>
      <mat-icon  (click)="closeSidebar()" class="headerclosebutton">close</mat-icon>
    </div>
    <div class="newclick">Enter item names separated by commas or in separate lines.
      <div class="strong"> Click <strong>'Enter'</strong> when done. </div></div>
    <!-- <textarea [(ngModel)]="itemList" rows="20" placeholder="Enter items here..."></textarea> -->
     <div class="matformfieldnew">
        <mat-form-field appearance="outline" style="width: 100%;">
        <!-- <mat-form-field  class="custom-mat-form-field"> -->
          <!-- <div class="custom-textarea-container"> -->
            <mat-label>Enter items here</mat-label>
            <textarea matInput [(ngModel)]="itemList" rows="15" style="text-align: left;"></textarea>
          <!-- </div> -->
        </mat-form-field>   
    </div> 
    <div class="enterbutton">
      <button class="cta-btn" (click)="submitItems()">Enter</button>
    </div>
    <p *ngIf="showError" class="Error" style="color: red;">Please enter at least one item name to proceed.</p>
  </div>




  <div *ngIf="isSidebarOpen && requestPage == '1'" class="sidebar-addItems-request">
    <div class="header">
      <mat-icon (click)="closeSidebar()"  class="headerclosebutton">keyboard_backspace</mat-icon>
      <h2>Enter Item List</h2>
      <mat-icon  (click)="closeSidebar()" class="headerclosebutton">close</mat-icon>
    </div>
    <div class="newclick-addItems-request">Enter item names separated by commas or in separate lines.
      <div class="strong"> Click <strong>'Enter'</strong> when done. </div></div>
    <!-- <textarea [(ngModel)]="itemList" rows="20" placeholder="Enter items here..."></textarea> -->
     <div class="matformfieldnew1">
        <mat-form-field appearance="outline" style="width: 100%;">
        <!-- <mat-form-field  class="custom-mat-form-field"> -->
          <!-- <div class="custom-textarea-container"> -->
            <mat-label>Enter items here</mat-label>
            <textarea matInput [(ngModel)]="itemList" rows="15" style="text-align: left;"></textarea>
          <!-- </div> -->
        </mat-form-field>   
    </div> 
    <div class="enterbutton">
      <button class="cta-btn" (click)="openRequestPopup()">Enter</button>
    </div>
    <p *ngIf="showError" class="Error" style="color: red;">Please enter at least one item name to proceed.</p>
  </div>


  <!-- Popup Section -->
  <div class="overlay-request" [class.visible]="isRequestPopupVisible" (click)="closeRequestPopup()"></div>

  <div *ngIf="isRequestPopupVisible" class="modal-overlay">
    <div class="modal-content" (click)="$event.stopPropagation()">
      <div class="modal-header">
        <img src="{{successIcon}}" alt="Success Icon" class="success-icon">
        <h2>Request Created!</h2>
        <button class="close-modal" (click)="closeRequestPopup()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="modal-body">
        <p>Request ID: <span class="request-id">{{createdJobCardId}}</span></p>
        <p>Date <span class="createdAt1">{{createdDate}}</span></p>
        <p>Let us deliver an awesome experience!</p>
      </div>
      <div class="modal-footer">
        <button class="view-request-btn" (click)="viewJobCardDetails()">View Request</button>
      </div>
    </div>
  </div>