import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { AspMyItemListService } from './asp-my-item-list.service';
import { AspCartService } from '../asp-cart/asp-cart.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ToastrService } from 'ngx-toastr';
import { AspSparesListService } from '../asp-spares-list/asp-spares-list.service';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-asp-my-item-list',
  templateUrl: './asp-my-item-list.component.html',
  styleUrls: ['./asp-my-item-list.component.scss']
})
export class AspMyItemListComponent implements OnInit {
  loading: boolean = false;  
  selectedItem: any;
  editSheetOpen: boolean;
  editingItem:any= null;
  editItemForm: FormGroup;
  vehicleData:any;
  itemList: any = [];
  cartList = [];
  cartCount: number = 0;
  cartSubTotal: number = 0;
  cartGrandTotal: number = 0;
  isCartCount:boolean = false;  
  sparesDiscount: number;
  shippingCharges: any;
  grandAmount: any;
  isMobile: boolean;
  addedCart = [];
  vehicleType:any = localStorage.getItem('vehicleType');
  newData = {};  
  amount: any;
  emptyImg:any = 'assets/images/no-cart.png';  
  Messages: any;  
  randomMessage: any;
  @ViewChild('dynamicContainers', { read: ViewContainerRef }) containers!: ViewContainerRef;

  options: AnimationOptions = {
    path: './assets/images/loader.json' // Replace with your JSON animation file URL
  };

  constructor(
    private fb: FormBuilder,
    private aspMyItemListService: AspMyItemListService,
    private aspCartService: AspCartService,    
    private breakpointObserver: BreakpointObserver,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private aspSparesListService: AspSparesListService
  ) {
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); 
  }

  ngOnInit(): void {
    this.getLoadingMsg();
    this.vehicleData = JSON.parse(localStorage.getItem('vehicleData'));
    this.getItemList();

    this.editItemForm = this.fb.group({
      itemName: [''],
    });
    // this.editItemForm.get('itemName')?.valueChanges.subscribe(value => {
    //   if (value) {
    //     // this.vehicleForm.get('regNo')?.setValue(value.toUpperCase(), { emitEvent: false });
    //     const formattedValue = this.formatRegNo(value);
    //     this.editItemForm.get('regNo')?.setValue(formattedValue, { emitEvent: false });
    //   }
    // });
  }
  
  get ef() { return this.editItemForm.controls; }


getItemList() {
  this.loading = true; 
  
  this.getCart();
  this.itemList = [];
  let searchItems = localStorage.getItem('searchItems');
  let garageKey = localStorage.getItem('garageKey');
  
  this.aspMyItemListService.getItemList(searchItems, this.vehicleData.variantKey, garageKey).subscribe(
    result => {
      if (result.code == 200) {
        this.loading = false; 
        Object.entries(result.data).forEach(([key, items]) => {
          let addedToCart = false;

          if (this.cartList?.length > 0) {
            this.cartList[0] && Object.entries(this.cartList[0]).forEach(([cart_key, cart]) => {
              if (cart['partName'].toLowerCase() === key.toLowerCase()) {
                addedToCart = true;
              }
            });
          }

          Object.entries(items).forEach(([item_key, item]) => {
            item.brand = {"name": item.brand};
            item.mrp = (item.mrp/100).toFixed(2);
            item.sellingPrice = (item.mrp-(item.mrp*(item.discount/100))).toFixed(2);
            item.spareStocks =  {
              "partNumber": item.partNumber,
              "quantity": 0,
              "mrp": item.mrp,
              "sellingPrice": item.sellingPrice,
              // "discountAmount": item.discount
            }            
          })

          this.itemList.push({
            id: key.replace(/\s+/g, "_"), // Fix space issue
            name: key.toUpperCase(), // Keep name formatting
            addedToCart: addedToCart,
            products: items
          });
        });

        // Handle search items
        if (searchItems) {
          let searchNames = searchItems.split(',').map(s => s.trim().toLowerCase()); // Store once
        
          searchNames.forEach(searchName => {
            if (!this.itemList.some(item => item.name.toLowerCase() === searchName)) {
              this.itemList.push({
                id: searchName.replace(/\s+/g, "_"),
                name: searchName.toUpperCase(),
                addedToCart: false,
                products: []
              });
            }
          });

          // **Fixed Sorting Logic**
          this.itemList.sort((a, b) => {
            let indexA = searchNames.indexOf(a.name.toLowerCase());
            let indexB = searchNames.indexOf(b.name.toLowerCase());

            return (indexA === -1 ? searchNames.length : indexA) - (indexB === -1 ? searchNames.length : indexB);
          });
        }

        // **Select the first item only if the list is not empty**
        if (this.itemList.length > 0) {
          this.selectItem(this.itemList[0]);
        }
      }
    }
  );
}



  selectItem(item: any): void {
    this.selectedItem = item;
  }

  increaseQuantity(product: any): void {
    product.quantity++;
  }

  decreaseQuantity(product: any): void {
    if (product.quantity > 1) {
      product.quantity--;
    }
  }

  openEdit(item: any) {
    this.editSheetOpen = true;
    this.editingItem = item;

    // Set the current item name into the form control
    this.editItemForm.patchValue({
      itemName: this.transform(item.name),
    });
  }

  closeEdit() {
    this.editSheetOpen = false;
    this.editingItem = null;
  }

  // saveChanges() {
  //   let updatedName = this.ef.itemName.value;

  //   let searchItems = localStorage.getItem('searchItems');
  //   // searchItems = searchItems.split(',').map(item => item.toLowerCase() === this.editingItem.name.toLowerCase() ? updatedName : item).join(',');
  //   searchItems = searchItems.split(',').map(item => item.toLowerCase() === this.editingItem.name.toLowerCase() ? updatedName: item.replace(/\s+/g, '')).join(',');
  //   // console.log(searchItems);
  //   localStorage.setItem('searchItems', searchItems);
  //   this.getItemList();  
  //   this.closeEdit();
  // }

  saveChanges() {
    let updatedName = this.ef.itemName.value.trim(); // Ensure trimmed value
    let searchItems = localStorage.getItem('searchItems');

    if (searchItems) {
        let itemsArray = searchItems.split(',').map(item => item.trim()); // Ensure list format remains same

        let updatedItems = itemsArray.map(item => 
            item.toLowerCase() === this.editingItem.name.toLowerCase() ? updatedName : item
        );

        // Ensure we save the list in the same order
        localStorage.setItem('searchItems', updatedItems.join(','));
        console.log('Updated search items:', updatedItems);

        // Refresh list if needed
        this.getItemList();
        this.closeEdit();
    }
}



  // removeFromList(removeItem: any) {
  //   // this.itemList = this.itemList.filter((item) => item.id !== removeItem.id);
  //   let searchItems = localStorage.getItem('searchItems');
  //   // searchItems = searchItems.split(',').filter(item => item.toLowerCase() !== removeItem.name.toLowerCase()).join(',');
  //   searchItems = searchItems.split(',').filter(item => item.toLowerCase() !== removeItem.name.toLowerCase()) .map(item => item.replace(/\s+/g, '')).join(',');
  //   localStorage.setItem('searchItems', searchItems);
  //   this.getItemList();  
  //   this.closeEdit();
  // }

  removeFromList(removeItem: any) {
    let searchItems = localStorage.getItem('searchItems');

    if (searchItems) {
        searchItems = searchItems
            .split(',')
            // .map(item => item.trim().replace(/\s+/g, '')) // Remove spaces
            .filter(item => item.toLowerCase() !== removeItem.name.toLowerCase()) // Remove the selected item
            .join(',');

        localStorage.setItem('searchItems', searchItems);

        this.getItemList();
        this.closeEdit();
    }
}


  // cart
  addCart(items, count){
    // console.log("items",items);
    // console.log("count",count);
    delete items.rowKey;
    delete items.variantKey;

    // if(this.isReplacePopup==true){
    //   sessionStorage.setItem('replaceCart', JSON.stringify(items));
    //   this.zone.run(() => {
    //     this.dialog.open(AspReplaceCartPopupComponent);
    //   });
    // }else{
    //   this.setNewMakeAsMake();
        
      this.addedCart = [];
      if(this.vehicleType=='universal'){
        items.type = 'universal';
      }
      if(sessionStorage.getItem('addedCart')){
        this.newData = JSON.parse(sessionStorage.getItem('addedCart'))[0];
        // console.log("items.cart_qty",items.cart_qty);
        items.cart_qty = count;
        items.key = items.partKey;
        items.name = items.partName;
        
        this.amount=items.spareStocks;
        let make = localStorage.getItem('selectedMake');
        let model = localStorage.getItem('selectedModel');
        let registrationNumber = localStorage.getItem('registrationNumber');
        let variant = localStorage.getItem('variant');
        let mfgYear = localStorage.getItem('selectedYear');
        let fuelType = localStorage.getItem('selectedFuel');
        let rowKey = localStorage.getItem('rowKey');
        let variantKey = localStorage.getItem('variantKey');
        items = {
          ...items.spareStocks, 
          ...items, 
          selectedMake: make,
          selectedModel: model,
          registrationNumber: registrationNumber,
          variant: variant,
          selectedYear: mfgYear,
          selectedFuel: fuelType, 
          rowKey: rowKey, 
          variantKey: variantKey      
        };
        // items.discount = items.discountAmount;  
        // delete items.discountAmount;
        if(items.discount && items.discount>0){
          items.discount = (items.mrp-items.sellingPrice).toFixed(2);  
        }
        
        this.newData[items.partKey] = items;
        this.addedCart.push(this.newData);
        sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
      }else{
        items.cart_qty = count;        
        items.key = items.partKey;
        items.name = items.partName;
        // this.amount=items.spareStocks;
        let make = localStorage.getItem('selectedMake');
        let model = localStorage.getItem('selectedModel');
        let registrationNumber = localStorage.getItem('registrationNumber');
        let variant = localStorage.getItem('variant');
        let mfgYear = localStorage.getItem('selectedYear');
        let fuelType = localStorage.getItem('selectedFuel');
        let rowKey = localStorage.getItem('rowKey');
        let variantKey = localStorage.getItem('variantKey');
        items = {
          ...items.spareStocks, 
          ...items,
          selectedMake: make,
          selectedModel: model,
          registrationNumber: registrationNumber,
          variant: variant,
          selectedYear: mfgYear,
          selectedFuel: fuelType,
          rowKey: rowKey, 
          variantKey: variantKey      
        };

        if(items.discount && items.discount>0){
          items.discount = (items.mrp-items.sellingPrice).toFixed(2);  
        }
        // items.discount = items.discountAmount;  
        // delete items.discountAmount;
        this.newData[items.partKey] = items;
        this.addedCart.push(this.newData);
        sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
      }      
      this.getCart();
    // }
  }

  getCart(){
    let cartData;  
    this.cartList = [];
    this.cartCount = 0;
    this.cartSubTotal = 0;
    this.cartGrandTotal = 0;
    
    if(sessionStorage.getItem('addedCart')){   
      cartData = JSON.parse(sessionStorage.getItem('addedCart'));
      if(Object.keys(cartData[0]).length==0){
        this.isCartCount = false;
      } 
      
      Object.values(cartData[0]).forEach(cart => { 
        if(cart){
          this.isCartCount = true;
          this.cartCount++;
        

        // this.cartCount += cart['cart_qty'];
        if(cart['sellingPrice']>0){
          this.cartSubTotal += (cart['sellingPrice']*cart['cart_qty']);
        }else{
          this.cartSubTotal += (cart['mrp']*cart['cart_qty']);
        }
        this.cartList.push(cart);
      }
      });      

      this.cartList = cartData;
      this.cartGrandTotal = this.cartSubTotal;
      // console.log(this.cartList);

      if(this.cartCount>0){
        (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = ''+this.cartCount+' items <div class="right-amount-carts">₹'+this.cartGrandTotal.toFixed(2)+'</div>';
      }else{
        (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = 'My Cart';
      }
      if(this.sparesDiscount>0){
        this.cartGrandTotal = (this.cartSubTotal - this.sparesDiscount);
      }else{
          this.cartGrandTotal = this.cartSubTotal;
      }
      // this.getShippingCharges();  
    }
    // console.log("cartList",this.cartList)
  }

  getShippingCharges(){    
    // this.loading = true; 
    let garageKey = localStorage.getItem('garageKey');
    this.aspCartService.getShippingCharges(this.cartGrandTotal, garageKey).subscribe(
      result => {
        let res = JSON.parse(JSON.stringify(result)); 
        if(res.code==200){
          // this.shippingChargesApplicable = res.data.shippingChargesApplicable;
          this.shippingCharges = res.data.shippingCharges;
          // this.avoidShippingCharges = res.data.avoidShippingCharges;
          this.grandAmount= res.data.cartValue;
          // this.loading = false;
            this.cartGrandTotal = this.grandAmount+this.shippingCharges;
  
            if(!this.isMobile){
              if(this.cartCount>0){
                (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = ''+this.cartCount+' items <div class="right-amount-carts">₹'+this.cartGrandTotal.toFixed(2)+'</div>';
              }else{
                (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = 'My Cart';
              }
            }else{
              if(this.cartCount>0){
                (document.getElementsByClassName('cart_count_mobile')[0] as HTMLInputElement).innerHTML = '<div class="cart_count">'+this.cartCount+'</div>';
              }else{
                (document.getElementsByClassName('cart_count_mobile')[0] as HTMLInputElement).innerHTML = '';
              }
            }            
          
        } else {
          if(!this.isMobile){
            if(this.cartCount>0){
              (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = ''+this.cartCount+' items <div class="right-amount-carts">₹'+this.cartGrandTotal.toFixed(2)+'</div>';
            }else{
              (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = 'My Cart';
            }
          }else{
            if(this.cartCount>0){
              (document.getElementsByClassName('cart_count_mobile')[0] as HTMLInputElement).innerHTML = '<div class="cart_count">'+this.cartCount+'</div>';
            }else{
              (document.getElementsByClassName('cart_count_mobile')[0] as HTMLInputElement).innerHTML = '';
            }
          }
        }
      }
    );
  }

  removeItem(count, item){ 
    this.addedCart = [];    
    let data;
    
    data = JSON.parse(sessionStorage.getItem('addedCart'))[0];
    if(count>1){
      let countval = count-1;
      data[item].cart_qty = countval;      
      data[item].key = data[item].partKey;

      if(this.vehicleType=='universal'){
        data[item].type = 'universal';
      }

      this.addedCart[0] = data;
      sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
      this.getCart();
    }else{
      this.deleteItem(item);
      this.toastr.error(data[item].partName+' removed from cart', 'Cart Item');
    }
  }

  addItem(count, item){
    let countval = count+1;
    
    // this.addCart(item,countval);
    this.addedCart = [];
    let data;
    
    // data = JSON.parse(sessionStorage.getItem('addedCart'))[0];
    // if(countval<=data[item].spareStocks.quantity){
    //   data[item].cart_qty = countval;

    //   if(this.vehicleType=='universal'){
    //     data[item].type = 'universal';
    //   }

    //   this.addedCart[0] = data;
    //   sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
    //   this.getCart();
    // }else{
    //   this.toastr.error('Only '+data[item].spareStocks.quantity+' unit available for this item', 'Error');
    // }
    data = JSON.parse(sessionStorage.getItem('addedCart'))[0];
    // if(countval<=data[item].spareStocks.quantity){
      data[item].cart_qty = countval;
      data[item].key = data[item].partKey;
      this.addedCart[0] = data;
      // console.log("this.addedCart",this.addedCart)
      sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
      this.getCart();
  }

  deleteItem(item){
    this.newData = {};
    let cartData;

    cartData = JSON.parse(sessionStorage.getItem('addedCart'));
    Object.values(cartData[0]).forEach(cart => { 
      if(cart['partKey']!=item) {
        this.newData[cart['partKey']] = cart;
      }
    });
    
    this.addedCart[0] = this.newData;
    sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
    this.getCart();
    // console.log(this.addedCart);
    
  }
  // end cart


  // loading
  getLoadingMsg() {
    this.aspSparesListService.getLoadingMsg().subscribe(
      result => {

        let res = JSON.parse(JSON.stringify(result));
        this.Messages = res.data.appLoadingMessages;
  
        // Call getRandomMessage only after the Messages array is populated
        this.getRandomMessage();
        this.cdr.detectChanges();
      },
      error => {
        // console.error('Error fetching loading messages', error);
      }
    );
  }
  
  getRandomMessage() {
    if (this.Messages && this.Messages.length > 0) {
      const randomIndex = Math.floor(Math.random() * this.Messages.length);
      this.randomMessage = this.Messages[randomIndex];
    } else {
      // console.error('No messages available to pick from');
    }
  }

  animationCreated(animationItem: any): void {
    // Animation is created and can be interacted with here
  }

  // End loading


  transform(value: string): string {
    if (!value) return value;
    return value
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

}
