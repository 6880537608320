<div class="page-layout blank fullwidth p-12" style="height: 1px; overflow-y: scroll; top: 1px;" >

  <div [ngClass]="isMobile ? 'containerMobile' : 'container'" *ngIf="!invoiceOpenItem">
    <div>
      <div class="header" >
        <i class="material-icons" style="color: black; float: left; cursor: pointer;margin-top: 0px; width: 3%;" (click)="goBack()" >keyboard_backspace</i> 
        <span class="title">Job Card Details <span class="request-number">#{{ request?.jobCard?.id}}</span></span>
        <div class="right">
          <div class="changebtn" >
            <div *ngFor="let status of filters.jobCardStatus" >
              <label class="{{status.statusclass}} "  *ngIf="request?.jobCard?.jobcardStatus && status.value==request?.jobCard?.jobcardStatus" >{{status.label}}</label>  

            </div>
            <i class="material-iconsMore" *ngIf="request?.jobCard?.jobcardStatus== 3 || request?.jobCard?.jobcardStatus== 4 ||  request?.jobCard?.jobcardStatus== 5" (click)="openMenu()">more_vert</i>

            </div>
            <button mat-icon-button (click)="toggle()" style="background: #e9e9e9;">
              <mat-icon [ngClass]="{ 'rotated': isOpen }" class="expand_more">expand_more</mat-icon>
            </button>
            <div class="overlay" [class.visible]="openSideShow" (click)="closePopup()"></div>
            <ul class="openSideShow" *ngIf="openSideShow">
              <li (click)="markReady()" *ngIf="request?.jobCard?.jobcardStatus==3">Mark Ready To Deliver</li>
              <li (click)="shareDeails()" *ngIf="request?.jobCard?.jobcardStatus==4">Send Status Update</li>
              <li (click)="invoiceOpen()" *ngIf="request?.jobCard?.jobcardStatus==4">Create Invoice</li>
              <li *ngIf="(request?.jobCard?.jobcardStatus == 4 || request?.jobCard?.jobcardStatus == 5) && !request?.jobCard?.rating" (click)="sendFeedback()">Get Feedback</li>
              <li *ngIf="request?.jobCard?.rating" style="color: black; cursor: default;">Feedback Received</li>
            </ul>
              <div class="overlay" [class.visible]="PopupOpen" (click)="closePopup()"></div>
              <div class="popup" *ngIf="PopupOpen">
                <div class="popup-content order-success-sec">
                    <i class="material-icons" class="close-btn" (click)="closePopup()">close</i>
                          <h3>You have pending quote items. Are you sure you want to proceed?</h3>
                          <div class="popup-buttons">
                            <button class="no-btn" (click)="closePopup()">No</button>
                            <button class="yes-btn" (click)="updateInfo()">Yes</button>
                          </div>
                </div>
              </div>
        </div>


   
      </div>
      <div class="border" *ngIf="isOpen">
      <div style="display: flex;">
        <div class="jobcard-new-tag" *ngIf="request?.jobCard?.isExistingCustomer === true">
          Repeat Customer
        </div>
        <div class="jobcard-tag" *ngIf="request?.jobCard?.isExistingCustomer === false">
          New Customer
        </div>

        <div class="more_vert">
          <button class="history-btn" (click)="openServiceHistory()">Service History</button>
         </div>
      </div>

      <div class="details">
        <div class="logo-and-model">
          <img [src]="getMakeImageUrl(request?.jobCard?.make)" alt="Car Make Image" class="model-icon">
          <div class="model-info">
            <div class="jobcard-header">
              <div style="display: flex;">
                <span class="model-name">{{ request?.jobCard?.make || 'Unknown Make' }}</span>
              </div>    
            </div> 
            <span class="model-details">{{ request?.jobCard?.model || 'Unknown Model' }}, {{ request?.jobCard?.fuelType || 'Unknown Fuel Type' }}</span>
            <span class="model-details">{{ request?.jobCard?.registrationNumber }}</span>
            <span class="model-details" *ngIf="request?.jobCard?.vinNumber">{{ request?.jobCard?.vinNumber }}</span>
          </div>
        </div>

    
        <div class="right-details">

          <div style="position: relative;">
            <div *ngIf="request?.jobCard?.rating > 0" class="food-rating">
              <div class="ratingss">Your Service Rating</div>
                <div class="rating">
                    <span class="star">&#9733;</span>
                    <span class="score">{{ request?.jobCard?.rating  }}</span>
                    <div *ngIf="request?.jobCard?.feedback !==null" class="toolbar-separator"></div>
                    <!-- <div *ngIf="jobCard.feedback !== null" class="toolbar-separator"></div> -->
                    <span class="review">{{ request?.jobCard?.feedback }}</span>
                    <span class="review">
                      {{ request?.jobCard?.rating === 1 ? 'Terrible' : 
                      request?.jobCard.rating === 2 ? 'Bad' : 
                      request?.jobCard.rating === 3 ? 'Disappointing' : 
                      request?.jobCard.rating === 4 ? 'Pleasant' : 
                      request?.jobCard.rating === 5 ? 'Awesome' : 'Not Rated' }}
                  </span>
                </div>
                <div class="timestamp">{{ request?.jobCard?.createdAt| date:'dd MMM yyyy, h:mm a': 'GMT+1060' }}</div> 
            </div> 
            
          </div>
    </div>
    </div>
  </div>
          <!-- <ul class="openSideShow" *ngIf="openSideShow">
            <li (click)="markReady()" *ngIf="request?.jobCard?.jobcardStatus==3">Mark Ready To Deliver</li>
            <li (click)="shareDeails()" *ngIf="request?.jobCard?.jobcardStatus==4">Send Status Update</li>
            <li (click)="invoiceOpen()" *ngIf="request?.jobCard?.jobcardStatus==4">Create Invoice</li>
            
          </ul> -->
 
          


          <!-- <div class="timestamp">{{ request?.jobCard?.createdAt| date:'dd MMM yyyy, h:mm a': 'GMT+1060' }}</div> -->

        
     
    
  </div>
     
  </div>
  <div id="myDynamicSec" #myDynamicSec></div>
  <div class="overlay" [class.visible]="sidebarOpenInvoice" (click)="CloseSidebar()" ></div>

  <div class="sidebar1" *ngIf="sidebarOpenInvoice">
    <mat-list>
        <header>
          <div class="back-button">
            <i (click)="CloseSidebar()" class="material-icons-arrowback">arrow_back</i>
            <span class="title1">Create Invoice</span>
            <div *ngIf="quoteId">
              <h2>{{ quoteId }}</h2>
            </div>
          </div>
        </header>
        <div class="detailsQuote">
          <div class="logoModel" >
            <img [src]="getMakeImageUrl(request?.jobCard?.make)" alt="Car Make Image" class="img">
            <div class="model-info">
              <span class="model-name">{{ request?.jobCard?.make || 'Unknown Make' }}</span>
              <span class="model-details">{{ request?.jobCard?.model || 'Unknown Model' }}, {{ request?.jobCard?.fuelType || 'Unknown Fuel Type' }}</span>
              <span class="model-details">{{ request?.jobCard?.registrationNumber }}</span>
              <span class="model-details">{{ request?.jobCard?.vinNumber || '' }}</span>

            </div>
          </div>
        <!-- <mat-divider></mat-divider> -->
        <div  *ngIf="customerQuotation?.length > 0; else noQuotesApproved">
          <div  class="items" style="margin-bottom: 56px;" >
            <!-- <div class="request-info">Quote received for {{itemLength?.length}} items </div> -->
            <div class="item" >
              <div class="checkboxselect">
                <input type="checkbox" [checked]="selectAllQuote" (change)="sidebarToggleSelectAllQuote()">
                <span>Select All</span>
              </div>
              <div class="grand-total" style="text-align: end;">
                <span class="customer-quote-price">₹ {{quoteGrandTotal | number:'1.0-0'}}</span>
                <div class="quote_total_amount">Total Amount</div>
              </div>
            </div>
            <div class="spare-part-header">
            <h2 style="font-weight: bold;">Spare Parts</h2>
            <p style="margin-top:23px;color:#144579;cursor:pointer;" (click)="AddItems()" *ngIf="customerQuotation.length>0">Add More</p>
          </div>
          <div class="quotereceived-items" *ngFor="let quote of customerQuotation" [ngClass]="{'border-box': quote.selected}">
              
            <div class="lines" style="display: flex;">
              <div style="width:5%">
                <input class="checkbox-spare" type="checkbox" [(ngModel)]="quote.selected" (change)="sidebarToggleItemSelectionQuote()">
              </div>
              <div style="width:85%">
                <span class="part-name" style="width: 100%;">{{quote.itemName}}</span>
              </div>
              <div style="width:10%">
                <i class="material-icons-spare" (click)="openEditDialog(quote)">edit</i> 
              </div>                 
              </div>


              <!-- <div class="lines" style="display: flex;">
                <input class="checkbox-spare" type="checkbox" [(ngModel)]="quote.selected" (change)="sidebarToggleItemSelectionQuote()">
                <span class="part-name">{{quote.itemName}}</span>
                <i class="material-icons-spare" (click)="openEditDialog(quote)">edit</i>                  
              </div> -->

              <div class="lines">
                <!-- {{quote.mrp * quote.itemQuantity}} -->
                <span class="spare-mrp" style="width: 100%;text-align: end;">₹{{quote.sellingPrice * quote.quantity | number:'1.2-2'}}</span> 
              </div>
              <!-- <div class="lines">
                <span class="date-time">{{quote.edt | date:'dd MMM yyyy'}}</span>
              </div>  -->
              <hr style="border: 1px solid #ebebeb;" />
              <div class="quantity-control">
                <div class="brand" style="    width: 71%;
                color: #999;
                font-size: 13px;">
                  {{quote.brand}}
                </div>
                <div class="addtocartcompatiblecar1">
                  <div class="spare-add-cart1">
                      <div class="btn-group">
                          <button class="button1" mat-raised-button style="min-width: 8px;
                          padding: 2px 0px;
                          margin-right: -3px;
                          height: 38px;
                          font-size: 11px;margin-top: 4px;" (click)="decrementLabourQuantity(quote)">
                              <mat-icon class="sidebuttons1" style="font-size: 18px;">remove</mat-icon>
                          </button>
                          <!-- <input type="text" [(ngModel)]="cartList[0][list.key].cart_qty" readonly style="height:38px;;text-align: center; width: 40%;"> -->
                          <button  class="button1" mat-raised-button style="    padding: 2px 0px;
                          margin-left: -3px;
                          height: 32px;
                          margin-right: 1px;
                          font-size: 13px;
                          margin-top: -4px;">
                            {{quote.quantity}} 
                          </button>
                          <mat-icon class="sidebutton1" style="    width: 1px;
                          padding: 7px 0px;
                          margin-left: -6px;
                          margin-right: -3px;
                          font-size: 18px;
                          height: 35px;
                          vertical-align: center;
                          color: #144579;margin-top: 0px;" (click)="incrementLabourQuantity(quote)">add</mat-icon>
                      </div>
                  </div>
              </div>
                <!-- <div class="add-minus">
                  <button mat-icon-button (click)="decrementjobcardQuantity(quote)">
                    <mat-icon style="margin-right: 6px;">remove</mat-icon>
                  </button>
                  <span style="padding: 7px;margin-left: 12px;">{{ quote.itemQuantity }}</span>
                  <button mat-icon-button (click)="incrementjobcardQuantity(quote)">
                    <mat-icon>add</mat-icon>
                  </button>
              </div> -->
              </div>
          </div>
          </div>
          
          <div class="labour-section-details" *ngIf="selectedItemsLabourQuote?.length>0">
          <div class="spare-part-header">
            <h2 style="font-weight: bold;">Labour Charges</h2>
            <p style="margin-top:23px;color:#144579;cursor:pointer;font-weight: bold;" (click)="addLabourSidebar()" >Add More</p>
          </div>
          <div class="quotereceived-items" *ngFor="let quote of selectedItemsLabourQuote" [ngClass]="{'border-box': quote.selected}">
          
              <div class="labour-lines" style="display:flex;">
                <div>
                <input type="checkbox" class="checkbox-spare" [(ngModel)]="quote.selected" (change)="toggleItemSelectionQuote()">
              </div>
              <div style="width: 86%;">
                <span class="part-name">{{quote.labourName}}</span>
              </div>
              <div>
                <i class="material-icons-spare" (click)="openLabourEditSidebar(quote)">edit</i>
              </div>
              
              
            </div>
            <span class="labour-mrp" >₹{{quote.labourAmount | number:'1.0-0'}}</span>
            <div class="lines">
              <span *ngIf="labourItemsCount > 0" class="date-time">{{quote.edt  | date:'dd MMM yyyy'}}</span>
            </div>
            <hr style="border: 1px solid #c0bebe;" />
            <div class="quantity-control">
              <div class="brand" style=" width: 71%;
              color: #999;
              font-size: 13px;">
                {{quote.brand}}
              </div>
              <div class="addtocartcompatiblecar1">
                <div class="spare-add-cart1">
                    <div class="btn-group">
                        <button class="button1" mat-raised-button style="    min-width: 8px;
                        padding: 2px 0px;
                        margin-right: -3px;
                        height: 38px;
                        font-size: 11px;
                        /* vertical-align: middle; */
                        ;margin-top: 4px;" (click)="decrementLabourQuantity(quote)">
                            <mat-icon class="sidebuttons1" style="font-size: 18px;margin-left: 1px;">remove</mat-icon>
                        </button>
                        <button  class="button1" mat-raised-button style="padding: 2px 0px;
                        margin-left: -3px;
                        height: 32px;
                        margin-right: 0px;
                        font-size: 13px;
                        margin-top:-4px;">
                          {{quote.quantity}} 
                        </button>
                        <mat-icon class="sidebutton1" style="    width: 1px;
                        padding: 7px 0px;
                        margin-left: -6px;
                        margin-right: -3px;
                        font-size: 18px;
                        height: 35px;
                        vertical-align: center;
                        color: #144579;margin-top:0px;" (click)="incrementLabourQuantity(quote)">add</mat-icon>
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>

      </div>
      <div class="overlay" [class.visible]="isPopupOpenEdit" (click)="closePopupEdit()"></div>
      <div *ngIf="isPopupOpenEdit" [ngClass]="isMobile ? 'bottom-sheetMobile' : 'bottom-sheet'">
        <header class="back-button">
          <div style="flex-direction: column;width: 87%;">
          <h3 class="header-name" style="margin-bottom: 2px;margin-top: -2px;">{{ itemQuote.itemName }}</h3>
          <h3 class="header-name" style="margin-bottom: 2px;margin-top: -2px;font-weight:100;font-size:13px">{{ itemQuote.brand }}</h3>
        </div>
          <mat-icon (click)="closePopupEdit()" class="close-pop-up">close</mat-icon>
        </header>
        <div class="bottom-sheet-content">
        <form [formGroup]="unitPrice" style="width: 100%;
        margin-left: -9px;">
        <div class="unit-price">
          <mat-form-field appearance="outline" class="full-width" class="edit-labour-details">
            <mat-label>Unit Price</mat-label>
            <input
              matInput
              formControlName="unitprice"                            
              [(ngModel)]="itemQuotesEdit.sellingPrice"
              placeholder="Enter Unit Price*"
              required
              name="Unit Price"
              pattern="^[0-9]*$"
            />
            </mat-form-field>
        </div>
      </form>
        <div class="item-update" style="bottom: -15px;height: 50px;">
          <button class="item-update-btn" style="margin-top:0px;" (click)="save()">Save Quote</button>
        </div>
        </div>
      </div>
      <ng-template #noQuotesApproved>
        <div class="empty-state">
          <div >
            
            <img src="../../../../assets/images/request-details images/requestdetailsimage.png" alt="Quote Not Approved" class="quote-image">
            <div class="message">
              <strong>Invoice Not Created Yet!</strong>
              <p>Create Invoice</p>
            </div>
          </div>
        </div>
        <!-- <button class="approveNow" (click)="approveNow()" *ngIf="activeTabs === 'quotation'">APPROVE QUOTE NOW</button> -->
      </ng-template>
        </div>
        <div class="floater-btn" (click)="AddItems()" *ngIf="customerQuotation?.length > 0 && selectedLabourslength == 0">
          <p style="font-weight: bold;">ADD ITEM</p>
        </div>
        <!-- Add Item Side Bar -->
        <div *ngIf="isSidebarOpen" class="new-sidebar">
          <div class="header1">
            <div class="maticon">
            <mat-icon (click)="toggleSidebar()">arrow_back</mat-icon>
          </div>
          <div class="add-items">
            <h3 style="font-weight: bold;margin-right:215px;margin-bottom:20px;">Add Items</h3>
          </div>
          </div>
          <div class="search-bar">
            <input
              type="text"
              placeholder="Search by Part Name"
              [(ngModel)]="searchWord"
              class="search-input"
              (input)="onSearch()"
            />
            <button *ngIf="searchWord" class="clear-btn" (click)="clearSearch()">&#x2715;</button>
          </div>
          
          <div class="item-list">
            <div *ngFor="let item of filteredItems" class="dropdown-item" (click)="addItemToQuotation(item)">
              <img [src]="item.imageUrl" alt="{{ item.partName }}" class="dropdown-image" />
              <div class="dropdown-details" style="width: 100%;">
                <h4>{{ item.name }}</h4>
                <p>Brand: {{ item.brand }}</p>
                <div class="item-price1">
                  <p>₹{{item.mrp }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="cantFind" (click)="createNewItems()" >
            <p>Can't Find Your Item?</p>
            <button mat-icon-button class="arrow-button">
              <mat-icon style="font-size: 15px;" class="arrow_forward">arrow_forward</mat-icon>
            </button>
          </div>
          
        </div>
        <div class="overlay" [class.visible]="newItem" (click)="createNewItems()" ></div>
        <div class="custom-sidebar" *ngIf="newItem">
          <button mat-icon-button class="back-buttonNew" (click)="createNewItems()" >
            <mat-icon>arrow_back</mat-icon>
          </button>
        
          <div class="content">
            <div class="image-container">
              <img  src="../../../../assets/images/car-repair.png" alt="car-repair">
            </div>
            
            <p class="title">Can't find what you are looking for?</p>
            <p class="subtitle" *ngIf="searchWord.length>0">Create {{searchWord}}</p>
            
          </div>
          <div class="fixedCTA">
            <button mat-button class="primary-btn" (click)="createItemSidebar()">CREATE ITEM</button>
          </div>
        </div>
        <div class="overlay" [class.visible]="newItems" (click)="createItemSidebar()" ></div>

        <mat-sidenav-container class="sidebar-containerNew" *ngIf="newItems">
          <mat-sidenav #sidenav mode="side" class="custom-sidebar" [(opened)]="newItems">
            <div class="sidebar-header">
              <mat-icon style="margin-top: 0px;" (click)="newItems = false">arrow_back</mat-icon>
              <h3>Create Item</h3>
            </div>
            <div class="sidebar-content">
              <form [formGroup]="itemForm" (ngSubmit)="onSubmit()">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Item Name *</mat-label>
                  <input matInput formControlName="itemName">
                  <mat-error *ngIf="itemForm.get('itemName')?.invalid && itemForm.get('itemName')?.touched">
                    Item Name is required
                  </mat-error>
                </mat-form-field>
              
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Part Number *</mat-label>
                  <input matInput formControlName="partNumber">
                  <mat-error *ngIf="itemForm.get('partNumber')?.invalid && itemForm.get('partNumber')?.touched">
                    Part Number is required
                  </mat-error>
                </mat-form-field>
              
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Brand *</mat-label>
                  <input matInput formControlName="brand">
                  <mat-error *ngIf="itemForm.get('brand')?.invalid && itemForm.get('brand')?.touched">
                    Brand is required
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>GST% *</mat-label>
                  <mat-select formControlName="gst">
                    <mat-option *ngFor="let gst of gstOptions" [value]="gst">
                      GST {{ gst }}%
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="itemForm.get('gst')?.invalid && itemForm.get('gst')?.touched">
                    Select GST%
                  </mat-error>
                </mat-form-field>
              
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>HSN *</mat-label>
                  <input matInput formControlName="hsn" >
                  <mat-error *ngIf="itemForm.get('hsn')?.invalid && itemForm.get('hsn')?.touched">
                    Enter a valid HSN (4-8 digits)
                  </mat-error>
                </mat-form-field>
              
            
                <mat-form-field appearance="outline" class="full-width" style="margin-bottom: 128px;">
                  <mat-label>Unit Price *</mat-label>
                  <input matInput formControlName="unitPrice">
                  <mat-error *ngIf="itemForm.get('unitPrice')?.invalid && itemForm.get('unitPrice')?.touched">
                    Enter a valid Unit Price 
                  </mat-error>
                </mat-form-field>
                <div class="fixedCTA" style="width: 29.5%;right: 18px;" >
                <button mat-flat-button color="primary" class="primary-btn" type="submit" >
                  Create Item
                </button>
              </div>
              </form>
            </div>
          </mat-sidenav>
        </mat-sidenav-container>
        <!-- Add Labour -->
        <div *ngIf="isAddLabour" class="labour-sidebar">
        <div class="labour-section">
            <div class="labour-maticon" >
              <mat-icon (click)="labourRedirectSidebar()">arrow_back</mat-icon>
            </div>
            <div class="labour-header" >
              <h2>Add Labour</h2>
            </div>
            <div class="labour-skip" >
              <p style="position: relative;float:right;padding:10px;cursor:pointer;color:#144579;"(click)="openSkipConfirmation()">Skip</p>
            </div>
          </div>
          <div class="search-bar">
            <input
              type="text"
              placeholder="Search Labour"
              [(ngModel)]="searchText"
              class="search-input"
              (input)="onLabourSearch()"/>
      
            <button *ngIf="searchText" class="clear-btn" (click)="clearLabourSearch()">&#x2715;</button>
          </div>
          <div class="item-list1">
          <!-- Labour List -->
          <div *ngFor="let labour of labourListData" class="dropdown-item">
            <div class="checkboxselect-labour">
              <input 
                type="checkbox" 
                [(ngModel)]="labour.selected" 
                (change)="onLabourSelectionChange(labour)"
              />
            </div>
            <div class="dropdown-details">
              <h4>{{ labour.labourName }}</h4>
              <div class="item-price">
                <p>₹{{ labour.labourAmount }}</p>
              </div>

            </div>
            <div class="edit-btn">
              <i class="material-icons" (click)="openLabourEditSidebar(labour)">edit</i>
            </div>
          </div>
          
            <div class="floater-labour-btn" (click)="openLabourEditSidebar()">
              <p>ADD LABOUR</p>
            </div>
        </div>
            <!-- Skip Confirmation Popup -->
            <div class="skip-confirmation" *ngIf="showSkipConfirmation">
              <!-- <button class="cancel-popup-close" (click)="closeSkipConfirmation()">×</button> -->
              <p class="skip-text">Are you sure you want to skip Adding Labour?</p>
              <button class="cancel-popup-btn no-btn" (click)="closeSkipConfirmation()">No</button>
              <button class="cancel-popup-btn confirm-btn" (click)="openPopup()">Yes</button>
              
          </div>
          <!-- labour bottom CTA -->
          <div 
            *ngIf="selectedLabourCount > 0 || selectedNewLabours > 0" 
            [ngClass]="isMobile?'bottom-boxQuoteMobile':'bottom-boxQuote'">
              <div class="bottom-contentQuote">
                <div class="total-items">{{ selectedNewLabours }} items
                  <p class="total-amount">₹{{totallabourListNewSelectedPrice | number:'1.0-0' }}</p>
                
                </div>
                  <button type="button" class="add-labour-bottom-cta" (click)="toggleSelectItem()">Add Labour</button>
              </div>
          </div>
        </div>
        <!-- quoteApprovedPrice -->
        <!-- <div 
          *ngIf="selectedtCount > 0" 
          [ngClass]="isMobile ? 'bottom-boxQuoteMobile' : 'bottom-boxQuote'">
          <div class="bottom-contentQuote">
            <div class="total-items">
              {{ selectedItemsCount }} items
              <p class="total-amount">₹{{ totallabourSelectedPrice | number:'1.2-2' }}</p>
            </div>
            <button type="button" class="create-quote-btn" (click)="addLabourSidebar()">Add Labour</button>
          </div>
        </div> -->

          <div *ngIf="customerQuotation?.length == 0" (click)="AddItems()" class="quotation-bottom-ctaInvoice" >
            <button class="quotation-bottom-cta">Add Invoice Items</button>
          </div>

          <!-- customer quote bottom CTA -->
          <div *ngIf="anyItemSelectedQuote() || anyItemSelected() || selectedItemsCountQuote>0 " [ngClass]="isMobile ? 'bottom-boxQuoteMobile':'bottom-boxQuote'">
          <!-- <div *ngIf="anyItemSelectedQuote() && customerQuotation?.length>0 && !selectedItemsLabourQuote" [ngClass]="isMobile ? 'bottom-boxQuoteMobile':'bottom-boxQuote'"> -->
              <div class="bottom-contentQuote">
                <div class="total-items">{{selectedItemsCount}} items
                  <p class="total-amount">₹{{sparePartsTotalcreation | number:'1.0-0'}}</p>
                  <!-- <p *ngIf="activeTab === 'quote-approved'" class="total-amount">₹{{quoteApprovedPrice | number:'1.2-2' }}</p> -->
                </div>
                <div class="in-next-step">
                <button class="add-labour-bottom-btn1" 
                  *ngIf="sparePartsTotalcreation > 0 || selectedItemsLabourQuote?.length== 0" 
                  type="button" 
                  (click)="addLabourSidebar()">
                  Add Labour
                  <span class="next-step-text">(In Next Step)</span>
                </button>
              </div>
              </div>  
          </div>
          

          <div *ngIf="(anyItemSelectedQuote() || anyItemSelected()) && customerQuotation?.length>0 && selectedItemsLabourQuote?.length>0" [ngClass]="isMobile ? 'bottom-boxQuoteMobile':'bottom-boxQuote'">
            <div class="bottom-contentQuote">
              <div class="total-items">{{selectedItemsCount}} items
                <p class="total-amount">₹{{totalSelectedPriceQuote | number:'1.0-0'}}</p>
                <!-- <p *ngIf="activeTab === 'quote-approved'" class="total-amount">₹{{quoteApprovedPrice | number:'1.2-2' }}</p> -->
              </div>

              <button class="create-quote-btn"  type="button" 
                (click)="openPopup()">Create Invoice</button>
      
            </div>  
        </div>
        <!-- Edit labour name Sidebar -->
        <div class="overlay" [class.visible]="isEditLabourSidebarOpen" (click)="closeAll()"></div>
        <!-- <div *ngIf="isEditLabourSidebarOpen" class="edit-labour-sidebar">
          <div class="labour-sidebar-header">
            <h2>Edit Labour</h2>
            <mat-icon (click)="closeLabourEditSidebar()">close</mat-icon>
          </div>
          <form [formGroup]="laborDetails" >
              <div class="labour-name-column" style="margin-top: -8px;">
                <mat-form-field appearance="outline" class="full-width" class="edit-labour-details" style="margin-top: 10px;">
                <mat-label>Labour Name</mat-label>
                <input
                  matInput
                  formControlName="labourName"
                  [(ngModel)]="selectedLabour.labourName"
                  placeholder="Enter Labour Name"
                  name="labourName"
                />
                </mat-form-field>
              </div>
              <div class="laboue-price-column" style="margin-top: -14px;">
                <mat-form-field appearance="outline" class="edit-labour-details">
                  <mat-label>Labour Amount</mat-label>
                  <input
                    matInput
                    formControlName="labourAmount"
                    [(ngModel)]="selectedLabour.labourAmount"
                    placeholder="Enter Labour Amount"
                    name="labourPrice"
                  />
                </mat-form-field>
              </div>  
          </form>
         
          <div class="item-update" style="margin-top: -14px;">
            <button class="item-update-btn" *ngIf="selectedLabour?.id || selectedLabour?.labourId" (click)="saveLabour()">Save Labour</button>
      <button class="item-update-btn" *ngIf="!(selectedLabour?.id || selectedLabour?.labourId)" (click)="addLabour()">Add Labour</button>
       </div>
          
        </div> -->
        
        <div *ngIf="isEditLabourSidebarOpen" class="edit-labour-sidebar">
          <div class="labour-sidebar-header">
            <h2>{{ selectedLabour?.id || selectedLabour?.labourId ? 'Edit Labour' : 'Add Labour' }}</h2>
            <mat-icon (click)="closeLabourEditSidebar()" style="margin-right: 0px;">close</mat-icon>
          </div>
        
          <form [formGroup]="laborDetails" style="width: 353px;float: left;margin-left: -8px;">
            <div class="labour-name-column" style="margin-top: -8px;margin-left: -17px;">
              <mat-form-field appearance="outline" class="full-width" style="margin-top: 10px;width:100%">
                <mat-label>Labour Name</mat-label>
                <input
                  matInput
                  formControlName="labourName"
                  [(ngModel)]="selectedLabour.labourName"
                  placeholder="Enter Labour Name"
                  name="labourName"
                />
              </mat-form-field>
            </div>
        
            <div class="laboue-price-column" style="margin-top: -8px;margin-left: -17px;">
              <mat-form-field appearance="outline" style="width:100%;">
                <mat-label>Labour Amount</mat-label>
                <input
                  matInput
                  formControlName="labourAmount"
                  [(ngModel)]="selectedLabour.labourAmount"
                  placeholder="Enter Labour Amount"
                  name="labourPrice"
                />
              </mat-form-field>
            </div>
          </form>
        
          <div class="item-update" style="margin-top: -14px;">
            <button class="item-update-btn" *ngIf="selectedLabour?.id || selectedLabour?.labourId" (click)="saveLabour()">Save Labour</button>
      <button class="item-update-btn" *ngIf="!(selectedLabour?.id || selectedLabour?.labourId)" (click)="addLabour()">Add Labour</button>
       </div>
        </div>
        
        <!-- Popup for skip labour -->
          <!-- <div class="cancel-popup-container" *ngIf="showCancelConfirmation">
            <div class="cancel-popup">
              <button class="cancel-popup-close" (click)="closeCancelPopup()">×</button>
              <p>Are you sure you want to skip?</p>
              <button class="cancel-popup-btn confirm-btn" (click)="confirmAction()">Yes</button>
              <button class="cancel-popup-btn no-btn" (click)="closeCancelPopup()">No</button>
            </div>
          </div> -->
    </mat-list>
  
  </div>

  <div class="overlay" [class.visible]="PopupOpen" (click)="closePopup()"></div>

  <div class="popup" *ngIf="PopupOpen">
    <div class="popup-content order-success-sec">
        <i class="material-icons" class="close-btn" (click)="closePopup()">close</i>
        <h3>You have pending quote items. Are you sure you want to proceed?</h3>
        <div class="popup-buttons">
          <button class="no-btn" (click)="closePopup()">No</button>
          <button class="yes-btn" (click)="updateInfo()">Yes</button>
        </div>
    </div>
  </div>
  <div *ngIf="isPopupOpen" class="popup">
    <div class="popup-content">
      <img src="{{successIcon}}" alt="successIcon" style="width: 36%;">
      <h3 *ngIf="resData?.invoiceId === null || resData?.invoiceId === 'null'">Invoice Created</h3>
      <h3 *ngIf="resData?.invoiceId !== null && resData?.invoiceId !== 'null'">Invoice Created</h3>
      <p class="order-label" *ngIf="resData?.invoiceId === null || resData?.invoiceId === 'null'">
        <span class="order-id">Invoice id {{ resData?.invoiceId }}</span>
      </p>
      <p class="order-label" *ngIf="resData?.invoiceId !== null && resData?.invoiceId !== 'null'">
        <span class="order-id">Invoice id {{ resData?.invoiceId }}</span>
      </p>
      <p class="date"><span>{{resData?.createdAt | date:'dd MMM yyyy, h:mm a': 'GMT+1060'}}</span></p>
      <p class="thank you">You have successfully created Invoice</p>
    
      <button class="goto-order" *ngIf="resData?.invoiceId === null || resData?.invoiceId === 'null'" (click)="navigateToDetails( resData.invoiceId)">View Customer Invoice</button>
      <button class="goto-order" *ngIf="resData?.invoiceId !== null && resData?.invoiceId !== 'null'" (click)="navigateToDetails(resData.invoiceId)">View Customer Invoice</button>
    </div>
  </div>
  <div *ngIf="isPopupOpen" class="popup">
    <div class="popup-content">
    <img src="{{successIcon}}" alt="successIcon" style="width: 36%;">
    <h3 *ngIf="resData?.invoiceId === null || resData?.invoiceId === 'null'">Invoice Created</h3>
    <h3 *ngIf="resData?.invoiceId !== null && resData?.invoiceId !== 'null'">Invoice Created</h3>
    <p class="order-label" *ngIf="resData?.invoiceId === null || resData?.invoiceId === 'null'">
      <span class="order-id">Invoice id {{ resData?.invoiceId }}</span>
    </p>
    <p class="order-label" *ngIf="resData?.invoiceId !== null && resData?.invoiceId !== 'null'">
      <span class="order-id">Invoice id {{ resData?.invoiceId }}</span>
    </p>
    <p class="date"><span>{{resData?.createdAt | date:'dd MMM yyyy, h:mm a': 'GMT+1060'}}</span></p>
    <p class="thank you">You have successfully created Invoice</p>
  
    <button class="goto-order" *ngIf="resData?.invoiceId === null || resData?.invoiceId === 'null'" (click)="navigateToDetails( resData.invoiceId)">View Customer Invoice</button>
    <button class="goto-order" *ngIf="resData?.invoiceId !== null && resData?.invoiceId !== 'null'" (click)="navigateToDetails(resData.invoiceId)">View Customer Invoice</button>
  </div>
    </div>
</div>
  
<ng-container #vehicleServiceHistoryContainer></ng-container>

<div #dynamicContainer1></div>

