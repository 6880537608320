<div class="Item" *ngIf="itemList?.length>0">
    <h2>My Item List({{itemList.length}})</h2>
    <div class="spares-card">
      <div class="logo">
        <img src="{{vehicleData.imageUrlPath}}" alt="Logo" />
      </div>
      <div class="details">
        <p><strong>{{vehicleData.model}}, {{vehicleData.fuel}}</strong></p>
        <p>{{vehicleData.variant}}, [{{vehicleData.year}}]</p>
        <p>{{vehicleData.registrationNumber}}</p>
      </div>
    </div>
  
  <div class="container">
    <!-- Sidebar -->
    <div class="sidebar">
      <mat-nav-list style="padding: 0 0;">
        <mat-list-item
            *ngFor="let item of itemList; let i = index"
            (click)="selectItem(item)"
            [class.selected]="selectedItem?.id === item.id"
            >
            <!-- <span matLine>{{ transform(item.name) }}</span> -->
            <span matLine *ngIf="item.products?.length > 0; else noItem">{{ transform(item.name) }}</span>
            <ng-template #noItem><span matLine>No Item Found</span></ng-template>            
            <!-- <span matLine style="font-size:11px; font-weight: 100;">{{ item.name }}</span> -->
            <span matLine style="font-size:11px; font-weight: 100;">{{ transform(item.name) }}</span>
            <span style="font-size: 11px;" *ngIf="item.products?.length<=10 && item.addedToCart!=true">{{item.products.length}} results</span>
            <span style="font-size: 11px;" *ngIf="item.products?.length>10 && item.addedToCart!=true">10+ items</span>
            <span class="added-sec" *ngIf="item.addedToCart==true"><mat-icon class="added-icon">check_circle_outline</mat-icon> <span class="added-text">Added</span></span>
            <i class="material" (click)="openEdit(item)">edit</i>
            <div class="overlay" [class.visible]="editSheetOpen" (click)="closeEdit()"></div>

            <div class="edit-item-container" *ngIf="editSheetOpen && editingItem?.id === item.id">
                <div class="edit-item-content">
                    <h3>Edit Item</h3>
                    <button class="close-btn" (click)="closeEdit()">
                        <i class="material-icons" (click)="closeEdit()">close</i>
                    </button>
                <form [formGroup]="editItemForm">
                  <div class="form-group">
                    <label for="itemName"></label>
                    <div class="input-container">
                      <input
                        type="text"
                        id="itemName"
                        formControlName="itemName"
                        [placeholder]="item.name"
                      />
                      <mat-icon class="input-icon" (click)="saveChanges()">arrow_upward</mat-icon>
                    </div>

                    <div class="form-actions">
                      <div class="form-actions-left">
                        <i class="material-icons remove-icon" (click)="removeFromList(item)">close</i>
                        <button type="button" class="remove-btn" (click)="removeFromList(item)">Remove from list</button>
                      </div>
                      <!-- <div class="form-actions-right">
                        <button type="button" class="save-btn" (click)="saveChanges()" style="color: #144579; padding: 0 0;">Save Changes</button>
                      </div> -->
                    </div>   
                    <div class="enterbutton">
                      <button class="cta-btn" (click)="saveChanges()">Edit Item</button>
                    </div>                 
                  </div>
                </form>
                </div>
            </div>
        </mat-list-item>

      </mat-nav-list>
    </div>
  
    <!-- Content -->
    <div class="content">  
      <h2>{{ transform(selectedItem?.name) || 'Select a Item'  }}</h2>
      <div class="product-grid" *ngIf="selectedItem?.products?.length > 0">
        <mat-card *ngFor="let product of selectedItem?.products" class="product-card">
          <!-- <a [routerLink]="['/product-details/key/']"> -->
            <div class="product-badge">
                <span class="discount1" *ngIf="product.discount">{{product.discount}}% OFF</span>
                <!-- <span class="bought" *ngIf="product.soldQuantity>0">Previously Bought</span> -->
                <div>
                  <div *ngIf="product.soldQuantity > 0" class="sku-tag-top-right ordered-count tag ordered-count">
                      {{ product.soldQuantity }} {{ product.soldQuantity === 1 ? 'Unit' : 'Units' }} Sold
                  </div>
              
                  <div *ngIf="product.soldQuantity > 0"  class="sku-tag-top-right previously-bought tag ordered-count">
                      Previously Bought
                  </div>
              </div>
              
            </div>
            <!-- <img mat-card-image src="{{product.productImageUrl}}" alt="{{ product.partName }}" /> -->
            <img mat-card-image [src]="product.productImageUrl || '../../../../assets/images/logos/logo.png'" 
            alt="{{ product.productImageUrl ? product.partName : 'autoverse' }}" onerror="this.src='../../../../assets/images/logos/logo.png'" />
       
            <!-- <mat-card-content style="color: black;"> -->
              <h3>{{ product.partName }}</h3>
              <div class="price">
                  <span class="current-price" *ngIf="product.discount">₹{{ product.sellingPrice }}</span>
                  <span class="original-price" *ngIf="product.discount">₹{{ product.mrp }}</span>
                  <div class="toolbar-separator" *ngIf="product.discount"></div>
                  <span class="current-price" *ngIf="!product.discount">₹{{ product.mrp }}</span>
                  <span class="discount" *ngIf="product.discount">{{ product.discount }}% OFF</span>
              </div>
              <div class="product-rating">
                  <span class="star"><i class="material-icons star-icon">star</i></span> 5 Ratings
              </div>
              <div class="product-details">
                <div class="detail-item">
                  <img src="../../../../assets/images/spares-list-images/unnamed (1).png" style="width: 18px;height: 18px;" alt="PN">
                  {{product.partNumber}}</div>
                <div class="detail-item">
                  <img src="../../../../assets/images/spares-list-images/unnamed.png" style="width: 18px;
                  height: 18px;" alt="B">
                  {{product.brand.name}}</div>
                <div style="    height: 25px;">
                  <div class="detail-item">
                    <img src="../../../../assets/images/spares-list-images/unnamed (2).png"style="width: 18px;
                    height: 18px; " alt="T">
                    45 Mins
                  </div>
                  <div class="spare-add-cart" >
                    <button  *ngIf="cartList.length<=0" (click)="addCart(product, 1)" class="add-control">
                        <mat-icon style="top: 3px; position: relative;">control_point</mat-icon>
                        Add
                    </button> 
                    <button *ngIf="cartList.length>0 && !cartList[0][product.partKey]"  (click)="addCart(product, 1)" class="add-control">
                        <mat-icon style="top: 3px; position: relative;">control_point</mat-icon>
                        Add
                    </button> 
                  <!-- {{product.partKey}}--------
                    {{cartList[0][product.partKey]|json}} -->
                </div>
                <div class="addtocartcompatiblecar1" *ngIf="cartList[0] && cartList[0][product.partKey]" >
                    <div class="spare-add-cart1">
                        <div class="btn-group" *ngIf="product.partKey==cartList[0][product.partKey].partKey && cartList[0][product.partKey].cart_qty>0" >
                          <button class="button1" mat-raised-button (click)="removeItem(cartList[0][product.partKey].cart_qty, product.partKey)">
                                <mat-icon class="sidebuttons1">remove</mat-icon>
                          </button>
                            <input type="text" [(ngModel)]="cartList[0][product.partKey].cart_qty" readonly>
                          <button  class="button1" mat-raised-button (click)="addItem(cartList[0][product.partKey].cart_qty, product.partKey)">
                                <mat-icon class="sidebutton1">add</mat-icon>
                          </button>
                        </div>
                    </div>
                </div>
                </div>

              </div>
              
              <!-- Add to cart -->
              
          <!-- End add to cart -->
        <!-- </mat-card-content> -->
          <!-- </a> -->
        
        </mat-card>
      </div>

      <div class="empty-item" *ngIf="selectedItem?.products?.length <= 0">
          <img style="padding: 0px 0px 25px;"src="{{emptyImg}}" alt="" />
          <div class="sec1">No matches found</div>
          <div class="sec2">We couldn't find a matching item</div>
          <button mat-button class='browse-parts-btn' (click)="openEdit(selectedItem)">Edit Item Name</button>
      </div>
    </div>
  </div>
</div>

<div class="loading-container" *ngIf="loading">
  <div class="loading-background"></div>
  
  <ng-lottie
  class="animation"
  [options]="options"
  (animationCreated)="animationCreated($event)">
</ng-lottie>
<p class="message">{{ randomMessage }}</p>

</div>