<div class="container">
  <!-- Sticky Container -->
  <div class="sticky-container">

    <!-- Search Bar Container -->
    <div class="SearchBarContainer" (click)="toggleSearch()">
      <div class="SearchBarButton">
        <div class="SearchBarIcon">
          <i class="material-icons">search</i>
        </div>
        <div class="SearchBarPlaceholder">
          <!-- Placeholder text that disappears when input is visible -->
          <div class="SearchBarAnimation" *ngIf="!isInputVisible">
            <div id="animationText-1" class="SearchBarAnimationText">Search By "Link ID"</div>
            <div id="animationText-2" class="SearchBarAnimationText">Search By "Contact Number"</div>
            <div id="animationText-3" class="SearchBarAnimationText">Search By "Amount"</div>
          </div>

          <!-- Input Box for Searching -->
          <div *ngIf="isInputVisible" class="SearchInputContainer" (click)="$event.stopPropagation()">
            <input 
              type="text"
              placeholder="Type your search" 
              class="SearchInput" 
              [(ngModel)]="searchText" 
              (input)="filterSuggestions()" 
              style="top: 0px; position: relative; width: 90%; margin-right: 40px;" 
            />
            <!-- Cross button is always visible when input is visible -->
            <button *ngIf="isInputVisible" (click)="clearSearch($event)" class="button">
              <i class="material-iconss" style="font-size: 16px;">clear</i>
            </button>
          </div>
        </div>
      </div>
      <button class="create-link-btn" (click)="openSidebar()">Create</button>
    </div>

    <!-- Filter Buttons -->
    <div class="filters">
      <button class="filter-btn" (click)="filterPayments('Paid')">Paid</button>
      <button class="filter-btn" (click)="filterPayments('Pending')">Pending</button>
      <button class="filter-btn" (click)="filterPayments('Cancelled')">Cancelled</button>
      <button class="filter-btn" (click)="filterPayments('Partial Paid')">Partial Paid</button>
    </div>
  </div>

  <!-- Payment Cards -->
  <div class="card" *ngFor="let payment of filteredPayments">
    <div class="card-header">
      <h3 class="contact-name">{{ payment.customerName }}</h3>
      <button mat-icon-button [matMenuTriggerFor]="menu" class="more-vert-btn">
        <span>⋮</span>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="confirmCancelPayment(payment)" style="color:rgb(182, 104, 104);">Cancel Payment</button>
      </mat-menu>
    </div>

    <div class="payment-for-status">
      <p>{{ payment.paymentFor }}</p>
      <div class="status" [ngClass]="{
        'paid': payment.status === 'Paid',
        'pending': payment.status === 'Pending',
        'partial-paid': payment.status === 'Partial Paid',
        'cancelled': payment.status === 'Cancelled'
      }">{{payment.status}}</div>
    </div>

    <div class="amount-line">
      <p class="amount">₹ {{ payment.amount }}</p>
      <p class="mobile">{{ payment.customerPhone }}</p>
    </div>

    <div *ngIf="payment.status === 'Partial Paid'" class="paid-amount-line">
      <p>Paid Amount: ₹ {{ payment.paidAmount }}</p>
    </div>

    <div class="link-line">
      <p class="link-id">Link ID: {{ payment.paymentId }}</p>
      <p class="date">{{ payment.createdOn | date: 'dd MMM yyyy h:m a'}}</p>
    </div>
    <hr class="dotted-line" />
    <div class="link-and-share" >
      <a href="{{payment.paymentLink}}" target="_blank" class="payment-link">{{payment.paymentLink}}</a>
      <button class="share-btn" (click)="shareOnWhatsApp()">
        <i class="material-icons" style="color: #144579">share</i>
        <span class="share-text">share</span>
      </button>
    </div>
  </div>

  <!-- Sidebar with Create Payment Form -->
  <div class="overlay" [class.visible]="isSidebarVisible" (click)="closeSidebar()"></div>
  
  <div class="sidebar" *ngIf="isSidebarVisible" (click)="$event.stopPropagation()">
    <header>
      <div class="back-button">
        <!-- <i (click)="CloseSidebarQuote()" style="margin-right: 10px;" class="material-icons-arrowback">arrow_back</i> -->
        <span class="title1" style="font-size: 18px;">Create Payment</span>
        <mat-icon (click)="closeSidebar()" class="sidebar-header">close</mat-icon>
        
      </div>
    </header>

    <div class="sidebar-content">
      <form [formGroup]="newPayments1">  <!-- ✅ Ensure the formGroup is correctly linked -->
        <!-- Contact Name -->
        <div class="laboue-price-column" style="margin-top: -8px;margin-left: -20px;">
          <mat-form-field appearance="outline" class="full-width" class="edit-labour-details" style="margin-top: 10px;">
          <mat-label>Labour Name</mat-label>
            <input
              matInput
              formControlName="contactName"
              placeholder="Enter Contact Name"
            />
          </mat-form-field>
        </div>
      
        <!-- Mobile Number -->
        <div class="laboue-price-column" style="margin-top: -14px; margin-left: -20px;">
          <mat-form-field appearance="outline" class="edit-labour-details">
            <mat-label>Mobile Number</mat-label>
            <input
              matInput
              formControlName="mobile"
              placeholder="Enter Mobile Number"
            />
          </mat-form-field>
        </div>
      
        <!-- Amount -->
        <div class="laboue-price-column" style="margin-top: -14px; margin-left: -20px;">
          <mat-form-field appearance="outline" class="edit-labour-details">
            <mat-label>Amount</mat-label>
            <input
              matInput
              formControlName="amount"
              placeholder="Enter Amount"
            />
          </mat-form-field>
        </div>
      
        <!-- Payment For (Optional) -->
        <div class="laboue-price-column" style="margin-top: -14px; margin-left: -20px;">
          <mat-form-field appearance="outline" class="edit-labour-details">
            <mat-label>Payment For</mat-label>
            <input
              matInput
              formControlName="paymentFor"
              placeholder="Provide the car Registration Number"
            />
          </mat-form-field>
        </div>
      </form>
      
      
    </div>
    
        <!-- Submit Button -->
        <div class="item-update" style="margin-top: -14px;">
          <button class="item-update-btn" [disabled]="newPayments1.invalid" (click)="submitPayment(newPayments1.value)">
            Create Payment Link
          </button>
          
        </div>
  </div>

  <!-- Payment Link Created Pop-up -->
  <div class="popup-container" *ngIf="paymentLinkCreated">
    <div class="popup">
      <button class="popup-close" (click)="closeSuccessPopup()">✖</button>
      <div class="popup-icon">
        <img src="{{successIcon}}" alt="successIcon">
      </div>
      <h2>Payment Link Created!</h2>
      
      <p><strong>Link ID: </strong>{{createdPaymentLinkId}}</p>
      <a [href]="createdPaymentLink" target="_blank">{{ createdPaymentLink }}</a>
      <i class="material-icons1" style="cursor: pointer;" (click)="copyToClipboard(createdPaymentLink)">file_copy</i>
      <span *ngIf="isCopied" style="color: green;">Copied to Clipboard</span>
      <p>{{ creationDate }}</p>
      <p>This Link will never expire</p>
      <button class="share-link-btn" (click)="shareOnWhatsApp()">
        <i class="material-icons">share</i>
        <span class="share-text">SHARE PAYMENT LINK</span>
      </button>
    </div>
  </div>

  <!-- Cancel Payment Confirmation Popup -->
  <div class="cancel-popup-container" *ngIf="showCancelConfirmation">
    <div class="cancel-popup">
      <p>Are you sure you want to cancel the payment link?</p>
      <button class="cancel-popup-btn" (click)="closeCancelPopup()">No</button>
      <button class="cancel-popup-btn confirm-btn" (click)="cancelPayment(selectedPayment)">Yes</button>
    </div>
  </div>

  <!-- Payment Link Canceled Success Popup -->
  <div class="success-popup-container" *ngIf="showCancelSuccess">
    <div class="success-popup">
      <div>
        <div class="cross-btn" (click)="closeSuccessPopup()">X</div>
      </div>
      <div class="success-popup-subcontainer">
        <div style="color: green;">✅</div>
        <h1 class="bold">Payment link cancelled!</h1>
        <h3 style="color:green">Payment Link Cancelled.</h3>
        <!-- <p><strong>Link ID: </strong>{{ linkId }}</p> -->

        <p *ngIf="selectedPayment.paymentId"><a href="{{ selectedPayment.paymentId }}" style="color: green;">{{ selectedPayment.paymentId}}</a></p>
        <p>{{ date }}</p>
      </div>
      <button class="blue-btn success-popup-btn" (click)="closeSuccessPopup()" style="background-color: #144579">OK, Got it</button>
    </div>
  </div>
  <!-- <meta name="viewport" content="width=device-width, initial-scale=1.0"> -->

</div>
