import { Route } from '@angular/compiler/src/core';
import { Component, OnInit,Output, EventEmitter, } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-asp-add-item-sidebar',
  templateUrl: './asp-add-item-sidebar.component.html',
  styleUrls: ['./asp-add-item-sidebar.component.scss']
})
export class AspAddItemSidebarComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  itemList: string = '';
  showError: boolean = false;
  isSidebarOpen = false;
  requestPage: string;
  isRequestPopupVisible = false;
  successIcon: string = '../../../../assets/icons/icons8-ok.gif';



  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    if(localStorage.getItem('searchItems') && localStorage.getItem('searchItems').length>0){
      this.itemList = localStorage.getItem('searchItems');
    }
  }
  submitItems(): void {
    if (!this.itemList.trim()) {
      this.showError = true; 
    } else {
      this.showError = false;       

      this.itemList = this.formatText(this.itemList);
      localStorage.setItem('searchItems', this.itemList);
      this.closeSidebar(); 

      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/my-item-list']);
      });
    }
  }
  openSidebar() {
    this.requestPage = localStorage.getItem('addItem')
    this.isSidebarOpen = true;
  }
  closeSidebar() {
    this.isSidebarOpen = false;
  }

  formatText(text: string): string {
    return text
      .split(/[\n,]+/) 
      .map(item => item.trim()) 
      .filter(item => item !== '') 
      .join(',');
  }
  openRequestPopup() {
    // Your existing logic
    // After logic, show the popup
    this.isRequestPopupVisible = true;
    this.isSidebarOpen = false;
  }
  
  closeRequestPopup() {
    this.isRequestPopupVisible = false;
  }
}
