import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyJobCardsDetailsComponent } from './my-job-cards-details.component';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
// import { AspRequestDetailsComponent } from '../asp-request-details/asp-request-details.component';
import { BrowserModule } from '@angular/platform-browser';
import { AspRequestDetailsModule } from '../asp-request-details/asp-request-details.module';  // Import the module
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import {MatListModule} from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';

const routes: Routes = [
  {
    path: 'my-job-cards-details/:id/:bookingId', 
    component: MyJobCardsDetailsComponent
   
  },
];


@NgModule({
  declarations: [
    MyJobCardsDetailsComponent,
    // AspRequestDetailsComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule, 
    HttpClientModule,
    BrowserModule,
    AspRequestDetailsModule ,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    MatListModule,
    MatDialogModule,
    MatIconModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatBottomSheetModule,
    MatDividerModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatOptionModule
  ],
  // entryComponents: [AspRequestDetailsComponent],
  exports: [MyJobCardsDetailsComponent, RouterModule],
})
export class MyJobCardsDetailsModule { }
