import { Component, OnInit } from '@angular/core';
import { AspInvoiceListService } from './asp-invoice-list.service';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AspLoadingService } from '../asp-loading/asp-loading.service';

interface JobCard {
  id: number;
  bookingId: number;
  phoneNumber: string;
  name: string;
  odometer: number;
  rowKey: string;
  variantKey: string;
  make: string;
  model: string;
  carSize: string;
  variant: string;
  fuelType: string;
  registrationNumber: string;
  createdAt: any;
  requestId: number;
  
}

interface FilterOption {
  label: string;
  value: string;
  checked: boolean;
  children?: FilterOption[];
}

interface Filters {
  jobCardStatus: FilterOption[];
  jobTime: FilterOption[];
}
@Component({
  selector: 'app-asp-invoice-list',
  templateUrl: './asp-invoice-list.component.html',
  styleUrls: ['./asp-invoice-list.component.scss']
})
export class AspInvoiceListComponent implements OnInit {
  loading: boolean;
  invoiceList: any;
  totalAmounts: any;
  filteredJobCards: JobCard[] = [];
  selectedJobCardStatus:  string = '';
  selectedJobCardTime: string = '';
  searchText:string = '';
  textInterval: NodeJS.Timeout;
  currentText:  string = "";
  currentIndex: number=0;
  searchOptions: any;
  emptyList: any = 'assets/images/no-cart.png';
  isMobile: any;
  isFilterSidebarOpen: boolean;
  constructor(private aspInvoiceListService: AspInvoiceListService,
    private router:Router,
    private breakpointObserver: BreakpointObserver,
    private aspLoadingService: AspLoadingService,
  ) { 
    this.breakpointObserver.observe([Breakpoints.Handset])
          .subscribe(result => {
            this.isMobile = result.matches;
          }); 
  }
  statusList = [
    { label: 'Draft', value: '1', checked: false, statusclass: 'delivered-status'},
    { label: 'Confirmed', value: '2', checked: false, statusclass: 'confirmed-status'},
     ];

  filters: Filters = {
    jobCardStatus: this.statusList,
      jobTime: [
      { label: 'Today', value: 'TODAY', checked: false },
      { label: 'Yesterday', value: 'YESTERDAY', checked: false },
      { label: 'Last 7 days', value: 'LAST_7_DAYS', checked: false },
      { label: 'Last 30 days', value: 'LAST_30_DAYS', checked: false },
      { label: 'This month', value: 'THIS_MONTH', checked: false },
      { label: 'Last Month', value: 'LAST_MONTH', checked: false },
      { label: 'Any Time', value: 'ANY_TIME', checked: false }
    ]
  };
  ngOnInit(): void {
    this.getInvoice()
  }
  getInvoice(){
    this.aspLoadingService.show();
    let garageKey = localStorage.getItem('garageKey');
    this.aspInvoiceListService.getInvoice(garageKey).subscribe(
      (result: any) => {
        this.aspLoadingService.hide();
        if (result && result.code == 200) {
          this.invoiceList = result.data.invoices;
          console.log("invoiceList",this.invoiceList);
          if (this.invoiceList) {
            this.invoiceList.sort((a: JobCard, b: JobCard) => {
              return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            });
            this.filteredJobCards = this.invoiceList;
            console.log("invoiceList",this.filteredJobCards);

          }
        }
      },
   );
  }

  getTotalAmount(invoice): string {
    return (invoice?.invoiceItems ?? [])
      .reduce((sum, item) => sum + ((item.sellingPrice / 100) * item.quantity), 0)
      .toFixed(0); // Ensures exactly two decimal places
  }
  
  navigateToDetails(id: string,jobcardId,quoteId) {
    this.router.navigate(['/invoice-details'], { queryParams: {"id": id, "jobcardId":jobcardId,"QuoteId":quoteId}})
  }
  filterRequest() {
    console.log("Status filter:",this.selectedJobCardStatus);
    console.log("List:",this.invoiceList);
    this.filteredJobCards = this.invoiceList.filter(invoice => {
      let status= invoice['invoiceStatus']?.toString();
      // let statusMatch = true; 
      const statusMatch = this.selectedJobCardStatus ? status === this.selectedJobCardStatus : true;
      const timeMatch = this.selectedJobCardTime ? this.filterByTime(invoice.createdAt, this.selectedJobCardTime) : true;


      const searchValue = this.searchText.trim().toLowerCase();
      const searchMatch = searchValue ? 
        (
          invoice.id.toString().includes(searchValue) || // Search by Invoice ID
          invoice.jobcardId?.toString().includes(searchValue) || // Search by Job Card ID
          invoice.jobCard?.registrationNumber?.toLowerCase().includes(searchValue) || // Search by Registration Number
          invoice.jobCard?.make?.toLowerCase().includes(searchValue) || // Search by Make
          invoice.jobCard?.model?.toLowerCase().includes(searchValue) || // Search by Model
          status.includes(searchValue) 
        ) 
        : true;
  
      return statusMatch && timeMatch && searchMatch;
    });

    // console.log("Filtered Data:",this.filteredJobCards);
  }

  

  filterByTime(jobcardTime: string, filter: string): boolean {
    const now = new Date();
    const jobDate = new Date(jobcardTime);

    switch (filter) {
      case 'TODAY':
        // Check if the jobDate is the same day as today
        return jobDate.toDateString() === now.toDateString();
        
      case 'YESTERDAY':
        // Check if the jobDate is the same day as yesterday
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);
        return jobDate.toDateString() === yesterday.toDateString();
        
      case 'LAST_7_DAYS':
        // Check if the jobDate is within the last 7 days
        const weekAgo = new Date(now);
        weekAgo.setDate(now.getDate() - 7);
        return jobDate >= weekAgo && jobDate <= now;
        
      case 'LAST_30_DAYS':
        // Check if the jobDate is within the last 30 days
        const monthAgo = new Date(now);
        monthAgo.setDate(now.getDate() - 30);
        return jobDate >= monthAgo && jobDate <= now;
        
      case 'LAST_MONTH':
        // Check if the jobDate is within the last month
        const lastMonthStart = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const lastMonthEnd = new Date(now.getFullYear(), now.getMonth(), 0);
        return jobDate >= lastMonthStart && jobDate <= lastMonthEnd;
        
      case 'ANY_TIME':
      default:
        return true;
    }
  }

  onEnterPress(event: any): void {
    this.filterRequest();

  }
  toggleSelection(filterType: keyof Filters, option: FilterOption) {
    if (filterType === 'jobCardStatus') {
        // Update request status filters
        this.filters.jobCardStatus.forEach(status => {
            status.checked = (status.value === option.value);
        });
        this.selectedJobCardStatus = option.value;
    } else if (filterType === 'jobTime') {
        // Update request time filters
        this.filters['jobTime'].forEach(time => {
            time.checked = (time.value === option.value);
            if (time.children) {
                time.children.forEach(child => {
                    child.checked = (child.value === option.value);
                });
            }
        });
        this.selectedJobCardTime = option.value;
    }

    // Save selected request status and request time to localStorage
    localStorage.setItem('selectedJobCardStatus', this.selectedJobCardStatus);
    localStorage.setItem('selectedJobCardTime', this.selectedJobCardTime);

    this.filterRequest();
  }
  
  clearFilters() {
    // Reset selected filter values
    this.selectedJobCardStatus = '';
    this.selectedJobCardTime = '';

    // Function to uncheck all options recursively
    const clearOptions = (options: FilterOption[]) => {
        options.forEach(option => {
            option.checked = false;
            if (option.children) {
                clearOptions(option.children);
            }
        });
    };

    // Clear all filter options in filters object
    (Object.keys(this.filters) as (keyof Filters)[]).forEach(jobTimeKey => {
        clearOptions(this.filters[jobTimeKey]);
    });

    // Clear filters in localStorage
    localStorage.removeItem('selectedJobCardStatus');
    localStorage.removeItem('selectedJobCardTime');
    this.searchText = '';

    // Call filterRequest to update the displayed data
    this.filterRequest();
  }
  removeFilter(jobTimeKey: keyof Filters, option: FilterOption) {
    option.checked = false;
    if (jobTimeKey === 'jobCardStatus') {
      this.selectedJobCardStatus = '';
    } else if (jobTimeKey === 'jobTime') {
      this.selectedJobCardTime = '';
    }
    this.filterRequest();
  }
  
  scrollText() {
    this.textInterval = setInterval(() => {
      this.currentText = this.searchOptions[this.currentIndex];
      this.currentIndex = (this.currentIndex + 1) % this.searchOptions.length;
    }, 2000);
  }

  onInputChange(): void {
    if (this.searchText.length === 0) {
      this.scrollText();
    } else {
      clearInterval(this.textInterval);
    }
    this.filterRequest();
  }

  clearSearch(): void {
    this.searchText = '';
    this.scrollText();
    this.filteredJobCards = this.invoiceList || [];
  }
  hasAppliedFilters(): boolean {
    return this.selectedJobCardStatus !== '' || this.selectedJobCardTime !== '';
  }
  getAppliedFilters(): { jobTime: keyof Filters, option: FilterOption }[] {
    const appliedFilters: { jobTime: keyof Filters, option: FilterOption }[] = [];

    const addFilter = (jobTimeKey: keyof Filters, option: FilterOption) => {
      if (option.checked) {
        appliedFilters.push({ jobTime: jobTimeKey, option });
      }
    };

    (Object.keys(this.filters) as (keyof Filters)[]).forEach(jobTimeKey => {
      this.filters[jobTimeKey].forEach(option => {
        addFilter(jobTimeKey, option);
        if (option.children) {
          option.children.forEach(child => {
            addFilter(jobTimeKey, child);
          });
        }
      });
    });

    return appliedFilters;
  }
  toggleFilterSidebar() {
    this.isFilterSidebarOpen = !this.isFilterSidebarOpen;
  }
  
}
