import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AspMyItemListComponent } from './asp-my-item-list.component';
import { RouterModule, Routes } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web'; 

export function playerFactory() {
  return player;
}

const routes: Routes = [
  {
    path: 'my-item-list',
    component: AspMyItemListComponent,
  },
];


@NgModule({
  declarations: [AspMyItemListComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatTabsModule,
    MatListModule,
    MatCardModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    LottieModule.forRoot({ player: playerFactory }) 
  ],
  exports: [AspMyItemListComponent, RouterModule],
})
export class AspMyItemListModule { }
