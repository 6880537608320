import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AspQuotationListService } from'./asp-quotation-list.service';
import { AspCustomerQuoteComponent } from'../asp-customer-quote/asp-customer-quote.component';
import { AspLoadingComponent } from '../asp-loading/asp-loading.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { AspLoadingService } from '../asp-loading/asp-loading.service'; 

@Component({
  selector: 'app-asp-quotation-list',
  templateUrl: './asp-quotation-list.component.html',
  styleUrls: ['./asp-quotation-list.component.scss']
})
export class AspQuotationListComponent implements OnInit, AfterViewInit {
  loading: boolean = false;
  orderList: any[] = [];
  filteredOrderList: any[]=[];
  isMobile: boolean;


  constructor(
    private aspQuotationListService:AspQuotationListService,
    private resolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private aspLoadingService: AspLoadingService,
  ) {
      this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); }

  ngOnInit(): void {
    this.getList();
  }
  @ViewChild('dynamicContainer1', { read: ViewContainerRef }) container1!: ViewContainerRef;
  @ViewChild('dynamicContainers', { read: ViewContainerRef }) containers!: ViewContainerRef;

  loadorderdetails(id,regNo) {
    
    const factory = this.resolver.resolveComponentFactory(AspCustomerQuoteComponent);
    const componentRef = this.container1.createComponent(factory);

    // Access the instance and call the method
    const componentBInstance = componentRef.instance as AspCustomerQuoteComponent;
    componentBInstance.closeSidebar(true);
    componentBInstance.getQuoteDetails();
    componentBInstance.resId(id);
    componentBInstance.regNo(regNo);
  }
ngAfterViewInit() {
  this.loadingMsg();
  this.cdr.detectChanges();
}

loadingMsg() {
  
  const factory = this.resolver.resolveComponentFactory(AspLoadingComponent);
  
 

  if (this.containers) {
    const componentRef = this.containers.createComponent(factory);
    

    const componentBInstance = componentRef.instance as AspLoadingComponent;
    componentBInstance.getLoadingMsg();
  } else {
    console.error('containers is undefined');
  }
}


  getList() {
    this.aspLoadingService.show();
    let spareUserKey = localStorage.getItem('spareUserKey');
    let garageKey = localStorage.getItem('garageKey');
    this.aspQuotationListService.orderList(spareUserKey, garageKey).subscribe(
      result => {
        let res = JSON.parse(JSON.stringify(result)); 
        if(res.code === 200){ 
          this.aspLoadingService.hide();
          // res.data.quotes.forEach(item => {
          //   // item.titleName = this.formatOrderDeliveryTime(item.orderConfirmTime, item.orderDeliveryTime);
          // });
          this.orderList = res.data.quotes;
  
          this.orderList.forEach(order => {
              this.filteredOrderList.push(order)
          });
        } else {
          this.aspLoadingService.hide();
        }
        this.filteredOrderList.sort((a, b) => new Date(b.placedOn).getTime() - new Date(a.placedOn).getTime());
      }
    );
    // console.log("this.filteredOrderList",this.filteredOrderList)
  }
  closeQuote(){
    this.router.navigate(['home']);
  }
}
