<div class="container">
  <!-- JobCard Info -->

    <!-- <div class="details" >
    <div class="logo-and-model">
      <img [src]="getMakeImageUrl(requestDetails?.spareRequest?.make)" alt="Icon" class="img">
      <div class="model-info">
        <span class="model-name">{{requestDetails?.spareRequest?.make}}</span>
        <span class="model-details">{{requestDetails?.spareRequest?.model}}, {{requestDetails?.spareRequest?.fuelType}}</span>
      </div>
    </div>
    <div class="timestamp">{{requestDetails?.spareRequest?.created | date:'dd MMM yyyy, hh:mm a': 'GMT+1060'}}
         </div>
    
  </div> -->
  <!-- <div class="buttons" >
    <button class="request-button" 
            [ngClass]="{'active': activeTab === 'requests'}"
            (click)="setActiveTab('requests')">REQUESTS</button>
    <button class="request-button" 
            [ngClass]="{'active': activeTab === 'quote-received'}"
            (click)="setActiveTab('quote-received')">QUOTE RECEIVED </button>
    <button class="request-button" 
            [ngClass]="{'active': activeTab === 'quote-approved'}"
            (click)="setActiveTab('quote-approved')">QUOTE APPROVED</button>
    <button class="request-button" 
            *ngIf="filteredOrderList?.length>0"
            [ngClass]="{'active': activeTab === 'order-created'}"
            (click)="setActiveTab('order-created')">ORDER CREATED</button>
  </div> -->

<div class="buttons" *ngIf="isJobCardDetails">
  <button class="request-button" 
          [ngClass]="{'active': activeTabs === 'Information'}"
          (click)="setActiveTabs('Information')">INFORMATION</button>
  <button class="request-button" 
          [ngClass]="{'active': activeTabs === 'Check-in'}"
          (click)="setActiveTabs('Check-in')">CHECK-IN </button>
  <button class="request-button" 
          [ngClass]="{'active': activeTabs === 'images'}"
          (click)="setActiveTabs('images')">IMAGES</button>
  <button class="request-button" 
          [ngClass]="{'active': activeTabs === 'quotation'}"
          (click)="aspAddItem()">REQUEST</button>
  <button class="request-button" 
          [ngClass]="{'active': activeTabs === 'quotation'}"
          (click)="setActiveTabs('quotation')">QUOTATION</button>
  <button class="request-button" 
          [ngClass]="{'active': activeTabs === 'invoice'}"
          (click)="setActiveTabs('invoice')">INVOICE</button>
</div>

<!-- Information Tab body -->
<div *ngIf="activeTabs === 'Information' && isJobCardDetails && data">
  <div class="header car-details">
      <h3 style="font-size: 20px;">Information</h3>
  </div>
  <div class="info-div" >
     <div class="car-details">
      <h3>Car Details 🚗</h3>
      <form>
        <div class="form-group">
          <label>Engine Number</label>
          <input
            type="text"
            id="engineNumber"
            [(ngModel)]="formData.engineNumber"
            placeholder="{{data?.engineNumber || 'Enter Engine Number' }}"
            name="engineNumber"
            required
          />
        </div>
        <div class="form-group">
          <label for="odometerReading">
            Odometer Reading<span class="required">*</span>

          </label>
          <input
          type="text"
          placeholder="{{ data?.odometer || 'Enter Odometer Reading' }}"
          name="odometerReading"
          required
          id="odometerReading"
          class="custom-placeholder"
          [(ngModel)]="formData.odometer"
        />
        
        </div>
                  
        <div class="form-group">
          <label for="date">Service Date</label>
          <input
              type="date"
              id="last-service-date"
              name="lastServiceDate"
              [(ngModel)]="formData.lastServiceDate"
            />
        </div>

        <!-- <mat-form-field appearance="outline">
          <mat-label>Last Service Date</mat-label>
          <input matInput [matDatepicker]="picker"  />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field> -->


        <div class="form-group">
          <label for="services">Services Type</label>
          <div class="dropdown-container" (click)="toggleDropdown()">
            <input type="text" placeholder="Select Services*"style="border: 1px solid #dddddd;" readonly />
            <mat-icon style="top: -39px; position: relative;    margin-left: 91%;">keyboard_arrow_down</mat-icon>
       
          </div>
          <div *ngIf="car.selectedServices.length > 0" style="margin-top:-21px">
            <div class="chips-container">
              <div class="chip" *ngFor="let service of car.selectedServices">
                {{ service }}
                <span class="close-icon" (click)="removeService(service)">×</span>
              </div>
            </div>
          </div>
          <div class="dropdoencompleteitems"  *ngIf="isDropdownOpen">
            <div class="dropdown-options" id="dropdownOptions">
              <div *ngFor="let service of serviceList" class="dropdown-item" >
                <label>
                 
                  <div class="dropdownitemnames" style="width:95%;">{{ service }}</div>
                  <input
                  style="width:5%;"
                    type="checkbox"
                    [checked]="car.selectedServices.includes(service)"
                    (change)="selectService(service, $event)"
                  />
                </label>
              </div>
            </div>
          </div>

          
        </div>
      </form>
    </div>
    <!-- <div *ngIf="errorMessage" class="error-message-new">
      {{ errorMessage }}
    </div> -->
       <div class="car-details">
      <div class="customerdetailsandicon">
        <h3>Customer Details</h3>
        <mat-icon class="customer-maticon">person_outline</mat-icon>
      </div>
      <form>
        <div class="form-group">
          <label for="customerName">Customer Name</label>
          <input
            type="text"
            placeholder="{{data?.customerName || 'Enter Customer Name'}}"
            name="customerName"
            [(ngModel)]="formData.customerName"
            required
          />
        </div>
        <div class="form-group">
          <label for="customerPhone">Phone Number</label>
          <input
            type="text"
            [(ngModel)]="formData.customerMobile"
            placeholder="{{data?.customerMobile || 'Enter Phone Number'}}"
            name="customerMobile"
            required
          />
        </div>
        <div class="form-group">
          <label >Email ID</label>
          <input
            type="email"
            [(ngModel)]="formData.customerEmail"
            placeholder="{{data?.customerEmail || 'Enter Email ID'}}"
            name="customerEmail"
          />
        </div>
      </form>
      
    </div> 

    <div  class="fuel-accessories-container">
      <div class="car-details">
        <h3 >Fuel Level</h3>
      </div>
       <div class="fuel-level-section">
        <p>Fuel Level: {{ data?.fuelLevel }}%</p>
        <div >
          <span>E</span>
          <input
            type="range"
            min="0"
            max="100"
            [(ngModel)]="data.fuelLevel"
            (ngModelChange)="updateFuelLevel($event)"
          />
          <span>F</span>
        </div>
      </div>
    
      <div class="accessories-section">
        <div class="car-details">
          <h3 >Accessories</h3>
        </div>
        <ul>
          <li *ngFor="let accessory of accessories">
            <img [src]="accessory.imageUrl" [alt]="accessory.description" />
            <label>
              {{ accessory.description }}
             <input
                type="checkbox"
                [checked]="fuelAndAccessories.checkedItems.includes(accessory.description)"
                (change)="selectAccessory(accessory.description, $event)"
              />
            </label>
          </li>
        </ul>
      </div>
    </div>
    <hr>
    <!-- --------------------ISSUES SECTION---------------- -->
     <div class="issues-section">
      <div class="issues-header">
        <div style="display: flex;">
          <mat-icon class="warning-maticon">warning</mat-icon>
          <h3>Do you have more issues?</h3>
        </div>
        <p style="color: #888;">
          Please mention any symptoms (issues) you have noticed in your car. This
          will help our technician to diagnose better.
        </p>
      </div>
      <div class="SearchBarContainer" (click)="toggleSearch()">
        <div class="SearchBarButton">
          <div class="SearchBarIcon">
            <i class="material-icons-search">search</i>
          </div>
          <input  type="text" placeholder="Search Your Issues"  [(ngModel)]="searchText" (input)="filterIssues()" (keydown.enter)="addTag()" class="SearchBarInput"    /> 
          <div class="remove-search-suggestion" *ngIf="filteredIssues"(click)="clearSearch1()" >
            <mat-icon>close</mat-icon>
          </div>             
      </div>
      </div>
      <div *ngIf="selectedIssues.length > 0" >
        <div  class="issue-chips-container">
          <div class="chip" *ngFor="let issue of selectedIssues">
            {{ issue }}
            <span class="close-icon" (click)="removeIssue(issue)">×</span>
          </div>
        </div>
      </div>
      <div class="issues-section">
        <ul>
          <li *ngFor="let issue of filteredIssues" [ngClass]="{ 'highlighted-item': issue.isHighlighted }">
            <label [innerHTML]="highlightMatch(issue, searchText)"></label>
            <input
              type="checkbox"
              [checked]="selectedIssues.includes(issue)"
              (change)="toggleIssue(issue, $event)"
            />
            {{ issue.description }}
          </li>
        </ul>
      </div>
   
        
      <!-- </div> -->
      <!-- <div class="classnext">
        <button class="create-order-btn" (click)="saveIssue()" >NEXT</button>
      </div> -->
    </div>
    <button class="updateInfo" (click)="checkInUpdate()">Update Information</button>
</div>
</div>

<!-- Check-in Tab body -->

<div *ngIf="activeTabs === 'images'" >
  <div class="header">
    <span style="width: 100%; display: contents;">
    <p style="width: 50%;font-size: 20px;text-align: left;">Add Images</p>
    <button class="add-image-btn"  (click)="openImageUpload()">
      <i class="camera_alt">camera_alt</i>
      <p style="margin-top: 10px;margin-bottom: 0px;">ADD IMAGES</p>
    </button>
  </span>
  <div class="popup" *ngIf="isUploading">
    <div class="popup-content">
      <div >
        <button mat-icon-button (click)="closeBottomSheet()" class="matClose">
          <mat-icon >close</mat-icon>
        </button>
        <h3 style="margin-left: -122px;margin-top: -26px;">Enter Image Tag </h3>
        <input type="text" [(ngModel)]="newTag" placeholder="Add a tag for images" class="newTag">
         <div *ngIf="tagError" class="error-text">Enter an image tag</div>
      </div>
      <input type="file" (change)="onFileChange($event)" multiple class="inputFile">
      
      <button (click)="uploadImages()" class="uploadBtn">Upload</button>
    </div>
  </div>
  </div>
<div class="imageDiv" >
  <div class="add-images-container">
    <!-- Title and Add Image Button -->
    <!-- <div class="image-upload-section" *ngIf="isUploading">
      <input type="file" (change)="onFileChange($event)" multiple>
      <input type="text" [(ngModel)]="newTag" placeholder="Add a tag for images">
      <button (click)="uploadImages()" class="uploadBtn">Upload</button>
    </div> -->
    <!-- Image Preview Section -->
    <div class="image-section">
      <div class="image-grid">
        <!-- Loop through predefined image tags -->
        <!-- Loop through predefined image tags -->
 <!-- Loop through the default image tags (front, engine, etc.) -->
 <div *ngFor="let tag of ['front', 'engine', 'battery', 'right', 'back', 'left','fuel-level']">
  <div *ngIf="getImage(tag)" style="margin-top: -27px;">
    <i class="material-icons cancelBtn" (click)="removeImage(tag)">cancel</i>
  </div>
  <div class="image-box" (click)="getImage(tag) ? openImageViewer(tag) : triggerFileInput(tag)">
    <ng-container *ngIf="getImage(tag); else defaultIcon">
      <img [src]="getImage(tag)" [alt]="tag" class="insideImage" />
    </ng-container>
    <ng-template #defaultIcon>
      <mat-icon>image</mat-icon>
    </ng-template>
  </div>
  <span class="image-gridtext">{{ tag | titlecase }}</span>
</div>

<!-- Loop through additional uploaded images -->
<div *ngFor="let image of additionalImages">
  <div *ngIf="additionalImages.length > 0" style="margin-top: -27px;">
    <i class="material-icons cancelBtn" (click)="removeImage(image.tag)">cancel</i>
  </div>
  <div class="image-box" (click)="triggerFileInput(image.tag)">
    <img [src]="image.url" [alt]="image.tag" style="height: 172px; border-radius: 8px;"/>
  </div>
  <span class="image-gridtext">{{ image.tag }}</span>
</div>

<!-- Image Viewer Popup -->
<div class="image-viewer" *ngIf="isViewerOpen" >
  <div class="image-container">
    <!-- <button class="prev-btn" (click)="prevImage()">&#10094;</button> -->
    <img [src]="getImagesForTag(currentTag)[currentIndex]" class="full-image">
    <div class="image-tag-popup">{{ currentTag | titlecase }}</div>
    <!-- <button class="next-btn1" (click)="nextImage()">&#10095;</button> -->
    <button class="close-btn" (click)="closeViewer()">✖</button>
  </div>
</div>



    
        <!-- Add New Image -->
       </div>
    
      <!-- Hidden file inputs for image upload -->
      <input *ngFor="let tag of ['front', 'engine', 'battery', 'right', 'back', 'left', 'fuel']"
        [id]="tag + 'FileInput'"
        type="file"
        accept="image/*"
        style="display: none"
        (change)="onImageSelected(tag, $event)"
      />
    </div>
    
    
    <!-- <div class="image-previews">
      <div *ngFor="let image of images; let i = index" class="image-item">
        <div>
          <img [src]="image.url" alt="Image Preview" class="image-preview" (click)="openImageViewer(i)">
          <i class="material-icons cancelBtn" (click)="removeImage(i)">cancel</i>
        </div>
        <div>
          <span class="image-tag">{{ image.tag }}</span>
        </div>
      </div>
    </div> -->
    
    <!-- Fullscreen Image Viewer (Modal) -->
    <!-- <div class="image-viewer" *ngIf="isViewerOpen">
      <div class="image-container">
        <button class="prev-btn" (click)="prevImage()" style="position: fixed;left: 127px;">&#10094;</button> 
        
        <img [src]="getImage(tag)" class="full-image">
        <div class="image-tag-popup">{{ images[currentIndex]?.tag }}</div>
    
        <button class="next-btn1" (click)="nextImage()">&#10095;</button>
    
        <button class="close-btn" (click)="closeViewer()">✖</button>
      </div>
    </div> -->
    
    
  
    <!-- Upload Section (hidden by default) -->

  </div>
  
</div>
</div>
<div *ngIf="activeTabs === 'Check-in'">
  <app-asp-checkin-reports (changeTab)="setActiveTabs($event)" [bookingId]="jobCard['bookingId']" 
  [jobCard]="jobCard['id']" style="top: 64px; position: relative;"></app-asp-checkin-reports>
 
</div>

<!-- <div *ngIf="activeTabs === 'quotation' && QuoteId">
  <app-asp-customer-quote 
  [QuoteId]="QuoteId" 
  [jobcardId]="jobcardId1"
  >
</app-asp-customer-quote> 
</div> -->
<div class="third-div" [hidden]="(activeTabs === 'invoice' && invoice)"  >
  <div #invoiceTab></div>
</div>
<div class="third-div" [hidden]="!(activeTabs === 'invoice' && invoice)">
  <div #invoiceContainer></div>
</div>
<!-- <div class="third-div" [hidden]="!(activeTabs === 'quotation' && QuoteId)">
  <div #dynamicContainer1></div>
</div> -->
<!-- Content for Quote Recieve Tab -->
<div *ngIf="activeTabs === 'quotation' || isRequestDetails" >
  <div *ngIf="activeTab === 'quote-received'">
    <div *ngIf="quotationreceived.length > 0" class="items" style="    margin-bottom: 78px;">
      <div class="request-info">Quote received for {{quotationreceived.length}} items </div>
      <div class="item" >
        <div class="checkboxselect">
          <input type="checkbox" [checked]="selectAll" (change)="toggleSelectAll()">
          <span >Select All</span>
        </div>
        <div>
          <span class="quote-rcvd-prz">₹ {{quoteReceivedPrice | number:'1.2-2'}}</span>
          <div class="total-slng-prz"> Total Selling Price  </div>
        </div>
      </div>
      <div class="quotereceived-items" *ngFor="let item of quotationreceived" [ngClass]="{'border-box': item.selected}">
        <div class="checkboxselect">
          <div class="lines" >
            <input type="checkbox" [(ngModel)]="item.selected" (change)="toggleItemSelection()">
            <span class="part-name" >{{item.itemName}}</span>
            <span class="ellipsis" (click)="openSidebar(item)" style="margin-top: -4px;">⋮</span>
          </div>
      
          <div class="lines" >
            <!-- <span class="availability"> Availability: </span> -->
            <span class="date">₹{{(item.mrp - (item.mrp* item.sellingDiscount /100))*item.itemQuantity | number:'1.2-2'}}</span>

          </div>
        </div>
        <div class="lines">
          <span class="date-time" style="margin-left: 18px;">{{item.edt  | date:'dd MMM yyyy'}}</span>
         
        </div>
      </div>
    </div>

    <!-- <ng-template #noQuotesReceived >
      <div>
        <div class="empty-state">
          <div  *ngIf="isQuoteApproved(); else notApproved">
            <img src="../../../../assets/images/request-details images/requestdetailsimages.png" alt="Quote Approved" class="quote-image">
            <div  class="message">
              <strong >Quote Has Been Approved!</strong>
              <p>Check Quote Approved Section</p>
            </div>
          </div>
        </div>
        <ng-template #notApproved>
          <div class="empty-state"  *ngIf="quotationreceived.length === 0">
            <img src="../../../../assets/images/request-details images/requestdetailsimage.png" alt="Quote Not Created" class="quote-image">
            <div class="message">
              <strong>Quote Not Created Yet!</strong>
              <p>Stay tuned for updates</p>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-template> -->
  </div>

  <!-- <div class="third-div" > -->
    <!-- <div class="overlay" [class.visible]="activeTabs === 'quotation'" (click)="closeSidebar1()" *ngIf="activeTabs === 'quotation'"></div> -->
  <!-- <div class="sidebar1" *ngIf="activeTabs === 'quotation'">
    <div class="sidebar-header">
      <span class="sidebar-title" >Quotation Details</span>
      <span class="close-btn" (click)="closeSidebar1()">&times;</span>
    </div>

    
    <div class="buttons">
      <button class="request-button" 
              [ngClass]="{'active': activeTab === 'quote-received'}"
              (click)="setActiveTab('quote-received')">QUOTE RECEIVED</button>
      <button class="request-button" 
              [ngClass]="{'active': 
              }"
              (click)="setActiveTab('quote-approved')">QUOTE APPROVED</button>
    </div>

      

  
      <div *ngIf="activeTab === 'quote-approved'">
        <div *ngIf="quotationapproved.length > 0; else noQuotesApproved" class="items">
          <div class="request-info"> Quote approved  for {{quotationapproved.length}} items </div>
          <div class="total-selling-price">
            <span>Total Selling Price</span>
            <span class="total-price">₹ {{quoteApprovedPrice | number:'1.2-2'}} </span>
          </div>
        
          <div class="quoteaproved-items" *ngFor="let item of quotationapproved" [ngClass]="{'border-box': item.selected}">
            <div class="line" >
              <span class="part-name">{{item.itemName}}</span>
              <span class="ellipsis" (click)="openSidebar(item)">⋮</span>
            </div>
            <div class="line">
              <span class="availability">Availability</span>
              <span class="date">₹{{(item.mrp - (item.mrp* item.sellingDiscount /100))*item.itemQuantity}}</span>
            </div>
            <div class="line">
              <span class="date-time">{{item.edt  | date:'dd MMM yyyy'}}</span>
              <span class="amount">Total Selling Price</span>
          </div> 
        </div>  
      </div>
      <ng-template #noQuotesApproved>
        <div class="empty-state">
          <div >
            
            <img src="../../../../assets/images/request-details images/requestdetailsimage.png" alt="Quote Not Approved" class="quote-image">
            <div class="message">
              <strong>Quote Not Approved Yet!</strong>
              <p>Awaiting your approval</p>
            </div>
          </div>
        </div>
        <button class="approveNow">APPROVE QUOTE NOW</button>
      </ng-template>
    </div>
  </div> -->


    <!-- <div style="font-weight: 900;font-size: larger; padding: 10px 0;border-bottom: 1px solid #ddd;" class="request-info" *ngIf="activeTab !== 'quote-received' ">
      <strong>Request made for {{requestDetails.spareRequestItemList.length}} items </strong>
       <hr /> 
    </div> -->

    <div *ngIf="isRequestDetails && activeTab === 'requests'" class="request-item">
      <div class="request-info">Request made for {{requestDetails?.spareRequestItemList?.length}} items </div>
      <div class="item" *ngFor="let item of requestDetails?.spareRequestItemList">
        <span class="span-item-name">{{item.itemName}}</span>
        <span class="quantity">{{item.itemQuantity}} Unit</span>
      </div>
    </div>
    <!-- <hr /> -->
     <!-- sidebarOpenQuote -->

    <!-- Content for Quote Received Tab -->
    <div *ngIf="activeTab === 'quote-received' && isRequestDetails && sidebarOpenQuote">   
      <div *ngIf="quotationreceived.length > 0; else noQuotesReceived" class="items" style="margin-bottom: 88px;">
        <div class="request-info">Quote received for {{quotationreceived.length}} items </div>
        <div class="item" >
          <div class="checkboxselect">
            <input type="checkbox" [checked]="selectAll" (change)="toggleSelectAll()">
            <span >Select All</span>
          </div>
          <div>
            <span class="quote-rcvd-prz">₹ {{quoteReceivedPrice | number:'1.2-2'}}</span>
            <div class="total-slng-prz"> Total Selling Price  </div>
          </div>
        </div>
        <div class="quotereceived-items" *ngFor="let item of quotationreceived" [ngClass]="{'border-box': item.selected}">
          <div class="checkboxselect">
            <div class="lines" >
              <input type="checkbox" [(ngModel)]="item.selected" (change)="toggleItemSelection()">
              <span class="part-name" >{{item.itemName}}</span>
              <span class="ellipsis" (click)="openSidebar(item)" >⋮</span>
            </div>
        
            <div class="lines" style="margin-left: 1104px;">
              <span class="availability"> Availability: </span>
              <span class="date">₹{{(item.mrp - (item.mrp* item.sellingDiscount /100))*item.itemQuantity | number:'1.2-2'}}</span>
  
            </div>
          </div>
          <div class="lines">
            <span class="date-time" >{{item.edt  | date:'dd MMM yyyy'}}</span>
           
          </div>
        </div>
      </div>
        <div class="quotereceived-items" *ngFor="let quote of selectedItemsLabourQuote" [ngClass]="{'border-box': quote.selected}">
          <div class="checkboxselect">
            <input 
              type="checkbox" 
              [(ngModel)]="quote.selected" 
              (change)="onLabourSelectionChange(quote)"
            />
            <span class="part-name">{{ quote.labourName }}</span>
            <span class="date">₹{{ quote.labourAmount | number: '1.2-2' }}</span>
            <i class="material-icons" (click)="openLabourEditSidebar(quote)">edit</i>
          </div>
        </div>
        
      </div>
      <!-- Template for No Quotes Received -->
       <ng-template #noQuotesReceived >
        <div>
          <div class="empty-state">
            <div  *ngIf="isQuoteApproved(); else notApproved">
              <img src="../../../../assets/images/request-details images/requestdetailsimages.png" alt="Quote Approved" class="quote-image">
              <div  class="message">
                <strong >Quote Has Been Approved!</strong>
                <p>Check Quote Approved Section</p>
              </div>
            </div>
          </div>
          <ng-template #notApproved>
            <div class="empty-state"  *ngIf="quotationreceived.length === 0">
              <img src="../../../../assets/images/request-details images/requestdetailsimage.png" alt="Quote Not Created" class="quote-image">
              <div class="message">
                <strong>Quote Not Created Yet!</strong>
                <p>Create Customer Quotation.</p>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-template> 
    </div>
    <!-- Content for Quote Approved Tab (under quote approved tab)-->
    <div *ngIf="activeTab === 'quote-approved' && isRequestDetails">
      <div *ngIf="quotationapproved.length > 0; else noQuotesApproved" class="items">
        <div class="request-info"> Quote approved for {{quotationapproved.length}} items </div>
        <div class="checkboxselect-approved">
          <input type="checkbox" [checked]="selectAll" (change)="ApprovedtoggleSelectAll()">
          <span style="margin-left: 21px;">Select All</span>
        </div>
        <div class="total-selling-price">
          <span>Total Selling Price</span>
          <span class="total-price">₹ {{quoteApprovedPrice | number:'1.2-2'}} </span>
        </div>

        <!-- <hr class="divider"> -->
        <div class="quoteaproved-items" *ngFor="let item of quotationapproved" [ngClass]="{'border-box': item.selected}">
          <!-- {{item.quoteItemStatus}} -->
           <div class="status-tag-ellips">
            <span class="ellipsis" (click)="openSidebar(item)">⋮</span>
          <!-- <div class="approved-pending-tag" *ngIf="item.quoteItemStatus== 2">
            <mat-icon class="hourglass-icon">hourglass_full</mat-icon>
            Pending
          </div>
          <div class="approved-completed-tag" *ngIf="item.quoteItemStatus == 1">
            <mat-icon class="thumb-icon">thumb_up</mat-icon>
            Completed
          </div> -->
         
        </div>
          <div class="line" >
            <input type="checkbox" [(ngModel)]="item.selected" (change)="ApprovedtoggleItemSelection()">
            <span class="part-name">{{item.itemName}}</span>
           
          </div>
          <div class="line-mrp" style="text-align: end;">
            <!-- <span class="availability">Availability</span> -->
            <span class="date">₹{{(item.mrp - (item.mrp* item.sellingDiscount /100))*item.itemQuantity}}</span>
          </div>
          <div class="line">
            <span class="date-time" style="margin-left: 29px;">{{item.edt  | date:'dd MMM yyyy'}}</span>
            <span class="amount">Total Selling Price</span>
        </div> 
      </div>  
    </div>
    <!-- Template for No Quotes Approved -->
    <ng-template #noQuotesApproved>
      <div class="empty-state">
        <div> 
          <img src="../../../../assets/images/request-details images/requestdetailsimage.png" alt="Quote Not Approved" class="quote-image">
          <div class="message">
            <strong>Quote Not Approved Yet!</strong>
            <p>Awaiting your approval</p>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <!-- Sidebar Section -->
  <div class="overlay" [class.visible]="sidebarOpen" (click)="closeSidebar()"></div>
   <div *ngIf='itemDetails' [class.sidebar-open]="sidebarOpen" class="sidebar">
          <div class="sidebar-header" >
            <span class="sidebar-title">{{itemDetails.itemName}}</span>
            <span class="close-btn" (click)="closeSidebar()">&times;</span>
          </div>
          <div class="sidebar-content">
            <div class="sidebar-item">
              <span class="label">Brand</span>
              <span class="value" >{{ itemDetails.brand}}</span>
            </div>
            <div class="sidebar-item">
              <span class="label">Quantity</span>
              <span class="value">{{itemDetails.itemQuantity}}</span>
            </div>
            <div class="sidebar-item">
              <span class="label">Availability</span>
              <span class="value">{{itemDetails.edt  | date:'dd MMM yyyy, hh:mm a'}}</span>
            </div>
            <div class="sidebar-item">
              <span class="label">MRP(Unit)</span>
              <span class="value">₹{{itemDetails.mrp | number:'1.2-2' }}</span>
            </div>
            <div class="sidebar-item" >
              <span class="label">MRP Total</span>
              <span class="value">₹{{itemDetails.mrp * itemDetails.itemQuantity | number:'1.2-2'}}</span>
            </div>
            <div class="sidebar-item">
              <span class="label">Selling Price(Unit)</span>
              <span class="value">₹{{itemDetails.mrp - (itemDetails.mrp* itemDetails.sellingDiscount /100) | number:'1.2-2'}}</span>
            </div>
            <div class="sidebar-item">
              <span class="label">Total Selling Price</span>
              <span class="value">₹{{(itemDetails.mrp - (itemDetails.mrp* itemDetails.sellingDiscount /100)) * itemDetails.itemQuantity | number:'1.2-2' }}</span>
            </div>
            <div class="sidebar-item">
              <span class="label">Discount</span>
              <span class="value">{{itemDetails.sellingDiscount}} %</span>
            </div>
            <div class="sidebar-item">
              <span class="label">Part Number</span>
              <span class="value">{{itemDetails.partNumber}}</span>
            </div>
          </div>
          <button class="okay-btn" (click)="closeSidebar()">Okay, got it</button>
        </div>
  <!-- Bottom Box for Selected Items -->
  <!-- <div *ngIf="activeTab === 'quote-received' && (activeTabs !== 'quotation' || activeTabs !== 'Information' || activeTabs !== 'Check-in' || activeTabs !== 'invoice')"> -->
  <div *ngIf="activeTab === 'quote-received' && !isJobCardDetails">

    <div *ngIf="selectAll>0 || selectedItemsCount>0" class="bottom-box">
      <div class="bottom-content">
        <div>
          <div class="total-items">selectedItemsCount items</div>

          <div class="total-amount">Total Selling Price ₹{{totalSelectedPrice | number:'1.2-2'}} </div>
        </div>
      </div>
      <!-- <button class="approve-quote-button" (click)="getAllRequestPopup()">Approve Quote</button> -->
      <button class="approve-quote-button" (click)="approveQuote()" class="approve-quote-btn">Approve Quote</button>     
    </div>
  </div>
      <!-- Bottom Box for approved Selected Items -->
      <div *ngIf="activeTab === 'quote-approved'">
        <div *ngIf="selectedApprovedItemsCount>0" class="bottom-box">
          <div class="bottom-content">
            <div>
              <div class="total-items">selectedApprovedItemsCount items</div>
    
              <div class="total-amount">Total Selling Price ₹{{totalSelectedApprovedPrice | number:'1.2-2'}} </div>
            </div>
          </div>
          <!-- <button class="approve-quote-button" (click)="getAllRequestPopup()">Approve Quote</button> -->
          <button class="approve-quote-button" (click)="markAsCompleteUpdate()" class="approve-quote-btn">Mark as Complete</button>
          
               
        </div>
      </div>
</div>



 <div *ngIf="activeTab === 'order-created'">
  <div *ngIf="!loading">
    <div *ngFor="let list of filteredOrderList" >
      
      <!-- <div *ngIf="list.requestId== id"> -->
      <div class="header-row0"  *ngIf="list.orderNumber !=null">  
        <div class="header-row">
          <div class="order-icon" style="width: 5%;">
            <i class="material-icons">check_box</i>
          </div>
          <div class="order-info">
            <div class="order-status">
              <h1 *ngIf="list.titleName && list.titleName !== '0 minutes'" >
                Order delivered in {{ list.titleName }}</h1>
                <h1 *ngIf="list.titleName && list.titleName === '0 minutes'">
                  Your order is in progress</h1>
              <div 
                class="delivery-status" 
                [ngClass]="{
                    'delivered-status': list.orderCurrentStatus === 'Delivered',
                    'confirmed-status': list.orderCurrentStatus === 'Confirmed',
                    'packed-status': list.orderCurrentStatus === 'Packed',
                    'on-the-way':list.orderCurrentStatus==='On The Way'}">
                  {{list.orderCurrentStatus}}
              </div>
            </div>
            <div class="request-id"  style="margin-top: 43px;" >Order No# {{list.orderNumber}}</div>
            <div class="request-id" *ngIf="list.requestId !== 0">Request ID# {{list.requestId}}</div>
            <div class="order-amount">Total Amount - ₹{{list.grandTotal}} </div>
          </div>
        </div>
        <div class="header-row1">
          <div class="order-item"><span *ngFor="let items of list.orderItems; index as i"><span *ngIf="i!=0">,</span> {{items.partName}}</span></div>
        </div>
        <div class="header-row2">
          <div class="order-date">
            <span>Placed on: {{list.createdAt | date: 'dd MMM yyyy'}}, {{list.createdAt | date: 'hh:mm a'}}</span>
            <button (click)="loadorderdetails(list.orderKey)" class="Viewbtn">
              <span class="view">View Details</span>
              <i class="material-icons">arrow_right</i> 
            </button>
          </div>
        </div>
      </div>
    <!-- </div> -->
      </div>
    </div>
</div>
<!-- </div> -->
<div #dynamicContainer></div>

<!-- Edit Dialog and Overlay -->
<div class="overlay1" [class.visible]="isPopupOpenEdit" (click)="closePopupEdit()"></div>
<div *ngIf="isPopupOpenEdit" [ngClass]="isMobile ? 'bottom-sheetMobile' : 'bottom-sheet'">
  <header class="back-button">
    <div style="flex-direction: column;width: 87%;">
      <h3 class="header-name" style="margin-bottom: 2px;margin-top: -2px;">{{ itemQuote.itemName }}</h3>
      <h3 class="header-name" style="margin-bottom: 2px;margin-top: -2px;font-weight:100;font-size:13px">{{ itemQuote.brand }}</h3>
    </div>
    <mat-icon (click)="closePopupEdit()" class="close-pop-up">close</mat-icon>
  </header>
  <div class="bottom-sheet-content">
    <form [formGroup]="unitPrice" class="unit-price">
    <div class="unit-price">
      <mat-form-field appearance="outline" class="full-width" class="edit-labour-details">
        <mat-label>Unit Price</mat-label>
        <input
          matInput
          formControlName="unitprice"                            
          [(ngModel)]="itemQuotesEdit.mrp"
          placeholder="Enter Unit Price*"
          required
          name="Unit Price"
          pattern="^[0-9]*$"
        />
        </mat-form-field>
    </div>
  </form>
  <div class="item-update">
    <button (click)="save()" class="item-update-btn">Save Quote</button>
  </div>
  </div>
</div>
<div *ngIf="activeTabs === 'quotation' && QuoteId">
    <!-- <app-asp-checkin-reports (changeTab)="setActiveTabs($event)" [bookingId]="jobCard['bookingId']" 
    [jobCard]="jobCard['id']"></app-asp-checkin-reports> -->
    <app-asp-customer-quote 
    [QuoteId]="QuoteId" 
    [jobcardId]="jobcardId1"
    >
  </app-asp-customer-quote> 
</div>

<mat-sidenav-container *ngIf="!QuoteId && sidebarOpenQuote">
  <div class="overlay" [class.visible]="sidebarOpenQuote" (click)="CloseSidebarQuote()" ></div>
  <div class="sidebar" [ngClass]="{
    'sidebar-openQuote': sidebarOpenQuote && !isMobile,
    'sidebar-openQuoteMobile': sidebarOpenQuote && isMobile
  }">
  



    <!-- starting creating customer quotation -->
    <mat-list >
        <header>
          <div class="back-button">
            <i (click)="CloseSidebarQuote()" class="material-icons-arrowback" style="margin-right: 10px;">arrow_back</i>
            <span class="title1">Customer Quotation</span>
            <div *ngIf="quoteId">
              <h2>{{ quoteId }}</h2>
            </div>
          </div>
        </header>
        <div class="detailsQuote">
          <div class="logoModel" *ngIf="(isJobCardDetails && requestId) ">
              <img [src]="getMakeImageUrl(requestDetails?.spareRequest?.make)" alt="Icon" class="img">
              <div class="model-info">
                <span class="model-name">{{requestDetails?.spareRequest?.make}}</span>
                <span class="model-details">{{requestDetails?.spareRequest?.model}}, {{requestDetails?.spareRequest?.fuelType}}</span>
              </div>
          </div>
          <div class="logoModel" *ngIf="isJobCardDetails && !requestId">
            <img [src]="getMakeImageUrl(request?.jobCard?.make)" alt="Car Make Image" class="img">
            <div class="model-info">
              <span class="model-name">{{ request?.jobCard?.make || 'Unknown Make' }}</span>
              <span class="model-details">{{ request?.jobCard?.model || 'Unknown Model' }}, {{ request?.jobCard?.fuelType || 'Unknown Fuel Type' }}</span>
              <span class="model-details">{{ request?.jobCard?.registrationNumber || '' }}</span>
              <span class="model-details">{{ request?.jobCard?.vinNumber || '' }}</span>
            </div>
          </div>
          <!-- <div class="buttons" *ngIf="activeTabs === 'quotation'">
            <button class="request-button" 
                    [ngClass]="{'active': activeTab === 'quote-received'}"
                    (click)="setActiveTab('quote-received')">QUOTE RECEIVED</button>
            <button class="request-button" 
                    [ngClass]="{'active': activeTab === 'quote-approved'}"
                    (click)="setActiveTab('quote-approved')">QUOTE APPROVED</button>
                    
          </div> -->
          <!-- <mat-divider></mat-divider> -->
          <div *ngIf="customerQuotationCount>=0 else noQuotesReceived">
              <div  class="items" *ngIf="(activeTab === 'quote-received') || (activeTabs==='quotation') ">
                <!-- <div class="request-info">Quote received for {{requestDetails?.spareRequestItemList?.length}} items </div> -->
                <div class="item" >
                  <div class="checkboxselect">
                    <input type="checkbox" [checked]="selectAllQuote" (change)="toggleSelectAllQuote()">
                    <span>Select All</span>
                  </div>
                  <div style="text-align: right;">
                    <span class="customer-quote-price" >₹ {{quoteGrandTotal | number:'1.0-0'}}</span>
                    <div class="quote_total_amount">Total Amount</div>
                  </div>
                </div>
                <div class="spare-part-header">
                <h2 style="font-weight: bold;">Spare Parts</h2>
                <p style="margin-top:23px;color:#144579;cursor:pointer;" (click)="AddItems()" *ngIf="(customerQuotation?.length > 0 && selectedItemsLabourQuote?.length > 0)">Add More</p>
              </div>
              <div class="quotereceived-items" *ngFor="let quote of customerQuotation" [ngClass]="{'border-box': quote.selected}">
                <div class="lines" style="display: flex;">
                  <input class="checkbox-spare" type="checkbox" [(ngModel)]="quote.selected" (change)="sidebarToggleItemSelectionQuote()">
                  <span class="part-name">{{quote.itemName}}</span>
                  <i class="material-icons-spare" style="cursor:pointer;" (click)="openEditDialog(quote)">edit</i>                  
                </div>
                <!-- <div class="lines">
                    <input type="checkbox" [(ngModel)]="quote.selected" (change)="sidebarToggleItemSelectionQuote()">
                    <span class="part-name">{{quote.itemName}}</span>
                    <i class="material-icons" (click)="openEditDialog(quote)">edit</i>                  
                  </div>-->
                
                  <div class="lines-mrp" style="text-align: right;">
                    
                    <span class="spare-mrp">₹{{quote.mrp * quote.itemQuantity | number:'1.0-0'}}</span> 
                  </div> 
                  <!-- <div class="lines">
                    <span class="date-time">{{quote.edt | date:'dd MMM yyyy'}}</span>
                  </div>  -->
                <hr style="border: 1px solid #ebebeb;" />
                <div class="quantity-control">
                  <div class="brand" style="    width: 71%;
                  color: #999;
                  font-size: 13px;">
                    {{quote.brand}}
                  </div>
                  <div class="addtocartcompatiblecar1">
                    <div class="spare-add-cart1">
                        <div class="btn-group">
                            <button class="button1" mat-raised-button style="    min-width: 8px;
                            padding: 2px 0px;
                            margin-right: -3px;
                            height: 38px;
                            font-size: 11px;" (click)="decrementjobcardQuantity(quote)">
                                <mat-icon class="sidebuttons1" style="font-size: 18px;">remove</mat-icon>
                            </button>
                            <!-- <input type="text" [(ngModel)]="cartList[0][list.key].cart_qty" readonly style="height:38px;;text-align: center; width: 40%;"> -->
                            <button  class="button1" mat-raised-button style="    padding: 2px 0px;
                            margin-left: -3px;
                            height: 32px;
                            margin-right: 1px;
                            font-size: 13px;">
                              {{quote.itemQuantity}} 
                            </button>
                            <mat-icon class="sidebutton1" style="    width: 1px;
                            padding: 7px 0px;
                            margin-left: -6px;
                            margin-right: -3px;
                            font-size: 18px;
                            height: 35px;
                            vertical-align: center;
                            color: #144579;" (click)="incrementjobcardQuantity(quote)">add</mat-icon>
                        </div>
                    </div>
                </div>
                  <!-- <div class="add-minus">
                    <button mat-icon-button (click)="decrementjobcardQuantity(quote)">
                      <mat-icon style="margin-right: 6px;">remove</mat-icon>
                    </button>
                    <span style="padding: 7px;margin-left: 12px;">{{ quote.itemQuantity }}</span>
                    <button mat-icon-button (click)="incrementjobcardQuantity(quote)">
                      <mat-icon>add</mat-icon>
                    </button>
                </div> -->
                </div>
              </div>
              </div>
              
              <div class="labour-section-details" *ngIf="selectedItemsLabourQuote?.length>0">
                <div class="labour-header-title">
                  <h2 style="font-weight: bold;">Labour Charges</h2>
                  <p style="margin-top:23px;color:#144579;cursor:pointer;font-weight: bold;" (click)="addLabourSidebar()" >Add More</p>
                </div>
                <div class="quotereceived-items" *ngFor="let quote of selectedItemsLabourQuote" [ngClass]="{'border-box': quote.selected}">
                  <div class="checkboxselect">
                    <div class="labour-lines" style="display:flex">
                      <input type="checkbox" class="checkbox-spare" [(ngModel)]="quote.selected" (change)="toggleItemSelectionQuote()">
                      <span class="part-name">{{quote.labourName}}</span>
            
                      <i class="material-icons-spare" style="cursor:pointer;" (click)="openLabourEditSidebar(quote)">edit</i>
                    </div>
            
                    <!-- <span class="date" style="text-align: end;">₹{{quote.labourAmount * quote.quantity | number:'1.0-0'}}</span>
             -->
                    <span class="date" style="text-align: end;">₹{{quote.labourAmount * quote.quantity | number:'1.0-0'}}</span>

                  </div>
                  <div class="lines">
                    <span *ngIf="labourItemsCount > 0" class="date-time">{{quote.edt  | date:'dd MMM yyyy'}}</span>
                  </div>
                  <hr style="border: 1px solid #ebebeb;" />
                  <div class="quantity-control">
                    <div class="brand" style="    width: 71%;
                    color: #999;
                    font-size: 13px;">
                      {{quote.brand}}
                    </div>
                    <div class="addtocartcompatiblecar1">
                      <div class="spare-add-cart1">
                          <div class="btn-group">
                              <button class="button1" mat-raised-button style="    min-width: 8px;
                              padding: 2px 0px;
                              margin-right: -3px;
                              height: 38px;
                              font-size: 11px;" (click)="decrementLabourQuantity(quote)">
                                  <mat-icon class="sidebuttons1" style="font-size: 18px;">remove</mat-icon>
                              </button>
                              <!-- <input type="text" [(ngModel)]="cartList[0][list.key].cart_qty" readonly style="height:38px;;text-align: center; width: 40%;"> -->
                              <button  class="button1" mat-raised-button style="    padding: 2px 0px;
                              margin-left: -3px;
                              height: 32px;
                              margin-right: 1px;
                              font-size: 13px;">
                                {{quote.quantity}} 
                              </button>
                              <mat-icon class="sidebutton1" style="    width: 1px;
                              padding: 7px 0px;
                              margin-left: -6px;
                              margin-right: -3px;
                              font-size: 18px;
                              height: 35px;
                              vertical-align: center;
                              color: #144579;" (click)="incrementLabourQuantity(quote)">add</mat-icon>
                          </div>
                      </div>
                  </div>
                    <!-- <div class="add-minus">
                      <button mat-icon-button (click)="decrementjobcardQuantity(quote)">
                        <mat-icon style="margin-right: 6px;">remove</mat-icon>
                      </button>
                      <span style="padding: 7px;margin-left: 12px;">{{ quote.itemQuantity }}</span>
                      <button mat-icon-button (click)="incrementjobcardQuantity(quote)">
                        <mat-icon>add</mat-icon>
                      </button>
                  </div> -->
                  </div>

                </div>
              </div>

            </div>
            <ng-template #noQuotesReceived>
              <div>
                  <div class="empty-state">
                    <img src="../../../../assets/images/request-details images/requestdetailsimage.png" alt="Quote Not Created" class="quote-image">
                    <div class="message">
                      <strong>Quote Not Created Yet!</strong>
                      <p>Create Customer Quotation.</p>
                    </div>
                  </div>
              </div>
            </ng-template> 
          </div>

        <div *ngIf="(customerQuotation?.length > 0 && selectedItemsLabourQuote?.length == 0)" class="floater-btn" (click)="AddItems()">
          <p style="font-weight: bold;">ADD ITEM</p>
        </div>
        <!-- Add Item Side Bar -->
        <div *ngIf="isSidebarOpen" class="new-sidebar">
          <div class="header1" style="margin-left: 3px;">
            <div class="maticon" style="margin-left: 3px;">
            <mat-icon style="margin-left: 3px;" (click)="toggleSidebar()" >arrow_back</mat-icon>
          </div>
          <div class="add-items">
            <h3 style="font-weight: bold;margin-bottom:20px;float:left;">Add Items</h3>
          </div>
          </div>
          <div class="search-bar">
            <input
              type="text"
              placeholder="Search by Part Name"
              [(ngModel)]="searchWord"
              class="search-input"
              (input)="onSearch()"
            />
            <button *ngIf="searchWord" class="clear-btn" (click)="clearSearch()">&#x2715;</button>
          </div>
          
          <div class="item-list">
            <div *ngFor="let item of filteredItems" class="dropdown-item" (click)="addItemToQuotation(item)">
              <img [src]="item.imageUrl" alt="{{ item.partName }}" class="dropdown-image" />
              <div class="dropdown-details" style="width: 100%;">
                <h4>{{ item.name }}</h4>
                <p>Brand: {{ item.brand }}</p>
                <div class="item-price1">
                  <p>₹{{item.mrp }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="cantFind" (click)="createNewItems()" >
            <p>Can't Find Your Item?</p>
            <button mat-icon-button class="arrow-button">
              <mat-icon style="font-size: 15px;" class="arrow_forward">arrow_forward</mat-icon>
            </button>
          </div>
          
        </div>
        
        <div class="overlay" [class.visible]="newItem" (click)="createNewItems()" ></div>
        <div class="custom-sidebar" *ngIf="newItem">
          <button mat-icon-button class="back-buttonNew" (click)="createNewItems()" >
            <mat-icon>arrow_back</mat-icon>
          </button>
        
          <div class="content">
            <div class="image-container">
              <img  src="../../../../assets/images/car-repair.png" alt="car-repair">
            </div>
            
            <p class="title">Can't find what you are looking for?</p>
            <p class="subtitle" *ngIf="searchWord.length>0">Create {{searchWord}}</p>
            
          </div>
          <div class="fixedCTA">
            <button mat-button class="primary-btn" (click)="createItemSidebar()">CREATE ITEM</button>
          </div>
        </div>

        <div class="overlay" [class.visible]="newItems" (click)="createItemSidebar()" ></div>
        <mat-sidenav-container class="sidebar-containerNew" *ngIf="newItems">
          <mat-sidenav #sidenav mode="side" class="custom-sidebar" [(opened)]="newItems">
            <div class="sidebar-header">
              <mat-icon (click)="newItems = false">arrow_back</mat-icon>
              <h3>Create Item</h3>
            </div>
            <div class="sidebar-content">
              <form [formGroup]="itemForm" (ngSubmit)="onSubmit()">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Item Name *</mat-label>
                  <input matInput formControlName="itemName">
                  <mat-error *ngIf="itemForm.get('itemName')?.invalid && itemForm.get('itemName')?.touched">
                    Item Name is required
                  </mat-error>
                </mat-form-field>
              
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Part Number *</mat-label>
                  <input matInput formControlName="partNumber">
                  <mat-error *ngIf="itemForm.get('partNumber')?.invalid && itemForm.get('partNumber')?.touched">
                    Part Number is required
                  </mat-error>
                </mat-form-field>
              
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Brand *</mat-label>
                  <input matInput formControlName="brand">
                  <mat-error *ngIf="itemForm.get('brand')?.invalid && itemForm.get('brand')?.touched">
                    Brand is required
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>GST% *</mat-label>
                  <mat-select formControlName="gst">
                    <mat-option *ngFor="let gst of gstOptions" [value]="gst">
                      GST {{ gst }}%
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="itemForm.get('gst')?.invalid && itemForm.get('gst')?.touched">
                    Select GST%
                  </mat-error>
                </mat-form-field>
              
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>HSN *</mat-label>
                  <input matInput formControlName="hsn" >
                  <mat-error *ngIf="itemForm.get('hsn')?.invalid && itemForm.get('hsn')?.touched">
                    Enter a valid HSN (4-8 digits)
                  </mat-error>
                </mat-form-field>
              
            
                <mat-form-field appearance="outline" class="full-width" style="margin-bottom: 128px;">
                  <mat-label>Unit Price *</mat-label>
                  <input matInput formControlName="unitPrice">
                  <mat-error *ngIf="itemForm.get('unitPrice')?.invalid && itemForm.get('unitPrice')?.touched">
                    Enter a valid Unit Price 
                  </mat-error>
                </mat-form-field>
                <div class="fixedCTA" style="width: 29.5%;right: 18px;" >
                <button mat-flat-button color="primary" class="primary-btn" type="submit" >
                  Create Item
                </button>
              </div>
              </form>
            </div>
          </mat-sidenav>
        </mat-sidenav-container>

        <!-- Add Labour -->
        <div *ngIf="isAddLabour" class="labour-sidebar">
         <div class="labour-section">
            <div class="labour-maticon" style="width: 12%;">
              <mat-icon (click)="labourRedirectSidebar()">arrow_back</mat-icon>
            </div>
            <div class="labour-header" >
              <h2 style="font-weight:600;">Add Labour</h2>
            </div>
            <div class="labour-skip" >
              <p style="position: relative;float:right;padding:10px;cursor:pointer;color:#144579;"(click)="openSkipConfirmation()">Skip</p>
            </div>
          </div>
          <div class="search-bar">
            <input
              type="text"
              placeholder="Search Labour"
              [(ngModel)]="searchText"
              class="search-input"
              (input)="onLabourSearch()"/>
      
            <button *ngIf="searchText" class="clear-btn" (click)="clearLabourSearch()">&#x2715;</button>
          </div>
          <div class="item-list1">
          <!-- Labour List -->
          <div *ngFor="let labour of labourListData" class="dropdown-item">
            <div class="checkboxselect">
              <input 
                type="checkbox" 
                [(ngModel)]="labour.selected" 
                (change)="onLabourSelectionChange(labour)"
              />
            </div>
            <div class="dropdown-details" style="margin-left: 20px;">
              <h4>{{ labour.labourName }}</h4>
              <div class="item-price">
                <p>₹{{ labour.labourAmount }}</p>
              </div>

            </div>
            <div class="edit-btn" >
              <i class="material-icons" (click)="openLabourEditSidebar(labour)">edit</i>
            </div>
          </div>
          
            <div class="floater-labour-btn" (click)="openLabourEditSidebar()">
              <p>ADD LABOUR</p>
            </div>
        </div>
            <!-- Skip Confirmation Popup -->
            <div class="skip-confirmation" *ngIf="showSkipConfirmation">
              <!-- <button class="cancel-popup-close" (click)="closeSkipConfirmation()">×</button> -->
              <p class="skip-text">Are you sure you want to skip Adding Labour?</p>
              <button class="cancel-popup-btn no-btn" (click)="closeSkipConfirmation()">No</button>
              <button class="cancel-popup-btn confirm-btn" (click)="openPopup()">Yes</button>
              
          </div>
          <!-- labour bottom CTA -->
          <div 
              *ngIf="selectedLabourslength > 0 || labourCount > 0" 
              [ngClass]="isMobile?'bottom-boxQuoteMobile':'bottom-boxQuote'"  style="width: 30.2%;">
                <div class="bottom-contentQuote" style="display: flex;gap: 29px;">
                  <div class="total-items">{{ selectedLaboursNewCount }} items
                    <p class="total-amount">₹{{totalSelectedPriceQuote1 | number:'1.0-0' }}</p>
                  
                  </div>
                  <button type="button" class="add-labour-bottom-cta" style="height: 38px;" (click)="toggleSelectItem()">Add Labour</button>
                  
              </div>
          </div>
        </div>
        
        <div *ngIf="customerQuotationListCount==0 || customerQuotationListCount == null" (click)="AddItems()" class="add-quote-items" >
          <button class="quotation-bottom-cta">Add Quote Items</button>
        </div>

        <!-- customer quote bottom CTA -->
        <div *ngIf="anyItemSelectedQuote() || anyItemSelected() || selectedItemsCountQuote>0 " [ngClass]="isMobile ? 'bottom-boxQuoteMobile':'bottom-boxQuote'">
        <!-- <div *ngIf="anyItemSelectedQuote() && customerQuotation?.length>0 && !selectedItemsLabourQuote" [ngClass]="isMobile ? 'bottom-boxQuoteMobile':'bottom-boxQuote'"> -->
            <div class="bottom-contentQuote">
              <div class="total-items">{{selectedItemsCount}} items
                <p class="total-amount">₹{{totalSelectedPriceQuote | number:'1.0-0'}}</p>
                <!-- <p *ngIf="activeTab === 'quote-approved'" class="total-amount">₹{{quoteApprovedPrice | number:'1.2-2' }}</p> -->
              </div>
              <div class="update-quote-btn2">
              <button class="add-labour-bottom-btn1" 
                *ngIf="totalSelectedPriceQuote > 0 && (selectedLabourslength == 0 || selectedLabourslength == null || selectedLabourslength == '') && activeTab === 'quote-received'" 
                type="button" 
                (click)="addLabourSidebar()">
                Add Labour
                <span class="next-step-text">(In Next Step)</span>
              </button>
              </div>
            </div>  
        </div>
        

        <div *ngIf="(anyItemSelectedQuote() || anyItemSelected()) && customerQuotation?.length>0 && selectedItemsLabourQuote?.length>0" [ngClass]="isMobile ? 'bottom-boxQuoteMobile':'bottom-boxQuote'">
          <div class="bottom-contentQuote">
            <div class="total-items">{{selectedItemsCount}} items
              <p class="total-amount">₹{{totalSelectedPriceQuote123 | number:'1.0-0'}}</p>
              <!-- <p *ngIf="activeTab === 'quote-approved'" class="total-amount">₹{{quoteApprovedPrice | number:'1.2-2' }}</p> -->
            </div>
            <div class="update-quote-btn-create2">
            <button class="create-quote-btn"  type="button" 
              (click)="openPopup()" >Create Customer Quote</button>
            </div>
          </div> 
          <!-- <div class="update-quote-btn2">
            <button class="create-quote-btn"  type="button" 
              (click)="openPopup()">Update Customer Quote</button>
          </div>
        </div>    -->
      </div>

        <!-- Edit labour name Sidebar -->
        <div class="overlay1" [class.visible]="isEditLabourSidebarOpen" (click)="closeLabourEditSidebar()"></div>
        <div *ngIf="isEditLabourSidebarOpen" class="edit-labour-sidebar">
          <div class="labour-sidebar-header">
            <h2>{{ selectedLabour?.id ? 'Edit Labour' : 'Add Labour' }}</h2>
            <mat-icon (click)="closeLabourEditSidebar()">close</mat-icon>
          </div>
        
          <form [formGroup]="laborDetails" class="labour-details">
            <div class="labour-name-column">
              <mat-form-field appearance="outline" class="full-width" style="margin-top: 10px;width:100%">
                <mat-label>Labour Name</mat-label>
                <input
                  matInput
                  formControlName="labourName"
                  [(ngModel)]="selectedLabour.labourName"
                  placeholder="Enter Labour Name"
                  name="labourName"
                />
              </mat-form-field>
            </div>
        
            <div class="labour-name-column" >
              <mat-form-field appearance="outline" class="full-width" style="margin-top: 10px;width:100%">
                <mat-label>Labour Amount</mat-label>
                <input
                  matInput
                  formControlName="labourAmount"
                  [(ngModel)]="selectedLabour.labourAmount"
                  placeholder="Enter Labour Amount"
                  name="labourPrice"
                />
              </mat-form-field>
            </div>
          </form>
        
          <div class="item-update" style="margin-top: -14px;">
            <button class="item-update-btn" *ngIf="selectedLabour?.id" (click)="saveLabour()">Save Labour</button>
            <button class="item-update-btn" *ngIf="!selectedLabour?.id" (click)="addLabour()">Add Labour</button>
          </div>
        </div>

        <!-- Popup for skip labour -->
          <!-- <div class="cancel-popup-container" *ngIf="showCancelConfirmation">
            <div class="cancel-popup">
              <button class="cancel-popup-close" (click)="closeCancelPopup()">×</button>
              <p>Are you sure you want to skip?</p>
              <button class="cancel-popup-btn confirm-btn" (click)="confirmAction()">Yes</button>
              <button class="cancel-popup-btn no-btn" (click)="closeCancelPopup()">No</button>
            </div>
          </div> -->
    </mat-list>
  
  </div>


</mat-sidenav-container>

<!-- Popup content -->
<div *ngIf="isPopupOpen" class="popup">
  <div class="popup-content">
    <img src="{{successIcon}}" alt="successIcon" style="width: 36%;">
    <h3 *ngIf="quoteId === undefined || quoteId === 'undefined' ">Quote Created</h3>
    <h3 *ngIf="quoteId !== undefined && quoteId !== 'undefined'">Quote Updated</h3>
    <p class="order-label" *ngIf="quoteId === undefined || quoteId === 'undefined'">
      <span class="order-id">Quotation id 
        <span [ngStyle]="{ 'color': '#144579', 'font-weight': 'bold' }">{{ resData?.id }}</span>
      </span>
    </p>
    
    <p class="order-label" *ngIf="quoteId !== undefined && quoteId !== 'undefined'">
      <span class="order-id">Quotation id 
        <span [ngStyle]="{ 'color': '#144579', 'font-weight': 'bold' }">{{ quoteId }}</span>
      </span>
    </p>
    
    <p class="date"><span>{{resData?.placedOn | date:'dd-MMM-yyyy, hh:mm a'}}</span></p>
    <p class="thank you">You have successfully created quotation</p>

    <button class="goto-order" *ngIf="quoteId === undefined || quoteId === 'undefined'" (click)="closeViewDetails()">View Customer Quote</button>
    <button class="goto-order" *ngIf="quoteId !== undefined && quoteId !== 'undefined'" (click)="closeViewDetails()">View Customer Quote</button>
  </div>
</div>