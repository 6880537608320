import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApplicationPipesModule } from 'app/main/applicationPipesModule.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AspRequestDetailsComponent } from './asp-request-details.component';
import {MatListModule} from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { AspCheckinReportsModule } from '../asp-checkin-reports/asp-checkin-reports.module'

const routes: Routes = [
  {
      path: 'request-details',
      component: AspRequestDetailsComponent,
  },
];



@NgModule({
  declarations: [AspRequestDetailsComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    CommonModule,
    MatIconModule,
    MatExpansionModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    ApplicationPipesModule,
    MatListModule,
    MatDialogModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    // AspCheckinReportsModule,
    MatBottomSheetModule,
    MatDividerModule,
    MatCardModule,
    HttpClientModule,
    ReactiveFormsModule
    // MatBottomSheet
  ],

  exports: [AspRequestDetailsComponent],
})
export class AspRequestDetailsModule { }












