import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AspPaymentlistComponent } from './asp-paymentlist.component';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

const routes: Routes = [
  {
      path: 'my-payments',
      component: AspPaymentlistComponent,
      
  },
];


@NgModule({
  declarations: [AspPaymentlistComponent],
  imports: [
    RouterModule.forChild(routes),
    MatIconModule,
    CommonModule,BrowserModule,FormsModule,MatMenuModule,MatFormFieldModule,BrowserAnimationsModule,
        MatFormFieldModule,
        MatInputModule,
            CommonModule,
            MatBottomSheetModule,
            MatButtonModule,
            MatIconModule,
            // MatListModule,
            MatDialogModule,
            ReactiveFormsModule,
    
            
  ],
  exports:[AspPaymentlistComponent,RouterModule]
})
export class AspPaymentlistModule { }







// @NgModule({
//   declarations: [AspBookingComponent],
//   imports: [
//     RouterModule.forChild(routes),
//     CommonModule,BrowserModule
//   ],
//   bootstrap: [AspBookingComponent],
//   exports:[AspBookingComponent,RouterModule]
// })