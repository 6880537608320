import { Component, Input, OnInit } from '@angular/core';
import { AspRecordPaymentService } from './asp-record-payment.service';
import { S3ImageUploadService } from 'app/shared/s3-image-upload.service';
import { HttpClient } from '@angular/common/http';
import { JobStatus } from 'app/main/globals';
import { ChangeDetectorRef } from '@angular/core';



@Component({
  selector: 'app-asp-record-payment',
  templateUrl: './asp-record-payment.component.html',
  styleUrls: ['./asp-record-payment.component.scss']
})
export class AspRecordPaymentComponent implements OnInit {
  paymentDate: string = ''; 
  paymentTypes: string[] = [];
  sidebarOpen: boolean = false; 
  selectedPaymentType: string = ''; 
  notePlaceholder1: string = ''; 
  notePlaceholder2: string = ''; 
  note1: string = ''; 
  note2: string = ''; 
  showCardDropdown: boolean = false;
  selectedCardType: string = ''; 
  cardTypes: string[] = []; 
  banks: string[] = [];
  selectedBank: string = ''; 
  bankSidebarOpen: boolean = false; 
  filteredBanks: string[] = [];
  searchText: string = '';
  isPopupVisible: boolean = false;
  paymentId: string = '';
  amountPaid: any;
  paymentType: string = '';
  successIcon: any = '../../../../assets/icons/icons8-ok.gif';
  isReceiptSidebarOpen: boolean = false;
  paymentMethods: any[] = [];
  garageKey: string | null = localStorage.getItem('garageKey');
  images: string[] = [];
  uploadedImages: string[] = [];
  showError = false;
  receiptNumber: string = '';
// amount: number = 0;
// paymentStatus: string = '';
transactionDate: string = '';
originalTotalAmount: number = 0;
// paymentDate: any;


  // ==============view receipt===================
  garageName!: string;
  garageMobile!: string;
  garageEmail!: string;
  paymentNumber!: string;
  invoiceNumber!: string;
  paymentDatereceipt!: string;
  status!: string;
  paymentMode!: string;
  amountreceipt!: number;
  customerName!: string;
  customerPhone!: string;
  customerEmail!: string;
  invoiceId!: any;
  bankName!: any;
  cardType!: any;
  totalAmount!: any;
  amountPending!: any;
  paymentStatus!: any;
  transactionId: string;
  cashReceiptNumber: string;
  chequeNumber: string;
  approvalNumber: string;
  otherNotes!: string;
  imageLink!: string;
  // paymentpopupId: any;
  resIds: any;
  data: any;
  totalAmounts: number = 0;
  garagePhoneNumber: any;
@Input() paymentpopupId: string;
@Input() jobcardId: string;
  name: any;
  phoneNumber: any;
  email: any;
  customerPhoneNumber: any;
  paymentReference: any;
  amountDue: any;


  constructor(private aspRecordPaymentService: AspRecordPaymentService,
    private s3ImageUploadService: S3ImageUploadService,
    private http: HttpClient,
    private cdRef: ChangeDetectorRef

  ) { }

  ngOnInit(): void {
    console.log("jobcardId123",this.jobcardId)
    console.log("this.paymentpopupId",this.paymentpopupId)
    this.setDefaultDate();
    this.fetchPaymentMethods();
    // this.recordPayment();
    this.fetchReceiptDetail(this.paymentpopupId,this.jobcardId);
    this.originalTotalAmount = this.totalAmounts;
    this.selectedPaymentType = "Cash"; // Set default selection

  }

  setDefaultDate() {
    let today = new Date();
    let day = today.getDate().toString().padStart(2, '0'); 
    let month = (today.getMonth() + 1).toString().padStart(2, '0'); 
    let year = today.getFullYear();
    this.paymentDate = `${year}-${month}-${day}`; 
  }
  
  invoiceresponse(result) {
    this.data = result;
    console.log("invoiceres", this.data);
    this.jobcardId = this.data.jobcardId;
    this.resIds = this.data.invoiceId;
    this.garageEmail = this.data.garageEmail;
    this.garagePhoneNumber = this.data.garagePhoneNumber;
    // Extract customer details
    const customerDetails = this.data.bookingJobCardResponse;
    this.customerName = customerDetails.customerName
    .toLowerCase()
    .replace(/\b\w/g, (char) => char.toUpperCase());
    this.customerPhone = customerDetails.customerPhoneNumber;
    this.customerEmail = customerDetails.customerEmail;
    // Calculate total amount
    this.totalAmounts = this.data.invoiceResponseList.reduce((sum, item) => sum + Number(item.itemTotal), 0);
}

  selectPaymentType(type: string) {
    this.selectedPaymentType = type;
    this.transactionId = '';
    this.cashReceiptNumber = '';
    this.chequeNumber = '';
    this.selectedCardType = '';
    this.selectedBank = '';
    this.approvalNumber = '';
  
    if (type === "Card") {
      this.showCardDropdown = true;
    } else {
      this.showCardDropdown = false;
    }
  }
  

  // Function to open sidebar
  openSidebar() {
    this.sidebarOpen = true;
  }

  // Function to close sidebar
  closeSidebar() {
    this.sidebarOpen = false;
  }
  openBankSidebar() {
    this.bankSidebarOpen = true;
    this.filteredBanks = this.banks;
  }
  
  // Close the Bank Name sideslider
  closeBankSidebar() {
    this.bankSidebarOpen = false;
  }
  selectBank(bank: string) {
    this.selectedBank = bank;
    this.note2 = bank; 
    this.closeBankSidebar();
  }
  filterBanks() {
    if (!this.searchText || this.searchText.length < 3) {
      this.filteredBanks = this.banks; 
    } else {
      const query = this.searchText.toLowerCase();
      this.filteredBanks = this.banks.filter(bank => bank.toLowerCase().includes(query));
    }
  }
  clearSearch() {
    this.searchText = '';
    this.filteredBanks = this.banks;
  }
  openPopup() {
    this.isPopupVisible = true;
    this.sidebarOpen = false;
    this.recordPayment();
  }

  closePopup() {
    this.isPopupVisible = false;
  }

  openReceiptSidebar() {
    this.isReceiptSidebarOpen = true;
    this.isPopupVisible = false;
    this.sidebarOpen = false;
    this.fetchReceiptDetail(this.paymentpopupId,this.jobcardId);
  }


  closeReceiptSidebar() {
    this.isReceiptSidebarOpen = false;
  }

  async downloadReceipt() {
    // Import html2pdf dynamically
    const html2pdf = await import('html2pdf.js');
    
    // Reference the container element
    const element = document.getElementById('receiptContent');
    
    if (!element) {
      console.error('Element not found!');
      return;
    }

    // Options for PDF generation
    const options = {
      margin: 1,
      filename: 'paymentReceipt.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };


    html2pdf.default().from(element).set(options).save();
  }
  
  fetchPaymentMethods(): void {
    this.aspRecordPaymentService.getPaymentMethods().subscribe(
      (res: any) => {
        console.log('Payment Methods:', res);
  
        if (res?.data?.paymentTypes) {
          this.paymentTypes = res.data.paymentTypes.map((type: any) => type.paymentType);
        } else {
          console.error('Payment types not found in API response');
        }
  
        if (res?.data?.cardTypes) {
          this.cardTypes = res.data.cardTypes.map((card: any) => card.cardType);
        } else {
          console.log('Updated Card Types:', this.cardTypes);   
        }
  
        if (res?.data?.bankList) {
          this.banks = res.data.bankList.map((bank: any) => bank.bankName);
          this.filteredBanks = [...this.banks]; 
        } else {
          console.error('Bank list not found in API response');
        }
  
        // Call recordPayment only after all data is retrieved
        this.recordPayment();
      },
      (error) => {
        console.error('Error fetching payment methods:', error);
      }
    );
  }
  

recordPayment(): void {
  let note1Value = '';
  let note2Value = '';
  let bankNameValue = '';

  if (this.selectedPaymentType === 'Net Banking' || this.selectedPaymentType === 'Cheque') {
    bankNameValue = this.selectedBank || ''; 
    note1Value = this.transactionId || this.chequeNumber || ''; 
    note2Value = this.approvalNumber || '';  
  } else if (this.selectedPaymentType === 'UPI') {
    note1Value = this.transactionId || ''; 
  } else if (this.selectedPaymentType === 'Cash') {
    note1Value = this.cashReceiptNumber || ''; 
  } else if (this.selectedPaymentType === 'EMI') {
    note1Value = this.transactionId || '';  
    note2Value = this.approvalNumber || ''; 
  } else if (this.selectedPaymentType === 'Card') {
    note1Value = this.transactionId || ''; 
  }

  if (!this.garageKey || !this.resIds || !this.selectedPaymentType || !this.originalTotalAmount || !this.amountPaid || !this.paymentDate) {
    console.error('Missing required fields:', {
      garageKey: this.garageKey,
      invoiceId: this.resIds,
      paymentType: this.selectedPaymentType,
      totalAmount: this.originalTotalAmount,
      amountPaid: this.amountPaid,
      paymentDate: this.paymentDate,
      amountDue: this.amountDue,

    });
    return;
  }
  const paymentData = {
    garageKey: this.garageKey || '',
    invoiceId: this.resIds,
    bankName: bankNameValue,  
    paymentType: this.selectedPaymentType || '',  
    cardType: this.selectedPaymentType === 'Card' ? this.selectedCardType : '',
    totalAmount: this.originalTotalAmount || 0,
    // this.totalAmount || 0, 
    amountPaid: this.amountPaid ||  this.totalAmounts,
    amountDue: this.amountDue,
    // amountPending: this.amountPending || 0,
    paymentDate: this.paymentDate ? new Date(this.paymentDate).toISOString().split('T')[0] : '',
    note1: note1Value, 
    note2: note2Value,   
    otherNotes: this.otherNotes || '',
    imageLink: this.uploadedImages.length > 0 ? this.uploadedImages[0] : ''  
  };

  console.log('Original Total Amount:', this.originalTotalAmount);
console.log('Amount Paid:', this.amountPaid);

  // Debugging logs to verify values before saving
  console.log('Payment Data before saving:', paymentData);

  this.aspRecordPaymentService.recordPayment(paymentData).subscribe(
    response => {
      console.log('Payment recorded successfully:', response);
      this.paymentpopupId=response.data.paymentId;
    },
    error => {
      console.error('Error recording payment:', error);
    }
  );
}

onImageUpload(event: any) {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.images.push(e.target.result); 
    };
    reader.readAsDataURL(file);

    // Upload image to S3
    this.s3ImageUploadService.getPresignedUrl(file.name).subscribe({
      next: (response) => {
        const presignedUrl = response[0]?.signedUrl;
        const link = response[0]?.link;

        if (presignedUrl && link) {
          this.uploadFileToS3(file, presignedUrl, link);
        } else {
          console.error('Invalid presigned URL or link:', response);
        }
      },
      error: (err) => {
        console.error('Error fetching presigned URL:', err);
      }
    });
  }
}

uploadFileToS3(file: File, presignedUrl: string, fileUrl: string): void {
  const contentType = file.type;

  this.http.put(presignedUrl, file, { headers: { 'Content-Type': contentType } }).subscribe({
    next: () => {
      console.log('File uploaded successfully:', fileUrl);
      
      this.uploadedImages.push(fileUrl);  
      
      console.log('Uploaded Images:', this.uploadedImages);
    },
    error: (err) => {
      console.error('Error uploading file:', err);
    }
  });
}

deleteImage(index: number) {
  this.images.splice(index, 1);
}
validateAndOpenPopup(form: any) {
  if (form.invalid) {
    form.controls.amountPaid.markAsTouched();  
  } else {
    console.log("Amount to be recorded:", this.totalAmounts);  // Debugging step
    this.openPopup();
  }
}

fetchReceiptDetail(paymentId: any, jobcardId: any): void {
  console.log("Fetching Receipt Details:", paymentId, jobcardId);
  
  this.aspRecordPaymentService.getReceiptDetail(paymentId, jobcardId).subscribe(
    (res: any) => {
      console.log('Receipt Detail:', res);

      if (!res?.data) {
        console.error('No data found in API response');
        return;
      }

      // Assign values from API response
      // this.invoiceId = res.data.invoice || 'N/A'; 
      this.resIds = res.data.invoice|| 'N/A'; 
      this.amountPaid = res.data.amountPaid || 0;
      this.totalAmounts = res.data.amountPaid || 0;  // ✅ Assigning totalAmounts
      this.amountDue = res.data.amountDue || 0;
      this.customerName = res.data.customerName || 'Unknown';
      this.customerPhoneNumber = res.data.customerPhoneNumber || 'N/A';
      this.customerEmail = res.data.customerEmail || 'N/A';

      // this.garageName = res.data.garageName || 'Unknown';
      this.garagePhoneNumber = res.data.garagePhoneNumber || 'N/A';
      this.garageEmail = res.data.garageEmail || 'N/A';

      this.paymentMode = res.data.mode || 'N/A';
      this.paymentReference = res.data.payment || 'N/A';
      this.paymentDate = res.data.paymentDate || 'N/A';

      // Determine payment status
      if (res.data.paymentStatus == 1) {
        this.paymentStatus = 'Paid';
      } else if (res.data.paymentStatus == 2) {
        this.paymentStatus = 'Partially Paid';
      } else {
        this.paymentStatus = 'Unknown Status';
      }
    },
    (error) => {
      console.error('Error fetching receipt details:', error);
    }
  );
}
restrictIncrease() {
  if (this.amountPaid > this.totalAmounts) {
    this.amountPaid = this.totalAmounts; 
  }
}
updateAmountPaid() {
  this.amountPaid = this.totalAmounts; // Store edited value in amountPaid
  console.log('Updated Amount Paid:', this.amountPaid);
  this.recordPayment(); // Ensure payment is recorded after updating the amount
}

}
