import { Component,ElementRef, OnInit, ViewChild } from '@angular/core';
import { S3ImageUploadService } from 'app/shared/s3-image-upload.service';
import { HttpClient } from '@angular/common/http';
import { AspFeedbackService } from './asp-feedback.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FuseConfigService } from '@fuse/services/config.service';


@Component({
  selector: 'app-asp-feedback',
  templateUrl: './asp-feedback.component.html',
  styleUrls: ['./asp-feedback.component.scss']
})
export class AspFeedbackComponent implements OnInit {
  @ViewChild('bottomSection') bottomSection!: ElementRef;
  @ViewChild('submitButton') submitButton!: ElementRef; // Add this line


  selectedStars: number = 0; 
  selectedTags: string[] = [];
  images: string[] = [];
  selectedGarageName: string = localStorage.getItem('spareSelectedGarageName') || 'Garage';
  garageIconUrl: string = '../../assets/images/logos/pitstop-white.png';
  isPopupVisible = false;
  uploadedImages: string[] = [];
  status: string;
  id: string;
  feedbackReceived: boolean = false;



  feedbackTags1 = [
    "Inexperienced Technician",
    "Delay in Service",
    "Unreasonable Pricing",
    "No Timely Update",
    "Spares Not Genuine"
  ];

  feedbackTags2 = [
    "Experienced Technicians",
    "Timely Delivery",
    "Reasonable Pricing",
    "Timely Update",
    "Genuine Parts"
  ];
  additionalFeedback: string;

  constructor(
    private s3ImageUploadService: S3ImageUploadService,
    private http: HttpClient,
    private aspFeedbackService: AspFeedbackService,
    private route: ActivatedRoute,
    private location: Location,
    private _fuseConfigService: FuseConfigService,
    ) 
    { 
  //     this.route.params.subscribe(params => {
  //   this.status = params['status'];
  //   this.id = params['id'];
  // });
}

  ngOnInit(): void {
    this._fuseConfigService.config = {
      layout: {
        navbar: { hidden: true },
        toolbar: { hidden: true },
        footer: { hidden: true },
        sidepanel: { hidden: true }
      }
    };
    this.route.paramMap.subscribe(params => {
      this.status = params.get('status');
      this.id = params.get('id');
    });
    this.getFeedbackStatus();
    window.addEventListener('resize', this.adjustButtonPosition);

  }
  adjustButtonPosition = () => {
    const bottomSection = this.bottomSection.nativeElement as HTMLElement;
    if (window.innerHeight < 600) {  // Adjust based on mobile screen size
      bottomSection.style.position = 'absolute';
      bottomSection.style.bottom = '10px';
    } else {
      bottomSection.style.position = 'fixed';
      bottomSection.style.bottom = '0';
    }
  };
  ngOnDestroy(): void {
    window.removeEventListener('resize', this.adjustButtonPosition);
  }

  // ngAfterViewChecked(): void {
  //   this.scrollToSubmitButton();
  // }
  rate(stars: number) {
    this.selectedStars = stars;
  }

  // Function to get tags based on selected star rating
  getTagsForRating(stars: number) {
    if (stars === 1 || stars === 2 || stars === 3) {
      return this.feedbackTags1;
    } else if (stars === 4 || stars === 5) {
      return this.feedbackTags2;
    }
    return []; // Return an empty array if no star is selected
  }

  scrollToBottom() {
    this.bottomSection.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

toggleTag(tag: string) {
  if (this.selectedTags.includes(tag)) {
      this.selectedTags = this.selectedTags.filter(t => t !== tag);
  } else {
      this.selectedTags.push(tag);
  }
  console.log("Selected Tags:", this.selectedTags);
  this.scrollToSubmitButton();
  this.scrollToAdditionalFeedback();  // Scroll to feedback section

}

scrollToSubmitButton() {
  // window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  this.bottomSection.nativeElement.scrollIntoView({ behavior: "smooth" });

 
}

scrollToAdditionalFeedback() {
  setTimeout(() => {
    const additionalFeedbackSection = document.querySelector('.additional-feedback') as HTMLElement;
    if (additionalFeedbackSection) {
      additionalFeedbackSection.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, 100);
}


submitFeedback() {
  const garageId = 'garageId'; 
  const userMobile = '9876543210'; 
  const rating = this.selectedStars;
  const comments = this.additionalFeedback || '';
  const images = this.uploadedImages || [];
  const jobCardId = this.id;  
  const jobCardStatus = this.status; 
  
  console.log("Submitting Feedback:", {
    jobCardId,
    jobCardStatus,
    garageId,
    userMobile,
    rating,
    comments,
    images,
    tags: this.selectedTags
  });

  this.aspFeedbackService.submitGarageFeedback( userMobile, rating,   comments,  images,  jobCardStatus,  jobCardId,this.selectedTags ).subscribe({
    next: (response) => {
      console.log('Feedback submitted successfully:', response);
    },
    error: (err) => {
      console.error('Error submitting feedback:', err);
    }
  });
}


  onImageUpload(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.images.push(e.target.result); // Add uploaded image to the array
      };
      reader.readAsDataURL(file);
      // Get the pre-signed URL for S3 upload
    this.s3ImageUploadService.getPresignedUrl(file.name).subscribe({
      next: (response) => {
        const presignedUrl = response[0]?.signedUrl;
        const link = response[0]?.link;

        if (presignedUrl && link) {
          console.log('Presigned URL:', presignedUrl);
          console.log('File link:', link);
          
          // Upload file to S3
          this.uploadFileToS3(file, presignedUrl, link);
        } else {
          console.error('Invalid presigned URL or link:', response);
        }
      },
      error: (err) => {
        console.error('Error fetching presigned URL:', err);
      }
    });
    }
  }

  uploadFileToS3(file: File, presignedUrl: string, fileUrl: string): void {
    const contentType = file.type;
  
    this.http.put(presignedUrl, file, {
      headers: { 'Content-Type': contentType },
    }).subscribe({
      next: () => {
        console.log('File uploaded successfully:', fileUrl);
        
        if (this.uploadedImages) {
          this.uploadedImages.push(fileUrl); // Push the image URL
        } else {
          console.error('uploadedImages is undefined');
        }
        console.log('Uploaded Images:', this.uploadedImages);

  
        // Proceed with submitting feedback
        const mobileNumber = localStorage.getItem("userMobile") || '';
        // const garageId = '12345'; // Replace with dynamic value if needed
        const rating = this.selectedStars;
        const comments = this.additionalFeedback || '';

        const images = this.uploadedImages;
  
        this.aspFeedbackService.submitGarageFeedback(mobileNumber, rating, comments, images, this.status,this.id,this.selectedTags).subscribe({
          next: (response) => {
            console.log('Feedback submitted successfully:', response);
          },
          error: (err) => {
            console.error('Error submitting feedback:', err);
          }
        });
      },
      error: (err) => {
        console.error('Error uploading file:', err);
      }
    });
  }
  
  

  deleteImage(index: number) {
    this.images.splice(index, 1);
  }
  goBackToRating() {
    this.selectedStars = 0; 
  }
  popUP() {
    this.isPopupVisible = true;
    this.submitFeedback();
  }
  closePopUp(){
    this.isPopupVisible = false;
    this.goBackToRating();
    this.ngOnInit();
    // window.location.href = "whatsapp://";
  }
  submitRating(star: number) {
    this.selectedStars = star;
    this.feedbackReceived = true; 
}
getFeedbackStatus(): void {
  console.log("jobcardid", this.id);
  this.aspFeedbackService.checkFeedbackExists(this.id).subscribe(
    (response) => {
      console.log('Feedback exists response:', response);

      // Check if response has data and isFeedbackExists is "true"
      if (response?.data?.isFeedbackExists === "true") {
        this.feedbackReceived = true;
      } else {
        this.feedbackReceived = false;
      }
    },
    (error) => {
      console.error('Error checking feedback:', error);
    }
  );
}

}
