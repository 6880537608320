import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [];
    if (localStorage.getItem('roleType') == "autoverse_employee") {
        navigation.push({
            id: "catalogue",
            title: "Catalogue",
            translate: "NAV.CAT.TITLE",
            type: "item",
            icon: "book",
            url: "/catalogue",
        });
    }
    
    // Add other items to the navigation array
    navigation.push(
        // Auto Spare Parts
        {
            id: "auto-spare-parts",
            title: "Buy Parts",
            translate: "NAV.ASP.TITLE",
            type: "item",
            icon: "store",
            url: "/login",
        },
        
        
        {
            id: "auto-spare-parts",
            title: "My Requests",
            translate: "NAV.MYREQUEST.TITLE",
            type: "item",
            icon: "note",
            url: "/my-request",
        },
        {
            id: "auto-spare-parts",
            title: "My Orders",
            translate: "NAV.ORDERLIST.TITLE",
            type: "item",
            icon: "list",
            url: "/order-list",
        },
        {
            id: "auto-spare-parts",
            title: "My Reports",
            translate: "NAV.MYREPORTS.TITLE",
            type: "item",
            icon: "insert_chart_outlined",
            url: "/my-reports",
        },

        {
            id: "auto-spare-parts",
            title: "Manage My Garage",
            translate: "Manage My Garage",
            icon: "build",
            type: "collapsable",
            children: [
                {
                    id: "manage-my-garage",
                    title: "My Bookings",
                    translate: "NAV.MYBOOKINGS.TITLE",
                    type: "item",
                    icon: "library_books",
                    url: "/my-bookings",
                },
                {
                    id: "auto-spare-parts",
                    title: "My Jobcards",
                    translate: "NAV.MYJOBCARDS.TITLE",
                    type: "item",
                    icon: "work_outline",
                    url: "/my-job-cards",
                },
                {
                    id: "auto-spare-parts",
                    title: "My Customer Quotations",
                    translate: "NAV.QUOTE.TITLE",
                    type: "item",
                    icon: "monetization_on",
                    url: "/quote",
                },
                {
                    id: "auto-spare-parts",
                    title: "My Customer Invoice",
                    translate: "NAV.INVOICE.TITLE",
                    type: "item",
                    icon: "import_contacts",
                    url: "/invoice-list",
                },
                {
                    id: "auto-spare-parts",
                    title: "My Customer Payments Link",
                    translate: "NAV.PAYMENT-LINK.TITLE",
                    type: "item",
                    icon: "edit_attributes",
                    url: "/my-payments",
                },
                {
                    id: "auto-spare-parts",
                    title: "My Customer Payments List",
                    translate: "NAV.PAYMENT.TITLE",
                    type: "item",
                    icon: "payment",
                    url: "/customer-payments",
                },
                {
                    id: "auto-spare-parts",
                    title: "My Employees",
                    translate: "NAV.EMPLOYEES.TITLE",
                    type: "item",
                    icon: "group_add",
                    url: "/my-employee",
                },
                {
                    id: "auto-spare-parts",
                    title: "My Documents",
                    translate: "NAV.DOCUMENTS.TITLE",
                    type: "item",
                    icon: "list",
                    url: "/my-documents",
                }
            ]
        },

        
        {
            id: "auto-spare-parts",
            title: "Join Autoverse",
            translate: "NAV.JOIN.TITLE",
            type: "item",
            icon: "group_work",
            url: "/join",
        }
    );